import React from "react";
import Button from '@material-ui/core/Button';

export default function DeleteConfirmation(props) {
  return (
    <div className="card confirmation">
      <p className="lead">
        Are You Sure You Want To <b>Delete</b> Equipment: {props.equipment}?
      </p>
      <div className="confirmationControls">
        <Button variant="contained" color="inherit" onClick={() => { props.answer(true); }}>
          Yes
        </Button>
        <Button variant="contained" color="inherit" onClick={() => { props.answer(false); }}>
          No
        </Button>
        <Button variant="contained" color="inherit" onClick={() => { props.answer(false); }}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
