import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";

class AddRequest extends Component {
  constructor() {
    super();
    this.state = {
      photo: undefined,
      photoName: "",
      description: "",
      message: "",
      loading: false,
    };
  }
  onFileSelect = (event) => {
    let name = event.target.files[0].name;
    let photo = event.target.files[0];
    this.setState({
      photo: event.target.files[0],
      photoName: name,
    });
  };
  onChange = (event) => {
    switch (event.target.name) {
      case "description":
        this.setState({ description: event.target.value });
        break;
      default:
        break;
    }
  };
  saveRequest = () => {
    this.setState({ loading: true });
    let time = new Date().getTime();
    let id = this.props.equipment.name + "-" + time;
    let request = {
      equipment: this.props.equipment,
      description: this.state.description,
      time: time,
      id: id,
      employee: this.props.firebase.auth.currentUser.displayName,
    };
    if (this.state.photo !== undefined) {
      console.log("prepping photo upload");
      const storagePath =
        this.props.equipment.name +
        "/" +
        request.time +
        "/" +
        request.employee +
        "/" +
        this.state.photoName;
      const storageRef = this.props.firebase.storage.ref();
      let uploadTask = storageRef.child(storagePath).put(this.state.photo);
      uploadTask.on(
        "state_changed",
        function(snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState((prevState) => {
            return {
              uploadProgress: progress + "%",
              message: "Upload is " + progress.toFixed(2) + "% done",
              valid: false,
            };
          });
        }.bind(this),
        function(error) {
          // Handle unsuccessful uploads
          console.log(error);
          this.setState((prevState) => {
            return {
              error: error.message,
              message: "Error Uploading Photo: " + error.message,
            };
          });
        }.bind(this),
        function() {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(
            function(downloadURL) {
              let photos = [downloadURL];
              request.photos = photos;
              this.props.firebase.db
                .collection("maintenance")
                .doc(request.id)
                .set(request)
                .then(
                  function() {
                    this.props.submit(request);
                  }.bind(this)
                );
            }.bind(this)
          );
        }.bind(this)
      );
    } else {
      this.props.firebase.db
        .collection("maintenance")
        .doc(request.id)
        .set(request)
        .then(
          function() {
            this.props.submit(request);
          }.bind(this)
        );
    }
  };
  render() {
    return (
      <div className="card addExpenseReport">
        Add Maintenance Request For: {this.props.equipment.name}
        <button
          className="btn btn-secondary btn-sm exit"
          onClick={this.props.cancel}
        >
          CLOSE
        </button>
        {this.state.message !== "" ? (
          <p className="alert alert-danger">{this.state.message}</p>
        ) : (
          ""
        )}
        <div className="form-group row">
          <label className="col-sm-3 col-form-label" htmlFor="customFile">
            Image Of Issue:
          </label>
          <div className="custom-file">
            <input
              type="file"
              className="custom-file-input lowInput"
              accept="image/*"
              onChange={this.onFileSelect}
              id="customFile"
            />
            <label className="custom-file-label" htmlFor="customFile">
              {this.state.photoName}
            </label>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="description">
              Description of issue:
            </label>
            <textarea
              className="form-control col-sm-9"
              id="description"
              onChange={this.onChange}
              placeholder="Description"
              name="description"
            />
          </div>
          <div className="form-group row">
            <button
              onClick={this.saveRequest}
              disabled={
                this.state.description == "" || this.state.loading
                  ? true
                  : false
              }
              className="btn btn-md btn-secondary"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose(withFirebase)(AddRequest);
