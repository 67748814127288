import React, { Component } from "react";
import { withFirebase } from "../../../firebase/index";
import { compose } from "redux";

class GetImage extends Component {
  constructor() {
    super();
    this.state = {
      selectedImage: undefined,
    };
    this.onFileSelect = this.onFileSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitWSMPhoto = this.submitWSMPhoto.bind(this);
  }

  onChange(event) {
    let caption = event.target.value;
    let images = [];
    if (this.state.photos.length > 0) {
      images = [...this.state.photos];
    } else {
      images = [];
    }

    let newImages = [];
    images.forEach((img, i) => {
      if (img.name == event.target.name) {
        img.caption = event.target.value;
      }
      newImages.push(img);
    });
    this.setState((prevState) => {
      return { photos: newImages };
    });
  }
  submitWSMPhoto(e) {
    e.preventDefault();

    this.setState({ valid: false });
    let photos = [];
    this.state.photos.forEach((img, i) => {
      let caption;
      if (img.caption == undefined || img.caption == "") {
        caption = "";
      } else {
        caption = img.caption;
      }
      let jobPhoto = {
        wsmID: this.props.wsmID,
        employee: this.props.firebase.auth.currentUser.displayName,
        photo: img.photo,
        photoName: img.name,
        caption: caption,
        type: this.props.type,
      };
      photos.push(jobPhoto);
    });

    this.props.submit(photos);
  }
  onFileSelect(event) {
    let name = event.target.files[0].name;
    let photo = event.target.files[0];
    let photos = [...event.target.files];
    let statePhotos = [];
    if (this.state.photos == undefined || this.state.photos.length < 1) {
      statePhotos = [];
    } else {
      statePhotos = [...this.state.photos];
    }

    photos.forEach((file, i) => {
      let photo = {
        photo: event.target.files[i],
        url: URL.createObjectURL(event.target.files[i]),
        name: event.target.files[i].name,
      };

      statePhotos.push(photo);
    });
    this.setState({
      photos: statePhotos,
      valid: true,
    });
  }
  selectImage = (event) => {
    let images = this.state.photos;
    let newImages = [];
    let selectedImage = undefined;
    images.forEach((img, i) => {
      if (img.name == event.target.name) {
        if (img.selected == true) {
          img.selected = false;
          selectedImage = undefined;
        } else {
          img.selected = true;
          selectedImage = img;
        }
        newImages.push(img);
      } else {
        img.selected = false;
        newImages.push(img);
      }
    });
    this.setState({ photos: newImages, selectedImage: selectedImage });
  };
  removeSelectedImage = () => {
    let images = [...this.state.photos];
    let newImages = [];
    images.forEach((img, i) => {
      if (img.selected) {
        //don't add
      } else {
        newImages.push(img);
      }
    });
    this.setState({ photos: newImages, selectedImage: undefined });
  };
  render() {
    let submit, title;
    if (this.state.valid) {
      submit = (
        <input
          type="submit"
          className="btn btn-secondary addJobButton"
          onClick={this.submitWSMPhoto}
          style={{ marginTop: "1em" }}
        />
      );
    } else {
      submit = (
        <input
          type="submit"
          className="btn btn-secondary addJobButton"
          disabled
          style={{ marginTop: "1em" }}
        />
      );
    }
    switch (this.props.imageType) {
      case "start":
        title = "Job Start Photos";
        break;
      case "finish":
        title = "Job Finish Photos";
        break;
      case "other":
        title = "Other Job Photos";
        break;
      default:
        break;
    }
    return (
      <div className="getImage">
        {title}

        <form>
          <div onClick={this.props.close} className="exit">
            <p className="lead">Exit</p>
          </div>
          <div className="form-group formRow row">
            {this.state.photos !== undefined
              ? this.state.photos.map((img, i) => (
                  <div
                    className={
                      img.selected ? "col col-md-3 selected" : "col col-md-3"
                    }
                    key={i}
                  >
                    <img
                      name={img.name}
                      onClick={this.selectImage}
                      className="addImage"
                      src={img.url}
                    />
                    <label className="row col-form-label" htmlFor="jobLocation">
                      Image Caption
                    </label>
                    <input
                      className="form-control row"
                      type="text"
                      id="imageCaption"
                      onChange={this.onChange}
                      placeholder="Caption"
                      value={img.caption}
                      name={img.name}
                    />
                  </div>
                ))
              : ""}{" "}
          </div>
          {this.state.selectedImage !== undefined ? (
            <a
              className="btn btn-secondary btn-sm"
              onClick={() => {
                this.removeSelectedImage();
              }}
            >
              Remove Selected Image
            </a>
          ) : (
            ""
          )}
          <div className="form-group formRow">
            <label className="row col-form-label" htmlFor="customFile">
              Select Images:
            </label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input lowInput"
                accept="image/*"
                multiple="multiple"
                onChange={this.onFileSelect}
                id="customFile"
              />
              <label className="custom-file-label" htmlFor="customFile">
                {this.state.photoName}
              </label>
            </div>
          </div>
          <div className="form-group row">{submit}</div>
        </form>
      </div>
    );
  }
}

export default compose(withFirebase)(GetImage);
