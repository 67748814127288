import React from "react";

function PipeForm(props) {
  let question1, question3, question2;

  question1 = "Size And Type Of Pipe Installed:";
  question2 = "Size And Type Of Wire Installed:";
  question3 = "Total Usable Bore Footage Installed AT DEPTH:";
  let name = "pipeForm" + props.number;
  return (
    <div className="pipeForm">
      <div className="pipeFormQuestion">
        <label htmlFor="sizeType">{question1}</label>
        <input
          className="pipeFormText"
          onChange={props.pipeFormChange}
          value={props.value.sizeType}
          type="text"
          name={name}
          id="sizeType"
        />
      </div>
      <br />
      <div className="pipeFormQuestion">
        <label htmlFor="sizeTypeWire">{question2}</label>
        <input
          className="pipeFormText"
          onChange={props.pipeFormChange}
          type="text"
          value={props.value.sizeTypeWire}
          name={name}
          id="sizeTypeWire"
        />
      </div>
      <br />
      <div className="pipeFormQuestion">
        <label htmlFor="boreFootage">{question3}</label>
        <input
          className="pipeFormText"
          onChange={props.pipeFormChange}
          type="text"
          value={props.value.boreFootage}
          name={name}
          id="boreFootage"
        />
      </div>
    </div>
  );
}

export default PipeForm;
