import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import JobsTable from "./../../common/JobsTable";
import Backdrop from "./../../common/Backdrop";
import Confirmation from "./../../common/Confirmation";
import AddJob from "./AddJob";
import { withRouter } from "react-router";
import ConfirmArchive from "./ConfirmArchive";
import {
  getTimesheets,
  getTailboards,
  getJobPhotosForArchiving,
  archiveBoringProcedure,
  archiveBorePreShot,
  moveAssociatedDocumentsForArchiving
} from "./ArchiveJobFunctions";
import { compareTime, compareTimeReverse, compareId, compareIdReverse } from "../../common/Helpers";
import "./jobs.css";
import Button from '@material-ui/core/Button';

class Jobs extends Component {
  constructor() {
    super();
    this.state = {
      addJob: false,
      jobs: [],
      selectedJob: null,
      confirmation: false,
      sortedBy: "date",
      sortOrder: true,
      loading: true,
      message: "",
      showConfirmArchiveDialog: false
    };
    this.onBackdropClick = this.onBackdropClick.bind(this);
    this.onAddJobClick = this.onAddJobClick.bind(this);
    this.submitJob = this.submitJob.bind(this);
    this.selectJob = this.selectJob.bind(this);
    this.onJobDeleteClick = this.onJobDeleteClick.bind(this);
    this.onJobEditClick = this.onJobEditClick.bind(this);
    this.onJobViewClick = this.onJobViewClick.bind(this);
    this.handleDeleteConfirmation = this.handleDeleteConfirmation.bind(this);
    this.sortJobs = this.sortJobs.bind(this);
    this.getTimesheets = this.getTimesheets.bind(this);
    this.getTailboards = this.getTailboards.bind(this);
    this.getExpenseReports = this.getExpenseReports.bind(this);
    this.deleteJobNode = this.deleteJobNode.bind(this);
    this.deleteJobTimesheet = this.deleteJobTimesheet.bind(this);
    this.deleteRegularTimesheet = this.deleteRegularTimesheet.bind(this);
    this.deleteExpenseReport = this.deleteExpenseReport.bind(this);
    this.deleteTailboard = this.deleteTailboard.bind(this);
    this.getRegularExpenseReports = this.getRegularExpenseReports.bind(this);
    this.deleteRegularExpenseReport = this.deleteRegularExpenseReport.bind(
      this
    );
    this.startArchiving = this.startArchiving.bind(this);
    this.handleArchiveConfirmation = this.handleArchiveConfirmation.bind(this);
    this.getJobPhotos = this.getJobPhotos.bind(this);
  }
  promptUserArchiveConfirmation() {
    this.setState({ showConfirmArchiveDialog: true });
  }

  componentDidMount() {
    this.props.firebase.db.collection("jobs").onSnapshot(
      function(jobsSnapshot) {
        let jobs = [];
        jobsSnapshot.forEach(function(job) {
          jobs.push(job.data());
        });
        switch (this.state.sortedBy) {
          case "id":
            if (this.state.sortOrder) {
              jobs.sort(compareId);
            } else {
              jobs.sort(compareIdReverse);
            }
            break;
          case "date":
            if (this.state.sortOrder) {
              jobs.sort(compareTime);
            } else {
              jobs.sort(compareTimeReverse);
            }
            break;
          case "type":
            break;
          default:
            break;
        }

        this.setState({ jobs: jobs, loading: false });
      }.bind(this)
    );
  }
  componentWillUnmount() {
    let unsubscribe = this.props.firebase.db
      .collection("jobs")
      .onSnapshot(() => {});
    unsubscribe();
  }
  sortJobs(param) {
    let jobs = this.state.jobs;
    switch (param) {
      case "id":
        if (this.state.sortOrder) {
          jobs.sort(compareId);
        } else {
          jobs.sort(compareIdReverse);
        }
        this.setState(prevState => {
          return {
            sortedBy: "id",
            jobs: jobs,
            sortOrder: !prevState.sortOrder
          };
        });
        break;
      case "date":
        if (this.state.sortOrder) {
          jobs.sort(compareTime);
        } else {
          jobs.sort(compareTimeReverse);
        }
        this.setState(prevState => {
          return {
            sortedBy: "date",
            jobs: jobs,
            sortOrder: !prevState.sortOrder
          };
        });
        break;
      case "type":
        this.setState({ sortedBy: "type" });
        break;
      default:
        this.setState({ sortedBy: "date" });
        break;
    }
  }
  onAddJobClick() {
    this.setState(prevState => {
      return { addJob: !prevState.addJob };
    });
  }
  onJobDeleteClick() {
    this.setState({ confirmation: true });
  }
  onJobEditClick() {}
  onJobViewClick() {
    this.props.history.push("/dashboard/jobs/" + this.state.selectedJob);
  }
  deleteJobTimesheet(timesheet) {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .collection("timesheets")
      .doc(timesheet)
      .delete()
      .then(
        function() {
          console.log("deleted timesheet");
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err : " + err.message);
      });
  }
  deleteRegularTimesheet(timesheet) {
    this.props.firebase.db
      .collection("timesheets")
      .doc(timesheet)
      .delete()
      .then(
        function() {
          console.log("deleted timesheet");
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err : " + err.message);
      });
  }
  deleteExpenseReport(expenseReport) {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .collection("expense-reports")
      .doc(expenseReport)
      .delete()
      .then(
        function() {
          console.log("deleted expenseReport");
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err : " + err.message);
      });
  }
  deleteTailboard(tailboard) {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .collection("timesheets")
      .doc(tailboard)
      .collection("tailboards")
      .doc(tailboard)
      .delete()
      .then(
        function() {
          console.log("deleted tailboard");
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err : " + err.message);
      });
  }
  deleteJobNode() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .delete()
      .then(
        function() {
          console.log("document deleted");
          this.setState({ selectedJob: null });
        }.bind(this)
      )
      .catch(err => {
        console.log("error deleting document: " + err.message);
      });
  }
  deleteRegularExpenseReport(expenseReport) {
    this.props.firebase.db
      .collection("expense-reports")
      .doc(expenseReport)
      .delete()
      .then(
        function() {
          console.log("deleted expense report");
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err : " + err.message);
      });
  }
  deleteDocuments = () => {
    console.log("deleteDocuments");
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .collection("documents")
      .get()
      .then(
        function(documentSnapshot) {
          documentSnapshot.forEach(
            function(document) {
              this.props.firebase.db
                .collection("jobs")
                .doc(this.state.selectedJob)
                .collection("documents")
                .doc(document.data().documentName)
                .delete()
                .then(function() {
                  console.log("deleted document");
                })
                .catch(function(err) {
                  console.log("error deleting documents: " + err.message);
                });
            }.bind(this)
          );
          this.deleteJobNode();
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting documents: " + err.message);
      });
  };
  getRegularExpenseReports() {
    this.props.firebase.db
      .collection("expense-reports")
      .get()
      .then(
        function(expenseReportsSnapshot) {
          expenseReportsSnapshot.forEach(
            function(expenseReport) {
              if (expenseReport.exists) {
                console.log("deleting expense report");
                if (expenseReport.data().job == this.state.selectedJob) {
                  this.deleteRegularExpenseReport(expenseReport.data().id);
                }
              }
            }.bind(this)
          );
          this.deleteDocuments();
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err : " + err.message);
      });
  }
  getExpenseReports() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .collection("expense-reports")
      .get()
      .then(
        function(expenseReportsSnapshot) {
          expenseReportsSnapshot.forEach(
            function(expenseReport) {
              console.log("calling deleteExpenseReport");
              this.deleteExpenseReport(expenseReport.data().id);
            }.bind(this)
          );
          this.deleteDocuments();
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err : " + err.message);
      });
  }
  getTailboards(timesheet) {
    console.log("getting tailboards");
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .collection("timesheets")
      .doc(timesheet)
      .collection("tailboards")
      .get()
      .then(
        function(tailboardSnapshot) {
          tailboardSnapshot.forEach(
            function(tailboard) {
              console.log("deleting tailboard : " + tailboard.id);
              this.deleteTailboard(tailboard.id);
            }.bind(this)
          );
          this.getExpenseReports();
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err: " + err.message);
      });
  }
  getRegularTimesheets() {
    this.props.firebase.db
      .collection("timesheets")
      .get()
      .then(
        function(timesheetSnapshot) {
          timesheetSnapshot.forEach(
            function(timesheet) {
              if (timesheet.data().job == this.state.selectedJob) {
                this.deleteRegularTimesheet(timesheet.id);
              }
            }.bind(this)
          );
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err : " + err.message);
      });
  }
  deleteBorePreShot = timesheetId => {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .collection("timesheets")
      .doc(timesheetId)
      .collection("borePreShot")
      .doc(timesheetId)
      .get()
      .then(
        function(boringSnapshot) {
          if (boringSnapshot.exists) {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.state.selectedJob)
              .collection("timesheets")
              .doc(timesheetId)
              .collection("borePreShot")
              .doc(timesheetId)
              .delete()
              .then()
              .catch(function(err) {
                console.log("error deleting borePreShot: " + err.message);
              });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting borePreShot: " + err.message);
      });
  };
  deleteBoringProcedure = timesheetId => {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .collection("timesheets")
      .doc(timesheetId)
      .collection("boringProcedure")
      .doc(timesheetId)
      .get()
      .then(
        function(boringSnapshot) {
          if (boringSnapshot.exists) {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.state.selectedJob)
              .collection("timesheets")
              .doc(timesheetId)
              .collection("boringProcedure")
              .doc(timesheetId)
              .delete()
              .then()
              .catch(function(err) {
                console.log("error deleting boringProcedure: " + err.message);
              });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting boringPRocedure: " + err.message);
      });
  };
  getJobPhotos(timesheetId) {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .collection("timesheets")
      .doc(timesheetId)
      .collection("job-photos")
      .get()
      .then(
        function(jobPhotoSnapshot) {
          jobPhotoSnapshot.forEach(
            function(jobPhoto) {
              let id;
              let photo = jobPhoto.data();
              if (photo.id == undefined || photo.id == null) {
                id = photo.type;
              } else {
                id = photo.id;
              }
              this.props.firebase.db
                .collection("jobs")
                .doc(this.state.selectedJob)
                .collection("timesheets")
                .doc(timesheetId)
                .collection("job-photos")
                .doc(id)
                .delete()
                .then(
                  function() {
                    const storageRef = this.props.firebase.storage.ref();
                    const path = photo.storagePath;
                    let ref = storageRef.child(path);
                    ref
                      .delete()
                      .then(function() {
                        console.log("deleted photo from storage");
                      })
                      .catch(function(err) {
                        console.log(
                          "error deleting photo from storage: " + err.message
                        );
                      });
                  }.bind(this)
                )
                .catch(function(err) {
                  console.log("error deleting jobphoto: " + err.message);
                });
            }.bind(this)
          );
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting jobphotos: " + err.message);
      });
  }
  getTimesheets() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.selectedJob)
      .collection("timesheets")
      .get()
      .then(
        function(timesheetSnapshot) {
          let timesheets = [];
          timesheetSnapshot.forEach(
            function(timesheet) {
              timesheets.push(timesheet.data());
              console.log("timesheet id is : " + timesheet.id);
              this.getTailboards(timesheet.id);
              this.getJobPhotos(timesheet.id);
              this.deleteJobTimesheet(timesheet.id);
              this.deleteBorePreShot(timesheet.id);
              this.deleteBoringProcedure(timesheet.id);
            }.bind(this)
          );
          if (timesheets.length == 0) {
            this.getExpenseReports();
          }
          this.getRegularTimesheets();
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err : " + err.message);
      });
  }
   startArchiving(jobId) {
    const jobToArchive = this.state.jobs.find(j => j.id === jobId);

    if (jobToArchive == undefined) {
      this.setState(
      {
        message: "Unable to archive job.",
        showConfirmArchiveDialog: false,
        loading: false
      });
    } else { 
      this.setState({ loading: true });
      getTimesheets(jobToArchive.id, this.props.firebase.db).then(
        function(res) {
          console.log("got response from getTimesheets, " + res);
          let timesheets = res;
          for (let i = 0; i < timesheets.length; i++) {
            getJobPhotosForArchiving(
              jobToArchive.id,
              timesheets[i],
              this.props.firebase.db
            );
          }
          getTailboards(jobToArchive.id, timesheets, this.props.firebase.db).then(
            function(res) {
              archiveBoringProcedure(
                jobToArchive.id,
                timesheets,
                this.props.firebase.db
              ).then(
                function(res) {
                  archiveBorePreShot(
                    jobToArchive.id,
                    timesheets,
                    this.props.firebase.db
                  ).then(
                    function(res) {
                      moveAssociatedDocumentsForArchiving(jobToArchive, timesheets, this.props.firebase.db, () => {
                        this.setState({message: "Job " + jobToArchive.id + " archived."});
                        return this.props.history.push("/dashboard/jobs");
                      });
                    }.bind(this)
                  );
                }.bind(this)
              );
            }.bind(this)
          );
        }.bind(this)
      );
    }
  }
  handleDeleteConfirmation(answer) {
    if (answer) {
      this.setState({ confirmation: false, loading: true });
      this.getTimesheets();
    } else {
      this.setState({ confirmation: false });
    }
  }
  onBackdropClick() {
    console.log("clicked Backdrop in Jobs");
    this.setState(prevState => {
      return { addJob: false };
    });
  }
  submitJob(job) {
    this.setState({ addJob: false });
    let docRef = this.props.firebase.db.collection("jobs").doc(job.id);
    docRef.set(job);
  }
  handleArchiveConfirmation(answer) {
    if (answer) {
      this.setState(
        {
          message: "archiving job, please wait",
          showConfirmArchiveDialog: false,
          loading: true
        },
        () => {
          this.startArchiving(this.state.selectedJob);
        }
      );
    } else {
      this.setState({ showConfirmArchiveDialog: false });
    }
  }
  selectJob(job) {
    if (job == null) {
      this.setState({ selectedJob: null });
    } else {
      this.setState({ selectedJob: job });
    }
  }
  render() {
    let addJob, backdrop, jobControls, confirmation, confirmArchiveDialog, message, loading;
    if (this.state.addJob) {
      backdrop = <Backdrop click={this.onBackdropClick} />;
      addJob = <AddJob jobs={this.state.jobs} submitJob={this.submitJob} />;
    } else {
      backdrop = "";
      addJob = "";
    }
    if (this.state.showConfirmArchiveDialog) {
      confirmArchiveDialog = (
        <div>
          <ConfirmArchive
            answer={this.handleArchiveConfirmation}
            job={this.state.selectedJob}
          />
          <Backdrop click={this.cancelFinishJob} />
        </div>
      );
    } else {
      confirmArchiveDialog = "";
    }
    if (this.state.message !== undefined && this.state.message !== "") {
      message = <div className="alert alert-primary">{this.state.message}</div>;
    } else {
      message = "";
    }
    if (this.state.selectedJob !== null) {
      jobControls = (
        <div className="jobControls">
          <Button onClick={() => this.onJobViewClick() } color="inherit" variant="contained">
            View Job
          </Button>
          <Button onClick={() => this.promptUserArchiveConfirmation() } color="default" variant="contained">
            Archive
          </Button>
          <Button onClick={() => this.onJobDeleteClick() } color="default" variant="contained">
            Delete
          </Button>
        </div>
      );
    } else {
      jobControls = "";
    }
    if (this.state.confirmation) {
      confirmation = (
        <Confirmation
          job={this.state.selectedJob}
          answer={this.handleDeleteConfirmation}
        />
      );
    } else {
      confirmation = "";
    }
    return (
      <div className="dashboardPanel">
        <div className="dashboardPanelHeading dashboardPanelHeading-flex">
          <h1 className="display-5">Jobs</h1>
          <Button onClick={() => {
              if (this.state.loading) {
                return;
              } else {
                this.onAddJobClick();
              }
            }} color="default" variant="contained">
            Add New Job
          </Button>
        </div>
        <hr />
        <div className="dashboardControls">
          {jobControls}
        </div>
        {message}
        <JobsTable
          sort={this.sortJobs}
          selectJob={this.selectJob}
          jobs={this.state.jobs}
        />
        {addJob}
        {confirmArchiveDialog}
        {backdrop}
        {confirmation}
      </div>
    );
  }
}

export default compose(
  withFirebase,
  withRouter
)(Jobs);
