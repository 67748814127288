import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router";
import Backdrop from "./../../common/Backdrop";
import Upload from "./Upload";
import ConfirmArchive from "./ConfirmArchive";
import {
  checkJobId,
  getTimesheets,
  getTailboards,
  getJobPhotosForArchiving,
  archiveBoringProcedure,
  archiveBorePreShot,
  moveAssociatedDocumentsForArchiving
} from "./ArchiveJobFunctions";
import {
  getTimesheetsNew,
  getTailboardsNew,
  getJobPhotosForArchivingNew,
  moveTimesheetsNew,
  getExpenseReportsNew,
  moveAndDeleteExpenseReportsNew,
  moveDocumentsNew,
  moveJobNodeNew,
  moveRegularTimesheetsNew,
  moveBoringProcedure,
  moveBorePreShot
} from "./MoveJobFunctions";
import Button from '@material-ui/core/Button';

class ViewJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: undefined,
      loading: true,
      state: undefined,
      date: undefined,
      type: undefined,
      edit: false,
      upload: false,
      confirmArchive: false,
      idError: false,
      newId: undefined,
      description: "",
      shopTime: undefined
    };
    this.editJob = this.editJob.bind(this);
    this.finishJob = this.finishJob.bind(this);
    this.cancelFinishJob = this.cancelFinishJob.bind(this);
    this.onBackClick = this.onBackClick.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.saveJobChanges = this.saveJobChanges.bind(this);
    this.getJob = this.getJob.bind(this);
    this.upload = this.upload.bind(this);
    this.onBackdropClick = this.onBackdropClick.bind(this);
    this.handleDocumentUpload = this.handleDocumentUpload.bind(this);
    this.selectDocument = this.selectDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.answerFinishJob = this.answerFinishJob.bind(this);
    this.startArchiving = this.startArchiving.bind(this);
    this.onJobIdChange = this.onJobIdChange.bind(this);
  }
  onJobIdChange(event) {
    let reg = new RegExp(/^[0-9\-]+$/);
    let newId = event.target.value;
    if (reg.test(newId)) {
      //good for numbers and dashes
      let requestedId = newId;
      console.log("requested id is " + requestedId);
      let check = checkJobId(requestedId, this.props.firebase.db).then(
        function (res) {
          if (res == true) {
            this.setState({
              idError: false,
              message: undefined,
              newId: requestedId
            });
          } else {
            console.log("returned false");
            this.setState({
              message: "There is already a job with that id",
              idError: true,
              newId: requestedId
            });
          }
        }.bind(this)
      );
    } else {
      this.setState({
        message: "Job ID Must Only Be Numbers and Dashes",
        idError: true
      });
    }
  }
  onDocumentLoadSuccess = ({ numPages }) => {
    this.setState({ numPages });
  };
  handleDocumentUpload(jobDocument) {
    console.log("jobDocument uploaded, setting state");
    this.setState(prevState => {
      return { upload: false, message: "Document Uploaded Successfully" };
    });
    this.getJob();
  }
  upload() {
    this.setState(prevState => {
      return { upload: true };
    });
  }
  onBackdropClick() {
    this.setState({ upload: false });
  }
  saveJobChanges() {
    let dateObj = new Date(this.state.date);
    let dateString = dateObj.toDateString();
    let newDescription;
    if (this.state.description == undefined || this.state.description == null) {
      newDescription = "";
    } else {
      newDescription = this.state.description;
    }
    let newJob = {
      state: this.state.state,
      dateTime: this.state.date,
      date: dateString,
      type: this.state.type,
      description: newDescription,
      title: this.state.title,
      shopTime: this.state.shopTime
    };
    if (this.state.newId !== undefined && this.state.newId !== null) {
      if (this.state.newId !== this.state.job.id) {
        //new id
        this.props.firebase.db
          .collection("jobs")
          .doc(this.state.job.id)
          .set(newJob, { merge: true })
          .then(
            function () {
              this.setState(
                prevState => {
                  return { edit: false };
                },
                () => {
                  this.startMoving(this.state.job, this.state.newId);
                }
              );
            }.bind(this)
          );
      } else {
        this.props.firebase.db
          .collection("jobs")
          .doc(this.state.job.id)
          .set(newJob, { merge: true })
          .then(
            function () {
              this.setState(
                prevState => {
                  return { edit: false };
                },
                () => {
                  this.getJob();
                }
              );
            }.bind(this)
          );
        return;
      }
    } else {
      this.props.firebase.db
        .collection("jobs")
        .doc(this.state.job.id)
        .set(newJob, { merge: true })
        .then(
          function () {
            this.setState(
              prevState => {
                return { edit: false };
              },
              () => {
                this.getJob();
              }
            );
          }.bind(this)
        );
    }
  }
  onChange(event) {
    switch (event.target.name) {
      case "selectState":
        this.setState({ state: event.target.value });
        break;
      case "jobType":
        this.setState({ type: event.target.value });
        break;
      case "jobDescription":
        this.setState({ description: event.target.value });
        break;
      case "jobTitle":
        this.setState({ title: event.target.value });
        break;
      case "shopTime":
        if (event.target.value == "Yes") {
          this.setState({ shopTime: true });
        } else {
          this.setState({ shopTime: false });
        }
      default:
        break;
    }
  }
  onDateSelect(date) {
    // TODO replace with dayjs
    console.log("selected date: " + date);
    let dateTime = date.getTime();
    let dateObj = new Date(date);
    let dateString = dateObj.toDateString();
    console.log("datetime is : " + dateTime);
    this.setState(prevState => {
      return { date: dateTime };
    });
  }
  onBackClick() {
    this.props.history.push("/dashboard/jobs");
  }
  finishJob() {
    this.setState({ confirmArchive: true });
  }
  cancelFinishJob() {
    this.setState({ confirmArchive: false });
  }
  // TODO JL
  startArchiving(job) {
    this.setState({ loading: true });
    getTimesheets(job.id, this.props.firebase.db).then(
      function (res) {
        let timesheets = res;
        for (let i = 0; i < timesheets.length; i++) {
          getJobPhotosForArchiving(
            job.id,
            timesheets[i],
            this.props.firebase.db
          );
        }
        getTailboards(job.id, timesheets, this.props.firebase.db).then(
          function (res) {
            archiveBoringProcedure(
              job.id,
              timesheets,
              this.props.firebase.db
            ).then(
              function (res) {
                archiveBorePreShot(
                  job.id,
                  timesheets,
                  this.props.firebase.db
                ).then(
                  function (res) {
                    moveAssociatedDocumentsForArchiving(job, timesheets, this.props.firebase.db, () => {
                      this.setState({ message: "Job " + jobToArchive.id + " archived." });
                      return this.props.history.push("/dashboard/jobs")
                    });
                  }.bind(this)
                );
              }.bind(this)
            );
          }.bind(this)
        );
      }.bind(this)
    );
  }

  startMoving(job, newId) {
    console.log("inside Archive Job, archiving: " + job.id);
    this.setState({ loading: true });
    getTimesheetsNew(newId, job.id, this.props.firebase.db).then(
      function (res) {
        console.log("got response from getTimesheets, " + res);
        let timesheets = res;
        for (let i = 0; i < timesheets.length; i++) {
          getJobPhotosForArchivingNew(
            newId,
            job.id,
            timesheets[i],
            this.props.firebase.db
          );
        }
        getTailboardsNew(
          newId,
          job.id,
          timesheets,
          this.props.firebase.db
        ).then(
          function (res) {
            moveBoringProcedure(
              newId,
              job.id,
              timesheets,
              this.props.firebase.db
            ).then(
              function (res) {
                moveBorePreShot(
                  newId,
                  job.id,
                  timesheets,
                  this.props.firebase.db
                ).then(
                  function (res) {
                    this.move2(newId, job, timesheets);
                  }.bind(this)
                );
              }.bind(this)
            );
          }.bind(this)
        );
      }.bind(this)
    );
  }
  move2(newId, job, timesheets) {
    moveTimesheetsNew(newId, job.id, timesheets, this.props.firebase.db).then(
      function (res) {
        getExpenseReportsNew(newId, job, this.props.firebase.db).then(
          function (res) {
            let expenseReports = res;
            moveAndDeleteExpenseReportsNew(
              newId,
              expenseReports,
              job,
              this.props.firebase.db
            ).then(
              function (res) {
                moveDocumentsNew(newId, job, this.props.firebase.db).then(
                  function (res) {
                    moveJobNodeNew(newId, job, this.props.firebase.db).then(
                      function (res) {
                        moveRegularTimesheetsNew(
                          newId,
                          job,
                          this.props.firebase.db
                        ).then(
                          function (res) {
                            this.props.history.push("/dashboard/jobs");
                          }.bind(this)
                        );
                      }.bind(this)
                    );
                  }.bind(this)
                );
              }.bind(this)
            );
          }.bind(this)
        );
      }.bind(this)
    );
  }
  answerFinishJob(answer) {
    console.log("inside answerFinishJob, answer is : " + answer);
    if (answer) {
      this.setState(
        {
          message: "archiving job, please wait",
          confirmArchive: false,
          loading: true
        },
        () => {
          this.startArchiving(this.state.job);
        }
      );
    } else {
      this.setState({ confirmArchive: false });
    }
  }
  editJob() {
    this.setState(prevState => {
      return { edit: !prevState.edit };
    });
  }
  getJob() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.match.params.job)
      .get()
      .then(
        function (docSnapshot) {
          if (docSnapshot.exists) {
            let job = docSnapshot.data();
            this.setState(prevState => {
              return {
                job: job,
                loading: true,
                state: job.state,
                type: job.type,
                date: job.dateTime,
                newId: job.id,
                description: job.description,
                title: job.title,
                shopTime: job.shopTime
              };
            });
            this.getJobDocuments();
          }
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error: " + err.message);
      });
  }
  selectDocument(document) {
    this.setState(prevState => {
      return { selectedDocument: document };
    });
  }
  deleteDocument() {
    const storagePath =
      "jobDocuments" +
      this.state.job.id +
      "/" +
      this.state.selectedDocument.documentName;
    const storageRef = this.props.firebase.storage.ref();
    storageRef
      .child(storagePath)
      .delete()
      .then(
        function () {
          this.props.firebase.db
            .collection("jobs")
            .doc(this.state.job.id)
            .collection("documents")
            .doc(this.state.selectedDocument.documentName)
            .delete()
            .then(
              function () {
                this.setState(prevState => {
                  return {
                    selectedDocument: undefined,
                    message: "document successfully deleted"
                  };
                });
                this.getJob();
              }.bind(this)
            )
            .catch(function (err) {
              console.log("error deleting document " + err.message);
            });
        }.bind(this)
      )
      .catch(
        function (err) {
          console.log("err deleting document " + err.message);
        }.bind(this)
      );
  }
  componentDidMount() {
    this.getJob();
  }
  getJobDocuments() {
    let documents = [];
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("documents")
      .get()
      .then(
        function (docSnapshot) {
          docSnapshot.forEach(function (doc) {
            documents.push(doc.data());
          });
          this.setState(prevState => {
            return { jobDocuments: documents, loading: false };
          });
        }.bind(this)
      );
  }
  render() {
    let loading,
      message,
      upload,
      backdrop,
      documents,
      pageNumber,
      numPages,
      deleteDoc,
      confirmArchive;
    pageNumber = this.state.pageNumber;
    numPages = this.state.numPages;
    let backString = "<- Back";
    if (this.state.confirmArchive) {
      confirmArchive = (
        <div>
          <ConfirmArchive
            answer={this.answerFinishJob}
            job={this.state.job.id}
          />
          <Backdrop click={this.cancelFinishJob} />
        </div>
      );
    } else {
      confirmArchive = "";
    }
    if (
      this.state.selectedDocument !== null &&
      this.state.selectedDocument !== undefined
    ) {
      deleteDoc = (
        <Button variant="contained" color="inherit"
          onClick={this.deleteDocument}
        >
          Delete Selected Document
        </Button>
      );
    } else {
      deleteDoc = "";
    }

    if (this.state.message !== undefined && this.state.message !== "") {
      message = <div className="alert alert-primary">{this.state.message}</div>;
    } else {
      message = "";
    }
    if (this.state.upload) {
      upload = (
        <Upload
          job={this.state.job}
          click={this.onBackdropClick}
          submit={this.handleDocumentUpload}
          documents={this.state.jobDocuments}
        />
      );
      backdrop = <Backdrop click={this.onBackdropClick} />;
    } else {
      upload = "";
      backdrop = "";
    }
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      if (this.state.edit) {
        loading = (
          <div className="dashboardPanel">
            <div className="dashboardPanelHeading">
              <h1 className="display-5">Job #</h1>
              <div
                className={
                  this.state.idError
                    ? "form-group has-error has-feedback"
                    : "form-group"
                }
              >
                <div className="col-sm-10">
                  <input
                    type="text"
                    id="jobId"
                    className={
                      this.state.idError
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    value={this.state.newId}
                    onChange={this.onJobIdChange}
                  />
                  <span className="glyphicon glyphicon-warning-sign form-control-feedback" />
                </div>
              </div>
            </div>
            {message}

            <hr />
            <div className="dashboardControls" style={{ marginBottom: 1 + "em" }}>
              <Button onClick={() => { this.editJob(); }} color="inherit" variant="contained">
                Edit
              </Button>
              <Button onClick={() => { this.finishJob(); }} color="inherit" variant="contained">
                Finish Job (Archive)
              </Button>
              <Button onClick={() => { this.onBackClick(); }} color="default" variant="contained">
                {backString}
              </Button>
            </div>
            <div className="row row-space">
              <div className="col-md-6">Date Created:</div>
              <div className="col-md-6">
                <DatePicker
                  style={{ width: "100%" }}
                  id="datePicker"
                  selected={new Date(this.state.date)}
                  onChange={this.onDateSelect}
                />
              </div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">Job Title:</div>
              <div className="col-md-6">
                <input
                  type="text"
                  id="jobTitle"
                  name="jobTitle"
                  className="form-control"
                  value={this.state.title}
                  onChange={this.onChange}
                />
              </div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">State:</div>
              <div className="col-md-6">
                <select
                  className="form-control col-sm-9"
                  id="selectState"
                  name="selectState"
                  value={this.state.state}
                  onChange={this.onChange}
                >
                  <option>Michigan</option>
                  <option>Indiana</option>
                  <option>Ohio</option>
                  <option>Illinois</option>
                </select>
              </div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">Type:</div>
              <div className="col-md-6">
                <select
                  className="form-control"
                  id="jobType"
                  onChange={this.onChange}
                  name="jobType"
                  value={this.state.type}
                >
                  <option>MTS</option>
                  <option>MTS Drilling</option>
                </select>
              </div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">Description:</div>
              <div className="col-md-6">
                <textarea
                  className="form-control"
                  type="text"
                  id="jobDescription"
                  onChange={this.onChange}
                  placeholder="Job Description"
                  value={this.state.description}
                  name="jobDescription"
                />
              </div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">Shop Time?:</div>
              <div className="col-md-6">
                <select
                  className="form-control"
                  id="shopTime"
                  onChange={this.onChange}
                  name="shopTime"
                  value={this.state.shopTime ? "Yes" : "No"}
                >
                  <option>No</option>
                  <option>Yes</option>
                </select>
              </div>
            </div>
            <div className="row row-space">
              <Button variant="contained" color="inherit" onClick={this.saveJobChanges} disabled={this.state.idError ? true : false}>
                Save
              </Button>
            </div>
          </div>
        );
      } else {
        loading = (
          <div className="dashboardPanel">
            <div className="dashboardPanelHeading">
              <h1 className="display-5">Job #{this.state.job.id}</h1>
            </div>
            {message}
            <hr />
            <div className="dashboardControls" style={{ marginBottom: 1 + "em" }}>
              <Button onClick={() => { this.upload(); }} color="inherit" variant="contained">
                Upload Job Docs
              </Button>
              <Button onClick={() => { this.editJob(); }} color="inherit" variant="contained">
                Edit
              </Button>
              <Button onClick={() => { this.finishJob(); }} color="inherit" variant="contained">
                Finish Job (Archive)
              </Button>
              <Button onClick={() => { this.onBackClick(); }} color="default" variant="contained">
                {backString}
              </Button>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>Date Created:</b>
              </div>
              <div className="col-md-6">{this.state.job.date}</div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>Job Title:</b>
              </div>
              <div className="col-md-6">{this.state.job.title}</div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>State:</b>
              </div>
              <div className="col-md-6">{this.state.job.state}</div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>Description:</b>
              </div>
              <div className="col-md-6">{this.state.job.description}</div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>Shop Time?:</b>
              </div>
              <div className="col-md-6">
                {this.state.job.shopTime ? "Yes" : "No"}
              </div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>Type:</b>
              </div>
              <div className="col-md-6">{this.state.job.type}</div>
            </div>
            <b>Documents</b>

            <table className="table table-hover">
              <thead className="thead-dark">
                <tr>
                  <th className="sort" scope="col" />
                  <th className="sort" scope="col">
                    Document Name
                  </th>
                  <th className="sort" scope="col">
                    Link
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.jobDocuments.map((document, i) => (
                  <tr
                    key={i}
                    style={{
                      backgroundColor:
                        this.state.selectedDocument == document
                          ? "#ccc"
                          : undefined
                    }}
                  >
                    <td>
                      <input
                        type="checkbox"
                        checked={
                          this.state.selectedDocument == document ? true : false
                        }
                        id="selectDocument"
                        onClick={() => {
                          this.selectDocument(document);
                        }}
                      />
                    </td>
                    <td>{document.documentName}</td>
                    <td>
                      <a href={document.documentURL} target="_blank">
                        {document.fileName}
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {confirmArchive}
            {deleteDoc}
            {upload}
            {backdrop}
          </div>
        );
      }
    }
    return <div>{loading}</div>;
  }
}

export default compose(
  withFirebase,
  withRouter
)(ViewJob);
