import React, { Component } from "react";
import "./toolbar.css";
import PropTypes from "prop-types";
import DrawerToggleButton from "./DrawerToggleButton";
import SideDrawer from "./SideDrawer";
import { logOutUser } from "../../../store/actions/authActions";
import { connect } from "react-redux";
import { AppBar, Toolbar, SwipeableDrawer } from '@material-ui/core';

class EmployeeToolbar extends Component {
  constructor() {
    super();
    this.state = {
      drawerToggle: false
    };
    this.onDrawerToggleClick = this.onDrawerToggleClick.bind(this);
    this.onBackdropClick = this.onBackdropClick.bind(this);
    this.onLogoutClick = this.onLogoutClick.bind(this);
    this.logOutUser = this.logOutUser.bind(this);
  }
  onLogoutClick() {
    this.props.logOutUser();
  }
  onDrawerToggleClick() {
    this.setState(prevState => {
      return { drawerToggle: !prevState.drawerToggle };
    });
  }
  onBackdropClick() {
    this.setState(prevState => {
      return { drawerToggle: false };
    });
  }
  logOutUser() {
    this.props.logOutUser();
  }

  render() {
    return (
      <div>
      <div className="toolbar">
        <AppBar position="sticky">
          <Toolbar className="toolbar_navigation secondaryBackground">
              <div>
                <DrawerToggleButton toggle={this.onDrawerToggleClick} />
              </div>
              <div className="spacer" />
              <div className="toolbarLinks"></div>
            </Toolbar>
        </AppBar>
      </div>
      <SwipeableDrawer
        anchor={"left"}
        open={this.state.drawerToggle}
        onClose={this.onBackdropClick}
        onOpen={this.onDrawerToggleClick}
        className="slideInLeft sideDrawer"
      >
        <SideDrawer logOut={this.logOutUser} closeBackdrop={this.onBackdropClick} />
      </SwipeableDrawer>
    </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.firebase.auth,
});
EmployeeToolbar.propTypes = {
  logOutUser: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, { logOutUser })(EmployeeToolbar);
