import React from "react";

function PipeForm(props) {
  let question1, question3, question2;
  question1 = "Size And Type Of HDPE Pipe Installed:";
  question2 = "Size And Type Of Wire Installed:";
  question3 = "Total Usable Bore Footage Installed AT DEPTH:";
  return (
    <div className="pipeForm">
      <label htmlFor="sizeType">{question1}</label>
      <input
        className="pipeFormText"
        onChange={props.hdpePipeFormChange}
        value={props.value.sizeType}
        type="text"
        name="hdpeForm"
        id="sizeType"
      />
      <label htmlFor="sizeTypeWire">{question2}</label>
      <input
        className="pipeFormText"
        onChange={props.hdpePipeFormChange}
        value={props.value.sizeTypeWire}
        type="text"
        name="hdpeForm"
        id="sizeTypeWire"
      />
      <label htmlFor="boreFootage">{question3}</label>
      <input
        className="pipeFormText"
        onChange={props.hdpePipeFormChange}
        value={props.value.boreFootage}
        type="text"
        name="hdpeForm"
        id="boreFootage"
      />
    </div>
  );
}

export default PipeForm;
