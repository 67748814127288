import React, { Component } from "react";
import Button from '@material-ui/core/Button';

export default class AddEquipment extends Component {
  constructor() {
    super();
    this.state = {
      equipmentName: "",
      loading: true,
      error: false,
      valid: false,
    };
  }
  onChange = (event) => {
    let name = event.target.value;
    if (this.checkEquipmentName(name)) {
      console.log("no equipment with that name, setting error to false");
      if (name !== "") {
        this.setState({ equipmentName: name, error: false, valid: true });
      } else {
        this.setState({ equipmentName: name, error: false, valid: false });
      }
    } else {
      console.log("have equipment with that name, valid is false, error true");
      this.setState({ equipmentName: name, error: true, valid: false });
    }
  };
  checkEquipmentName = (name) => {
    let equipment = [...this.props.equipment];
    let unique = true;
    equipment.forEach((truck, i) => {
      if (truck.name == name) {
        console.log("same name");
        unique = false;
      }
    });
    return unique;
  };
  saveEquipment = () => {
    console.log("save equipment from addEquipment");
    let now = new Date().getTime();
    let equipment = { name: this.state.equipmentName, dateCreated: now };
    this.props.saveEquipment(equipment);
  };
  render() {
    return (
      <div className="card addJob">
        <p className="lead">Add Equipment</p>
        <div className="form-group row">
          <label className="col-form-label" htmlFor="equipmentName">
            Unique Equipment Name
          </label>
          <input
            className={
              this.state.error ? "form-control is-invalid" : "form-control"
            }
            type="text"
            id="equipmentName"
            onChange={this.onChange}
            placeholder="Equipment Name"
            name="equipmentName"
          />
        </div>

        <Button variant="contained" color="inherit" onClick={this.saveEquipment} disabled={this.state.valid ? false : true} >
          Save Equipment
        </Button>
      </div>
    );
  }
}
