import React, { Component } from 'react';
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ForemanJobs from './ForemanJobs';
import WorkerJobs from './WorkerJobs';

class Jobs extends Component {
  constructor(props){
    super(props);
    this.state = {
      isForeman:false,
      currentUser: null,
      jobs:[],
      loading:false
    }
  }
  componentDidMount(){
    this.setState({loading:true});
    let jobsRef = this.props.firebase.db.collection('jobs');
    let jobs = [];
    jobsRef.get().then(function(jobsSnapshot) {
      jobsSnapshot.forEach(function(job){
        jobs.push(job.data());
      });
      if (this.props.type === 'FOREMAN_TYPE'){
        this.setState(prevState => {
          return {isForeman:true,jobs:jobs,loading:false}
        });
      } else {
        this.setState(prevState => {
          return {isForeman:false,jobs:jobs,loading:false}
        })
      }
    }.bind(this)).catch(function(err){
      console.log(err.message);
    }.bind(this));
  }
  render() {
    let jobs;
    
    if (this.state.isForeman){
      if (this.state.loading){
        jobs = <i className="spinner fas fa-circle-notch fa-spin"></i>;
      } else {
        jobs = <ForemanJobs jobs={this.state.jobs}/>
      }
      
    } else {
      if (this.state.loading){
        jobs = <i className="spinner fas fa-circle-notch fa-spin"></i>;
      } else {
        jobs = <WorkerJobs jobs={this.state.jobs}/>
      }
    }
    return (
      <div className="dashboardPanel">
      <div className="dashboardPanelHeading">
        <h1 className="display-5">Jobs</h1>
      </div>
      <hr />
        {jobs}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  authError:state.auth.authError,
  type:state.auth.type
});
Jobs.propTypes = {
  auth: PropTypes.object.isRequired,
  authError: PropTypes.string,
  type: PropTypes.string.isRequired
};

export default compose(withFirebase, connect(mapStateToProps))(Jobs);