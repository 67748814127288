import React, { Component } from "react";
import JobPhoto from "./JobPhoto";
import GetImage from "./../GetImage";
import { withFirebase } from "./../../../../firebase/index";
import { compose } from "redux";
import formatDate from "./../../../common/formatDate";
import Backdrop from "./../../../common/Backdrop";

class EditJobPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jobPhotos: [],
      selectedPhoto: undefined,
      imageUpload: true
    };
    this.getJobPhotos = this.getJobPhotos.bind(this);
    this.close = this.close.bind(this);
    this.submitJobImage = this.submitJobImage.bind(this);
    this.deleteJobPhoto = this.deleteJobPhoto.bind(this);
    this.onClickImage = this.onClickImage.bind(this);
  }
  cancelGetImage = () => {
    this.setState({ getImage: false });
  };
  uploadSingleJobPhoto = (imageObject, index, total) => {
    let ref = this.props.firebase.db
      .collection("jobs")
      .doc(imageObject.job)
      .collection("timesheets")
      .doc(this.props.timesheet.id)
      .collection("job-photos")
      .doc();
    let documentId = ref.id;
    // TODO dayjs
    let date = new Date();
    let month = date.getMonth() + 1;
    let dateString = month + "-" + date.getDate() + "-" + date.getFullYear();
    console.log(dateString);
    let dateForNode = formatDate(date);
    imageObject.dateForNode = dateForNode;
    const storagePath =
      imageObject.job +
      "/" +
      "JobPhotos" +
      dateString +
      "/" +
      documentId +
      "/" +
      imageObject.photoName;
    imageObject.storagePath = storagePath;
    const storageRef = this.props.firebase.storage.ref();
    let uploadTask = storageRef.child(storagePath).put(imageObject.photo);
    uploadTask.on(
      "state_changed",
      function(snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        this.setState(prevState => {
          return {
            uploadProgress: progress + "%",
            message: "Upload is " + progress.toFixed(2) + "% done",
            valid: false
          };
        });
      }.bind(this),
      function(error) {
        // Handle unsuccessful uploads
        console.log(error);
        this.setState(prevState => {
          return {
            error: error.message,
            message: "Error Uploading Photo: " + error.message
          };
        });
      }.bind(this),
      function() {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(
          function(downloadURL) {
            imageObject.photo = downloadURL;
            imageObject.id = documentId;
            this.props.firebase.db
              .collection("jobs")
              .doc(imageObject.job)
              .collection("timesheets")
              .doc(this.props.timesheet.id)
              .collection("job-photos")
              .doc(documentId)
              .set(imageObject)
              .then(
                function() {
                  this.setState({
                    message: "Image Uploaded Successfully"
                  });
                  this.getJobPhotos();
                }.bind(this)
              );
          }.bind(this)
        );
      }.bind(this)
    );
  };
  submitJobImage(imageObject) {
    this.setState(prevState => {
      return {
        getImage: false,
        imageUpload: true,
        message: "Uploading Job Image..."
      };
    });
    imageObject.forEach((img, i) => {
      if (i + 1 == imageObject.length) {
        console.log("last one");
        this.uploadSingleJobPhoto(img, i, imageObject.length);
        this.setState({
          imageUpload: false,
          message: "Final Success Uploading Image(s)"
        });
      } else {
        this.uploadSingleJobPhoto(img, i, imageObject.length);
      }
    });
  }
  deleteJobPhoto() {
    console.log("inside deleteJobPhoto");
    this.setState({ imageUpload: true });
    const storageRef = this.props.firebase.storage.ref();
    let path, ref;
    path = this.state.selectedImage.storagePath;
    ref = storageRef.child(path);

    ref
      .delete()
      .then(
        function() {
          // File deleted successfully
          this.props.firebase.db
            .collection("jobs")
            .doc(this.props.timesheet.job)
            .collection("timesheets")
            .doc(this.props.timesheet.id)
            .collection("job-photos")
            .doc(this.state.selectedImage.id)
            .delete()
            .then(
              function() {
                console.log("Document successfully deleted!");
                this.setState({ jobStartPhoto: undefined }, () => {
                  this.getJobPhotos();
                });
              }.bind(this)
            )
            .catch(function(error) {
              console.error("Error removing document: ", error);
            });
        }.bind(this)
      )
      .catch(function(error) {
        // Uh-oh, an error occurred!
        console.log("error deleting object: " + error.message);
      });
  }
  onClickImage(photo) {
    console.log("clicked image: " + photo.photo);
    if (this.state.selectedImage !== photo) {
      this.setState({ selectedImage: photo });
    } else {
      this.setState({ selectedImage: undefined });
    }
  }

  close() {
    this.props.close();
  }
  uploadJobPhoto() {
    this.setState({ getImage: true });
  }
  getJobPhotos() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.timesheet.job)
      .collection("timesheets")
      .doc(this.props.timesheet.id)
      .collection("job-photos")
      .get()
      .then(
        function(jobPhotoSnapshot) {
          console.log("inside jobphotosnapshot ");
          let jobPhotos = [];
          jobPhotoSnapshot.forEach(function(jobPhoto) {
            console.log("got job photo");
            jobPhotos.push(jobPhoto.data());
          });

          this.setState(prevState => {
            return {
              jobPhotos: jobPhotos,
              selectedImage: null,
              imageUpload: false
            };
          });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting job photos: " + err.message);
      });
  }
  componentDidMount() {
    this.getJobPhotos();
  }
  render() {
    let getImage, upload, message, photos, deleteSelectedImage;
    if (
      this.state.selectedImage !== undefined &&
      this.state.selectedImage !== null
    ) {
      deleteSelectedImage = (
        <button
          className="btn btn-secondary btn-sm dashboardButton"
          onClick={() => {
            this.deleteJobPhoto();
          }}
        >
          Delete Selected Photo
        </button>
      );
    } else {
      deleteSelectedImage = "";
    }
    if (this.state.jobPhotos !== undefined) {
      photos = (
        <div className="row">
          {this.state.jobPhotos.map((photo, i) => (
            <div
              onClick={() => {
                this.onClickImage(photo);
              }}
              className={
                this.state.selectedImage == photo
                  ? "col col-md-4 col-xs-4 jobPhoto card selectedJobPhoto"
                  : "col col-md-4 col-xs-4 jobPhoto card"
              }
              key={i}
            >
              <img src={photo.photo} />

              <br />
            </div>
          ))}
        </div>
      );
    } else {
      photos = "No Job Photos";
    }
    if (this.state.message !== undefined) {
      message = <div className="alert">{this.state.message}</div>;
    } else {
      message = "";
    }
    if (this.state.imageUpload) {
      upload = (
        <div className="jobPhotoLoading ">
          {" "}
          <i className="spinner fas fa-circle-notch fa-spin" />
        </div>
      );
    } else {
      upload = (
        <div>
          {" "}
          <a
            className="btn btn-secondary dashboardButton"
            onClick={() => {
              this.uploadJobPhoto();
            }}
          >
            <i className="buttonIcon fas fa-camera" />
            Add Photo
          </a>
          <hr />
          {deleteSelectedImage}
          {photos}
        </div>
      );
    }
    if (this.state.getImage) {
      getImage = (
        <div>
          <GetImage
            job={this.props.timesheet.job}
            submit={this.submitJobImage}
            close={this.cancelGetImage}
          />
          <Backdrop click={this.cancelGetImage} />
        </div>
      );
    } else {
      getImage = "";
    }

    return (
      <div className="jobForm">
        {" "}
        <button className="btn btn-secondary btn-sm exit" onClick={this.close}>
          CLOSE
        </button>
        <div className="jobFormContainer">
          <h1 className="display-5">
            Edit Photos For Job # {this.props.timesheet.job} For{" "}
            {formatDate(this.props.timesheet.datetime)}
          </h1>
          <br />
          {message}
          {upload}
        </div>
        {getImage}
      </div>
    );
  }
}

export default compose(withFirebase)(EditJobPhotos);
