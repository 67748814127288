import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

//components
import Dashboard from "./components/dashboard/Dashboard";
import EmployeeDashboard from "./components/employee-dashboard/EmployeeDashboard";
import PrivateRoute from "./components/common/PrivateRoute";
import PrivateAdminRoute from "./components/common/PrivateAdminRoute";
import Landing from "./components/Landing";
import "./App.css";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

class App extends Component {
  render() {
    return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Router>
          <div className="appContainer d-flex flex-column">
            <Route exact path="/" component={Landing} />
            <Switch>
              <PrivateAdminRoute path="/dashboard" component={Dashboard} />
            </Switch>
            <Switch>
              <PrivateRoute
                path="/employee-dashboard"
                component={EmployeeDashboard}
              />
            </Switch>
          </div>
        </Router>
      </LocalizationProvider>
    );
  }
}

export default App;
