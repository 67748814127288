import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import GeoCode from "react-geocode";
import SignatureGrab from "./../../common/SignatureGrab";
import BorePreShotSecond from "./BorePreShotSecond";
import Checkbox from '@material-ui/core/Checkbox';

class BorePreShot extends Component {
  constructor(props) {
    super(props);
    this.state = {
      boreSize: "",
      comments: "",
      locationOfBore: "",
      length: "",
      operator: { name: "" },
      locator: { name: "" },
      pic: { name: "" },
      operatorName: "",
      locatorName: "",
      picName: "",
      loading: false,
      message: undefined,
      addSignature: false,
      signatureValid: false,
      questionValid: false,
      editing: false,
      address: "",
      questions: [
        "Tailboard Conference Completed?",
        "Job Site Secure With Proper Dilineators And Caution Tape?",
        'Gas and all other utility crossings verified/spotted per GOM standards "Damage Prevention and Third Party Activity"? ',
        "Walked With Crew Leader using prints and service cards?",
        "Have the sewers been located/verified?",
        'Proposed route staked/painted according to GOM standards "Boring Near Existing Gas Facilities"?',
        "Evidence of unmarked utilities?",
        "Voltage stake is in place and strike alert has been tested?",
        "Radio communications verified?",
        "Locator calibration verified?",
        "Remote lockout tested?"
      ]
    };
    GeoCode.setApiKey("AIzaSyB2_Mj46H543xr7f3yPvushEfmsSsQ5dvo");
  }
  loadForm = () => {
    this.setState({
      editing: true,
      address: this.props.borePreShot.address,
      boreSize: this.props.borePreShot.boreSize,
      length: this.props.borePreShot.length,
      locationOfBore: this.props.borePreShot.locationOfBore,
      operatorName: this.props.borePreShot.operator.name,
      operatorSignature: this.props.borePreShot.operator.signature.signatureURL,
      locatorName: this.props.borePreShot.locator.name,
      locatorSignature: this.props.borePreShot.locator.signature.signatureURL,
      picName: this.props.borePreShot.pic.name,
      picSignature: this.props.borePreShot.pic.signature.signatureURL,
      comments: this.props.borePreShot.comments,
      question0: true,
      question1: true,
      question2: true,
      question3: true,
      question4: true,
      question5: true,
      question6: true,
      question7: true,
      question8: true,
      question9: true,
      question10: true,
      questionValid: true,
      signatureValid: true
    });
  };
  componentDidMount() {
    if (this.props.borePreShot !== undefined) {
      this.loadForm();
    }
  }
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };
  answer = (question, answer) => {
    console.log("answered question: " + question + " with answer : " + answer);
    this.setState({ [question]: answer, questionValid: false }, () => {
      if (
        this.state.question0 &&
        this.state.question1 &&
        this.state.question2 &&
        this.state.question3 &&
        this.state.question4 &&
        this.state.question5 &&
        this.state.question7 &&
        this.state.question8 &&
        this.state.question9 &&
        this.state.question10
      ) {
        console.log("questionValid is true");
        this.setState({ questionValid: true });
      }
    });
  };
  checkSignatures = () => {
    console.log("inside checkSignatures");
    let sigsToUpload = [];
    if (this.state.operatorNew) {
      //upload operator signature
      sigsToUpload.push("operator");
    }
    if (this.state.locatorNew) {
      //upload locator signature
      sigsToUpload.push("locator");
    }
    if (this.state.picNew) {
      //upload pic signature
      sigsToUpload.push("pic");
    }
    console.log("uploading : " + sigsToUpload.length + " signatures");
    if (sigsToUpload.length == 0) {
      this.enterInDB();
    } else {
      for (let i = 0; i < sigsToUpload.length; i++) {
        if (i == sigsToUpload.length - 1) {
          //last
          this.uploadSingleSignature(sigsToUpload[i]).then(() => {
            this.enterInDB();
          });
        } else {
          this.uploadSingleSignature(sigsToUpload[i]);
        }
      }
    }
  };
  uploadSingleSignature = type => {
    let nameString = type;
    let dateTime = new Date().getTime();
    const storagePath =
      this.props.job.id +
      "/" +
      this.state.date +
      "/" +
      "boringPreShot" +
      nameString +
      dateTime;
    const storageRef = this.props.firebase.storage.ref();
    return new Promise((res, rej) => {
      let signaturePic;
      if (type == "operator") {
        signaturePic = this.state.operatorSignature;
      }
      if (type == "locator") {
        signaturePic = this.state.locatorSignature;
      }
      if (type == "pic") {
        signaturePic = this.state.picSignature;
      }
      let uploadTask = storageRef
        .child(storagePath)
        .putString(signaturePic, "data_url", {
          contentType: "image/png"
        });
      uploadTask.on(
        "state_changed",
        function (snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");

          let message = "Upload " + " is " + progress.toFixed(2) + "% done";
          this.setState(prevState => {
            return {
              uploadProgress: progress + "%",
              message: message
            };
          });
        }.bind(this),
        function (error) {
          // Handle unsuccessful uploads
          console.log(error);
          this.setState(prevState => {
            return {
              error: error.message,
              message: "Error Uploading Photo: " + error.message
            };
          }, rej("error"));
        }.bind(this),
        function () {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...

          uploadTask.snapshot.ref.getDownloadURL().then(
            function (downloadURL) {
              let sigObj;
              switch (type) {
                case "operator":
                  this.setState(
                    prevState => {
                      return {
                        operatorSignature: { signatureURL: downloadURL }
                      };
                    },
                    () => {
                      res("uploaded");
                    }
                  );
                  break;
                case "locator":
                  this.setState(
                    prevState => {
                      return {
                        locatorSignature: { signatureURL: downloadURL }
                      };
                    },
                    () => {
                      res("uploaded");
                    }
                  );
                  break;
                case "pic":
                  this.setState(
                    prevState => {
                      return { picSignature: { signatureURL: downloadURL } };
                    },
                    () => {
                      res("uploaded");
                    }
                  );
                  break;
                default:
                  break;
              }
            }.bind(this)
          );
        }.bind(this)
      );
    });
  };
  uploadSignature = () => {
    console.log("inside upload signature");
    if (this.state.editing) {
      console.log("editing is true, calling checkSignatures");
      this.checkSignatures();
    } else {
      this.setState({ loading: true });
      for (let i = 0; i < 3; i++) {
        let nameString, signaturePic;
        switch (i) {
          case 0:
            signaturePic = this.state.operatorSignature;
            nameString = "operator";
            break;
          case 1:
            signaturePic = this.state.locatorSignature;
            nameString = "locator";
            break;
          case 2:
            signaturePic = this.state.picSignature;
            nameString = "pic";
            break;
          default:
            break;
        }
        let dateTime = new Date().getTime();
        const storagePath =
          this.props.job.id +
          "/" +
          this.state.date +
          "/" +
          "boringPreShot" +
          nameString +
          dateTime;
        const storageRef = this.props.firebase.storage.ref();
        let uploadTask = storageRef
          .child(storagePath)
          .putString(signaturePic, "data_url", {
            contentType: "image/png"
          });
        uploadTask.on(
          "state_changed",
          function (snapshot) {
            // Observe state change events such as progress, pause, and resume
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            var progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            let iterator = i + 1;
            let message =
              "Upload " +
              iterator +
              " of 3" +
              " is " +
              progress.toFixed(2) +
              "% done";
            this.setState(prevState => {
              return {
                uploadProgress: progress + "%",
                message: message
              };
            });
          }.bind(this),
          function (error) {
            // Handle unsuccessful uploads
            console.log(error);
            this.setState(prevState => {
              return {
                error: error.message,
                message: "Error Uploading Photo: " + error.message
              };
            });
          }.bind(this),
          function () {
            // Handle successful uploads on complete
            // For instance, get the download URL: https://firebasestorage.googleapis.com/...

            uploadTask.snapshot.ref.getDownloadURL().then(
              function (downloadURL) {
                let sigObj;
                console.log(
                  "done uploading, nameString is: " +
                  nameString +
                  " downloadURL is " +
                  downloadURL +
                  " and iterator is : " +
                  i
                );
                switch (nameString) {
                  case "operator":
                    this.setState(
                      prevState => {
                        return {
                          operatorSignature: { signatureURL: downloadURL },
                          operatorUpload: true
                        };
                      },
                      () => {
                        if (this.state.picUpload && this.state.locatorUpload) {
                          this.enterInDB();
                        }
                      }
                    );
                    break;
                  case "locator":
                    this.setState(
                      prevState => {
                        return {
                          locatorSignature: { signatureURL: downloadURL },
                          locatorUpload: true
                        };
                      },
                      () => {
                        if (this.state.operatorUpload && this.state.picUpload) {
                          this.enterInDB();
                        }
                      }
                    );
                    break;
                  case "pic":
                    this.setState(
                      prevState => {
                        return {
                          picSignature: { signatureURL: downloadURL },
                          picUpload: true
                        };
                      },
                      () => {
                        console.log("done uploading photos, calling enterindb");
                        if (
                          this.state.operatorUpload &&
                          this.state.locatorUpload
                        ) {
                          this.enterInDB();
                        }
                      }
                    );
                    break;
                  default:
                    break;
                }
              }.bind(this)
            );
          }.bind(this)
        );
      }
    }
  };
  enterInDB = () => {
    console.log("inside enterindb");

    if (this.state.editing) {
      console.log("enter in db, editing");
      if (this.props.node == "timesheets") {
        console.log("entering edited borePreShot into timesheets");
        //timesheets node
        let borePreShot = {
          address: this.state.address,

          boreSize: this.state.boreSize,
          length: this.state.length,
          locationOfBore: this.state.locationOfBore,
          comments: this.state.comments
        };
        if (this.state.operatorNew) {
          borePreShot.operator = {
            name: this.state.operatorName,
            signature: this.state.operatorSignature
          };
        } else {
          borePreShot.operator = {
            name: this.state.operatorName,
            signature: this.props.borePreShot.operator.signature
          };
        }
        if (this.state.locatorNew) {
          borePreShot.locator = {
            name: this.state.locatorName,
            signature: this.state.locatorSignature
          };
        } else {
          borePreShot.locator = {
            name: this.state.locatorName,
            signature: this.props.borePreShot.locator.signature
          };
        }
        if (this.state.picNew) {
          borePreShot.pic = {
            name: this.state.picName,
            signature: this.state.picSignature
          };
        } else {
          borePreShot.pic = {
            name: this.state.picName,
            signature: this.props.borePreShot.pic.signature
          };
        }
        borePreShot.id = this.props.timesheet.id;
        this.props.firebase.db
          .collection("jobs")
          .doc(this.props.job.id)
          .collection("timesheets")
          .doc(this.props.timesheet.id)
          .collection("borePreShot")
          .doc(this.props.timesheet.id)
          .set(borePreShot, { merge: true })
          .then(
            function () {
              this.props.finish("submitted borePreShot");
            }.bind(this)
          )
          .catch(function (err) {
            console.log("error setting borePreShot: " + err.message);
          });
      } else {
        // merge into tip
        console.log("entering edited borePreShot into timesheets-in-progress");
        let borePreShot = {
          address: this.state.address,

          boreSize: this.state.boreSize,
          length: this.state.length,
          locationOfBore: this.state.locationOfBore,
          comments: this.state.comments
        };
        if (this.state.operatorNew) {
          borePreShot.operator = {
            name: this.state.operatorName,
            signature: this.state.operatorSignature
          };
        } else {
          borePreShot.operator = {
            name: this.state.operatorName,
            signature: this.props.borePreShot.operator.signature
          };
        }
        if (this.state.locatorNew) {
          borePreShot.locator = {
            name: this.state.locatorName,
            signature: this.state.locatorSignature
          };
        } else {
          borePreShot.locator = {
            name: this.state.locatorName,
            signature: this.props.borePreShot.locator.signature
          };
        }
        if (this.state.picNew) {
          borePreShot.pic = {
            name: this.state.picName,
            signature: this.state.picSignature
          };
        } else {
          borePreShot.pic = {
            name: this.state.picName,
            signature: this.props.borePreShot.pic.signature
          };
        }
        borePreShot.id = this.props.tip.id;
        this.props.firebase.db
          .collection("jobs")
          .doc(this.props.job.id)
          .collection("timesheets-in-progress")
          .doc(this.props.tip.id)
          .collection("borePreShot")
          .doc(this.props.tip.id)
          .set(borePreShot, { merge: true })
          .then(
            function () {
              this.props.finish("submitted borePreShot");
            }.bind(this)
          )
          .catch(function (err) {
            console.log("error setting borePreShot: " + err.message);
          });
      }
    } else {
      //new node in tip
      let borePreShot = {
        address: this.state.address,
        operator: {
          name: this.state.operatorName,
          signature: this.state.operatorSignature
        },
        locator: {
          name: this.state.locatorName,
          signature: this.state.locatorSignature
        },
        pic: { name: this.state.picName, signature: this.state.picSignature },
        boreSize: this.state.boreSize,
        length: this.state.length,
        locationOfBore: this.state.locationOfBore,
        comments: this.state.comments
      };
      if (borePreShot.operator.name == undefined) {
        borePreShot.operator.name = "";
      }
      if (borePreShot.locator.name == undefined) {
        borePreShot.locator.name = "";
      }
      if (borePreShot.pic.name == undefined) {
        borePreShot.pic.name = "";
      }
      borePreShot.id = this.props.tip.id;
      this.props.firebase.db
        .collection("jobs")
        .doc(this.props.job.id)
        .collection("timesheets-in-progress")
        .doc(this.props.tip.id)
        .collection("borePreShot")
        .doc(this.props.tip.id)
        .set(borePreShot)
        .then(
          function () {
            this.props.finish("submitted borePreShot");
          }.bind(this)
        )
        .catch(function (err) {
          console.log("error setting borePreShot: " + err.message);
        });
    }
  };
  getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log(
          "location is lat: " +
          position.coords.latitude +
          " lng: " +
          position.coords.longitude
        );
        GeoCode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          response => {
            const address = response.results[0].formatted_address;

            this.setState({ address: address });
          },
          error => {
            console.error(error);
            this.setState({ message: "Error Accessing Device Location" });
          }
        );
      });
    } else {
      this.setState({ message: "Cannot access device location" });
    }
  };
  closeForm = () => {
    this.props.close();
  };
  closeSignatureGrab = () => {
    this.setState({ addSignature: false });
  };
  grabSignature = (worker, signature) => {
    switch (worker.name) {
      case "operator":
        if (
          this.state.locatorSignature !== undefined &&
          this.state.picSignature !== undefined
        ) {
          this.setState(prevState => {
            return {
              operatorSignature: signature,
              operatorNew: true,
              addSignature: false,
              signatureValid: true
            };
          });
        } else {
          this.setState(prevState => {
            return {
              operatorSignature: signature,
              operatorNew: true,
              addSignature: false,
              signatureValid: true
            };
          });
        }

        break;
      case "locator":
        if (
          this.state.operatorSignature !== undefined &&
          this.state.picSignature !== undefined
        ) {
          this.setState(prevState => {
            return {
              locatorSignature: signature,
              locatorNew: true,
              addSignature: false,
              signatureValid: true
            };
          });
        } else {
          this.setState(prevState => {
            return {
              locatorSignature: signature,
              addSignature: false,
              locatorNew: true,
              signatureValid: false
            };
          });
        }

        break;
      case "pic":
        if (
          this.state.operatorSignature !== undefined &&
          this.state.locatorSignature !== undefined
        ) {
          this.setState(prevState => {
            return {
              picSignature: signature,
              addSignature: false,
              picNew: true,
              signatureValid: true
            };
          });
        } else {
          this.setState(prevState => {
            return {
              picSignature: signature,
              addSignature: false,
              picNew: true,
              signatureValid: false
            };
          });
        }

      default:
        break;
    }
  };
  openSignature = person => {
    console.log("openSignature, person is " + person);

    this.setState(prevState => {
      return {
        addSignature: !prevState.addSignature,
        selectedPerson: person
      };
    });
  };
  render() {
    let message, confirmation, addSignature, loading;
    if (this.state.addSignature) {
      addSignature = (
        <SignatureGrab
          worker={{
            name: this.state.selectedPerson
          }}
          grab={(worker, signature) => {
            this.grabSignature(worker, signature);
          }}
          close={this.closeSignatureGrab}
        />
      );
    } else {
      addSignature = "";
    }
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = (
        <button
          disabled={
            this.state.signatureValid && this.state.questionValid ? false : true
          }
          className="btn btn-secondary addEmployeeButton dashboardButton submitTimesheet"
          onClick={() => {
            this.uploadSignature();
          }}
        >
          Submit Bore Pre-Shot Checklist
        </button>
      );
    }
    if (this.state.message !== "" && this.state.message !== undefined) {
      message = <div className="alert alert-danger">{this.state.message}</div>;
    } else {
      message = "";
    }
    return (
      <div className="jobForm">
        <button
          className="btn btn-secondary btn-sm exit"
          onClick={this.closeForm}
        >
          CLOSE
        </button>
        <div className="jobFormContainer">
          <h1 className="display-5">
            Directional Bore PRE-SHOT Checklist For Job # {this.props.job.id}
          </h1>
          <br />
          {message}
          {confirmation}
          {addSignature}
          <p className="headline lead bold">
            The Shot must be walked by the Operator, Locator and
            Employee-In-Charge of the job
          </p>
          <div className="row jobFormRow">
            <label htmlFor="address" className="col col-md-6">
              Address/Location of Work
            </label>
            <div className="col col-md-6">
              <button
                className="btn btn-sm btn-secondary"
                onClick={this.getLocation}
              >
                Get Location
              </button>
            </div>
          </div>
          <div className="row jobFormRow">
            <input
              type="text"
              className="form-control col col-md-12"
              name="address"
              value={this.state.address}
              onChange={this.onChange}
            />
          </div>
          <hr />
          <h3 className="display-5">Walked By</h3>
          <hr />
          <div className="row jobFormRow">
            <div className="row jobFormRow">
              <h3>Operator</h3>
            </div>
            <div className="col col-md-6">
              <label htmlFor="operatorName">Name</label>
              <input
                type="text"
                className="form-control"
                name="operatorName"
                value={this.state.operatorName}
                onChange={this.onChange}
              />
            </div>
            <div className="col col-md-6">
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  this.openSignature("operator");
                }}
              >
                Add Signature
              </button>
              <img className="sigImage" src={this.state.operatorSignature} />
            </div>
          </div>
          <hr />
          <div className="row jobFormRow">
            <div className="row jobFormRow">
              <h3>Locator</h3>
            </div>
            <div className="col col-md-6">
              <label htmlFor="locatorName">Name</label>
              <input
                type="text"
                className="form-control"
                name="locatorName"
                value={this.state.locatorName}
                onChange={this.onChange}
              />
            </div>
            <div className="col col-md-6">
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  this.openSignature("locator");
                }}
              >
                Add Signature
              </button>
              <img className="sigImage" src={this.state.locatorSignature} />
            </div>
          </div>{" "}
          <hr />
          <div className="row jobFormRow">
            <div className="row jobFormRow">
              <h3>PIC</h3>
            </div>
            <div className="col col-md-6">
              <label htmlFor="picName">Name</label>
              <input
                type="text"
                className="form-control"
                name="picName"
                value={this.state.picName}
                onChange={this.onChange}
              />
            </div>
            <div className="col col-md-6">
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  this.openSignature("pic");
                }}
              >
                Add Signature
              </button>
              <img className="sigImage" src={this.state.picSignature} />
            </div>
          </div>
          <hr />
          <h3>BORE</h3>
          <hr />
          <div className="row jobFormRow">
            <div className="col col-md-6">Bore Size</div>
            <div className="col col-md-6">
              <input
                type="text"
                className="form-control"
                value={this.state.boreSize}
                name="boreSize"
                onChange={this.onChange}
              />
            </div>
          </div>
          <hr />
          <div className="row jobFormRow">
            <div className="col col-md-6">Length</div>
            <div className="col col-md-6">
              <input
                type="text"
                className="form-control"
                value={this.state.length}
                name="length"
                onChange={this.onChange}
              />
            </div>
          </div>
          <hr />
          <div className="row jobFormRow">
            <div className="col col-md-6">
              Specific location of the bore shot in the job (i.e. between
              addresses 111 and 113)
            </div>
            <div className="col col-md-6">
              <textarea
                className="form-control"
                value={this.state.locationOfBore}
                name="locationOfBore"
                onChange={this.onChange}
              />
            </div>
          </div>
          <hr />
          <h3>CONFIRM</h3>
          <hr />
          <div className="generalSafetyQuestions">
            <table className="table table-hover weeklyTable">
              <thead className="thead-dark">
                <tr>
                  <th className="sort" scope="col" />
                  <th className="sort" scope="col">
                    Yes
                  </th>
                  <th className="sort" scope="col">
                    No
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.questions[0]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question0Yes"
                      checked={this.state.question0 ? true : false}
                      onChange={() => this.answer("question0", true)}
                      name="checkedB"
                      color="primary"
                    />
                  </td>
                  <td>
                    <Checkbox color="primary"
                      id="question0No"
                      checked={!this.state.question0 ? true : false}
                      onChange={() => {
                        this.answer("question0", false);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{this.state.questions[1]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question1Yes"
                      checked={this.state.question1 ? true : false}
                      onChange={() => {
                        this.answer("question1", true);
                      }}
                    />
                  </td>

                  <td>
                    <Checkbox color="primary"
                      id="question1No"
                      checked={!this.state.question1 ? true : false}
                      onChange={() => {
                        this.answer("question1", false);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table table-hover weeklyTable">
              <thead className="thead-dark">
                <tr>
                  <th className="sort" scope="col">
                    MISS DIG Completed
                  </th>
                  <th className="sort" scope="col">
                    Yes
                  </th>
                  <th className="sort" scope="col">
                    No
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.questions[2]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question2Yes"
                      checked={this.state.question2 ? true : false}
                      onChange={() => {
                        this.answer("question2", true);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox color="primary"
                      id="question2No"
                      checked={!this.state.question2 ? true : false}
                      onChange={() => {
                        this.answer("question2", false);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{this.state.questions[3]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question3Yes"
                      checked={this.state.question3 ? true : false}
                      onClick={() => {
                        this.answer("question3", true);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox color="primary"
                      id="question3No"
                      checked={!this.state.question3 ? true : false}
                      onChange={() => {
                        this.answer("question3", false);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{this.state.questions[4]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question4Yes"
                      checked={this.state.question4 ? true : false}
                      onChange={() => {
                        this.answer("question4", true);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox color="primary"
                      id="question4No"
                      checked={!this.state.question4 ? true : false}
                      onChange={() => {
                        this.answer("question4", false);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{this.state.questions[5]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question5Yes"
                      checked={this.state.question5 ? true : false}
                      onChange={() => {
                        this.answer("question5", true);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox color="primary"
                      id="question5No"
                      checked={!this.state.question5 ? true : false}
                      onChange={() => {
                        this.answer("question5", false);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{this.state.questions[6]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question6Yes"
                      checked={this.state.question6 ? true : false}
                      onChange={() => {
                        this.answer("question6", true);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox color="primary"
                      id="question6No"
                      checked={!this.state.question6 ? true : false}
                      onChange={() => {
                        this.answer("question6", false);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="table table-hover weeklyTable">
              <thead className="thead-dark">
                <tr>
                  <th className="sort" scope="col">
                    Safety
                  </th>
                  <th className="sort" scope="col">
                    Yes
                  </th>
                  <th className="sort" scope="col">
                    No
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.state.questions[7]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question7Yes"
                      checked={this.state.question7 ? true : false}
                      onChange={() => {
                        this.answer("question7", true);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox color="primary"
                      id="question7No"
                      checked={!this.state.question7 ? true : false}
                      onChange={() => {
                        this.answer("question7", false);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{this.state.questions[8]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question8Yes"
                      checked={this.state.question8 ? true : false}
                      onChange={() => {
                        this.answer("question8", true);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox color="primary"
                      id="question8No"
                      checked={!this.state.question8 ? true : false}
                      onChange={() => {
                        this.answer("question8", false);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{this.state.questions[9]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question9Yes"
                      checked={this.state.question9 ? true : false}
                      onChange={() => {
                        this.answer("question9", true);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox color="primary"
                      id="question9No"
                      checked={!this.state.question9 ? true : false}
                      onChange={() => {
                        this.answer("question9", false);
                      }}
                    />
                  </td>
                </tr>
                <tr>
                  <td>{this.state.questions[10]}</td>
                  <td>
                    <Checkbox color="primary"
                      id="question10Yes"
                      checked={this.state.question10 ? true : false}
                      onChange={() => {
                        this.answer("question10", true);
                      }}
                    />
                  </td>
                  <td>
                    <Checkbox color="primary"
                      id="question10No"
                      checked={!this.state.question10 ? true : false}
                      onChange={() => {
                        this.answer("question10", false);
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr />
          <div className="row jobFormRow">
            <div className="col col-md-6">Comments</div>
            <div className="col col-md-6">
              <textarea
                className="form-control"
                name="comments"
                value={this.state.comments}
                onChange={this.onChange}
              />
            </div>
          </div>
          {message}
          {loading}
        </div>
        <BorePreShotSecond />
      </div>
    );
  }
}

export default compose(withFirebase)(BorePreShot);
