import React, { Component } from "react";
import { withFirebase } from "../../../../firebase/index";
import { compose } from "redux";
import JobsTable from "./../../../common/JobsTable";
import { compareTime, compareTimeReverse, compareId, compareIdReverse } from "../../../common/Helpers";
import Button from '@material-ui/core/Button';

class ArchivedJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getArchivedJobs = this.getArchivedJobs.bind(this);
    this.selectJob = this.selectJob.bind(this);
    this.sortJobs = this.sortJobs.bind(this);
  }
  getArchivedJobs() {
    this.props.firebase.db
      .collection("archived-jobs")
      .get()
      .then(
        function (jobSnapshot) {
          let jobs = [];
          jobSnapshot.forEach(function (job) {
            jobs.push(job.data());
          });
          this.setState({ jobs: jobs });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting archived jobs : " + err.message);
      });
  }
  componentDidMount() {
    console.log("inside archived jobs");
    this.getArchivedJobs();
  }
  selectJob(job) {
    if (job == null) {
      this.setState({ selectedJob: null });
    } else {
      this.setState({ selectedJob: job });
    }
  }
  onJobViewClick = () => {
    this.props.history.push(
      "/dashboard/archived-jobs/" + this.state.selectedJob
    );
  };
  sortJobs(param) {
    let jobs = this.state.jobs;
    switch (param) {
      case "id":
        if (this.state.sortOrder) {
          jobs.sort(compareId);
        } else {
          jobs.sort(compareIdReverse);
        }
        this.setState((prevState) => {
          return {
            sortedBy: "id",
            jobs: jobs,
            sortOrder: !prevState.sortOrder,
          };
        });
        break;
      case "date":
        if (this.state.sortOrder) {
          jobs.sort(compareTime);
        } else {
          jobs.sort(compareTimeReverse);
        }
        this.setState((prevState) => {
          return {
            sortedBy: "date",
            jobs: jobs,
            sortOrder: !prevState.sortOrder,
          };
        });
        break;
      case "type":
        this.setState({ sortedBy: "type" });
        break;
      default:
        this.setState({ sortedBy: "date" });
        break;
    }
  }
  render() {
    let jobs, jobControls;
    if (this.state.jobs !== undefined && this.state.jobs !== null) {
      jobs = (
        <JobsTable
          sort={this.sortJobs}
          selectJob={this.selectJob}
          jobs={this.state.jobs}
        />
      );
    }
    if (
      this.state.selectedJob !== null &&
      this.state.selectedJob !== undefined
    ) {
      jobControls = (
        <div className="jobControls">
          <Button onClick={() => { this.onJobViewClick(); }} color="inherit" variant="contained">
            View Job
          </Button>
        </div>
      );
    } else {
      jobControls = "";
    }
    return (
      <div className="dashboardPanel">
        <div className="dashboardPanelHeading">
          <h1 className="display-5">Archived Jobs</h1>
        </div>
        <hr />
        <div className="dashboardControls">{jobControls}</div>
        {jobs}
      </div>
    );
  }
}

export default compose(withFirebase)(ArchivedJobs);
