import React from "react";

export default function ViewMaintenanceRequest(props) {
  let photo;
  if (props.request.photos !== null && props.request.photos !== undefined) {
    photo = props.request.photos[0];
  }
  return (
    <div className="card addExpenseReport">
      <p className="lead">
        View Maintenance Request By: {props.request.employee}
      </p>
      <p className="lead">Equipment: {props.request.equipment.name}</p>
      <img
        style={{ width: "50%", marginLeft: "25%" }}
        src={photo}
        className="jobPhoto"
      />
      <br />
      Description: {props.request.description}
    </div>
  );
}
