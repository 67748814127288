import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import Alert from "./../../common/Alert";
import Button from '@material-ui/core/Button';


class Account extends Component {
  constructor() {
    super();
    this.state = {
      email: undefined,
    };
  }
  sendResetEmail = () => {
    this.props.firebase.auth
      .sendPasswordResetEmail(this.state.email)
      .then(
        function () {
          this.setState({
            message: "Password Reset Email Sent Successfully",
            messageType: "success",
          });
        }.bind(this)
      )
      .catch(
        function (err) {
          this.setState({
            message:
              "There Was An Error Sending The Password Reset Email. " +
              err.message,
            messageType: "danger",
          });
        }.bind(this)
      );
  };
  closeMessage = () => {
    this.setState({ message: undefined, messageType: undefined });
  };
  changeEmail = (event) => {
    this.setState({ email: event.target.value });
  };
  render() {
    let message;
    if (this.state.message !== undefined) {
      let messageType;
      if (
        this.state.messageType == undefined ||
        this.state.messageType == null
      ) {
        messageType = "danger";
      } else {
        messageType = this.state.messageType;
      }
      message = (
        <Alert
          closeMessage={this.closeMessage}
          alertType={messageType}
          message={this.state.message}
        />
      );
    } else {
      message = "";
    }
    return (
      <div className="dashboardPanel">
        <div className="dashboardPanelHeading">
          <h1 className="display-5">Account Management</h1>
        </div>
        <hr />
        <div className="dashboardControls">{message}</div>
        <div className="container">
          <div className="row">
            <div className="col col-md-6">
              <p className="lead">Password Reset</p>
              <label htmlFor="changeEmail">
                Email Address Associated With Account:
              </label>
              <input
                type="text"
                className="form-control"
                onChange={this.changeEmail}
              />
              <Button variant="contained" color="primary" style={{ marginTop: "1em" }}
                onClick={this.sendResetEmail}
                disabled={this.state.email == undefined ? true : false}
              >
                Send Password Reset Email
              </Button>
            </div>
          </div>
        </div>
        {message}
      </div>
    );
  }
}

export default compose(withFirebase)(Account);
