import React, { Component } from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

class ClockOutConfirmation extends Component {
  constructor() {
    super();
  }
  render() {
    let hoursWorked = parseFloat(this.props.hours.toFixed(2));
    return (
      <Dialog
        className="dialog-padding"
        open={this.props.open}
        aria-labelledby="clock-out-confirmation-dialog-title"
        aria-describedby="clock-out-confirmation-description"
      >
        <DialogTitle id="clock-out-confirmation-dialog-title" className="alert red">Please Confirm Hours</DialogTitle>
        <DialogContent>
          <DialogContentText id="clock-out-confirmation-dialog-description">The amount of hours worked is {hoursWorked}.<br/><br/>Are you sure you want to clock out?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.props.answer(true)} color="inherit" variant="contained">
            Yes
          </Button>
          <Button onClick={() => this.props.answer(false)} color="inherit" variant="contained">
            No
          </Button>
          <Button onClick={() => this.props.answer(false)} color="inherit" variant="contained">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

    );
  }
}
export default ClockOutConfirmation;
