import React, { Component } from "react";
import { Route } from "react-router-dom";
import EmployeeToolbar from "./toolbar/Toolbar";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Index from "./jobs/Index";
import ExpenseReports from "./expenseReports/ExpenseReports.js";
import { setEmployeeType } from "./../../store/actions/authActions";
import Home from "./Home";
import Maintenance from "./maintenance/Maintenance";
import WSM from "./wsm/WSM";

class EmployeeDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    let uid = this.props.auth.uid;
    let usersRef = this.props.firebase.db.collection("users");
    usersRef
      .get()
      .then(
        function(usersSnapshot) {
          usersSnapshot.forEach(
            function(user) {
              if (user.data().uid == uid) {
                if (user.data().type == "foreman") {
                  this.props.setEmployeeType("foreman");
                  this.setState((prevState) => {
                    return {
                      currentUser: user,
                      isForeman: true,
                      loading: false,
                    };
                  });
                } else if (user.data().type == "worker") {
                  this.props.setEmployeeType("worker");
                  this.setState((prevState) => {
                    return {
                      currentUser: user,
                      isForeman: false,
                      loading: false,
                    };
                  });
                }
              }
            }.bind(this)
          );
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error: " + err.message);
      });
  }
  render() {
    let loading;
    if (this.state.loading) {
      loading = "loading";
    } else {
      loading = (
        <div>
          <EmployeeToolbar />
          <div className="dashboardContent">
            <Route exact path="/employee-dashboard" component={Home} />
            <Route path="/employee-dashboard/jobs" component={Index} />
            <Route exact path="/employee-dashboard/expense-reports" component={ExpenseReports} />
            <Route exact path="/employee-dashboard/maintenance" component={Maintenance} />
            <Route exact path="/employee-dashboard/wsm" component={WSM} />
          </div>
        </div>
      );
    }
    return <div>{loading}</div>;
  }
}

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  authError: state.auth.authError,
});

EmployeeDashboard.propTypes = {
  auth: PropTypes.object.isRequired,
  authError: PropTypes.string,
};

export default compose(
  withFirebase,
  connect(mapStateToProps, { setEmployeeType })
)(EmployeeDashboard);
