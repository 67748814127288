import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import Button from '@material-ui/core/Button';

class Upload extends Component {
  constructor() {
    super();
    this.state = {
      valid: false,
      message: undefined,
      working: false
    };
    this.close = this.close.bind(this);
    this.onFileSelect = this.onFileSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.uploadPdf = this.uploadPdf.bind(this);
  }
  close() {
    this.props.click();
  }
  uploadPdf(e) {
    e.preventDefault();
    let documentName = this.state.fileName.substring(
      this.state.fileName.lastIndexOf("/") + 1
    );
    this.setState({ working: true });
    for (let i = 0; i < this.props.documents.length; i++) {
      if (documentName == this.props.documents[i].documentName) {
        this.setState({
          nameError: true,
          message: "There is already a document with that name"
        });
        return;
      }
    }

    let jobDocument = {
      jobId: this.props.job.id,
      documentName: documentName,
      fileName: this.state.fileName
    };
    if (this.state.document !== undefined) {
      const storagePath =
        "jobDocuments" + jobDocument.jobId + "/" + jobDocument.documentName;
      const storageRef = this.props.firebase.storage.ref();
      let uploadTask = storageRef.child(storagePath).put(this.state.document);
      uploadTask.on(
        "state_changed",
        function(snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState(prevState => {
            return {
              uploadProgress: progress + "%",
              message: "Upload is " + progress.toFixed(2) + "% done",
              valid: false
            };
          });
        }.bind(this),
        function(error) {
          // Handle unsuccessful uploads
          console.log(error);
          this.setState(prevState => {
            return {
              error: error.message,
              message: "Error Uploading Photo: " + error.message
            };
          });
        }.bind(this),
        function() {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(
            function(downloadURL) {
              jobDocument.documentURL = downloadURL;
              this.props.firebase.db
                .collection("jobs")
                .doc(jobDocument.jobId)
                .collection("documents")
                .doc(jobDocument.documentName)
                .set(jobDocument)
                .then(
                  function() {
                    this.props.submit(jobDocument);
                  }.bind(this)
                );
            }.bind(this)
          );
        }.bind(this)
      );
    }
  }
  onFileSelect(event) {
    console.log(event.target.files[0]);
    let name = event.target.files[0].name;
    let doc = event.target.files[0];
    this.setState(prevState => {
      return { valid: true, document: doc, fileName: name };
    });
  }
  onChange(event) {
    let name = event.target.value;
    let documents = this.props.documents;
    for (let i = 0; i < documents.length; i++) {
      if (name == documents[i].documentName) {
        this.setState(prevState => {
          return {
            valid: false,
            message: "There is already a document with that name",
            nameError: true
          };
        });
        return;
      }
    }
    if (name == "") {
      this.setState(prevState => {
        return { valid: false, nameError: false };
      });
    } else {
      if (this.state.document !== undefined) {
        this.setState(prevState => {
          return { valid: true, documentName: name, nameError: false };
        });
      } else {
        this.setState(prevState => {
          return { valid: false, documentName: name, nameError: false };
        });
      }
    }
  }
  render() {
    let submit, message, working;
    if (this.state.message !== undefined) {
      message = <p>{this.state.message}</p>;
    } else {
      message = "";
    }
    if (this.state.working) {
      working = (
        <div>
          <i className="spinner fas fa-circle-notch fa-spin" />
        </div>
      );
    } else {
      working = "";
    }
    if (this.state.valid && !this.state.working) {
      submit = (
        <input
          type="submit"
          className="btn btn-secondary addJobButton"
          style={{ marginTop: "1em" }}
        />
      );
    } else {
      submit = (
        <input
          type="submit"
          className="btn btn-secondary addJobButton"
          disabled
          style={{ marginTop: "1em" }}
        />
      );
    }
    return (
      <div className="card uploadPdf">
        
        <div className="uploadPdfContent">
          <div className="lead">Upload Job Documents</div>
          <Button variant="contained" color="inherit" onClick={this.close}>
            CLOSE
          </Button>
        </div>
        {message}
        {working}
        <form onSubmit={this.uploadPdf}>
          <div className="form-group row">
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input lowInput"
                accept="image/*,application/pdf"
                onChange={this.onFileSelect}
                id="customFile"
              />
              <label className="custom-file-label" htmlFor="customFile">
                {this.state.fileName}
              </label>
            </div>
          </div>
          <div className="form-group row">{submit}</div>
        </form>
      </div>
    );
  }
}

export default compose(withFirebase)(Upload);
