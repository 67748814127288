import React, { Component } from "react";
import ExportCSV from "./../../common/ExportCSV";

class WeeklyReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeesAndReports: [],
      week: {}
    };
  }

  calculateTotals = employeesAndHours => {
    for (let i = 0; i < employeesAndHours.length; i++) {
      let total = 0;
      total =
        parseInt(employeesAndHours[i].sunday) +
        parseInt(employeesAndHours[i].monday) +
        parseInt(employeesAndHours[i].tuesday) +
        parseInt(employeesAndHours[i].wednesday) +
        parseInt(employeesAndHours[i].thursday) +
        parseInt(employeesAndHours[i].friday) +
        parseInt(employeesAndHours[i].saturday);
      employeesAndHours[i].total = total;
    }
    this.setState(prevState => {
      return { employeesAndHours: employeesAndHours };
    });
  };
  componentDidMount() {
  }
  getEmployeeExpenseForDay = (name, day) => {
    let expenses = [];

    for (let i = 0; i < day.length; i++) {
      let date = new Date(day[i].dateTimeOfCharge);
      let dateString = date.toDateString();

      // Expense categories
      let hotel = 0;
      let tools = 0;
      let equipmentRepair = 0;
      let autoTruck = 0;
      let jobSupplies = 0;
      let fuel = 0;
      let meal = 0;
      let water = 0;
      let site = 0;
      let personal = 0;
      let officeSupplies = 0;
      let duesSubscriptionExpense = 0;
      let travel = 0;
      let advertisingPromotion = 0;
      let computerInternetExpense = 0;
      let telephoneExpense = 0;

      switch (day[i].typeOfCharge) {
        case "Advertising & Promotion": 
          advertisingPromotion = day[i].amount;
          break;
        case "Auto/Truck Expense": 
          autoTruck = day[i].amount;
          break;
        case "Computer & Internet Expenses": 
          computerInternetExpense = day[i].amount;
          break;
        case "Dues & Subscriptions": 
          duesSubscriptionExpense = day[i].amount;
          break;
        case "Equipment Repair": 
          equipmentRepair = day[i].amount;
          break;
        case "Fuel": 
          fuel = day[i].amount;
          break;
        case "Job Supplies": 
          jobSupplies = day[i].amount;
          break;
        case "Hotel": 
          hotel = day[i].amount;
          break;
        case "Meal": 
          meal = day[i].amount;
          break;
        case "Office Supplies": 
          officeSupplies = day[i].amount;
          break;
        case "Personal": 
          personal = day[i].amount;
          break;
        case "Site": 
          site = day[i].amount;
          break;
        case "Telephone Expense": 
          telephoneExpense = day[i].amount;
          break;
        case "Tools": 
          tools = day[i].amount;
          break;
        case "Travel Expense":
          travel = day[i].amount;
          break;
        case "Water": 
          water = day[i].amount;
          break;
        default:
          break;
      }
      let expense = {
        name: name,
        date: dateString,
        job: day[i].job,
        description: day[i].descriptionOfPurchase,
        hotel: hotel,
        tools: tools,
        equipmentRepair: equipmentRepair,
        autoTruck: autoTruck,
        jobSupplies: jobSupplies,
        fuel: fuel,
        meal: meal,
        water: water,
        site: site,
        personal: personal,
        officeSupplies: officeSupplies,
        duesSubscriptionExpense: duesSubscriptionExpense,
        travel: travel,
        advertisingPromotion: advertisingPromotion, 
        computerInternetExpense: computerInternetExpense, 
        telephoneExpense: telephoneExpense, 
        amount: day[i].amount
      };
      expenses.push(expense);
    }
    return expenses;
  };

  prepareAllEmployeeExport = () => {
    let CSVdata = [];
    
    this.props.employees.map((employee, i) => {
      let employeeExpenseWeek = this.prepareDate(employee);

      if (employeeExpenseWeek.length && employeeExpenseWeek.length > 0) {
        for (let i = 0; i < employeeExpenseWeek.length; i++) {
          CSVdata.push(employeeExpenseWeek[i]);
        }
        CSVdata.push({"": ""});
      }
    });

    return CSVdata;
  };

  prepareDate = employee => {
    let monday = this.getEmployeeExpenseForDay(employee.name, employee.monday);
    let tuesday = this.getEmployeeExpenseForDay(
      employee.name,
      employee.tuesday
    );
    let wednesday = this.getEmployeeExpenseForDay(
      employee.name,
      employee.wednesday
    );
    let thursday = this.getEmployeeExpenseForDay(
      employee.name,
      employee.thursday
    );
    let friday = this.getEmployeeExpenseForDay(employee.name, employee.friday);
    let saturday = this.getEmployeeExpenseForDay(
      employee.name,
      employee.saturday
    );
    let sunday = this.getEmployeeExpenseForDay(employee.name, employee.sunday);
    let employeeExpenseWeek = [];

    monday.forEach(day => {
      employeeExpenseWeek.push(day);
    });
    tuesday.forEach(day => {
      employeeExpenseWeek.push(day);
    });
    wednesday.forEach(day => {
      employeeExpenseWeek.push(day);
    });
    thursday.forEach(day => {
      employeeExpenseWeek.push(day);
    });
    friday.forEach(day => {
      employeeExpenseWeek.push(day);
    });
    saturday.forEach(day => {
      employeeExpenseWeek.push(day);
    });
    sunday.forEach(day => {
      employeeExpenseWeek.push(day);
    });

    return employeeExpenseWeek;
  }

  prepareEmployeeExport = employee => {
    let employeeExpenseWeek = this.prepareDate(employee);

    if (employeeExpenseWeek.length > 0) {
      this.setState({
        selectedEmployee: employee,
        exportData: employeeExpenseWeek,
        exportReady: true
      });
    } else {
      this.setState({
        selectedEmployee: employee,
        exportReady: false
      });
    }
  };

  selectEmployee = employee => {
    if (this.state.selectedEmployee == employee) {
      this.setState({
        selectedEmployee: undefined,
        exportData: undefined,
        exportHeaders: undefined,
        exportReady: false
      });
    } else {
      this.prepareEmployeeExport(employee);
    }
  };

  render() {
    return (
      <div style={{margin: "0em 1em"}}>
        <div className="exportContainer">
          {this.state.exportReady && this.state.exportData.length > 0 ? (
            <ExportCSV
              csvData={this.state.exportData}
              buttonName={"Export CSV for " + this.state.selectedEmployee.name}
              filename={
                this.state.selectedEmployee.name +
                "-reports-" +
                this.props.sunday +
                "-" +
                this.props.saturday
              }
            />
          ) : (
            <ExportCSV
              csvData={this.prepareAllEmployeeExport()}
              buttonName={"Export CSV for all employees"}
              filename={
                "all-employee-reports-" +
                this.props.sunday +
                "-" +
                this.props.saturday
              }
              />
          )}
        </div>
        <table className="table table-hover weeklyTable">
          <thead className="thead-dark">
            <tr>
              <th className="sort" scope="col">
                Name
              </th>
              <th className="sort" scope="col">
                Sunday
              </th>
              <th className="sort" scope="col">
                Monday
              </th>
              <th className="sort" scope="col">
                Tuesday
              </th>
              <th className="sort" scope="col">
                Wednesday
              </th>
              <th className="sort" scope="col">
                Thursday
              </th>
              <th className="sort" scope="col">
                Friday
              </th>
              <th className="sort" scope="col">
                Saturday
              </th>
              <th className="sort" scope="col">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.employees.map((employee, i) => (
              <tr
                key={i}
                onClick={ () => { this.selectEmployee(employee); }}
                style={{
                  backgroundColor:
                    this.state.selectedEmployee == employee ? "#ccc" : undefined
                }}
              >
                <th className="" scope="row">
                  {employee.name}
                </th>
                <th className="" scope="row">
                  {employee.sunday.map((report, i) => (
                    <button
                      onClick={() => {
                        this.props.open(report);
                      }}
                      className=" btn btn-link charge"
                      key={i}
                    >
                      Amount: ${report.amount}
                    </button>
                  ))}
                </th>
                <th className="" scope="row">
                  {employee.monday.map((report, i) => (
                    <button
                      onClick={() => {
                        this.props.open(report);
                      }}
                      className=" btn btn-link charge"
                      key={i}
                    >
                      Amount: ${report.amount}
                    </button>
                  ))}
                </th>
                <th className="" scope="row">
                  {employee.tuesday.map((report, i) => (
                    <button
                      onClick={() => {
                        this.props.open(report);
                      }}
                      className=" btn btn-link charge"
                      key={i}
                    >
                      Amount: ${report.amount}
                    </button>
                  ))}
                </th>
                <th className="" scope="row">
                  {employee.wednesday.map((report, i) => (
                    <button
                      onClick={() => {
                        this.props.open(report);
                      }}
                      className=" btn btn-link charge"
                      key={i}
                    >
                      Amount: ${report.amount}
                    </button>
                  ))}
                </th>
                <th className="" scope="row">
                  {employee.thursday.map((report, i) => (
                    <button
                      onClick={() => {
                        this.props.open(report);
                      }}
                      className=" btn btn-link charge"
                      key={i}
                    >
                      Amount: ${report.amount}
                    </button>
                  ))}
                </th>
                <th className="" scope="row">
                  {employee.friday.map((report, i) => (
                    <button
                      onClick={() => {
                        this.props.open(report);
                      }}
                      className=" btn btn-link charge"
                      key={i}
                    >
                      Amount: ${report.amount}
                    </button>
                  ))}
                </th>
                <th className="" scope="row">
                  {employee.saturday.map((report, i) => (
                    <button
                      onClick={() => {
                        this.props.open(report);
                      }}
                      className=" btn btn-link charge"
                      key={i}
                    >
                      Amount: ${report.amount}
                    </button>
                  ))}
                </th>
                <th className="" scope="row">
                  ${employee.total}
                </th>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default WeeklyReportTable;
