import React, { Component } from "react";
import { withRouter } from "react-router";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { compareTime, compareTimeReverse, compareId, compareIdReverse } from "../../common/Helpers";
import JobsTable from "./../../common/JobsTable";
import Button from '@material-ui/core/Button';

class ForemanJobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedJob: null,
      confirmation: false,
      sortedBy: "date",
      sortOrder: true,
    };

    this.onJobOpenClick = this.onJobOpenClick.bind(this);
    this.sortJobs = this.sortJobs.bind(this);
    this.selectJob = this.selectJob.bind(this);
  }
  sortJobs(param) {
    console.log("sorting jobs by: " + param);
    let jobs = this.props.jobs;
    switch (param) {
      case "id":
        if (this.state.sortOrder) {
          jobs.sort(compareId);
        } else {
          jobs.sort(compareIdReverse);
        }
        this.setState(prevState => {
          return {
            sortedBy: "id",
            jobs: jobs,
            sortOrder: !prevState.sortOrder,
          };
        });
        break;
      case "date":
        if (this.state.sortOrder) {
          jobs.sort(compareTime);
        } else {
          jobs.sort(compareTimeReverse);
        }
        this.setState(prevState => {
          return {
            sortedBy: "date",
            jobs: jobs,
            sortOrder: !prevState.sortOrder,
          };
        });
        break;
      case "type":
        this.setState({ sortedBy: "type" });
        break;
      default:
        this.setState({ sortedBy: "date" });
        break;
    }
  }
  selectJob(job) {
    if (job == null) {
      this.setState({ selectedJob: null });
    } else {
      this.setState({ selectedJob: job });
    }
  }

  onJobOpenClick() {
    this.props.history.push("/employee-dashboard/jobs/" + this.state.selectedJob);
  }

  render() {
    let jobControls;
    if (this.state.selectedJob !== null) {
      jobControls = (
        <div className="jobControls">
          <Button onClick={() => { this.onJobOpenClick(); }} color="inherit" variant="contained">
            Open
          </Button>
        </div>
      );
    } else {
      jobControls = "";
    }
    return (
      <div>
        {jobControls}
        <JobsTable
          jobs={this.props.jobs}
          sort={this.sortJobs}
          selectJob={this.selectJob}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  authError: state.auth.authError,
  type: state.auth.type,
});
ForemanJobs.propTypes = {
  auth: PropTypes.object.isRequired,
  authError: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default compose(
  withFirebase,
  withRouter,
  connect(mapStateToProps)
)(ForemanJobs);
