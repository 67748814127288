import React, { Component } from "react";

class ViewExpenseReport extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let date = new Date(this.props.expenseReport.dateTimeOfCharge);
    let dateString = date.toDateString();
    return (
      <div className="viewReport" style={{ backgroundColor: "white" }}>
        <div
          className="dashboardPanelHeading"
          style={{ backgroundColor: "white" }}
        >
          <h3 className="display-5">
            Expenses For Job #{this.props.expenseReport.job}
          </h3>
          <p className="lead">
            Employee Name: {this.props.expenseReport.employee}
            <br />
            Date: {dateString}
          </p>
        </div>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th className="sort" scope="col">
                Last4#:
              </th>
              <th className="sort" scope="col">
                Amount
              </th>
              <th className="sort" scope="col">
                Type Of Charge
              </th>
              <th className="sort" scope="col">
                Description Of Purchase
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{this.props.expenseReport.lastFour}</td>
              <td>{this.props.expenseReport.amount}</td>
              <td>{this.props.expenseReport.typeOfCharge}</td>
              <td>{this.props.expenseReport.descriptionOfPurchase}</td>
            </tr>
          </tbody>
        </table>
        <div className="row row-space">
          <img
            src={this.props.expenseReport.photo}
            className="viewExpenseReportPic"
          />
        </div>
      </div>
    );
  }
}

export default ViewExpenseReport;
