import React, { Component } from "react";
import formatDate from "./../../common/formatDate";
import ViewImage from "./../../common/ImageView";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";

class ViewWSMPictures extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      viewImage: false,
      selectedImage: undefined,
      pics: [],
    };
    this.onClickImage = this.onClickImage.bind(this);
    this.closeViewImage = this.closeViewImage.bind(this);
  }
  componentDidMount() {
    this.getPictures();
    window.scrollTo(0, 0);
  }
  closeViewImage() {
    this.setState({ viewImage: false, selectedImage: undefined });
  }
  onClickImage(photo) {
    if (this.state.selectedImage == photo) {
      this.setState({ selectedImage: undefined, viewImage: false });
    } else {
      this.setState({ selectedImage: photo, viewImage: true });
    }
  }
  getPictures = () => {
    this.props.firebase.db
      .collection("wsm")
      .doc(this.props.wsm.id)
      .collection("photos")
      .get()
      .then(
        function(res) {
          let photos = [];
          res.forEach((pic, i) => {
            console.log("got one");
            photos.push(pic.data());
          });
          this.setState({ pics: photos, loading: false });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting photos: " + err.message);
      });
  };
  render() {
    let message, loading, viewImage, photos;
    if (this.state.pics.length > 0) {
      photos = (
        <div className="row">
          {this.state.pics.map((photo, i) => (
            <div
              onClick={() => {
                this.onClickImage(photo);
              }}
              className={
                this.state.selectedImage == photo
                  ? "col col-md-4 col-xs-4 jobPhoto card selectedJobPhoto"
                  : "col col-md-4 col-xs-4 jobPhoto card"
              }
              key={i}
            >
              <p className="lead">{photo.type}</p>
              <img src={photo.photo} />

              <br />
            </div>
          ))}
        </div>
      );
    }
    if (this.state.viewImage) {
      viewImage = (
        <ViewImage
          photo={this.state.selectedImage}
          close={() => {
            this.closeViewImage();
          }}
        />
      );
    } else {
      viewImage = "";
    }

    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = (
        <div>
          <div className="jobFormContainer">
            <h1 className="display-5">
              Photos For WSM # {this.props.wsm.address} For{" "}
              {formatDate(this.props.wsm.time)}
            </h1>

            <br />
            {message}
            {viewImage}
            {photos}
          </div>
        </div>
      );
    }
    return (
      <div className="jobForm">
        {" "}
        <button
          className="btn btn-secondary btn-sm exit"
          onClick={() => {
            this.props.close();
          }}
        >
          CLOSE
        </button>
        {loading}
      </div>
    );
  }
}

export default compose(withFirebase)(ViewWSMPictures);
