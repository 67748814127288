import React, { Component } from "react";
import ViewExpenseReport from "./ViewExpenseReport";
import ViewTimesheet from "./ViewTimesheet";
import ViewTailboard from "./ViewTailboard";
import ViewBorePreShot from "./ViewBorePreShot";
import ViewBoreValidation from "./ViewBoreValidation";
import ViewCovidForm from "./ViewCovidForm";
import ViewWSM from "./ViewWSM";
import Button from "@material-ui/core/Button";
import ReactToPrint from "react-to-print";

class ViewPrintableReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: undefined,
      expenseReport: null,
      timesheet: null,
      tailboard: null,
      boreValidation: null,
      borePreShot: null,
      covidForm: null,
      wsm: null,
    };
  }
  componentDidMount() {
    switch (this.props.type) {
      case "expenseReport":
        this.setState((prevState) => {
          return { type: "expenseReport", expenseReport: this.props.report };
        });
        break;
      case "timesheet":
        this.setState((prevState) => {
          return { type: "timesheet", timesheet: this.props.report };
        });
        break;
      case "tailboard":
        this.setState((prevState) => {
          return { type: "tailboard", tailboard: this.props.report };
        });
        break;
      case "boreValidation":
        this.setState({
          type: "boreValidation",
          boreValidation: this.props.report,
        });
        break;
      case "borePreShot":
        this.setState({ type: "borePreShot", borePreShot: this.props.report });
        break;
      case "covidForm":
        this.setState({ type: "covidForm", covidForm: this.props.report });
        break;
      case "WSM":
        this.setState({ type: "wsm", wsm: this.props.report });
        break;
      default:
        break;
    }
  }
  render() {
    let content;
    let componentRef;
    if (this.state.boreValidation !== null) {
      content = (
        <ViewBoreValidation
          timesheet={this.props.report}
          ref={(el) => (this.componentRef = el)}
        />
      );
    }
    if (
      this.state.borePreShot !== null &&
      this.state.borePreShot !== undefined
    ) {
      content = (
        <ViewBorePreShot
          timesheet={this.props.report}
          ref={(el) => (this.componentRef = el)}
        />
      );
    }
    if (this.state.expenseReport !== null) {
      content = (
        <ViewExpenseReport
          expenseReport={this.state.expenseReport}
          closeReport={() => {
            this.props.exit();
          }}
          ref={(el) => (this.componentRef = el)}
        />
      );
    } else if (this.state.tailboard !== null) {
      content = (
        <ViewTailboard
          tailboard={this.state.tailboard}
          closeReport={() => {
            this.props.exit();
          }}
          ref={(el) => (this.componentRef = el)}
        />
      );
    } else if (this.state.timesheet !== null) {
      content = (
        <ViewTimesheet
          timesheet={this.state.timesheet}
          closeReport={() => {
            this.props.exit();
          }}
          ref={(el) => (this.componentRef = el)}
        />
      );
    } else if (this.state.covidForm !== null) {
      content = (
        <ViewCovidForm
          timesheet={this.props.report}
          closeReport={() => {
            this.props.exit();
          }}
          ref={(el) => (this.componentRef = el)}
        />
      );
    } else if (this.state.wsm !== null) {
      content = (
        <ViewWSM
          wsm={this.state.wsm}
          closeReport={() => {
            this.props.exit();
          }}
          // TODO innerRef error 
          ref={(el) => (this.componentRef = el)}
        />
      );
    }
    return (
      <div className="viewPrintableReport" style={{ backgroundColor: "white" }}>
        {content}
        <div className="printableReportControls">
          <ReactToPrint pageStyle="@page { size: auto;  margin: 3em; } }"
            trigger={() => (
              <Button variant="contained" color="primary" >Print</Button>
            )}
            debug={true}
            copyStyles="true"
            content={() => this.componentRef}
          />

          <Button variant="contained" color="inherit" className="exit"
            onClick={() => {
              this.props.exit();
            }}
          >
            CLOSE
          </Button>
        </div>
      </div>
    );
  }
}

export default ViewPrintableReport;
