import React from "react";

export default function ViewOptions(props) {
  return (
    <div className="viewOptions">
      <p className="lead">
        View Options For WSM: Address:{props.report.address}
      </p>
      <button
        className="btn btn-secondary viewOptionsButton"
        onClick={props.openReport}
      >
        Open Report
      </button>
      <button
        className="btn btn-secondary viewOptionsButton"
        onClick={props.openPics}
      >
        Open Pictures
      </button>
    </div>
  );
}
