import React from "react";

export default function ConfirmDeleteTimesheet(props) {
  return (
    <div className="card confirmation">
      <p className="lead">
        Are You Sure You Want To <b>Delete</b> This Timesheet?
      </p>
      <div className="confirmationControls">
        <button
          className="btn btn-secondary "
          style={{ marginTop: "1em" }}
          onClick={() => {
            props.answer(true);
          }}
        >
          Yes
        </button>
        <button
          className="btn btn-secondary "
          style={{ marginTop: "1em" }}
          onClick={() => {
            props.answer(false);
          }}
        >
          No
        </button>
        <button
          className="btn btn-secondary "
          style={{ marginTop: "1em" }}
          onClick={() => {
            props.answer(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
}
