import app from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";

class Firebase {
  constructor() {
    app.initializeApp(PROD_FIREBASE_CONFIG);
    this.db = app.firestore();
    this.db.enablePersistence().catch(function(err) {
      console.log("error enabling persistence: " + err);
      if (err.code == "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
        console.log(
          "failed precondition setting enablePersistance, multiple tabs open"
        );
      } else if (err.code == "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
        console.log("the current browser doesnt support persistanceEnabled");
      }
    });
    this.auth = app.auth();
    this.storage = app.storage();
  }
  db = () => this.db;
  auth = () => this.auth;
}
const DEV_FIREBASE_CONFIG = {
  apiKey: "AIzaSyCZnZhYyoPECdEwt4NwpNsQ5SsMtCyxroo",
  authDomain: "mts-drilling-1518037291906.firebaseapp.com",
  databaseURL: "https://mts-drilling-1518037291906.firebaseio.com",
  projectId: "mts-drilling-1518037291906",
  storageBucket: "mts-drilling-1518037291906.appspot.com",
  messagingSenderId: "571034747188",
};

const PROD_FIREBASE_CONFIG = {
  apiKey: "AIzaSyDJaYLIifv-6wHvsQLZU_t2xYz2VwVH2UQ",
  authDomain: "mts-drilling-production.firebaseapp.com",
  databaseURL: "https://mts-drilling-production.firebaseio.com",
  projectId: "mts-drilling-production",
  storageBucket: "mts-drilling-production.appspot.com",
  messagingSenderId: "793024512980",
  appId: "1:793024512980:web:c6c080853a51ae3d924c41",
};

export default Firebase;
