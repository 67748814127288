import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import DatePicker from "react-datepicker";
import CloseConfirmation from "./CloseConfirmation";
import dayjs from "dayjs";

class AddExpenseReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valid: false,
      jobs: undefined,
      weekEnding: new Date(),
      currentUser: undefined,
      loading: true,
      selectedJob: undefined,
      dateOfCharge: new Date(),
      typeOfCharge: undefined,
      amount: undefined,
      lastFour: undefined,
      descriptionOfPurchase: undefined,
      message: undefined,
      photo: undefined,
      photoName: undefined,
      closeConfirmation: false,
    };
    this.getJobs = this.getJobs.bind(this);
    this.getUser = this.getUser.bind(this);
    this.onJobSelect = this.onJobSelect.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onDateOfChargeSelect = this.onDateOfChargeSelect.bind(this);
    this.onFileSelect = this.onFileSelect.bind(this);
    this.onTypeOfChargeSelect = this.onTypeOfChargeSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitExpenseReport = this.submitExpenseReport.bind(this);
    this.close = this.close.bind(this);
    this.answerClose = this.answerClose.bind(this);
  }
  close() {
    this.setState((prevState) => {
      return { closeConfirmation: true };
    });
  }
  answerClose(answer) {
    if (answer) {
      this.props.close("");
    } else {
      this.setState((prevState) => {
        return { closeConfirmation: false };
      });
    }
  }
  submitExpenseReport(e) {
    e.preventDefault();
    this.setState({ valid: false });
    let dateTime = new Date(this.state.dateOfCharge).getTime();
    let expenseReport = {
      job: this.state.selectedJob,
      employee: this.props.firebase.auth.currentUser.displayName,
      uid: this.props.firebase.auth.currentUser.uid,
      weekEnding: this.state.weekEnding,
      dateTimeOfCharge: dateTime,
      typeOfCharge: this.state.typeOfCharge,
      amount: this.state.amount,
      id: dateTime.toString(),
    };
    if (this.state.lastFour !== undefined) {
      expenseReport.lastFour = this.state.lastFour;
    }
    if (this.state.descriptionOfPurchase !== undefined) {
      expenseReport.descriptionOfPurchase = this.state.descriptionOfPurchase;
    }
    if (this.state.jobLocation !== undefined) {
      expenseReport.jobLocation = this.state.jobLocation;
    }
    if (this.state.photo !== undefined) {
      const storagePath =
        expenseReport.job +
        "/" +
        expenseReport.dateTimeOfCharge +
        "/" +
        expenseReport.employee +
        "/" +
        this.state.photoName;
      const storageRef = this.props.firebase.storage.ref();
      let uploadTask = storageRef.child(storagePath).put(this.state.photo);
      uploadTask.on(
        "state_changed",
        function(snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          this.setState((prevState) => {
            return {
              uploadProgress: progress + "%",
              message: "Upload is " + progress.toFixed(2) + "% done",
              valid: false,
            };
          });
        }.bind(this),
        function(error) {
          // Handle unsuccessful uploads
          console.log(error);
          this.setState((prevState) => {
            return {
              error: error.message,
              message: "Error Uploading Photo: " + error.message,
            };
          });
        }.bind(this),
        function() {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(
            function(downloadURL) {
              expenseReport.photo = downloadURL;
              this.props.firebase.db
                .collection("jobs")
                .doc(expenseReport.job)
                .collection("expense-reports")
                .doc(expenseReport.id)
                .set(expenseReport)
                .then(
                  function() {
                    this.props.firebase.db
                      .collection("expense-reports")
                      .doc()
                      .set(expenseReport)
                      .then(
                        function() {
                          this.props.submit(expenseReport);
                        }.bind(this)
                      );
                  }.bind(this)
                );
            }.bind(this)
          );
        }.bind(this)
      );
    } else {
      this.props.firebase.db
        .collection("jobs")
        .doc(expenseReport.job)
        .collection("expense-reports")
        .doc(expenseReport.id)
        .set(expenseReport)
        .then(
          function() {
            this.props.firebase.db
              .collection("expense-reports")
              .doc()
              .set(expenseReport)
              .then(
                function() {
                  this.props.submit(expenseReport);
                }.bind(this)
              );
          }.bind(this)
        );
    }
  }
  onChange(event) {
    const storeEventName = event.target.name;
    const storeEventValue = event.target.value;
    if (storeEventName == "amount") {
      if (
        this.state.selectedJob !== undefined &&
        this.state.typeOfCharge !== undefined
      ) {
        this.setState((prevState) => {
          return { amount: storeEventValue, valid: true };
        });
      } else {
        this.setState((prevState) => {
          return { amount: storeEventValue, valid: false };
        });
      }
    } else {
      this.setState(() => {
        return { [storeEventName]: storeEventValue };
      });
    }
  }
  onTypeOfChargeSelect(event) {
    let type = event.target.value;

    if (
      this.state.selectedJob !== undefined &&
      this.state.amount !== undefined
    ) {
      if (type == "") {
        this.setState((prevState) => {
          return { typeOfCharge: undefined, valid: false };
        });
      } else {
        this.setState((prevState) => {
          return { typeOfCharge: type, valid: true };
        });
      }
    } else {
      if (type == "") {
        this.setState((prevState) => {
          return { typeOfCharge: undefined };
        });
      } else {
        this.setState((prevState) => {
          return { typeOfCharge: type };
        });
      }
    }
  }
  onFileSelect(event) {
    let name = event.target.files[0].name;
    let photo = event.target.files[0];
    this.setState({
      photo: event.target.files[0],
      photoName: name,
    });
  }
  onDateOfChargeSelect(date) {
    let dateObj = new Date(date);
    this.setState((prevState) => {
      return { dateOfCharge: dateObj };
    });
  }
  onDateSelect(date) {
    let dateObj = new Date(date);
    this.setState((prevState) => {
      return { weekEnding: dateObj };
    });
  }
  onJobSelect(event) {
    let jobId = event.target.value;
    if (jobId == "") {
      this.setState({ selectedJob: undefined, valid: false });
    } else {
      if (
        this.state.typeOfCharge !== undefined &&
        this.state.amount !== undefined
      ) {
        this.setState((prevState) => {
          return { selectedJob: jobId, valid: true };
        });
      } else {
        this.setState((prevState) => {
          return { selectedJob: jobId, valid: false };
        });
      }
    }
    this.setState((prevState) => {
      return { selectedJob: jobId };
    });
  }
  getJobs() {
    this.props.firebase.db
      .collection("jobs")
      .get()
      .then(
        function(jobSnapshot) {
          let jobs = [];
          jobSnapshot.forEach(function(job) {
            jobs.push(job.data());
          });
          if (this.state.currentUser !== undefined) {
            this.setState((prevState) => {
              return { jobs: jobs, loading: false };
            });
          } else {
            this.setState((prevState) => {
              return { jobs: jobs };
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error: " + err.message);
      });
  }
  getUser() {
    let user = {
      uid: this.props.firebase.auth.currentUser.uid,
      name: this.props.firebase.auth.currentUser.displayName,
    };
    if (this.state.jobs !== undefined) {
      this.setState((prevState) => {
        return { currentUser: user, loading: false };
      });
    } else {
      this.setState((prevState) => {
        return { currentUser: user };
      });
    }
  }
  figureOutNextSaturday() {
    let today = dayjs().day();
    let Sat = 6;
    let nextSaturday = dayjs().add(Sat-today, 'day').valueOf();

    this.setState((prevState) => {
      return { weekEnding: nextSaturday };
    });
  }
  componentDidMount() {
    this.figureOutNextSaturday();
    this.getJobs();
    this.getUser();
  }
  render() {
    let submit, loading, message, closeConfirmation;
    if (this.state.message !== undefined) {
      message = <div className="alert alert-danger">{this.state.message}</div>;
    } else {
      message = "";
    }
    if (this.state.closeConfirmation) {
      closeConfirmation = <CloseConfirmation answer={this.answerClose} />;
    } else {
      closeConfirmation = "";
    }
    if (this.state.valid) {
      submit = (
        <input
          type="submit"
          className="btn btn-secondary addJobButton"
          style={{ marginTop: "1em" }}
        />
      );
    } else {
      submit = (
        <input
          type="submit"
          className="btn btn-secondary addJobButton"
          disabled
          style={{ marginTop: "1em" }}
        />
      );
    }
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = (
        <form onSubmit={this.submitExpenseReport}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="exampleFormControlSelect1">
              Expenses For Job#
            </label>
            <select onChange={this.onJobSelect} className="form-control col-sm-9" id="exampleFormControlSelect1">
              <option key="-1" />
              {this.state.jobs.map((job, i) => (
                <option key={i}>{job.id}</option>
              ))}
            </select>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="jobLocation">
              Week Ending:
            </label>
            <DatePicker
              className="form-control"
              style={{ width: "100%" }}
              id="datePicker"
              selected={this.state.weekEnding}
              onChange={this.onDateSelect}
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="jobLocation">
              Job Location
            </label>
            <input
              className="form-control col-sm-9"
              type="text"
              id="jobLocation"
              onChange={this.onChange}
              placeholder="Job Location"
              name="jobLocation"
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="lastFour">
              Credit Card # Used (last 4 digits)
            </label>
            <input
              className="form-control col-sm-9"
              type="number"
              id="lastFour"
              onChange={this.onChange}
              placeholder="Last 4 #"
              name="lastFour"
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="dateOfCharge">
              Date Of Charge:
            </label>
            <DatePicker
              className="form-control elevatedDatePicker"
              style={{ width: "100%", zIndex: 5000 }}
              id="dateOfCharge"
              selected={this.state.dateOfCharge}
              onChange={this.onDateOfChargeSelect}
            />
          </div>
          <div className="form-group row">
            <label
              className="col-sm-3 col-form-label"
              htmlFor="descriptionOfPurchase"
            >
              Description Of Purchase:
            </label>
            <input
              className="form-control col-sm-9"
              type="text"
              id="descriptionOfPurchase"
              onChange={this.onChange}
              placeholder="Description Of Purchase"
              name="descriptionOfPurchase"
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="typeOfCharge">
              Type Of Charge:
            </label>
            <select
              onChange={this.onTypeOfChargeSelect}
              className="form-control col-sm-9"
              id="typeOfCharge"
            >
              <option />
              <option id="advertisingPromotion">Advertising & Promotion</option>
              <option id="auto-truck">Auto/Truck Expense</option>
              <option id="computerInternetExpense">Computer & Internet Expenses</option>
              <option id="duesSubscriptionExpense">Dues & Subscriptions</option>
              <option id="equipmentRepair">Equipment Repair</option>
              <option id="fuel">Fuel</option>
              <option id="jobSupplies ">Job Supplies</option>
              <option id="hotel">Hotel</option>
              <option id="meal">Meal</option>
              <option id="officeSupplies">Office Supplies</option>
              <option id="personal">Personal</option>
              <option id="site">Site</option>
              <option id="telephoneExpense">Telephone Expense</option>
              <option id="tools">Tools</option>
              <option id="travel">Travel Expense</option>
              <option id="water">Water</option>
            </select>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="amount">
              Amount:
            </label>
            <input
              className="form-control col-sm-9"
              type="number"
              id="amount"
              onChange={this.onChange}
              min="0.01"
              step="0.01"
              placeholder="$Amount"
              name="amount"
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="customFile">
              Receipt Image:
            </label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input lowInput"
                accept="image/*"
                onChange={this.onFileSelect}
                id="customFile"
              />
              <label className="custom-file-label" htmlFor="customFile">
                {this.state.photoName}
              </label>
            </div>
          </div>
          {message}
          <div className="form-group row">{submit}</div>
        </form>
      );
    }

    return (
      <div className="card addExpenseReport">
        <button className="btn btn-secondary btn-sm exit" onClick={this.close}>
          CLOSE
        </button>
        <div className="addExpenseReportContent">
          <p className="lead">Add Expense Report</p>
          {message}
          {loading}
          {closeConfirmation}
        </div>
      </div>
    );
  }
}

export default compose(withFirebase)(AddExpenseReport);
