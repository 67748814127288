import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo.png";
import "./sideDrawer.css";

export default function SideDrawer(props) {
  return (
    <div>
      <div className="navbar-brand">
        <img src={logo} alt="MTS" className="logo img-responsive sideDrawerLogo"/>
      </div>
      <ul>
        <li className="firstListItem">
          <Link
            to="/employee-dashboard"
            onClick={() => {
              props.closeBackdrop();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-home drawerIcon" />
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/employee-dashboard/jobs"
            onClick={() => {
              props.closeBackdrop();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-calendar-alt drawerIcon" />
            Jobs
          </Link>
        </li>
        <li>
          <Link
            to="/employee-dashboard/expense-reports"
            onClick={() => {
              props.closeBackdrop();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-money-check-alt drawerIcon" />
            Expense Reports
          </Link>
        </li>
        <li>
          <Link
            to="/employee-dashboard/maintenance"
            onClick={() => {
              props.closeBackdrop();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-wrench drawerIcon" />
            Equipment Maintenance
          </Link>
        </li>
        <li>
          <Link
            to="/employee-dashboard/wsm"
            onClick={() => {
              props.closeBackdrop();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-water drawerIcon" />
            WSM Reports
          </Link>
        </li>
        <li>
          <Link
            to=""
            onClick={() => {
              props.logOut();
            }}
            className="drawerNavLink"
          >
            <i className="fas fa-sign-out-alt drawerIcon" />
            Log Out
          </Link>
        </li>
      </ul>
    </div>
  );
}
