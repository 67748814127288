import React, { Component } from "react";
import { withRouter } from "react-router";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import CloseConfirmation from "./CloseConfirmation";
import AddEmployee from "./AddEmployee";
import Backdrop from "./../../common/Backdrop";
import DatePicker from "react-datepicker";
import GeoCode from "react-geocode";
import SignatureGrab from "./../../common/SignatureGrab";
import BoreValidationSecond from "./BoreValidationSecond";
import Checkbox from "@material-ui/core/Checkbox";

class BoringProcedureValidation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmation: false,
      addEmployee: false,
      loading: false,
      selectedEmployee: null,
      employees: [],
      date: new Date(),
      dateTime: new Date().getTime(),
      message: undefined,
      orderNumber: "",
      inspectorsName: "",
      description: "",
      address: "",
      title: "",
      boreMethod: "",
      addSignature: false,
      editing: false
    };
    this.answerClose = this.answerClose.bind(this);
    this.closeForm = this.closeForm.bind(this);
    this.onAddEmployeeClick = this.onAddEmployeeClick.bind(this);
    this.closeAddEmployee = this.closeAddEmployee.bind(this);
    this.addEmployee = this.addEmployee.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.selectEmployee = this.selectEmployee.bind(this);
    this.onChange = this.onChange.bind(this);
    this.getLocation = this.getLocation.bind(this);
    this.deleteEmployee = this.deleteEmployee.bind(this);
    this.changeBoreMethod = this.changeBoreMethod.bind(this);
    this.agreeCheck = this.agreeCheck.bind(this);
    this.grabSignature = this.grabSignature.bind(this);
    this.closeSignatureGrab = this.closeSignatureGrab.bind(this);
    this.uploadSignature = this.uploadSignature.bind(this);
    this.loadFields = this.loadFields.bind(this);
    GeoCode.setApiKey("AIzaSyB2_Mj46H543xr7f3yPvushEfmsSsQ5dvo");
  }
  loadFields() {
    let procVal = this.props.boringProcedure;
    this.setState({
      title: procVal.title,
      inspectorsName: procVal.inspectorsName,
      date: new Date(procVal.datetime),
      orderNumber: procVal.orderNumber,
      description: procVal.description,
      address: procVal.address,
      boreMethod: procVal.boreMethod,
      agreeSignature: procVal.signature,
      agree: true,
      editing: true,
      employees: procVal.employees
    });
  }
  componentDidMount() {
    if (this.props.boringProcedure !== undefined) {
      //load data
      console.log("have procedure, loading fields");
      this.loadFields();
    } else {
      //no data
      console.log("no data, leaving as is");
    }
  }
  uploadSignature() {
    if (this.state.editing) {
      this.enterInDB();
    } else {
      this.setState({ loading: true });
      console.log("inside upload signature");
      let signaturePic = this.state.agreeSignature;
      console.log("signature pic is " + signaturePic);
      const storagePath =
        this.props.job.id +
        "/" +
        this.state.date +
        "/" +
        "boringProcedureValidation" +
        this.props.firebase.auth.currentUser.uid;
      const storageRef = this.props.firebase.storage.ref();
      let uploadTask = storageRef
        .child(storagePath)
        .putString(signaturePic, "data_url", {
          contentType: "image/png"
        });
      uploadTask.on(
        "state_changed",
        function(snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          let message = "Upload " + " is " + progress.toFixed(2) + "% done";
          this.setState(prevState => {
            return {
              uploadProgress: progress + "%",
              message: message
            };
          });
        }.bind(this),
        function(error) {
          // Handle unsuccessful uploads
          console.log(error);
          this.setState(prevState => {
            return {
              error: error.message,
              message: "Error Uploading Photo: " + error.message
            };
          });
        }.bind(this),
        function() {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...

          uploadTask.snapshot.ref.getDownloadURL().then(
            function(downloadURL) {
              let procedureValidation = { signature: downloadURL };
              this.setState(
                prevState => {
                  return { procedureValidation: procedureValidation };
                },
                function() {
                  //enter in database
                  this.enterInDB();
                }
              );
            }.bind(this)
          );
        }.bind(this)
      );
    }
  }
  enterInDB() {
    if (!this.state.editing) {
      //enter in tip node
      let procedureValidation = this.state.procedureValidation;
      procedureValidation.orderNumber = this.state.orderNumber;
      procedureValidation.employees = this.state.employees;
      procedureValidation.inspectorsName = this.state.inspectorsName;
      procedureValidation.description = this.state.description;
      procedureValidation.address = this.state.address;
      procedureValidation.boreMethod = this.state.boreMethod;
      procedureValidation.date = this.state.date;
      procedureValidation.datetime = this.state.dateTime;
      procedureValidation.title = this.state.title;
      procedureValidation.id = this.props.tip.id;
      this.props.firebase.db
        .collection("jobs")
        .doc(this.props.job.id)
        .collection("timesheets-in-progress")
        .doc(this.props.tip.id)
        .collection("boringProcedure")
        .doc(this.props.tip.id)
        .set(procedureValidation)
        .then(
          function() {
            this.props.finish();
          }.bind(this)
        )
        .catch(function(err) {
          console.log("error setting boreProcedure node: " + err.message);
        });
      console.log("signature is : " + procedureValidation.signature);
    } else {
      let procVal = {};
      procVal.orderNumber = this.state.orderNumber;
      procVal.employees = this.state.employees;
      procVal.inspectorsName = this.state.inspectorsName;
      procVal.description = this.state.description;
      procVal.address = this.state.address;
      procVal.boreMethod = this.state.boreMethod;
      procVal.date = this.state.date;
      procVal.datetime = this.state.dateTime;
      procVal.title = this.state.title;

      if (this.props.node == "timesheets") {
        console.log(
          "submitting boreValdation to timesheets, node " +
            this.props.timesheet.id
        );
        procVal.id = this.props.timesheet.id;
        this.props.firebase.db
          .collection("jobs")
          .doc(this.props.job.id)
          .collection("timesheets")
          .doc(this.props.timesheet.id)
          .collection("boringProcedure")
          .doc(this.props.timesheet.id)
          .set(procVal, { merge: true })
          .then(
            function() {
              this.props.finish("edited");
            }.bind(this)
          )
          .catch(function(err) {
            console.log("error setting boreProcedure node: " + err.message);
          });
      } else {
        procVal.id = this.props.tip.id;
        this.props.firebase.db
          .collection("jobs")
          .doc(this.props.job.id)
          .collection("timesheets-in-progress")
          .doc(this.props.tip.id)
          .collection("boringProcedure")
          .doc(this.props.tip.id)
          .set(procVal, { merge: true })
          .then(
            function() {
              this.props.finish("edited");
            }.bind(this)
          )
          .catch(function(err) {
            console.log("error setting boreProcedure node: " + err.message);
          });
      }

      console.log("signature is : " + procVal.signature);
    }
  }
  closeSignatureGrab() {
    this.setState({ addSignature: false });
  }
  grabSignature(worker, signature) {
    this.setState(prevState => {
      return { agreeSignature: signature, agree: true, addSignature: false };
    });
  }
  agreeCheck() {
    console.log("inside agreeCheck");
    this.setState(prevState => {
      return { addSignature: !prevState.addSignature };
    });
  }
  changeBoreMethod(method) {
    console.log("changed bore method to : " + method);
    if (this.state.boreMethod == method) {
      this.setState({ boreMethod: "" });
    } else {
      this.setState({ boreMethod: method });
    }
  }
  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log(
          "location is lat: " +
            position.coords.latitude +
            " lng: " +
            position.coords.longitude
        );
        GeoCode.fromLatLng(
          position.coords.latitude,
          position.coords.longitude
        ).then(
          response => {
            const address = response.results[0].formatted_address;

            this.setState({ address: address });
          },
          error => {
            console.error(error);
            this.setState({ message: "Error Accessing Device Location" });
          }
        );
      });
    } else {
      this.setState({ message: "Cannot access device location" });
    }
  }
  onChange(event) {
    console.log("inside onChange");
    this.setState({ [event.target.name]: event.target.value });
  }
  onDateSelect(date) {
    let date1 = new Date(date);
    let dateTime = date1.getTime();
    this.setState(prevState => {
      return { date: date1, dateTime: dateTime, message: "" };
    });
  }
  selectEmployee(employee) {
    console.log("selecting employee");
    if (this.state.selectedEmployee == employee) {
      this.setState({ selectedEmployee: undefined });
    } else {
      this.setState({ selectedEmployee: employee });
    }
  }

  addEmployee(employee) {
    for (let i = 0; i < this.state.employees.length; i++) {
      if (employee.uid == this.state.employees[i].uid) {
        this.setState({ addEmployee: false });
        return;
      }
    }
    let oldEmployees = this.state.employees;
    oldEmployees.push(employee);
    this.setState({
      employees: oldEmployees,
      addEmployee: false,
      message: "Employee Successfully Added"
    });
  }
  onAddEmployeeClick() {
    console.log("clicked addEmployee");
    this.setState(prevState => {
      return { addEmployee: true, message: "" };
    });
  }
  deleteEmployee() {
    let oldEmployees = this.state.employees;
    for (let i = 0; i < oldEmployees.length; i++) {
      if (oldEmployees[i].uid == this.state.selectedEmployee.uid) {
        oldEmployees.splice(i, 1);
      }
    }
    this.setState({
      employees: oldEmployees,
      message: "Deleted Employee",
      selectedEmployee: undefined
    });
  }
  closeAddEmployee() {
    this.setState(prevState => {
      return { addEmployee: false, message: "" };
    });
  }
  answerClose(answer) {
    if (answer) {
      //close
      console.log("will close");
      this.props.close("exit");
    } else {
      console.log("wont close");
      this.setState(prevState => {
        return { confirmation: false };
      });
    }
  }
  closeForm() {
    this.setState({ confirmation: true });
  }
  render() {
    let confirmation,
      message,
      addEmployee,
      deleteEmployee,
      addSignature,
      loading;
    if (this.state.addSignature) {
      addSignature = (
        <SignatureGrab
          worker={{
            name: this.props.firebase.auth.currentUser.displayName,
            uid: this.props.firebase.auth.currentUser.uid
          }}
          grab={(worker, signature) => {
            this.grabSignature(worker, signature);
          }}
          close={this.closeSignatureGrab}
        />
      );
    } else {
      addSignature = "";
    }
    if (this.state.addEmployee) {
      addEmployee = (
        <div>
          <AddEmployee
            employees={this.props.employees}
            addEmployee={this.addEmployee}
          />
          <Backdrop click={this.closeAddEmployee} />{" "}
        </div>
      );
    } else {
      addEmployee = "";
    }
    if (this.state.confirmation) {
      confirmation = (
        <CloseConfirmation
          answer={this.answerClose}
          form="Boring Validation Form"
        />
      );
    } else {
      confirmation = "";
    }
    if (this.state.message !== "" && this.state.message !== undefined) {
      message = <div className="alert alert-danger">{this.state.message}</div>;
    } else {
      message = "";
    }
    if (
      this.state.selectedEmployee !== null &&
      this.state.selectedEmployee !== undefined
    ) {
      deleteEmployee = (
        <button
          onClick={this.deleteEmployee}
          className="btn btn-secondary btn-sm dashboardButton"
        >
          Remove Selected Employee
        </button>
      );
    } else {
      deleteEmployee = "";
    }
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = (
        <button
          disabled={!this.state.agree ? true : false}
          className="btn btn-secondary addEmployeeButton dashboardButton submitTimesheet"
          onClick={() => {
            this.uploadSignature();
          }}
        >
          Submit Bore Procedure Validation
        </button>
      );
    }
    return (
      <div className="jobForm">
        <button
          className="btn btn-secondary btn-sm exit"
          onClick={this.closeForm}
        >
          CLOSE
        </button>
        <div className="jobFormContainer">
          <h1 className="display-5">
            Boring Procedure Validation Form For Job # {this.props.job.id}
          </h1>
          <br />
          {message}
          {confirmation}
          <p className="headline lead">Date:</p>
          <DatePicker
            style={{ width: "100%" }}
            selected={this.state.date}
            onChange={this.onDateSelect}
          />
          <hr />
          <button
            onClick={() => {
              this.onAddEmployeeClick();
            }}
            className="btn btn-secondary addEmployeeButton dashboardButton"
          >
            Add Employee
          </button>
          {addSignature}
          {addEmployee}
          {deleteEmployee}
          <table className="table table-hover">
            <caption>Crew Member's Names</caption>
            <thead className="thead-dark">
              <tr>
                <th />
                <th className="sort" scope="col">
                  Name
                </th>
              </tr>
            </thead>
            <tbody>
              {this.state.employees.map((employee, i) => (
                <tr key={i}>
                  <td>
                    <Checkbox color="primary"
                      checked={
                        this.state.selectedEmployee == employee ? true : false
                      }
                      id="selectEmployee"
                      onChange={() => {
                        this.selectEmployee(employee);
                      }}
                    />
                  </td>
                  <th scope="row">{employee.name}</th>
                </tr>
              ))}
            </tbody>
          </table>
          <hr />
          <div className="row jobFormRow">
            <label htmlFor="orderNumber" className="col col-md-3">
              Order Number
            </label>
            <input
              type="number"
              className="form-control col col-md-9"
              name="orderNumber"
              value={this.state.orderNumber}
              onChange={this.onChange}
            />
          </div>
          <hr />
          <div className="row jobFormRow">
            <div className="col col-md-12">
              <p className="display-5">
                Name Of Company: {this.props.job.type}
              </p>
            </div>
          </div>
          <hr />
          <div className="row jobFormRow">
            <label htmlFor="inspectorsName" className="col col-md-3">
              Inspector's Name
            </label>
            <input
              type="text"
              className="form-control col col-md-9"
              name="inspectorsName"
              value={this.state.inspectorsName}
              onChange={this.onChange}
            />
          </div>
          <hr />

          <div className="row jobFormRow">
            <label htmlFor="inspectorsName" className="col col-md-3">
              Description of Work
            </label>
            <textarea
              type="text"
              className="form-control col col-md-9"
              name="description"
              value={this.state.description}
              onChange={this.onChange}
            />
          </div>
          <hr />
          <div className="row jobFormRow">
            <label htmlFor="address" className="col col-md-6">
              Address/Location of Work
            </label>
            <div className="col col-md-6">
              <button
                className="btn btn-sm btn-secondary"
                onClick={this.getLocation}
              >
                Get Location
              </button>
            </div>
          </div>
          <div className="row jobFormRow">
            <input
              type="text"
              className="form-control col col-md-12"
              name="address"
              value={this.state.address}
              onChange={this.onChange}
            />
          </div>

          <hr />
          <div className="row jobFormRow">
            <div className="col col-md-12">
              {" "}
              <label htmlFor="boreMethod">Bore Method Used</label>
            </div>

            <div className="row jobFormRow">
              <div className="col col-md-4">
                <div className="form-check form-check-inline">
                  <Checkbox color="primary"
                    id="squeezeBore"
                    value="squeezeBore"
                    onChange={() => {
                      this.changeBoreMethod("Squeeze Bore");
                    }}
                    checked={
                      this.state.boreMethod == "Squeeze Bore" ? true : false
                    }
                  />
                  <label className="form-check-label" htmlFor="squeezeBore">
                    Squeeze Bore
                  </label>
                </div>
              </div>
              <div className="col col-md-4">
                <div className="form-check form-check-inline">
                  <Checkbox color="primary"
                    id="guidedBore"
                    value="guidedBore"
                    onChange={() => {
                      this.changeBoreMethod("Guided Bore");
                    }}
                    checked={
                      this.state.boreMethod == "Guided Bore" ? true : false
                    }
                  />
                  <label className="form-check-label" htmlFor="guidedBore">
                    Guided Bore
                  </label>
                </div>
              </div>
              <div className="col col-md-4">
                <div className="form-check form-check-inline">
                  <Checkbox color="primary"
                    id="pushBore"
                    value="pushBore"
                    onChange={() => {
                      this.changeBoreMethod("Push Bore");
                    }}
                    checked={
                      this.state.boreMethod == "Push Bore" ? true : false
                    }
                  />
                  <label className="form-check-label" htmlFor="pushBore">
                    Push Bore
                  </label>
                </div>
              </div>
            </div>
            <div className="row jobFormRow">
              <div className="col col-md-4">
                <div className="form-check form-check-inline">
                  <Checkbox color="primary"
                    id="jackingAugering"
                    value="jackingAugering"
                    onChange={() => {
                      this.changeBoreMethod("Jacking and Augering");
                    }}
                    checked={
                      this.state.boreMethod == "Jacking and Augering"
                        ? true
                        : false
                    }
                  />
                  <label className="form-check-label" htmlFor="jackingAugering">
                    Jacking and Augering
                  </label>
                </div>
              </div>
              <div className="col col-md-4">
                <div className="form-check form-check-inline">
                  <Checkbox color="primary"
                    id="piercingTool"
                    value="piercingTool"
                    onChange={() => {
                      this.changeBoreMethod("Piercing Tool");
                    }}
                    checked={
                      this.state.boreMethod == "Piercing Tool" ? true : false
                    }
                  />
                  <label className="form-check-label" htmlFor="piercingTool">
                    Piercing Tool
                  </label>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className="digitalSignature">
            <h3 className="display-5">Submit Agreement:</h3>
            <p className="lead">
              As Gas Lines Worker/Person In-Charge, my assigned crew and I
              followed all applicable standards and procedures required to
              execute the work provided within the work order.
              <br />
              <br />
              Applicable Standards and Procedures requred for boring operations:
              Gas Operations Construction and Field Practice 005, 008, 012, 013,
              014, 015.
            </p>
            <div className="timesheetSignature sigImage">
              {this.state.agree ? <img src={this.state.agreeSignature} /> : ""}
            </div>
            <br />
            <button
              id="addSignature"
              disabled={this.state.editing ? true : false}
              onClick={this.agreeCheck}
            >
              Add Signature
            </button>
            <div className="row jobFormRow">
              <label
                htmlFor="inspectorsName"
                className="col col-md-3 titleMargin"
              >
                Title
              </label>
              <input
                type="text"
                className="form-control col col-md-9"
                name="title"
                value={this.state.title}
                onChange={this.onChange}
              />
            </div>
            {message}
            {loading}
          </div>
          <BoreValidationSecond />
        </div>
      </div>
    );
  }
}

export default compose(withFirebase)(BoringProcedureValidation);
