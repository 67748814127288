import React, { Component } from "react";
import formatDate from "./../common/formatDate";
import { Link } from "react-router-dom";
import Button from '@material-ui/core/Button';

export default class WeekGlance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: undefined
    };
  }

  render() {
    let content;
    if (this.props.type == "timesheets") {
      content = (
        <div>
          {this.props.reports.map((report, i) => (
            <div key={i}>
              {" "}
              <div className="reportLink recentReportsText row">
                <div className="col col-md-6">
                  Job:{" "}
                  <Link
                    className="btn btn-link recentReportsText"
                    to={"dashboard/reports/job/" + report.job}
                  >
                    {report.job}
                  </Link>
                  <br />
                  Created by: {report.createdBy.name}
                  <br />
                  Date: {formatDate(report.datetime)}
                </div>
                <div className="col col-md-6">
                  <Button variant="contained" color="inherit" className="viewReport"
                    onClick={() => { this.props.setActive(report); }} >
                    View Report
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    } else if (this.props.type == "expenseReports") {
      content = (
        <div>
          {this.props.reports.map((report, i) => (
            <div key={i}>
              {" "}
              <div className="reportLink recentReportsText row">
                <div className="col col-md-6">
                  Job:{" "}
                  <Link
                    className="btn btn-link recentReportsText"
                    to={"dashboard/reports/job/" + report.job}
                  >
                    {report.job}
                  </Link>
                  <br />
                  Created by: {report.employee}
                  <br />
                  Amount: ${report.amount}
                  <br />
                  Date: {formatDate(report.dateTimeOfCharge)}
                </div>
                <div className="col col-md-6">
                  <Button variant="contained" color="inherit" className="viewReport"
                    onClick={() => {
                      this.props.setActive(report);
                    }}
                  >
                    View Report
                  </Button>
                </div>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return <div>{content}</div>;
  }
}
