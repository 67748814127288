import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import Button from '@material-ui/core/Button';

class ViewEquipment extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      message: undefined,
      equipment: {},
      edit: false,
      nameError: false,
      valid: false,
    };
  }
  getEquipmentData = () => {
    this.props.firebase.db
      .collection("equipment")
      .where("name", "==", this.props.match.params.equipment)
      .get()
      .then(
        function(res) {
          res.forEach((truck, i) => {
            this.setState({ equipment: truck.data(), loading: false });
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error getting equipment stuff: " + err.message);
          this.setState({
            message:
              "There Was An Error Accessing The Database. Refresh And Try Again.",
            loading: false,
          });
        }.bind(this)
      );
  };
  componentDidMount() {
    console.log("inside viewEquipment: " + this.props.match.params.equipment);
    this.getEquipmentData();
  }
  getAllEquipment = () => {
    this.props.firebase.db
      .collection("equipment")
      .get()
      .then(
        function(res) {
          console.log("got equipment for all equipment");
          let allEquipment = [];
          res.forEach((truck, i) => {
            console.log("got truck");
            allEquipment.push(truck.data());
          });
          this.setState({ allEquipment: allEquipment, loading: false });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error getting all equipment: " + err.message);
          this.setState({
            message:
              "There Was An Error Accessing The Database. Refresh And Try Again",
            loading: false,
            edit: false,
          });
        }.bind(this)
      );
  };
  editEquipment = () => {
    if (this.state.edit) {
      this.setState({ edit: false });
    } else {
      this.setState(
        { edit: true, loading: true, newName: this.state.equipment.name },
        () => {
          this.getAllEquipment();
        }
      );
    }
  };
  checkName = (name) => {
    let nameGood = true;
    this.state.allEquipment.forEach((truck, i) => {
      if (this.state.newName === truck.name) {
        nameGood = false;
      }
    });
    return nameGood;
  };
  onEquipmentNameChange = (event) => {
    let newName = event.target.value;
    this.setState({ newName: newName }, () => {
      if (this.checkName(newName)) {
        //name is good, should be valid
        console.log("name is good");
        if (newName !== "") {
          this.setState({ valid: true, nameError: false });
        } else {
          this.setState({ valid: false, nameError: false });
        }
      } else {
        //name is taken or empty, invalid
        console.log("name taken");
        this.setState({ nameError: true, valid: false });
      }
    });
  };
  saveEdit = () => {
    this.setState({ loading: true });
    let equipment = this.state.equipment;
    equipment.name = this.state.newName;
    this.props.firebase.db
      .collection("equipment")
      .doc(this.state.equipment.id)
      .set(equipment)
      .then(
        function(res) {
          this.setState({
            message: "Successfully Saved Equipment Edit",
            edit: false,
            loading: false,
          });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error saving equipment name: " + err.message);
        this.setState({
          message: "There Was An Error Saving Equipment Edit",
          loading: false,
        });
      });
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="dashboardPanel">
            <div className="dashboardPanelHeading">
              {this.state.edit ? (
                <div>
                  {" "}
                  <h1 className="display-5">Equipment</h1>
                  <div
                    className={
                      this.state.nameError
                        ? "form-group has-error has-feedback"
                        : "form-group"
                    }
                  >
                    <div className="col-sm-10">
                      <input
                        type="text"
                        id="equipmentName"
                        className={
                          this.state.nameError
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        value={this.state.newName}
                        onChange={this.onEquipmentNameChange}
                      />
                      <span className="glyphicon glyphicon-warning-sign form-control-feedback" />
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <h1 className="display-5">
                    Equipment: {this.state.equipment.name}
                  </h1>
                </div>
              )}

              {this.state.message !== undefined ? (
                <p className="alert alert-danger">{this.state.message}</p>
              ) : (
                ""
              )}
              <hr />
              <div className="dashboardControls">
                <Button variant="contained" color="inherit" onClick={() => { this.upload(); }}>
                  Upload Equipment Picture
                </Button>
                <Button variant="contained" color="inherit" onClick={() => { this.editEquipment(); }} >
                  Edit
                </Button>
                {this.state.edit ? (
                  <Button variant="contained" color="inherit" onClick={this.saveEdit} disabled={this.state.valid ? false : true}>
                    {this.state.edit ? "Cancel Edit" : "Edit"}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(ViewEquipment);
