import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Job from "./Job";
import Jobs from "./Jobs";
import "./employeeJobs.css";

class Index extends Component {
  render() {
    return (
      <div className="jobsContainer">
        <Route exact path="/employee-dashboard/jobs" component={Jobs} />
        <Route exact path="/employee-dashboard/jobs/:job" component={Job} />
      </div>
    );
  }
}

export default Index;
