import React from 'react';
import './confirmation.css';
import Button from '@material-ui/core/Button';

export default function Confirmation(props) {
  return (
    <div className="card confirmation">
      <p className="lead">Are You Sure You Want To <b>Delete</b> Job #{props.job}?</p>
      <div className="confirmationControls" style={{ marginTop: "1em" }}>
        <Button color="inherit" variant="contained" onClick={() => { props.answer(true) }} >Yes</Button>
        <Button color="inherit" variant="contained" onClick={() => { props.answer(false) }}>No</Button>
        <Button color="inherit" variant="contained" onClick={() => { props.answer(false) }}>Cancel</Button>
      </div>
    </div>
  )
}
