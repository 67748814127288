import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import formatDate from "./../../common/formatDate";
import ClockOutConfirmation from "./ClockOutConfirmation";
import Timesheet from "./timesheet/Timesheet";
import TimesheetsTable from "./TimesheetsTable";
import CancelTIPConfirmation from "./CancelTIPConfirmation";
import JobPhotos from "./JobPhotos";
import BoringProcedureValidation from "./BoringProcedureValidation";
import BorePreShot from "./BorePreShot";
import ConfirmReset from "./ConfirmReset";

class Job extends Component {
  constructor() {
    super();
    this.state = {
      job: {},
      loading: true,
      tips: undefined,
      message: undefined,
      getImage: false,
      imageUpload: false,
      jobStartPhoto: undefined,
      jobFinishPhoto: undefined,
      otherJobPhoto: undefined,
      clockOutConfirmation: false,
      timesheet: false,
      cancelTIPConfirmation: false,
      boringProcedureValidation: false,
      boringPreShotChecklist: false,
      haveBoringProcedure: false,
      haveBoringPreShot: false,
      confirmReset: false,
    };
    this.clockIn = this.clockIn.bind(this);
    this.clockOut = this.clockOut.bind(this);
    this.getTimesheetsInProgress = this.getTimesheetsInProgress.bind(this);
    this.getTimesheets = this.getTimesheets.bind(this);
    this.getJob = this.getJob.bind(this);
    this.cancelTIP = this.cancelTIP.bind(this);
    this.addJobFinishPhotos = this.addJobFinishPhotos.bind(this);
    this.addJobStartPhotos = this.addJobStartPhotos.bind(this);
    this.addOtherJobPhotos = this.addOtherJobPhotos.bind(this);
    this.finishClockIn = this.finishClockIn.bind(this);

    this.getJobImages = this.getJobImages.bind(this);

    this.getDocuments = this.getDocuments.bind(this);
    this.clockOutConfirmationAnswer = this.clockOutConfirmationAnswer.bind(
      this
    );
    this.closeTimesheet = this.closeTimesheet.bind(this);
    this.getEmployees = this.getEmployees.bind(this);
    this.checkTimesheets = this.checkTimesheets.bind(this);
    this.deleteJobPhotos = this.deleteJobPhotos.bind(this);
    this.cancelTIPConfirmationAnswer = this.cancelTIPConfirmationAnswer.bind(
      this
    );
    this.closeCancelTIPConfirmation = this.closeCancelTIPConfirmation.bind(
      this
    );
    this.cancelTIPConfirmation = this.cancelTIPConfirmation.bind(this);
    this.doneDeleting = this.doneDeleting.bind(this);
    this.doneEditing = this.doneEditing.bind(this);
    this.openBoringProcedure = this.openBoringProcedure.bind(this);
    this.closeBoringProcedure = this.closeBoringProcedure.bind(this);
    this.openBoringPreShot = this.openBoringPreShot.bind(this);
    this.closeBoringPreShot = this.closeBoringPreShot.bind(this);
    this.finishBoringProcedure = this.finishBoringProcedure.bind(this);
    this.getBoringProcedure = this.getBoringProcedure.bind(this);
  }
  getBoringProcedure() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets-in-progress")
      .doc(this.state.tips[0].id)
      .collection("boringProcedure")
      .doc(this.state.tips[0].id)
      .get()
      .then(
        function(boringSnapshot) {
          if (boringSnapshot.exists) {
            this.setState({
              haveBoringProcedure: true,
              boringProcedure: boringSnapshot.data(),
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting boringProcedure: " + err.message);
      });
  }
  finishBoringProcedure() {
    this.setState(
      {
        boringProcedureValidation: false,
        message: "Boring Procedure Validation Successfully Submitted",
      },
      () => {
        this.getBoringProcedure();
      }
    );
  }
  finishBoringPreShot = () => {
    this.setState(
      {
        boringPreShot: false,
        message: "Bore Pre Shot Checklist Successfully Submitted",
      },
      () => {
        this.getBoringPreShot();
      }
    );
  };
  getBoringPreShot = () => {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets-in-progress")
      .doc(this.state.tips[0].id)
      .collection("borePreShot")
      .doc(this.state.tips[0].id)
      .get()
      .then(
        function(borePreShotSnapshot) {
          if (borePreShotSnapshot.exists) {
            this.setState({
              haveBoringPreShot: true,
              boringPreShotChecklist: borePreShotSnapshot.data(),
            });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting borePreShot: " + err.message);
      });
  };
  closeBoringPreShot() {
    this.setState({ boringPreShot: false });
  }
  closeBoringProcedure() {
    this.setState({ boringProcedureValidation: false });
  }
  openBoringPreShot() {
    window.scrollTo(0, 0);
    this.setState({ boringPreShot: true });
  }
  openBoringProcedure() {
    window.scrollTo(0, 0);
    this.setState({ boringProcedureValidation: true });
  }
  doneDeleting() {
    this.setState({ message: "Timesheet Deleted Successfully" });
    this.getTimesheets();
  }
  doneEditing() {
    this.setState({ message: "Timesheet Edited Successfully" });
    this.getTimesheets();
  }
  cancelTIPConfirmationAnswer(answer) {
    if (answer) {
      this.setState({ cancelTIPConfirmation: false, loading: true }, () => {
        this.cancelTIP();
      });
    } else {
      this.setState({ cancelTIPConfirmation: false });
    }
  }
  closeCancelTIPConfirmation() {
    this.setState({ cancelTIPConfirmation: false });
  }
  cancelTIPConfirmation() {
    this.setState({ cancelTIPConfirmation: true });
  }
  getEmployees() {
    let employees = [];
    let employeeRef = this.props.firebase.db.collection("users");
    employeeRef
      .get()
      .then(
        function(employeeSnapshot) {
          employeeSnapshot.forEach(function(employee) {
            let newEmployee = {
              email: employee.data().email,
              name: employee.data().name,
              uid: employee.data().uid,
              type: employee.data().type,
            };
            employees.push(newEmployee);
          });
          this.setState((prevState) => {
            return { employees: employees, loading: false };
          });
        }.bind(this)
      )
      .catch(function(err) {
        console.log(err.message);
      });
  }
  closeTimesheet(words) {
    if (words == "success") {
      this.setState(
        { timesheet: false, message: "Timesheet submitted successfully" },
        () => {
          this.getTimesheetsInProgress();
        }
      );
    } else {
      this.setState({ timesheet: false });
    }
  }
  getDocuments() {
    let docs = [];
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("documents")
      .get()
      .then(
        function(documentsSnapshot) {
          documentsSnapshot.forEach(function(doc) {
            docs.push(doc.data());
          });
          this.setState((prevState) => {
            return { jobDocuments: docs };
          });
        }.bind(this)
      );
  }
  closeWSM = () => {
    this.setState({ wsm: false });
  };

  cancelGetImage() {
    this.setState((prevState) => {
      return { getImage: false };
    });
  }
  addJobFinishPhotos() {
    this.setState((prevState) => {
      return { getImage: true, imageType: "finish" };
    });
  }
  addJobStartPhotos() {
    this.setState((prevState) => {
      return { getImage: true, imageType: "start" };
    });
  }
  addOtherJobPhotos() {
    this.setState((prevState) => {
      return { getImage: true, imageType: "other" };
    });
  }

  cancelTIP() {
    //get and delete job images
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets-in-progress")
      .doc(this.state.tips[0].id)
      .collection("job-photos")
      .get()
      .then(
        function(imageSnapshot) {
          let images = [];
          imageSnapshot.forEach(function(image) {
            images.push(image.data());
          });
          this.deleteJobPhotos(images);
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting images: " + err.message);
      });
  }
  deleteJobPhotos(photos) {
    for (let i = 0; i < photos.length; i++) {
      const storageRef = this.props.firebase.storage.ref();
      let path, ref;
      path = photos[i].storagePath;
      ref = storageRef.child(path);

      ref
        .delete()
        .then(
          function() {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.state.job.id)
              .collection("timesheets-in-progress")
              .doc(this.state.tips[0].id)
              .collection("job-photos")
              .doc(photos[i].id)
              .delete()
              .then(function() {
                console.log("deleted image");
              })
              .catch(function(err) {
                console.log("error deleting image: " + err.message);
              });
          }.bind(this)
        )
        .catch(function(err) {
          console.log("error deleting photo: " + err.message);
        });
    }
    //delete tailboard if there is one, then delete tip
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets-in-progress")
      .doc(this.state.tips[0].id)
      .collection("boringProcedure")
      .doc(this.state.tips[0].id)
      .get()
      .then(
        function(boringSnapshot) {
          if (boringSnapshot.exists) {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.state.job.id)
              .collection("timesheets-in-progress")
              .doc(this.state.tips[0].id)
              .collection("boringProcedure")
              .doc(this.state.tips[0].id)
              .delete()
              .then(
                function() {
                  console.log("deleted boring");
                  this.deleteBorePreShot();
                }.bind(this)
              );
          } else {
            this.deleteBorePreShot();
          }
        }.bind(this)
      );
  }
  deleteBorePreShot = () => {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets-in-progress")
      .doc(this.state.tips[0].id)
      .collection("borePreShot")
      .doc(this.state.tips[0].id)
      .get()
      .then(
        function(boreSnapshot) {
          if (boreSnapshot.exists) {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.state.job.id)
              .collection("timesheets-in-progress")
              .doc(this.state.tips[0].id)
              .collection("borePreShot")
              .doc(this.state.tips[0].id)
              .delete()
              .then(
                function() {
                  this.deleteTailboard();
                }.bind(this)
              )
              .catch(function(err) {
                console.lob("error deleting borePreShot: " + err.message);
              });
          } else {
            this.deleteTailboard();
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting borePreShot: " + err.message);
      });
  };
  deleteTailboard = () => {
    // TODO replace with dayjs
    let date = new Date();

    let month = parseInt(date.getMonth() + 1);
    let year = date.getFullYear();
    let day = date.getDay();
    let docString = month + "-" + date.getFullYear();
    let docString2 = date.getDate() + "-" + docString;
    let timesheetTitle =
      this.props.firebase.auth.currentUser.uid +
      "-" +
      this.state.job.id +
      "-" +
      docString2;
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets-in-progress")
      .doc(this.state.tips[0].id)
      .collection("tailboards")
      .doc(this.state.tips[0].id)
      .get()
      .then(
        function(tailboard) {
          if (tailboard.exists) {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.state.job.id)
              .collection("timesheets-in-progress")
              .doc(this.state.tips[0].id)
              .collection("tailboards")
              .doc(this.state.tips[0].id)
              .delete()
              .then(
                function() {
                  //call delete tip db
                  this.props.firebase.db
                    .collection("jobs")
                    .doc(this.state.job.id)
                    .collection("timesheets-in-progress")
                    .doc(this.state.tips[0].id)
                    .delete()
                    .then(
                      function() {
                        this.setState({
                          tips: undefined,
                          loading: false,
                          message: "",
                        });
                      }.bind(this)
                    )
                    .catch(function(err) {
                      console.log("error: " + err.message);
                    });
                }.bind(this)
              );
          } else {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.state.job.id)
              .collection("timesheets-in-progress")
              .doc(this.state.tips[0].id)
              .delete()
              .then(
                function() {
                  this.setState({
                    tips: undefined,
                    loading: false,
                    message: "",
                  });
                }.bind(this)
              )
              .catch(function(err) {
                console.log("error: " + err.message);
              });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting tailboards : " + err.message);
      });
  };
  answerConfirmReset = (answer) => {
    if (answer) {
      this.setState({ confirmReset: false }, () => {
        this.restartTime();
      });
    } else {
      this.setState({ confirmReset: false });
    }
  };
  closeConfirmReset = () => {
    this.setState({ confirmReset: false });
  };
  confirmReset = () => {
    this.setState({ confirmReset: true });
  };
  restartTime = () => {
    let date = new Date();
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets-in-progress")
      .doc(this.state.tips[0].id)
      .update({ timeIn: date.getTime() })
      .then(
        function(res) {
          console.log("updated timesheet in progress");
          this.getJob();
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error updating timesheet in progress: " + err.message);
      });
  };
  clockOut() {
    let date = new Date();
    let clockOutTime = date.getTime();
    let difference = clockOutTime - this.state.tips[0].timeIn;
    let seconds = difference / 1000;
    let minutes = seconds / 60;
    let hours = minutes / 60;
    this.setState({
      clockOutConfirmation: true,
      hours: hours,
      clockOutTime: clockOutTime,
      clockInTime: this.state.tips[0].timeIn,
    });
  }
  clockOutConfirmationAnswer(answer) {
    if (answer) {
      this.setState({ clockOutConfirmation: false, timesheet: true });
    } else {
      this.setState({ clockOutConfirmation: false });
    }
  }
  clockIn() {
    this.setState({ loading: true });
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets-in-progress")
      .get()
      .then(
        function(tipSnapshot) {
          let alreadyTIP = false;
          tipSnapshot.forEach(
            function(tip) {
              if (
                tip.data().createdBy ==
                this.props.firebase.auth.currentUser.displayName
              ) {
                this.setState({
                  message: "You Already Have A Timesheet In Progress",
                  loading: false,
                });
                alreadyTIP = true;
              }
              if (!alreadyTIP) {
                this.checkTimesheets();
              }
            }.bind(this)
          );
          if (!alreadyTIP) {
            this.checkTimesheets();
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error : " + err.message);
      });
  }
  checkTimesheets() {
    //check timesheets for today
    let date = new Date();

    let month = parseInt(date.getMonth() + 1);
    let year = date.getFullYear();
    let day = date.getDay();
    let docString = month + "-" + date.getFullYear();
    let docString2 = date.getDate() + "-" + docString;
    let timesheetTitle =
      this.props.firebase.auth.currentUser.uid +
      "-" +
      this.state.job.id +
      "-" +
      docString2;
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets")
      .doc(timesheetTitle)
      .get()
      .then(
        function(sheet) {
          if (sheet.exists) {
            //have timesheet for that day
            if (this.state.job.shopTime) {
              this.finishClockIn();
            } else {
              this.setState({
                message:
                  "You Already Have A Timesheet For Today, Edit That One",
                loading: false,
              });
            }
          } else {
            this.finishClockIn();
          }
        }.bind(this)
      );
  }
  finishClockIn() {
    // TODO replace with dayjs
    this.setState({ loading: true });
    let date = new Date();
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    let dateString = day + "-" + month + "-" + year;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          let sheet = {
            createdBy: this.props.firebase.auth.currentUser.displayName,
            timeIn: date.getTime(),
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            date: date,
          };
          let docRef = this.props.firebase.db
            .collection("jobs")
            .doc(this.state.job.id)
            .collection("timesheets-in-progress")
            .doc();

          sheet.id = docRef.id;
          docRef.set(sheet).then(
            function() {
              let tips = [];
              tips.push(sheet);
              this.setState(
                { tips: tips },
                function() {
                  this.getJob();
                }.bind(this)
              );
            }.bind(this)
          );
        },
        () => {
          let sheet = {
            createdBy: this.props.firebase.auth.currentUser.displayName,
            timeIn: date.getTime(),
            date: date,
          };
          let docRef = this.props.firebase.db
            .collection("jobs")
            .doc(this.state.job.id)
            .collection("timesheets-in-progress")
            .doc();

          sheet.id = docRef.id;
          docRef.set(sheet).then(
            function() {
              let tips = [];
              tips.push(sheet);
              this.setState(
                { tips: tips },
                function() {
                  this.getJob();
                }.bind(this)
              );
            }.bind(this)
          );
        }
      );
    } else {
      let sheet = {
        createdBy: this.props.firebase.auth.currentUser.displayName,
        timeIn: date.getTime(),
        date: date,
      };
      let docRef = this.props.firebase.db
        .collection("jobs")
        .doc(this.state.job.id)
        .collection("timesheets-in-progress")
        .doc();

      sheet.id = docRef.id;
      docRef.set(sheet).then(
        function() {
          let tips = [];
          tips.push(sheet);
          this.setState(
            { tips: tips },
            function() {
              this.getJob();
            }.bind(this)
          );
        }.bind(this)
      );
    }
  }
  getJobImages() {
    this.setState({ loading: true });
    if (this.state.tips[0] !== undefined) {
      this.props.firebase.db
        .collection("jobs")
        .doc(this.state.job.id)
        .collection("timesheets-in-progress")
        .doc(this.state.tips[0].id)
        .collection("job-photos")
        .get()
        .then(
          function(jobPhotoSnapshot) {
            let jobPhotos = [];
            jobPhotoSnapshot.forEach(
              function(jobPhoto) {
                jobPhotos.push(jobPhoto.data());
              }.bind(this)
            );
            this.setState({ jobPhotos: jobPhotos, loading: false });
          }.bind(this)
        );
    }
    this.getDocuments();
    this.getEmployees();
    this.getBoringProcedure();
    this.getBoringPreShot();
  }

  getTimesheetsInProgress() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets-in-progress")
      .get()
      .then(
        function(tipSnapshot) {
          let tips = [];

          tipSnapshot.forEach(
            function(tip) {
              if (
                tip.data().createdBy ==
                this.props.firebase.auth.currentUser.displayName
              ) {
                let docId = tip.id;
                let timesheetInProgress = tip.data();
                timesheetInProgress.id = docId;
                tips.push(timesheetInProgress);
              }
            }.bind(this)
          );
          this.setState(
            (prevState) => {
              return { tips: tips };
            },
            function() {
              this.getTimesheets();
            }
          );
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error :" + err.message);
      });
  }
  getTimesheets() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.state.job.id)
      .collection("timesheets")
      .get()
      .then(
        function(timesheetSnapshot) {
          let timesheets = [];
          timesheetSnapshot.forEach(
            function(timesheet) {
              if (
                timesheet.data().createdBy.uid ==
                this.props.firebase.auth.currentUser.uid
              ) {
                timesheets.push(timesheet.data());
              }
            }.bind(this)
          );
          this.setState(
            (prevState) => {
              return { timesheets: timesheets };
            },
            () => {
              if (this.state.tips.length > 0) {
                this.getJobImages();
              } else {
                this.getEmployees();
              }
            }
          );
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error :" + err.message);
      });
  }
  getJob() {
    let docRef = this.props.firebase.db
      .collection("jobs")
      .doc(this.props.match.params.job);

    docRef
      .get()
      .then(
        function(job) {
          if (job.exists) {
            this.setState(
              (prevState) => {
                return {
                  job: job.data(),
                };
              },
              function() {
                this.getTimesheetsInProgress();
                this.getDocuments();
              }
            );
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error: " + err.message);
      });
  }
  componentDidMount() {
    this.getJob();
  }
  render() {
    let loading,
      jobControls,
      message,
      upload,
      jobDocuments,
      clockOutConfirmation,
      timesheet,
      boringProcedure,
      boringPreShot;
    if (this.state.boringPreShot) {
      boringPreShot = (
        <BorePreShot
          job={this.state.job}
          finish={this.finishBoringPreShot}
          tip={this.state.tips[0]}
          borePreShot={
            this.state.haveBoringPreShot
              ? this.state.boringPreShotChecklist
              : undefined
          }
          close={this.closeBoringPreShot}
          node="tip"
        />
      );
    } else {
      boringPreShot = "";
    }
    if (this.state.boringProcedureValidation) {
      boringProcedure = (
        <BoringProcedureValidation
          job={this.state.job}
          finish={this.finishBoringProcedure}
          tip={this.state.tips[0]}
          boringProcedure={
            this.state.haveBoringProcedure
              ? this.state.boringProcedure
              : undefined
          }
          close={this.closeBoringProcedure}
          employees={this.state.employees}
          node="tip"
        />
      );
    } else {
      boringProcedure = "";
    }
    if (this.state.timesheet) {
      timesheet = (
        <Timesheet
          hours={this.state.hours}
          clockOutTime={this.state.clockOutTime}
          clockInTime={this.state.clockInTime}
          job={this.state.job}
          close={this.closeTimesheet}
          employees={this.state.employees}
          tip={this.state.tips[0]}
          uid={this.props.firebase.auth.currentUser.uid}
          timesheets={this.state.timesheets}
        />
      );
    } else {
      timesheet = "";
    }
    if (
      this.state.jobDocuments !== undefined &&
      this.state.jobDocuments.length > 0
    ) {
      jobDocuments = (
        <div>
          {this.state.jobDocuments.map((doc, i) => (
            <div key={i}>
              <a target="_blank" href={doc.documentURL}>
                {doc.documentName}
              </a>
              <br />
            </div>
          ))}
        </div>
      );
    } else {
      jobDocuments = <p>There Are No Documents For This Job</p>;
    }

    if (this.state.message !== undefined) {
      message = <div className="alert alert-danger">{this.state.message}</div>;
    } else {
      message = "";
    }
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      if (this.state.tips !== undefined && this.state.tips.length > 0) {
        let date = new Date(this.state.tips[0].timeIn);
        let dateTime = formatDate(date);
        jobControls = (
          <div className="jobControls">
            {" "}
            <div className="alert alert-primary">Clocked In At: {dateTime}</div>
            {message}
            <div className="row row-space">
              <JobPhotos
                job={this.state.job}
                tip={this.state.tips[0]}
                getJobImages={this.getJobImages}
                jobPhotos={this.state.jobPhotos}
              />
              <div className="clock col-md-4">
                Job Clock <hr />{" "}
                <a
                  className="btn btn-secondary dashboardButton"
                  onClick={() => {
                    this.confirmReset();
                  }}
                >
                  <i className="buttonIcon fas fa-stopwatch" />
                  Reset Job Time
                </a>
                <a
                  className="btn btn-secondary dashboardButton"
                  onClick={() => {
                    this.clockOut();
                  }}
                >
                  <i className="buttonIcon fas fa-stopwatch" />
                  Clock Out
                </a>
                <a
                  className="btn btn-secondary dashboardButton"
                  onClick={() => {
                    this.cancelTIPConfirmation();
                  }}
                >
                  <i className="buttonIcon fas fa-stopwatch" />
                  Cancel Timesheet
                </a>
              </div>
              <div className="jobDocuments col-md-4">
                Job Documents
                <hr />
                {jobDocuments}
              </div>
            </div>
            <div className="row row-space">
              <div className="safety col-md-4">
                Consumers Energy
                <hr />
                <a
                  className="btn btn-secondary dashboardButton"
                  onClick={() => {
                    this.openBoringPreShot();
                  }}
                >
                  Directional Bore Pre-Shot Checklist
                </a>
                <a
                  className="btn btn-secondary dashboardButton"
                  onClick={() => {
                    this.openBoringProcedure();
                  }}
                >
                  Boring Procedure Validation Form
                </a>
              </div>
            </div>
          </div>
        );
      } else {
        jobControls = (
          <div>
            {message}
            <a
              className="btn btn-secondary dashboardButton"
              onClick={() => {
                this.clockIn();
              }}
            >
              <i className="buttonIcon fas fa-stopwatch" />
              Clock In
            </a>
            <hr />
            <TimesheetsTable
              job={this.state.job}
              timesheets={this.state.timesheets}
              employees={this.state.employees}
              uid={this.props.firebase.auth.currentUser.uid}
              refresh={() => {
                this.doneDeleting();
              }}
              refreshEdit={() => {
                this.doneEditing();
              }}
            />
          </div>
        );
      }
      loading = (
        <div className="dashboardPanel">
          <div className="dashboardPanelHeading">
            <h1 className="display-5">Job #{this.state.job.id}</h1>
            <p>
              <b>Title: </b>
              {this.state.job.title}
            </p>
            {this.state.job.shopTime ? <b>Shop Time</b> : ""}
            <p>
              <b>Description: </b>
              {this.state.job.description}
            </p>
            <div className="jobDocuments col-md-4">
              Job Documents
              <hr />
              {jobDocuments}
            </div>
          </div>
          { this.state.cancelTIPConfirmation &&
            (
              <CancelTIPConfirmation
                answer={this.cancelTIPConfirmationAnswer}
                close={this.closeCancelTIPConfirmation}
                open={this.state.cancelTIPConfirmation}
              />
            )
          }
          {this.state.clockOutConfirmation && 
            (
              <ClockOutConfirmation
                open={this.state.clockOutConfirmation}
                hours={this.state.hours}
                answer={this.clockOutConfirmationAnswer}
              />
            )
          }
          {boringProcedure}
          {boringPreShot}
          {timesheet}

          {this.state.confirmReset ? (
            <ConfirmReset
              answer={this.answerConfirmReset}
              close={this.closeConfirmReset}
            />
          ) : (
            ""
          )}
          <hr />
          <div className="dashboardControls">{jobControls}</div>
        </div>
      );
    }
    return <div>{loading}</div>;
  }
}
const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  authError: state.auth.authError,
  type: state.auth.type,
});
Job.propTypes = {
  auth: PropTypes.object.isRequired,
  authError: PropTypes.string,
  type: PropTypes.string.isRequired,
};

export default compose(withFirebase, connect(mapStateToProps))(Job);
