import React from "react";
import { Link } from "react-router-dom";

function Tabs() {
  // TODO fix this and use some other type of routing or state instead. 
  let urlString = window.location.href;
  let length = urlString.length - 2;
  let lastChar = urlString.substr(-1);
  let partialStringIndex;
  if (lastChar !== "/") {
    partialStringIndex = urlString.indexOf("/reports");
  } else {
    partialStringIndex = urlString.indexOf("/reports/");
  }

  let partialString = urlString.slice(partialStringIndex);
  return (
    <div className="tabs">
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            to="/dashboard/reports/weekly"
            className={
              partialString == "/reports/weekly" ||
              partialString == "/reports/weekly/" ||
              partialString == "/reports" ||
              partialString == "/reports/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Weekly
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/reports/job"
            className={
              partialString == "/reports/job" ||
              partialString == "/reports/job/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Jobs
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/reports/expense-reports"
            className={
              partialString == "/reports/expense-reports" ||
              partialString == "/reports/expense-reports/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            Expense Reports
          </Link>
        </li>
        <li className="nav-item">
          <Link
            to="/dashboard/reports/wsm"
            className={
              partialString == "/reports/wsm" ||
              partialString == "/reports/wsm/"
                ? "tabLink nav-link active"
                : "tabLink nav-link"
            }
          >
            WSM Reports
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default Tabs;
