import React, { Component } from "react";
import { withFirebase } from "../../../firebase";
import { compose } from "redux";
import Tabs from "./Tabs";
import WSMTable from "./WSMTable";
import ViewPrintableReport from "./ViewPrintableReport";
import ViewOptions from "./ViewOptions";
import Backdrop from "./../../common/Backdrop";
import ViewWSMPictures from "./ViewWSMPictures";

class WSMReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: undefined,
      wsm: [],
      selectedWSM: undefined,
      viewReport: false,
    };
  }
  getWSM = () => {
    this.props.firebase.db
      .collection("wsm")
      .get()
      .then(
        function(res) {
          let wsm = [];
          res.forEach(rep => {
            wsm.push(rep.data());
          });
          this.setState({ wsm: wsm, loading: false });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting wsm from db: " + err.message);
      });
  };
  componentDidMount() {
    this.getWSM();
  }
  openWSM = (wsm) => {
    this.setState({ viewOptions: true, selectedReport: wsm });
  };
  openReport = () => {
    this.setState({ viewReport: true, viewOptions: false });
  };
  openPics = () => {
    this.setState({ viewPictures: true, viewOptions: false });
  };
  closePics = () => {
    this.setState({ viewPictures: false, viewOptions: true });
  };
  closeReport = () => {
    this.setState({ viewReport: false, viewOptions: true });
  };
  closeViewOptions = () => {
    this.setState({ viewOptions: false, selectedReport: undefined });
  };
  render() {
    return (
      <div>
        <Tabs />
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div>
            <WSMTable wsm={this.state.wsm} open={this.openWSM} />
            {this.state.viewOptions ? (
              <div>
                <ViewOptions
                  report={this.state.selectedReport}
                  openReport={this.openReport}
                  openPics={this.openPics}
                />
                <Backdrop click={this.closeViewOptions} />
              </div>
            ) : (
              ""
            )}
            {this.state.viewReport ? (
              <ViewPrintableReport
                report={this.state.selectedReport}
                type="WSM"
                exit={this.closeReport}
              />
            ) : (
              ""
            )}
          </div>
        )}
        {this.state.viewPictures ? (
          <ViewWSMPictures
            wsm={this.state.selectedReport}
            close={this.closePics}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(WSMReports);
