import React, { Component } from "react";
import formatDate from "../../common/formatDate";

export default class ViewBorePreShot extends Component {
  constructor(props) {
    super();
    this.state = {
      questions: [
        "Tailboard Conference Completed?",
        "Job Site Secure With Proper Dilineators And Caution Tape?",
        'Gas and all other utility crossings verified/spotted per GOM standards "Damage Prevention and Third Party Activity"? ',
        "Walked With Crew Leader using prints and service cards?",
        "Have the sewers been located/verified?",
        'Proposed route staked/painted according to GOM standards "Boring Near Existing Gas Facilities"?',
        "Evidence of unmarked utilities?",
        "Voltage stake is in place and strike alert has been tested?",
        "Radio communications verified?",
        "Locator calibration verified?",
        "Remote lockout tested?"
      ],
      operator: {},
      locator: {},
      pic: {}
    };
  }

  render() {
    return (
      <div className="viewReport" style={{ backgroundColor: "white" }}>
        <div
          className="dashboardPanelHeading"
          style={{ backgroundColor: "white" }}
        >
          <h3 className="display-5">
            Bore Pre-Shot Checklist For Job #{this.props.timesheet.job.id}
          </h3>
          <p className="lead">
            Created by: {this.props.timesheet.createdBy.name}
            <br />
            Date: {formatDate(this.props.timesheet.datetime)}
          </p>
        </div>
        <div
          className="workersAndSignatures"
          style={{ display: "inline-block" }}
        >
          <h3 className="display-5">Walked By</h3>
          <div
            style={{
              display: "inline-block",
              width: "300px",

              margin: "0 1em",
              border: "1px solid black"
            }}
            className="workerAndSignature col col-md-4"
          >
            <h3 className="display-5">Operator</h3>
            <p className="lead">
              <b>Name: </b>
              {this.props.timesheet.borePreShot.operator.name}
            </p>
            <img
              style={{ width: "100%", height: "auto" }}
              className="borePreShotSignatures"
              src={
                this.props.timesheet.borePreShot.operator.signature.signatureURL
              }
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: "300px",
              margin: "0 1em",
              border: "1px solid black"
            }}
            className="workerAndSignature col col-md-4"
          >
            <h3 className="display-5">Locator</h3>
            <p className="lead">
              <b>Name: </b>
              {this.props.timesheet.borePreShot.locator.name}
            </p>
            <img
              style={{ width: "100%", height: "auto" }}
              className="borePreShotSignatures"
              src={
                this.props.timesheet.borePreShot.locator.signature.signatureURL
              }
            />
          </div>
          <div
            style={{
              display: "inline-block",
              width: "300px",
              margin: "0 1em",
              border: "1px solid black"
            }}
            className="workerAndSignature col col-md-4"
          >
            <h3 className="display-5">PIC</h3>
            <p className="lead">
              <b>Name: </b>
              {this.props.timesheet.borePreShot.pic.name}
            </p>
            <img
              style={{ width: "100%", height: "auto" }}
              className="borePreShotSignatures"
              src={this.props.timesheet.borePreShot.pic.signature.signatureURL}
            />
          </div>
        </div>
        <h3 className="display-5">Bore</h3>
        <p className="lead">
          Bore Size: {this.props.timesheet.borePreShot.boreSize}
        </p>
        <p className="lead">
          Length: {this.props.timesheet.borePreShot.length}
        </p>
        <p className="lead">
          Specific Location Of Bore:{" "}
          {this.props.timesheet.borePreShot.locationOfBore}
        </p>
        <hr />
        <div className="generalSafetyQuestions">
          {this.state.questions.map((question, i) => (
            <p key={i} className="lead">
              {question} - {i==6?'NO':'YES'}
            </p>
          ))}
          <hr />
        </div>
      </div>
    );
  }
}
