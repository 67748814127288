import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./store/reducers/index";
import { reduxFirestore, getFirestore } from "redux-firestore";
import {
  reactReduxFirebase,
  getFirebase,
  authIsReady
} from "react-redux-firebase";
import Firebase, { FirebaseContext } from "./firebase/index";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const middleWare = [thunk.withExtraArgument({ getFirebase, getFirestore })];

const initialState = {};
const FirebaseInstance = new Firebase();

const store = createStore(
  rootReducer,
  initialState,
  compose(
    applyMiddleware(...middleWare),
    reduxFirestore(firebase, FirebaseInstance),
    reactReduxFirebase(firebase, FirebaseInstance, {
      attachAuthIsReady: true,
      firebaseStateName: "firebase"
    })
  )
);
serviceWorker.register();
authIsReady(store).then(() => {
  ReactDOM.render(
    <FirebaseContext.Provider value={FirebaseInstance}>
      <Provider store={store}>
        <App style={{ height: "100%" }} />
      </Provider>
    </FirebaseContext.Provider>,
    document.getElementById("root")
  );
});
