import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import EquipmentTable from "../../common/EquipmentTable";
import RequestsTable from "./../../common/RequestsTable";
import AddRequest from "./AddRequest";
import Backdrop from "./../../common/Backdrop";
import DeleteRequestConfirmation from "./../../common/DeleteRequestConfirmation";
import EditMaintenanceRequest from "./EditMaintenanceRequest";
import "./maintenance.css";

class Maintenance extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      message: undefined,
      equipment: [],
      selectedEquipment: undefined,
      selectedRequest: undefined,
      deleteRequest: false,
    };
  }
  componentDidMount() {
    this.getEquipment();
    this.getRequests();
  }
  viewEquipment = () => {
    this.props.history.push(
      "/employee-dashboard/maintenance/equipment/" +
        this.state.selectedEquipment.name
    );
  };
  selectEquipment = (equipment) => {
    if (equipment == null) {
      this.setState({ selectedEquipment: undefined });
    } else {
      this.setState({ selectedEquipment: equipment });
    }
  };
  selectRequest = (request) => {
    if (request == null) {
      this.setState({ selectedRequest: undefined });
    } else {
      this.setState({ selectedRequest: request });
    }
  };
  getEquipment = () => {
    this.props.firebase.db
      .collection("equipment")
      .get()
      .then(
        function(res) {
          let equipment = [];
          res.forEach((truck, i) => {
            console.log("got truck");
            equipment.push(truck.data());
          });
          this.setState({ equipment: equipment });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error getting equipment: " + err.message);
          this.setState({
            message:
              "There Was An Error Accessing The Database. Refresh And Try Again.",
            loading: false,
          });
        }.bind(this)
      );
  };
  getRequests = () => {
    this.props.firebase.db
      .collection("maintenance")
      .where("employee", "==", this.props.firebase.auth.currentUser.displayName)
      .get()
      .then(
        function(res) {
          let requests = [];
          res.forEach((request, i) => {
            requests.push(request.data());
          });
          this.setState({ requests: requests, loading: false });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting requests: " + err.message);
      });
  };
  addRequest = () => {
    this.setState({ addRequest: true });
  };
  deleteRequest = () => {
    this.setState({ deleteRequest: true });
  };
  answerDeleteRequest = (answer) => {
    if (answer) {
      //will delete
      this.setState({ loading: true, deleteRequest: false });
      this.props.firebase.db
        .collection("maintenance")
        .doc(this.state.selectedRequest.id)
        .delete()
        .then(
          function(res) {
            this.setState(
              {
                message: "Successfully Deleted Maintenance Request",
                loading: false,
                selectedRequest: undefined,
              },
              () => {
                this.getRequests();
              }
            );
          }.bind(this)
        )
        .catch(
          function(err) {
            console.log("error deleting request: " + err.message);
            this.setState(
              {
                message: "There Was An Error Deleting The Maintenance Request.",
                loading: false,
                selectedRequest: undefined,
              },
              () => {
                this.getRequest();
              }
            );
          }.bind(this)
        );
    } else {
      //no delete
      this.setState({ deleteRequest: false, loading: false });
    }
  };
  cancelAddRequest = () => {
    this.setState({ addRequest: false });
  };
  submitAddRequest = (request) => {
    console.log("successfully submitted");
    this.setState(
      {
        message: "Successfully Saved Maintenance Request",
        addRequest: false,
      },
      () => {
        this.getRequests();
      }
    );
  };
  editRequest = () => {
    this.setState({ editRequest: true });
  };
  cancelEditRequest = () => {
    this.setState({ editRequest: false });
  };
  submitEdit = () => {
    this.setState({
      editRequest: false,
      message: "Successfully Saved Maintenance Request",
    });
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          "loading"
        ) : (
          <div>
            <div className="dashboardPanel">
              <div className="dashboardPanelHeading">
                <h1 className="display-5">Maintenance</h1>
              </div>
              {this.state.message !== undefined ? (
                <p className="alert">{this.state.message}</p>
              ) : (
                ""
              )}
              <hr />

              <h1 className="display-5">Equipment:</h1>
              <div className="dashboardControls">
                {this.state.selectedEquipment !== undefined ? (
                  <div>
                    <a
                      className="btn btn-secondary dashboardButton"
                      onClick={() => {
                        if (this.state.loading) {
                          return;
                        } else {
                          this.addRequest();
                        }
                      }}
                    >
                      Add Maintenance Request
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <EquipmentTable
                equipment={this.state.equipment}
                selectEquipment={this.selectEquipment}
              />
              <hr />
              <h1 className="display-5">Your Maintenance Requests:</h1>
              <div className="dashboardControls">
                {this.state.selectedRequest !== undefined ? (
                  <div>
                    <a
                      className="btn btn-secondary dashboardButton"
                      onClick={() => {
                        if (this.state.loading) {
                          return;
                        } else {
                          this.editRequest();
                        }
                      }}
                    >
                      Edit Maintenance Request
                    </a>
                    <a
                      className="btn btn-secondary dashboardButton"
                      onClick={() => {
                        if (this.state.loading) {
                          return;
                        } else {
                          this.deleteRequest();
                        }
                      }}
                    >
                      Delete Maintenance Request
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <RequestsTable
                requests={this.state.requests}
                equipment={this.state.equipment}
                selectRequest={this.selectRequest}
              />
            </div>
            {this.state.deleteRequest ? (
              <DeleteRequestConfirmation answer={this.answerDeleteRequest} />
            ) : (
              ""
            )}
            {this.state.addRequest ? (
              <div>
                <AddRequest
                  equipment={this.state.selectedEquipment}
                  cancel={this.cancelAddRequest}
                  submit={this.submitAddRequest}
                />
                <Backdrop click={this.cancelAddRequest} />
              </div>
            ) : (
              ""
            )}
            {this.state.editRequest ? (
              <EditMaintenanceRequest
                request={this.state.selectedRequest}
                submit={this.submitEdit}
                close={this.cancelEditRequest}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Maintenance);
