import React, { Component } from 'react';
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import JobsTable from './../../common/JobsTable';
import { compareTime, compareTimeReverse, compareId, compareIdReverse } from "../../common/Helpers";
import { withRouter } from 'react-router';
import Tabs from './Tabs';
import Button from '@material-ui/core/Button';

class JobReports extends Component {
  constructor() {
    super();
    this.state = {
      jobs: [],
      selectedJob: null,
      sortedBy: "date",
      sortOrder: true,
      loading: true,
      viewJob: false
    }
    this.selectJob = this.selectJob.bind(this);
    this.sortJobs = this.sortJobs.bind(this);
    this.onJobViewClick = this.onJobViewClick.bind(this);
  }
  onJobViewClick() {
    this.props.history.push('/dashboard/reports/job/' + this.state.selectedJob);
  }

  sortJobs(param) {
    let jobs = this.state.jobs;
    switch (param) {
      case "id":
        if (this.state.sortOrder) {
          jobs.sort(compareId);
        } else {
          jobs.sort(compareIdReverse);
        }
        this.setState(prevState => {
          return {
            sortedBy: "id",
            jobs: jobs,
            sortOrder: !prevState.sortOrder
          };
        });
        break;
      case "date":
        if (this.state.sortOrder) {
          jobs.sort(compareTime);
        } else {
          jobs.sort(compareTimeReverse);
        }
        this.setState(prevState => {
          return {
            sortedBy: "date",
            jobs: jobs,
            sortOrder: !prevState.sortOrder
          };
        });
        break;
      case "type":
        this.setState({ sortedBy: "type" });
        break;
      default:
        this.setState({ sortedBy: "date" });
        break;
    }
  }
  selectJob(job) {
    if (job == null) {
      this.setState({ selectedJob: null });
    } else {
      this.setState({ selectedJob: job });
    }
  }
  componentDidMount() {
    this.props.firebase.db.collection('jobs').get().then(function (jobsSnapshot) {
      let jobs = [];
      jobsSnapshot.forEach(function (job) {
        jobs.push(job.data());
      })
      this.setState({ jobs: jobs, loading: false });
    }.bind(this)).catch(function (err) {
      console.log(err.message);
    })
  }
  render() {
    let jobs, jobControls;
    if (this.state.loading) {
      jobs = <i className="spinner fas fa-circle-notch fa-spin"></i>;
    } else {
      jobs = <JobsTable
        sort={this.sortJobs}
        selectJob={this.selectJob}
        jobs={this.state.jobs}
      />;
    }
    if (this.state.selectedJob !== null) {
      jobControls = (
        <div className="jobControls" style={{marginTop: 1 + 'em'}} >
          <Button onClick={() => { this.onJobViewClick(); }} color="inherit" variant="contained">
          View Job Reports
          </Button>
        </div>
      );
    } else {
      jobControls = "";
    }
    return (
      <div>
        <Tabs />
        {jobControls}
        {jobs}
      </div>
    )
  }
}

export default compose(withFirebase, withRouter)(JobReports);