import React, { Component } from "react";
import AddExpenseReport from "./AddExpenseReport";
import Backdrop from "./../../common/Backdrop";
import "./expenseReports.css";

class ExpenseReports extends Component {
  constructor() {
    super();
    this.state = {
      addExpenseReport: false,
      message: undefined
    };
    this.onBackdropClick = this.onBackdropClick.bind(this);
    this.submitExpenseReport = this.submitExpenseReport.bind(this);
    this.onAddExpenseReportClick = this.onAddExpenseReportClick.bind(this);
    this.closeAddExpenseReport = this.closeAddExpenseReport.bind(this);
  }
  closeAddExpenseReport() {
    this.setState(prevState => {
      return { addExpenseReport: false };
    });
  }
  onBackdropClick() {
    this.setState(prevState => {
      return { addExpenseReport: false, message: undefined };
    });
  }
  onAddExpenseReportClick() {
    this.setState(prevState => {
      return { addExpenseReport: true, message: undefined };
    });
  }
  submitExpenseReport(expenseReport) {
    this.setState(prevState => {
      return {
        addExpenseReport: false,
        message: "Expense Report Successfully Submitted"
      };
    });
  }
  render() {
    let addExpenseReport, message;
    if (this.state.addExpenseReport) {
      addExpenseReport = (
        <div>
          <AddExpenseReport
            close={this.closeAddExpenseReport}
            submit={this.submitExpenseReport}
          />
          <Backdrop click={this.onBackdropClick} />
        </div>
      );
    }
    if (this.state.message !== undefined) {
      message = <div className="alert alert-primary">{this.state.message}</div>;
    } else {
      message = "";
    }
    return (
      <div className="dashboardPanel">
        <div className="dashboardPanelHeading">
          <h1 className="display-5">Expense Reports</h1>
        </div>
        <hr />
        {message}
        <div className="dashboardControls">
          <a
            className="btn btn-secondary dashboardButton"
            onClick={() => {
              if (this.state.loading) {
                return;
              } else {
                this.onAddExpenseReportClick();
              }
            }}
          >
            Add Expense Report
          </a>
        </div>
        <hr />
        {addExpenseReport}
      </div>
    );
  }
}

export default ExpenseReports;
