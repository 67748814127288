import React, { Component } from "react";
import axios from "axios";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import EmployeesTable from "./EmployeesTable";
import Backdrop from "./../../common/Backdrop";
import EmployeeConfirmation from "./../../common/EmployeeConfirmation";
import Button from '@material-ui/core/Button';
import "./employees.css";

class Employees extends Component {
  constructor() {
    super();
    this.state = {
      addEmployee: false,
      message: "",
      employees: [],
      selectedEmployee: null,
      confirmation: false,
      editEmployee: false,
      loading: false,
    };
    this.onAddEmployeeClick = this.onAddEmployeeClick.bind(this);
    this.onBackdropClick = this.onBackdropClick.bind(this);
    this.submitEmployee = this.submitEmployee.bind(this);
    this.dismissMessage = this.dismissMessage.bind(this);
    this.sortEmployees = this.sortEmployees.bind(this);
    this.selectEmployee = this.selectEmployee.bind(this);
    this.onEmployeeEditClick = this.onEmployeeEditClick.bind(this);
    this.onEmployeeDeleteClick = this.onEmployeeDeleteClick.bind(this);
    this.onEmployeeViewClick = this.onEmployeeViewClick.bind(this);
    this.handleConfirmation = this.handleConfirmation.bind(this);
    this.onEditBackdropClick = this.onEditBackdropClick.bind(this);
    this.submitEdit = this.submitEdit.bind(this);
  }
  componentDidMount() {
    this.props.firebase.db.collection("users").onSnapshot(
      function (employeeSnapshot) {
        let employees = [];
        employeeSnapshot.forEach(function (employee) {
          employees.push(employee.data());
        });
        this.setState((prevState) => {
          return { employees: employees };
        });
      }.bind(this)
    );
  }
  onAddEmployeeClick() {
    this.setState((prevState) => {
      return { addEmployee: true };
    });
  }

  onEmployeeDeleteClick() {
    this.setState({ confirmation: true });
  }
  onEmployeeEditClick() {
    this.setState((prevState) => {
      return { editEmployee: true };
    });
  }
  onEmployeeViewClick() { }
  onBackdropClick() {
    this.setState((prevState) => {
      return { addEmployee: false };
    });
  }
  onEditBackdropClick() {
    this.setState((prevState) => {
      return { editEmployee: false };
    });
  }
  sortEmployees(param) { }
  selectEmployee(employee) {
    if (employee == null) {
      this.setState({ selectedEmployee: null });
    } else {
      this.setState({ selectedEmployee: employee });
    }
  }
  submitEdit(employee) {
    this.setState((prevState) => {
      return { editEmployee: false, loading: true };
    });
    let docRef = this.props.firebase.db
      .collection("users")
      .doc(this.state.selectedEmployee.name);
    docRef
      .get()
      .then(
        function (doc) {
          if (doc.exists) {
            let prev = doc.data();
            let auth = false;
            if (this.state.selectedEmployee.name !== employee.name) {
              //update name
              prev.name = employee.name;
            }
            if (this.state.selectedEmployee.type !== employee.type) {
              //update type
              prev.type = employee.type;
            }
            if (this.state.selectedEmployee.email !== employee.email) {
              //update email
              prev.email = employee.email;
              auth = true;
            }
            if (this.state.selectedEmployee.password !== employee.password) {
              //update password
              prev.password = employee.password;
              auth = true;
            }
            if (auth) {
              axios
                .post(
                  "https://us-central1-mts-drilling-production.cloudfunctions.net/editAccount",
                  {
                    email: prev.email,
                    password: prev.password,
                    uid: prev.uid,
                  }
                )
                .then(
                  function (uid) {
                    let docRef = this.props.firebase.db
                      .collection("users")
                      .doc(this.state.selectedEmployee.name);
                    docRef.set(prev);
                    let message = "Successfully edited " + employee.name;
                    this.setState({ message: message, loading: false });
                  }.bind(this)
                )
                .catch(
                  function (error) {
                    console.log(error);
                    let message = "Error adding Employee: " + error.message;
                    this.setState({ message: message, loading: false });
                  }.bind(this)
                );
            } else {
              let message = "Successfully edited " + employee.name;
              this.setState({ message: message, loading: false });
              docRef.set(prev);
            }
          }
        }.bind(this)
      )
      .catch(
        function (err) {
          this.setState((prevState) => {
            return { message: err.message, loading: false };
          });
        }.bind(this)
      );
  }
  submitEmployee(employee) {
    this.setState({ addEmployee: false, loading: true });
    axios
      .post(
        "https://us-central1-mts-drilling-production.cloudfunctions.net/createAccount",
        {
          name: employee.name,
          email: employee.email,
          password: employee.password,
          type: employee.type,
        }
      )
      .then(
        function (uid) {
          let docRef = this.props.firebase.db
            .collection("users")
            .doc(uid.data.name);
          employee.uid = uid.data.uid;
          docRef.set(employee);
          let message = "Successfully added " + employee.name;
          this.setState({ message: message, loading: false });
        }.bind(this)
      )
      .catch(
        function (error) {
          console.log(error);
          let message = "Error adding Employee: " + error.message;
          this.setState({ message: message, loading: false });
        }.bind(this)
      );
  }
  dismissMessage() {
    this.setState({ message: "" });
  }

  handleConfirmation(answer) {
    this.setState({ confirmation: false });
    if (answer) {
      if (
        this.state.selectedEmployee.type == "foreman" ||
        this.state.selectedEmployee.type == "worker"
      ) {
        let uid = this.state.selectedEmployee.uid;

        let responseMsg = "User deletion has two parts: removing login, and removing from timesheet database. ";

        axios
          .post(
            "https://us-central1-mts-drilling-production.cloudfunctions.net/deleteAccount",
            { uid: uid } /* <-- this guy */,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then(() => {
            responseMsg += "1. Employee's login credentials were successfully revoked. ";
            this.removeUserFromDb(this.state.selectedEmployee.name, responseMsg);
          })
          .catch(() => {
            responseMsg += "1. Error removing employee login information (or login may already be revoked). ";
            this.removeUserFromDb(this.state.selectedEmployee.name, responseMsg);
          });
      } else {
        this.removeUserFromDb(this.state.selectedEmployee.name, "");
      }
    }
  }

  removeUserFromDb(employeeName, firstMessage) {
    this.props.firebase.db.collection("users").doc(employeeName).delete()
      .then(
        function () {
          this.setState(() => {
            return {
              message: firstMessage + "2. Employee was successfully deleted from the timesheet database. ",
              selectedEmployee: null,
            };
          });
        }.bind(this)
      )
      .catch(
        function (err) {
          this.setState(() => {
            return {
              message: firstMessage + "2. Unable to delete employee from timesheet database (or they may already be removed). ",
              selectedEmployee: null,
            };
          });
        }.bind(this)
      );
  }

  render() {
    let addEmployee,
      backdrop,
      message,
      employeeControls,
      confirmation,
      editEmployee,
      loading;
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = "";
    }
    if (this.state.addEmployee) {
      addEmployee = <AddEmployee submitEmployee={this.submitEmployee} />;
      backdrop = <Backdrop click={this.onBackdropClick} />;
    } else {
      addEmployee = "";
    }
    if (this.state.editEmployee) {
      editEmployee = (
        <EditEmployee
          employee={this.state.selectedEmployee}
          submitEdit={this.submitEdit}
        />
      );
      backdrop = <Backdrop click={this.onEditBackdropClick} />;
    } else {
      editEmployee = "";
    }
    if (this.state.message !== "") {
      message = (
        <div>
          <p className="lead">{this.state.message}</p>
          <a className="dismiss btn btn-sm btn-secondary" onClick={this.dismissMessage}>
            Dismiss
          </a>
        </div>
      );
    } else {
      message = "";
    }
    if (this.state.selectedEmployee !== null) {
      employeeControls = (
        <div className="jobControls">
          {/* <Button onClick={() => { this.onEmployeeViewClick(); }} color="inherit" variant="contained">
            View Employee
          </Button> */}
          <Button onClick={() => { this.onEmployeeEditClick(); }} color="default" variant="contained">
            Edit
          </Button>
          <Button onClick={() => { this.onEmployeeDeleteClick(); }} color="default" variant="contained">
            Delete
          </Button>

        </div>
      );
    }
    if (this.state.confirmation) {
      confirmation = (
        <EmployeeConfirmation
          job={this.state.selectedEmployee}
          answer={this.handleConfirmation}
        />
      );
    } else {
      confirmation = "";
    }
    return (
      <div className="dashboardPanel">
        <div className="dashboardPanelHeading dashboardPanelHeading-flex">
          <h1 className="display-5">Employees</h1>
          <Button onClick={() => {
            this.onAddEmployeeClick();
          }} color="default" variant="contained">
            Add Employee
          </Button>
        </div>
        <hr />
        <div className="dashboardControls">
          {employeeControls}
        </div>
        {loading}
        {message}
        <EmployeesTable
          sort={this.sortEmployees}
          selectEmployee={this.selectEmployee}
          employees={this.state.employees}
        />
        {addEmployee}
        {editEmployee}
        {backdrop}
        {confirmation}
      </div>
    );
  }
}

export default compose(withFirebase)(Employees);
