import React, { Component } from "react";
import formatDate from "../../common/formatDate";

class ViewTimesheet extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let date = new Date(this.props.timesheet.datetime);
    let dateString = date.toDateString();
    let imageString = this.props.timesheet.signature;
    return (
      <div className="viewReport" style={{ backgroundColor: "white" }}>
        <div
          className="dashboardPanelHeading"
          style={{ backgroundColor: "white" }}
        >
          <h3 className="display-5">
            Timesheet For Job #{this.props.timesheet.job}
          </h3>
          <p className="lead">
            Created by: {this.props.timesheet.createdBy.name}
            <br />
            Date: {dateString}
          </p>
        </div>
        <div>
          <hr />
          {this.props.timesheet.employeesAndHours.map((employee, i) => (
            <div key={i} className="employeeAndHours">
              {employee.regular.hours > 0 ? (
                <div>
                  {employee.name} - {employee.regular.hours} hours - Rate:
                  Regular
                  {employee.regular.times !== undefined ? (
                    <div>
                      Clock In: {formatDate(employee.regular.times.startTime)}
                      <br />
                      Clock Out:
                      {formatDate(employee.regular.times.stopTime)}
                      <hr />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {employee.laborer.hours > 0 ? (
                <div>
                  {employee.name} - {employee.laborer.hours} hours - Rate:
                  Laborer
                  {employee.laborer.times !== undefined ? (
                    <div>
                      Clock In: {formatDate(employee.laborer.times.startTime)}
                      <br />
                      Clock Out:
                      {formatDate(employee.laborer.times.stopTime)}
                      <hr />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
              {employee.operator.hours > 0 ? (
                <div>
                  {employee.name} - {employee.operator.hours} hours - Rate:
                  Operator
                  {employee.operator.times !== undefined ? (
                    <div>
                      Clock In: {formatDate(employee.operator.times.startTime)}
                      <br />
                      Clock Out:
                      {formatDate(employee.operator.times.stopTime)}
                      <hr />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
          <hr />
          <div className="pipeForm">
            <p className="lead font-weight-bold">
              Size and Type of HDPE Pipe Installed:
            </p>
            <p className="lead">
              {" "}
              {this.props.timesheet.hdpePipeForm.sizeType}
            </p>
            <p className="lead font-weight-bold">
              Size and Type of Wire Installed:
            </p>
            <p className="lead">
              {" "}
              {this.props.timesheet.hdpePipeForm.sizeTypeWire}
            </p>
            <p className="lead font-weight-bold">
              Total Usable Bore Footage Installed AT DEPTH:
            </p>
            <p className="lead">
              {" "}
              {this.props.timesheet.hdpePipeForm.boreFootage}
            </p>
          </div>
          <div className="pipeForm">
            <p className="lead font-weight-bold">
              Size and Type of Pipe Installed:
            </p>
            <p className="lead"> {this.props.timesheet.pipeForm1.sizeType}</p>
            <p className="lead font-weight-bold">
              Size and Type of Wire Installed:
            </p>
            <p className="lead">
              {" "}
              {this.props.timesheet.pipeForm1.sizeTypeWire}
            </p>
            <p className="lead font-weight-bold">
              Total Usable Bore Footage Installed AT DEPTH:
            </p>
            <p className="lead">
              {" "}
              {this.props.timesheet.pipeForm1.boreFootage}
            </p>
          </div>
          <div className="pipeForm">
            <p className="lead font-weight-bold">
              Size and Type of HDPE Pipe Installed:
            </p>
            <p className="lead"> {this.props.timesheet.pipeForm2.sizeType}</p>
            <p className="lead font-weight-bold">
              Size and Type of Wire Installed:{" "}
            </p>
            <p className="lead">
              {" "}
              {this.props.timesheet.pipeForm2.sizeTypeWire}
            </p>
            <p className="lead font-weight-bold">
              Total Usable Bore Footage Installed AT DEPTH:
            </p>
            <p className="lead">
              {" "}
              {this.props.timesheet.pipeForm2.boreFootage}
            </p>
          </div>
          <div className="pipeForm">
            <p className="lead font-weight-bold">
              Size and Type of HDPE Pipe Installed:{" "}
            </p>
            <p className="lead"> {this.props.timesheet.pipeForm3.sizeType}</p>
            <p className="lead font-weight-bold">
              Size and Type of Wire Installed:
            </p>
            <p className="lead">
              {" "}
              {this.props.timesheet.pipeForm3.sizeTypeWire}
            </p>
            <p className="lead font-weight-bold">
              Total Usable Bore Footage Installed AT DEPTH:{" "}
            </p>
            <p className="lead">
              {" "}
              {this.props.timesheet.pipeForm3.boreFootage}
            </p>
          </div>
          <div className="pipeForm">
            <p className="lead font-weight-bold">Notes:</p>
            <p className="lead">{this.props.timesheet.notes}</p>
          </div>
        </div>
        <div className=" timesheetReportSignature" >
          <p className="lead">{this.props.timesheet.createdBy.name}</p>
          <img src={this.props.timesheet.signature}/>
        </div>
        <div style={{ height: "32px"}}></div>
      </div>
    );
  }
}

export default ViewTimesheet;
