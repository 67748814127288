import React, { Component } from "react";

export default class ViewCovidForm extends Component {
  render() {
    return (
      <div className="viewReport" style={{ backgroundColor: "white" }}>
        <div
          className="dashboardPanelHeading"
          style={{ backgroundColor: "white" }}
        >
          <h3 className="display-5">
            Coronavirus Disease Workplace Screening For Job #{" "}
            {this.props.timesheet.job}
          </h3>
          <b>In the Past 24 Hours Have You Experienced :</b>
          <p>
            Subjective Fever (felt feverish):{" "}
            {this.props.timesheet.covidForm.question0 ? "Yes" : "No"}
          </p>
          <p>
            New Or Worsening Cough:{" "}
            {this.props.timesheet.covidForm.question1 ? "Yes" : "No"}
          </p>
          <p>
            Shortness Of Breath:{" "}
            {this.props.timesheet.covidForm.question2 ? "Yes" : "No"}
          </p>
          <p>
            Sore Throat:{" "}
            {this.props.timesheet.covidForm.question3 ? "Yes" : "No"}
          </p>
          <p>
            Diarrhea (unless due to known cause):{" "}
            {this.props.timesheet.covidForm.question4 ? "Yes" : "No"}
          </p>
          <p>
            If you answer "yes" to any of the symptoms listed above, or your
            temperature is 100.4°F or higher, please do not go to into work.
            Self-isolate at home and contact your primary care physician's
            office or nearest urgent care facility for direction.
          </p>
          <ul>
            <li>
              You should isolate at home for a minimum of 10 days since symptoms
              first appear.
            </li>
            <li>
              {" "}
              You must also have 3 days without fevers and improvement in
              respiratory symptoms.
            </li>
          </ul>
          <b>In The Past 14 Days Have You:</b>
          <p>
            Had Close Contact With Someone Diagnosed With COVID-19? -{" "}
            {this.props.timesheet.covidForm.covidContact ? "Yes" : "No"}
          </p>
          <p>
            Traveled Internationally Or Domestically? -{" "}
            {this.props.timesheet.covidForm.traveled ? "Yes" : "No"}
          </p>
          <div
            className="workersAndSignatures"
            style={{ display: "inline-block" }}
          >
            {this.props.timesheet.covidForm.workersAndSignatures.map(
              (worker, i) => (
                <div
                  style={{
                    display: "inline-block",
                    width: "300px",
                    margin: "0 1em",
                    border: "1px solid black",
                  }}
                  className="workerAndSignature col-md-4"
                  key={i} 
                >
                  <p className="lead">{worker.name}</p>
                  <img
                    style={{ width: "100%", height: "auto" }}
                    src={worker.signaturePic}
                  />
                </div>
              )
            )}
          </div>
        </div>
      </div>
    );
  }
}
