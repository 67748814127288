import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import GetImage from "./GetImage";
import Backdrop from "./../../common/Backdrop";
import formatDate from "./../../common/formatDate";

class JobPhotos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      images: [],
      getImage: false,
      selectedImage: undefined,
      imageUpload: false,
    };
    this.deleteJobPhoto = this.deleteJobPhoto.bind(this);
    this.submitJobImage = this.submitJobImage.bind(this);
    this.cancelGetImage = this.cancelGetImage.bind(this);
    this.openGetImage = this.openGetImage.bind(this);
    this.onClickImage = this.onClickImage.bind(this);
    this.setPhotos = this.setPhotos.bind(this);
  }
  onClickImage(photo) {
    console.log("clicked image: " + photo.photo);
    if (this.state.selectedImage !== photo) {
      this.setState({ selectedImage: photo });
    } else {
      this.setState({ selectedImage: undefined });
    }
  }
  cancelGetImage() {
    this.setState({ getImage: false });
  }
  openGetImage() {
    this.setState({ getImage: true });
  }
  setPhotos() {
    this.setState({ images: this.props.jobPhotos });
  }

  componentDidMount() {
    if (this.props.jobPhotos !== undefined) {
      this.setPhotos();
    }
  }
  uploadJobImage = (img, index, total) => {
    return new Promise(async (res, rej) => {
      let ref = this.props.firebase.db
        .collection("jobs")
        .doc(img.job)
        .collection("timesheets-in-progress")
        .doc(this.props.tip.id)
        .collection("job-photos")
        .doc();
      let documentId = ref.id;
      // TODO replace with dayjs
      let date = new Date();
      let month = date.getMonth() + 1;
      let dateString = month + "-" + date.getDate() + "-" + date.getFullYear();
      console.log(dateString);
      let dateForNode = formatDate(date);
      img.dateForNode = dateForNode;
      const storagePath =
        img.job +
        "/" +
        "JobPhotos" +
        dateString +
        "/" +
        documentId +
        "/" +
        img.photoName;
      img.storagePath = storagePath;
      const storageRef = this.props.firebase.storage.ref();
      let uploadTask = storageRef.child(storagePath).put(img.photo);
      await uploadTask.on(
        "state_changed",
        function(snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          let i = index + 1;
          let message =
            "Upload " +
            i +
            " of " +
            total +
            " is " +
            progress.toFixed(2) +
            "% done";
          this.setState((prevState) => {
            return {
              uploadProgress: progress + "%",
              message: message,
              valid: false,
            };
          });
        }.bind(this),
        function(error) {
          // Handle unsuccessful uploads
          console.log(error);
          this.setState((prevState) => {
            return {
              error: error.message,
              message: "Error Uploading Photo: " + error.message,
            };
          });
          rej(error.message);
        }.bind(this),
        function() {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(
            function(downloadURL) {
              img.photo = downloadURL;
              img.id = documentId;
              this.props.firebase.db
                .collection("jobs")
                .doc(img.job)
                .collection("timesheets-in-progress")
                .doc(this.props.tip.id)
                .collection("job-photos")
                .doc(documentId)
                .set(img)
                .then(
                  function() {
                    this.setState({
                      message: "Image Uploaded Successfully",
                    });
                    res("success");
                  }.bind(this)
                );
            }.bind(this)
          );
        }.bind(this)
      );
    });
  };
  submitJobImage(imageObject) {
    this.setState((prevState) => {
      return {
        getImage: false,
        imageUpload: true,
        message: "Uploading " + imageObject.length + " Images",
      };
    });
    console.log("uploading :" + imageObject.length + " images");
    imageObject.forEach((img, i) => {
      console.log("index in foreach is: " + i);
      if (i + 1 == imageObject.length) {
        console.log("last one");
        this.uploadJobImage(img, i, imageObject.length).then(() => {
          console.log("done uploading");
          this.setState({
            imageUpload: false,
            message: "Success uploading " + imageObject.length + " images",
          });
          this.props.getJobImages();
        });
      } else {
        this.uploadJobImage(img, i, imageObject.length);
      }
    });
  }
  deleteJobPhoto() {
    console.log("inside deleteJobPhoto");
    this.setState({ imageUpload: true });
    const storageRef = this.props.firebase.storage.ref();
    let path, ref;
    path = this.state.selectedImage.storagePath;
    ref = storageRef.child(path);

    ref
      .delete()
      .then(
        function() {
          // File deleted successfully
          this.props.firebase.db
            .collection("jobs")
            .doc(this.props.job.id)
            .collection("timesheets-in-progress")
            .doc(this.props.tip.id)
            .collection("job-photos")
            .doc(this.state.selectedImage.id)
            .delete()
            .then(
              function() {
                console.log("Document successfully deleted!");
                this.setState({ jobStartPhoto: undefined }, () => {
                  this.props.getJobImages();
                });
              }.bind(this)
            )
            .catch(function(error) {
              console.error("Error removing document: ", error);
            });
        }.bind(this)
      )
      .catch(function(error) {
        // Uh-oh, an error occurred!
        console.log("error deleting object: " + error.message);
      });
  }
  render() {
    let getImage, upload, message, photos, deleteSelectedImage;
    if (
      this.state.selectedImage !== undefined &&
      this.state.selectedImage !== null
    ) {
      deleteSelectedImage = (
        <button
          className="btn btn-secondary btn-sm dashboardButton"
          onClick={() => {
            this.deleteJobPhoto();
          }}
        >
          Delete Selected Photo
        </button>
      );
    } else {
      deleteSelectedImage = "";
    }
    if (this.state.images !== undefined) {
      photos = (
        <div className="row">
          {this.state.images.map((photo, i) => (
            <div
              onClick={() => {
                this.onClickImage(photo);
              }}
              className={
                this.state.selectedImage == photo
                  ? "col-md-6 col-xs-6 selectedJobPhoto"
                  : "col-md-6 col-xs-6 jobPhoto"
              }
              key={i}
            >
              <img src={photo.photo} />

              <br />
            </div>
          ))}
        </div>
      );
    } else {
      photos = "No Job Photos";
    }
    if (this.state.message !== undefined) {
      message = <div className="alert">{this.state.message}</div>;
    } else {
      message = "";
    }
    if (this.state.imageUpload) {
      upload = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      upload = (
        <div>
          {" "}
          <a
            className="btn btn-secondary dashboardButton"
            onClick={() => {
              this.openGetImage();
            }}
          >
            <i className="buttonIcon fas fa-camera" />
            Add Photo
          </a>
          <hr />
          {deleteSelectedImage}
          {photos}
        </div>
      );
    }
    if (this.state.getImage) {
      getImage = (
        <div>
          <GetImage
            close={this.cancelGetImage}
            job={this.props.job.id}
            submit={this.submitJobImage}
          />
          <Backdrop click={this.cancelGetImage} />
        </div>
      );
    } else {
      getImage = "";
    }
    return (
      <div className="jobPhotos col-md-4">
        Job Photos
        <hr />
        {message}
        {upload}
        {getImage}
      </div>
    );
  }
}

export default compose(withFirebase)(JobPhotos);
