import {
  LOGIN_SUCCESS,
  LOGIN_ERROR,
  LOGOUT_SUCCESS,
  WORKER_TYPE,
  FOREMAN_TYPE,
} from "./types";

export const setEmployeeType = (type) => (dispatch, getState) => {
  if (type == "foreman") {
    dispatch({ type: FOREMAN_TYPE });
  } else if (type == "worker") {
    dispatch({ type: WORKER_TYPE });
  }
};

// TODO needs CORS fix
export const logInUser = (user) => (dispatch, getState, { getFirebase }) => {
  let firebase = getFirebase();
  firebase
    .auth()
    .signInWithEmailAndPassword(user.email, user.password)
    .then((res) => {
      dispatch({ type: LOGIN_SUCCESS });
    })
    .catch((err) => {
      dispatch({ type: LOGIN_ERROR, err });
    });
};

export const logOutUser = () => (dispatch, getState, { getFirebase }) => {
  let firebase = getFirebase();
  firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch({ type: LOGOUT_SUCCESS });
    });
};
