import React, { Component } from "react";
import { withFirebase } from "./../../../../firebase/index";
import { compose } from "redux";
import ConfirmRestore from "./ConfirmRestore";
import Backdrop from "./../../../common/Backdrop";
import {
  checkJobId,
  getTimesheets,
  getTailboards,
  getJobPhotosForArchiving,
  moveTimesheets,
  getExpenseReports,
  moveAndDeleteExpenseReports,
  moveDocuments,
  moveJobNode,
  archiveBoringProcedure,
  archiveBorePreShot,
} from "./../RestoreJobsFunctions";

class ViewArchivedJob extends Component {
  constructor(props) {
    super(props);
    this.state = { loading: true, confirmRestore: false };
  }
  getJob = () => {
    console.log("getting job: " + this.props.match.params.job);
    this.props.firebase.db
      .collection("archived-jobs")
      .doc(this.props.match.params.job)
      .get()
      .then(
        function(res) {
          if (res.exists) {
            let job = res.data();
            this.setState({ job: job, loading: false });
          } else {
            this.setState({
              message:
                "There Was An Error Getting That Specific Job. Refresh And Try Again.",
              loading: false,
            });
          }
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error getting archived job: " + err.message);
          this.setState({
            message:
              "There Was An Error Accessing The Database. Refresh And Try Again.",
            loading: false,
          });
        }.bind(this)
      );
  };
  restoreJob = () => {
    this.setState({ confirmRestore: true });
  };
  cancelRestoreJob = () => {
    this.setState({ confirmRestore: false });
  };
  answerRestoreJob = (answer) => {
    if (answer) {
      this.setState(
        {
          message: "restoring job, please wait",
          confirmRestore: false,
          loading: true,
        },
        () => {
          this.startRestoring(this.state.job);
        }
      );
    } else {
      this.setState({ confirmRestore: false });
    }
  };
  startRestoring = (job) => {
    this.setState({ loading: true });
    getTimesheets(job.id, this.props.firebase.db).then(
      function(res) {
        console.log("got response from getTimesheets, " + res);
        let timesheets = res;
        for (let i = 0; i < timesheets.length; i++) {
          getJobPhotosForArchiving(
            job.id,
            timesheets[i],
            this.props.firebase.db
          );
        }
        getTailboards(job.id, timesheets, this.props.firebase.db).then(
          function(res) {
            console.log("done with gettailboards, calling archive2");
            archiveBoringProcedure(
              job.id,
              timesheets,
              this.props.firebase.db
            ).then(
              function(res) {
                archiveBorePreShot(
                  job.id,
                  timesheets,
                  this.props.firebase.db
                ).then(
                  function(res) {
                    this.restore2(job, timesheets);
                  }.bind(this)
                );
              }.bind(this)
            );
          }.bind(this)
        );
      }.bind(this)
    );
  };
  restore2 = (job, timesheets) => {
    moveTimesheets(job.id, timesheets, this.props.firebase.db).then(
      function(res) {
        console.log("done with the first round of move and delete");
        getExpenseReports(job, this.props.firebase.db).then(
          function(res) {
            let expenseReports = res;
            moveAndDeleteExpenseReports(
              expenseReports,
              job,
              this.props.firebase.db
            ).then(
              function(res) {
                moveDocuments(job, this.props.firebase.db).then(
                  function(res) {
                    console.log("after moveDocuments, calling moveJobNode");
                    moveJobNode(job, this.props.firebase.db).then(
                      function(res) {
                        this.props.history.push("/dashboard/jobs");
                      }.bind(this)
                    );
                  }.bind(this)
                );
              }.bind(this)
            );
          }.bind(this)
        );
      }.bind(this)
    );
  };
  viewJobReports = () => {
    this.props.history.push(
      "/dashboard/reports/archived-job/" + this.props.match.params.job
    );
  };
  componentDidMount() {
    this.getJob();
  }
  render() {
    let backString = "<- Back";
    let confirmRestore, message;
    if (this.state.message !== undefined && this.state.message !== "") {
      message = <div className="alert alert-primary">{this.state.message}</div>;
    } else {
      message = "";
    }
    if (this.state.confirmRestore) {
      confirmRestore = (
        <div>
          <ConfirmRestore
            answer={this.answerRestoreJob}
            job={this.state.job.id}
          />
          <Backdrop click={this.cancelRestoreJob} />
        </div>
      );
    } else {
      confirmRestore = "";
    }
    return (
      <div>
        {this.state.loading ? (
          <i className="spinner fas fa-circle-notch fa-spin" />
        ) : (
          <div className="dashboardPanel">
            <div className="dashboardPanelHeading">
              <h1 className="display-5">Archived Job #{this.state.job.id}</h1>
            </div>
            {message}
            <hr />
            <div className="dashboardControls">
            {/* TODO */}
              <a
                className="btn btn-secondary dashboardButton"
                onClick={() => {
                  this.restoreJob();
                }}
              >
                Restore Job (Unarchive)
              </a>
              <a
                className="btn btn-secondary dashboardButton"
                onClick={() => {
                  this.viewJobReports();
                }}
              >
                View Job Reports
              </a>
              <a
                className="btn btn-secondary returnLink"
                onClick={() => {
                  this.onBackClick();
                }}
              >
                {backString}
              </a>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>Date Created:</b>
              </div>
              <div className="col-md-6">{this.state.job.date}</div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>Job Title:</b>
              </div>
              <div className="col-md-6">{this.state.job.title}</div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>State:</b>
              </div>
              <div className="col-md-6">{this.state.job.state}</div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>Description:</b>
              </div>
              <div className="col-md-6">{this.state.job.description}</div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>Shop Time?:</b>
              </div>
              <div className="col-md-6">
                {this.state.job.shopTime ? "Yes" : "No"}
              </div>
            </div>
            <div className="row row-space">
              <div className="col-md-6">
                <b>Type:</b>
              </div>
              <div className="col-md-6">{this.state.job.type}</div>
            </div>

            {confirmRestore}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(ViewArchivedJob);
