function getHours(employee, timesheets, day) {
  return new Promise(function(resolve, reject) {
    let hours = {};
    let regularHours = 0;
    let regularJobs = [];
    let laborerHours = 0;
    let laborerJobs = [];
    let operatorHours = 0;
    let operatorJobs = [];
    let jobs = [];
    for (let i = 0; i < timesheets.length; i++) {
      for (let b = 0; b < timesheets[i].employeesAndHours.length; b++) {
        if (timesheets[i].employeesAndHours[b].uid == employee.uid) {
          let jobHours = 0;
          let job = {};
          if (
            timesheets[i].employeesAndHours[b].regular.hours == 0 ||
            timesheets[i].employeesAndHours[b].regular.hours == undefined ||
            !timesheets[i].employeesAndHours[b].regular.hours
          ) {
          } else {
            regularHours =
              timesheets[i].employeesAndHours[b].regular.hours + regularHours;
            jobHours =
              jobHours + timesheets[i].employeesAndHours[b].regular.hours;
            job.rate = "regular";
            regularJobs.push({
              jobId: timesheets[i].job,
              timesheetId: timesheets[i].id,
            });
          }
          if (
            timesheets[i].employeesAndHours[b].laborer.hours == 0 ||
            timesheets[i].employeesAndHours[b].laborer.hours == undefined ||
            !timesheets[i].employeesAndHours[b].laborer.hours
          ) {
          } else {
            laborerHours =
              timesheets[i].employeesAndHours[b].laborer.hours + laborerHours;
            jobHours =
              jobHours + timesheets[i].employeesAndHours[b].laborer.hours;
            job.rate = "laborer";
            laborerJobs.push({
              jobId: timesheets[i].job,
              timesheetId: timesheets[i].id,
            });
          }
          if (
            timesheets[i].employeesAndHours[b].operator.hours == 0 ||
            timesheets[i].employeesAndHours[b].operator.hours == undefined ||
            !timesheets[i].employeesAndHours[b].operator.hours
          ) {
          } else {
            operatorHours =
              timesheets[i].employeesAndHours[b].operator.hours + operatorHours;
            jobHours =
              jobHours + timesheets[i].employeesAndHours[b].operator.hours;
            job.rate = "operator";
            operatorJobs.push({
              jobId: timesheets[i].job,
              timesheetId: timesheets[i].id,
            });
          }

          job.jobId = timesheets[i].job;
          job.hours = jobHours;
          job.timesheetId = timesheets[i].id;

          jobs.push(job);
        }
      }
    }
    let res = {
      jobs: jobs,
      regularHours: regularHours,
      regularJobs: regularJobs,
      laborerJobs: laborerJobs,
      operatorJobs: operatorJobs,
      laborerHours: laborerHours,
      operatorHours: operatorHours,
    };
    resolve(res);
  });
}

export default function prepareEmployeeWeek(employee, timesheets) {
  return new Promise(function(resolve, reject) {
    if (employee == undefined || timesheets == undefined) {
      reject("error undefined");
    } else {
      let sundaySheets = [];
      let mondaySheets = [];
      let tuesdaySheets = [];
      let wednesdaySheets = [];
      let thursdaySheets = [];
      let fridaySheets = [];
      let saturdaySheets = [];
      for (let i = 0; i < timesheets.length; i++) {
        switch (timesheets[i].day) {
          case 0:
            //sunday
            sundaySheets.push(timesheets[i]);
            break;
          case 1:
            //monday
            mondaySheets.push(timesheets[i]);
            break;
          case 2:
            //tuesday

            tuesdaySheets.push(timesheets[i]);
            break;
          case 3:
            //wednesday
            wednesdaySheets.push(timesheets[i]);
            break;
          case 4:
            //thursday
            thursdaySheets.push(timesheets[i]);
            break;
          case 5:
            //friday
            fridaySheets.push(timesheets[i]);
            break;
          case 6:
            //saturday
            saturdaySheets.push(timesheets[i]);
            break;
          default:
            break;
        }
      }
      getHours(employee, mondaySheets, "monday").then(function(res) {
        let monday = {
          regular: res.regularHours,
          laborer: res.laborerHours,
          operator: res.operatorHours,
          operatorJobs: res.operatorJobs,
          laborerJobs: res.laborerJobs,
          regularJobs: res.regularJobs,
          jobs: res.jobs,
        };

        employee.monday = monday;
      });
      getHours(employee, tuesdaySheets, "tuesday").then(function(res) {
        let tuesday = {
          regular: res.regularHours,
          laborer: res.laborerHours,
          operator: res.operatorHours,
          operatorJobs: res.operatorJobs,
          laborerJobs: res.laborerJobs,
          regularJobs: res.regularJobs,
          jobs: res.jobs,
        };

        employee.tuesday = tuesday;
      });
      getHours(employee, wednesdaySheets, "wednesday").then(function(res) {
        let wednesday = {
          regular: res.regularHours,
          laborer: res.laborerHours,
          operator: res.operatorHours,
          operatorJobs: res.operatorJobs,
          laborerJobs: res.laborerJobs,
          regularJobs: res.regularJobs,
          jobs: res.jobs,
        };

        employee.wednesday = wednesday;
      });
      getHours(employee, thursdaySheets, "thursday").then(function(res) {
        let thursday = {
          regular: res.regularHours,
          laborer: res.laborerHours,
          operator: res.operatorHours,
          operatorJobs: res.operatorJobs,
          laborerJobs: res.laborerJobs,
          regularJobs: res.regularJobs,
          jobs: res.jobs,
        };

        employee.thursday = thursday;
      });
      getHours(employee, fridaySheets, "friday").then(function(res) {
        let friday = {
          regular: res.regularHours,
          laborer: res.laborerHours,
          operator: res.operatorHours,
          operatorJobs: res.operatorJobs,
          laborerJobs: res.laborerJobs,
          regularJobs: res.regularJobs,
          jobs: res.jobs,
        };

        employee.friday = friday;
      });
      getHours(employee, saturdaySheets, "saturday").then(function(res) {
        let saturday = {
          regular: res.regularHours,
          laborer: res.laborerHours,
          operator: res.operatorHours,
          operatorJobs: res.operatorJobs,
          laborerJobs: res.laborerJobs,
          regularJobs: res.regularJobs,
          jobs: res.jobs,
        };

        employee.saturday = saturday;
      });
      getHours(employee, sundaySheets, "sunday").then(function(res) {
        let sunday = {
          regular: res.regularHours,
          laborer: res.laborerHours,
          operator: res.operatorHours,
          operatorJobs: res.operatorJobs,
          laborerJobs: res.laborerJobs,
          regularJobs: res.regularJobs,
          jobs: res.jobs,
        };

        employee.sunday = sunday;
        resolve(employee);
      });
    }
  });
}

export function prepareEmployeeExpenseWeek(employee, expenseReports) {
  return new Promise(function(resolve, reject) {
    if (employee == undefined || expenseReports == undefined) {
      reject("error undefined");
    } else {
      let sundaySheets = [];
      let mondaySheets = [];
      let tuesdaySheets = [];
      let wednesdaySheets = [];
      let thursdaySheets = [];
      let fridaySheets = [];
      let saturdaySheets = [];
      for (let i = 0; i < expenseReports.length; i++) {
        let reportDate = new Date(expenseReports[i].dateTimeOfCharge);

        if (
          expenseReports[i].employee === employee.name ||
          expenseReports[i].uid === employee.uid
        ) {
          let day = reportDate.getDay();
          switch (day) {
            case 0:
              //sunday
              sundaySheets.push(expenseReports[i]);
              break;
            case 1:
              //monday
              mondaySheets.push(expenseReports[i]);
              break;
            case 2:
              //tuesday
              tuesdaySheets.push(expenseReports[i]);
              break;
            case 3:
              //wednesday
              wednesdaySheets.push(expenseReports[i]);
              break;
            case 4:
              //thursday
              thursdaySheets.push(expenseReports[i]);
              break;
            case 5:
              //friday
              fridaySheets.push(expenseReports[i]);
              break;
            case 6:
              //saturday
              saturdaySheets.push(expenseReports[i]);
              break;
            default:
              break;
          }
        }
      }
      employee.monday = mondaySheets;
      employee.tuesday = tuesdaySheets;
      employee.wednesday = wednesdaySheets;
      employee.thursday = thursdaySheets;
      employee.friday = fridaySheets;
      employee.saturday = saturdaySheets;
      employee.sunday = sundaySheets;
      getTotal(employee).then(function(res) {
        employee.total = res;
        resolve(employee);
      });
    }
  });
}

function getTotal(employee) {
  return new Promise(function(res, rej) {
    let mondaySpent = getTotalForDay("monday", employee);
    let tuesdaySpent = getTotalForDay("tuesday", employee);
    let wednesdaySpent = getTotalForDay("wednesday", employee);
    let thursdaySpent = getTotalForDay("thursday", employee);
    let fridaySpent = getTotalForDay("friday", employee);
    let saturdaySpent = getTotalForDay("saturday", employee);
    let sundaySpent = getTotalForDay("sunday", employee);

    let total =
      parseInt(mondaySpent) +
      parseInt(tuesdaySpent) +
      parseInt(wednesdaySpent) +
      parseInt(thursdaySpent) +
      parseInt(fridaySpent) +
      parseInt(saturdaySpent) +
      parseInt(sundaySpent);
    res(total);
  });
}

function getTotalForDay(day, obj) {
  let hours = 0;
  switch (day) {
    case "monday":
      for (let i = 0; i < obj.monday.length; i++) {
        hours = hours + parseInt(obj.monday[i].amount);
      }
      break;
    case "tuesday":
      for (let i = 0; i < obj.tuesday.length; i++) {
        hours = hours + parseInt(obj.tuesday[i].amount);
      }
      break;
    case "wednesday":
      for (let i = 0; i < obj.wednesday.length; i++) {
        hours = hours + parseInt(obj.wednesday[i].amount);
      }
      break;
    case "thursday":
      for (let i = 0; i < obj.thursday.length; i++) {
        hours = hours + parseInt(obj.thursday[i].amount);
      }
      break;
    case "friday":
      for (let i = 0; i < obj.friday.length; i++) {
        hours = hours + parseInt(obj.friday[i].amount);
      }
      break;
    case "saturday":
      for (let i = 0; i < obj.saturday.length; i++) {
        hours = hours + parseInt(obj.saturday[i].amount);
      }
      break;
    case "sunday":
      for (let i = 0; i < obj.sunday.length; i++) {
        hours = hours + parseInt(obj.sunday[i].amount);
      }
      break;
    default:
      break;
  }

  return hours;
}
