import React, { Component } from "react";
import TextField from '@material-ui/core/TextField';
import dayjs from "dayjs";
import { TimePicker } from "@mui/x-date-pickers";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default class EnterTime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startTime: '',
      stopTime: ''
    };
  }

  onChangeStartTime = event => {
    const timestamp = dayjs(event);
    this.setState({ message: undefined });
    if (timestamp === null || timestamp === undefined) {
      this.setState({ startTime: timestamp, valid: false });
    } else {
      if (this.state.stopTime !== undefined) {
        this.setState({ startTime: timestamp, valid: true });
      } else {
        this.setState({ startTime: timestamp, valid: false });
      }
    }
  };
  onChangeStopTime = event => {
    const timestamp = dayjs(event);
    this.setState({ message: undefined });
    if (timestamp === null || timestamp === undefined) {
      this.setState({ stopTime: timestamp, valid: false });
    } else {
      if (this.state.startTime !== undefined) {
        this.setState({ stopTime: timestamp, valid: true });
      } else {
        this.setState({ stopTime: timestamp, valid: false });
      }
    }
  };
  submitTimes = () => {
    const millisecondDiff = this.state.stopTime.diff(this.state.startTime, 'millisecond');
    const hoursWorked = millisecondDiff / 1000 / 60 / 60;

    let times = { startTime: this.state.startTime.format(), stopTime: this.state.stopTime.format() };
    if (hoursWorked < 0) {
      this.setState({
        message:
          "You Cannot Enter A Negative Time. Start Time Must Be Earlier Than Stop Time",
      });
    } else {
      this.props.submit(
        hoursWorked,
        this.props.uid,
        this.props.timeRate,
        times
      );
    }
  };
  render() {
    return (
      <Dialog
        disableEnforceFocus
        open={true}
        onClose={() => this.props.close()}
        aria-labelledby="enter-time-dialog-title"
        aria-describedby="enter-time-description"
      >
        <DialogTitle id="cancel-tip-confirmation-dialog-title">
          Enter Clock times
          <div onClick={this.props.close} className="exit">
            Exit
          </div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component="span" id="clock-out-confirmation-dialog-description">
            <div className="clock-entertime">
              Enter Start Time:{" "}
              <TimePicker
                id="startTime"
                className="timepicker"
                onChange={(val) => this.onChangeStartTime(val)}
                value={this.state.startTime}
              />
            </div>
            <div className="clock-entertime">
              Enter Stop Time:{" "}
              <TimePicker
                id="stopTime"
                className="timepicker"
                onChange={(val) => this.onChangeStopTime(val)}
                value={this.state.stopTime}
              />
            </div>
            {this.state.message !== undefined ? (
              <div className="alert alert-danger">{this.state.message}</div>
            ) : (
              ""
            )}
            <div className="hint">Click clock icon for time dropdown.</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.submitTimes}
            disabled={this.state.valid ? false : true}
            color="primary" variant="contained">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
