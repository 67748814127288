import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";

class EditMaintenanceRequest extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
    };
  }
  componentDidMount() {
    this.setFields();
  }
  setFields = () => {
    if (this.state.request == null) {
      this.setState({ request: this.props.request, loading: false });
    }
  };
  saveEdit = () => {
    this.setState({ loading: true });
    this.props.firebase.db
      .collection("maintenance")
      .doc(this.state.request.id)
      .set(this.state.request)
      .then(
        function(res) {
          this.props.submit();
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error saving request edit: " + err.message);
          this.setState({
            message: "Error Saving Request Edit",
            loading: false,
          });
        }.bind(this)
      );
  };
  selectPhoto = (photo, iterator) => {
    if (this.state.selectedPhoto == this.state.request.photos[iterator]) {
      this.setState({ selectedPhoto: undefined });
    } else {
      this.setState({ selectedPhoto: this.state.request.photos[iterator] });
    }
  };
  onChange = (event) => {
    let request = this.state.request;
    request.description = event.target.value;
    this.setState({ request: request });
  };
  render() {
    let photos;

    if (this.state.request !== null && this.state.request !== undefined) {
      if (this.state.request.photos !== null) {
        photos = (
          <div className="row">
            <div>
              {this.state.request.photos.map((photo, i) => (
                <img
                  className="maintenancePhoto"
                  src={photo}
                  onClick={() => {
                    this.selectPhoto(photo, i);
                  }}
                  key={i} 
                />
              ))}
            </div>
          </div>
        );
      } else {
        photos = "No photos";
      }
    } else {
      photos = "No photos";
    }

    return (
      <div className="card addExpenseReport">
        {this.state.loading ? (
          "loading"
        ) : (
          <div>
            <h1 className="display-5">Edit Maintenance Request</h1>
            <button
              className="btn btn-secondary btn-sm exit"
              onClick={this.props.close}
            >
              CLOSE
            </button>
            {photos}
            <div className="form-group row">
              <label className="col-sm-3 col-form-label" htmlFor="description">
                Description of issue:
              </label>
              <textarea
                className="form-control col-sm-9"
                id="description"
                onChange={this.onChange}
                placeholder="Description"
                value={this.state.request.description}
                name="description"
              />
            </div>
            <button
              onClick={this.saveEdit}
              className="btn btn-secondary btn-md"
            >
              Save
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(EditMaintenanceRequest);
