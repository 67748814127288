import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { logInUser } from "../store/actions/authActions";
import logo from "./../assets/logo.png";
import admin from "./../userInfo";
import "./landing.css";

class Landing extends Component {
  constructor() {
    super();
    this.state = {
      errors: {},
      email: "",
      password: ""
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }
  onChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  onSubmit(event) {
    event.preventDefault();
    let user = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.logInUser(user);
  }
  componentDidUpdate() {
    if (this.props.auth.uid) {
      for (let i = 0; i < admin.length; i++) {
        if (this.props.auth.email == admin[i]) {
          this.props.history.push("/dashboard");
          return;
        }
      }
      this.props.history.push("/employee-dashboard");
    }
  }
  componentDidMount() {
    if (this.props.auth.uid) {
      for (let i = 0; i < admin.length; i++) {
        if (this.props.auth.email == admin[i]) {
          this.props.history.push("/dashboard");
          return;
        }
      }
      this.props.history.push("/employee-dashboard");
    }
  }
  render() {
    let errors = this.props.authError;
    return (
      <div className="container container-fluid">
        <div className="formCard col-md-8 m-auto login">
          <img src={logo} className="mainLogo" />
          {this.props.authError ? (
            <div className="alert alert-danger" role="alert">
              {this.props.authError}
            </div>
          ) : (
            ""
          )}
          <form onSubmit={this.onSubmit}>
            <div className="row">
              <div className="form-group">
                <input
                  type="text"
                  className={classnames(
                    "form-control form-control-lg authInput",
                    {
                      "is-invalid": errors
                    }
                  )}
                  placeholder="Email"
                  name="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <input
                  type="password"
                  className={classnames(
                    "form-control form-control-lg authInput",
                    {
                      "is-invalid": errors
                    }
                  )}
                  placeholder="Password"
                  name="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  required
                />
              </div>
            </div>
            <input type="submit" className="btn btn-dark btn-block submit" />
          </form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => ({
  auth: state.firebase.auth,
  authError: state.auth.authError
});
Landing.propTypes = {
  auth: PropTypes.object.isRequired,
  authError: PropTypes.string
};

export default connect(
  mapStateToProps,
  { logInUser }
)(Landing);
