import React, { Component } from "react";
import CloseConfirmation from "./../CloseConfirmation";
import DatePicker from "react-datepicker";
import { withFirebase } from "./../../../../firebase/index";
import { compose } from "redux";
import AddEmployee from "./../AddEmployee";
import Backdrop from "./../../../common/Backdrop";
import PipeForm from "./PipeForm";
import HDPEPipeForm from "./HDPEPipeForm";
import Overwrite from "./Overwrite";
import SignatureGrab from "./../../../common/SignatureGrab";
import EnterTime from "./EnterTime";
import Checkbox from "@material-ui/core/Checkbox";

class Timesheet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeConfirmation: false,
      dateTime: new Date().getTime(),
      date: new Date(),
      addEmployee: false,
      selectedEmployee: null,
      employeesAndHours: [],
      message: "",
      pipeFormNumber: 1,
      pipeForm1: { sizeType: "", sizeTypeWire: "", boreFootage: "" },
      pipeForm2: { sizeType: "", sizeTypeWire: "", boreFootage: "" },
      pipeForm3: { sizeType: "", sizeTypeWire: "", boreFootage: "" },
      hdpePipeForm: { sizeType: "", sizeTypeWire: "", boreFootage: "" },
      notes: "",
      agree: false,
      loading: false,
      timesheet: undefined,
      overwrite: false,
      enterTime: false,
    };
    this.answerClose = this.answerClose.bind(this);
    this.closeTimesheet = this.closeTimesheet.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onAddEmployeeClick = this.onAddEmployeeClick.bind(this);
    this.addEmployee = this.addEmployee.bind(this);
    this.onBackdropClick = this.onBackdropClick.bind(this);
    this.onHoursChange = this.onHoursChange.bind(this);
    this.pipeFormChange = this.pipeFormChange.bind(this);
    this.hdpePipeFormChange = this.hdpePipeFormChange.bind(this);
    this.selectEmployee = this.selectEmployee.bind(this);
    this.deleteEmployee = this.deleteEmployee.bind(this);
    this.onNotesChange = this.onNotesChange.bind(this);
    this.agreeCheck = this.agreeCheck.bind(this);
    this.confirmOverwrite = this.confirmOverwrite.bind(this);
    this.uploadSignature = this.uploadSignature.bind(this);
    this.deleteTipsPhotos = this.deleteTipsPhotos.bind(this);
    this.writeTailboard = this.writeTailboard.bind(this);
    this.onJobRateChange = this.onJobRateChange.bind(this);
    this.closeSignatureGrab = this.closeSignatureGrab.bind(this);
  }
  // TODO not sure this is being called
  onJobRateChange(event) {
    for (let i = 0; i < this.state.employeesAndHours.length; i++) {
      if (this.state.employeesAndHours[i].uid == event.target.name) {
        let oldEmployeesAndHours = this.state.employeesAndHours;
        oldEmployeesAndHours[i].jobRate = event.target.value;
        this.setState({ employeesAndHours: oldEmployeesAndHours });
      }
    }
  }
  closeSignatureGrab() {
    this.setState(prevState => {
      return { addSignature: false };
    });
  }
  grabSignature(worker, signature) {
    this.setState(prevState => {
      return { agreeSignature: signature, agree: true, addSignature: false };
    });
  }
  confirmOverwrite(answer) {
    if (answer) {
      this.uploadSignature();
    } else {
      this.setState(
        prevState => {
          return { overwrite: false, loading: false };
        },
        () => {
          this.props.close("");
        }
      );
    }
  }
  agreeCheck() {
    this.setState(prevState => {
      return { addSignature: !prevState.addSignature };
    });
  }
  onNotesChange(event) {
    let notes = event.target.value;
    this.setState(prevState => {
      return { notes: notes };
    });
  }
  deleteEmployee() {
    let oldEmployeesAndHours = this.state.employeesAndHours;
    for (let i = 0; i < oldEmployeesAndHours.length; i++) {
      if (oldEmployeesAndHours[i].uid == this.state.selectedEmployee.uid) {
        oldEmployeesAndHours.splice(i, 1);
      }
    }
    this.setState(prevState => {
      return {
        employeesAndHours: oldEmployeesAndHours,
        selectedEmployee: null,
      };
    });
  }
  selectEmployee(employee) {
    if (this.state.selectedEmployee == employee) {
      this.setState(prevState => {
        return { selectedEmployee: null };
      });
    } else {
      this.setState(prevState => {
        return { selectedEmployee: employee };
      });
    }
  }
  hdpePipeFormChange(event) {
    let value = event.target.value;
    switch (event.target.id) {
      case "sizeType":
        this.setState(prevState => {
          return {
            hdpePipeForm: { ...prevState.hdpePipeForm, sizeType: value },
          };
        });
        break;
      case "sizeTypeWire":
        this.setState(prevState => {
          return {
            hdpePipeForm: { ...prevState.hdpePipeForm, sizeTypeWire: value },
          };
        });
        break;
      case "boreFootage":
        this.setState(prevState => {
          return {
            hdpePipeForm: { ...prevState.hdpePipeForm, boreFootage: value },
          };
        });
        break;
      default:
        break;
    }
  }
  pipeFormChange(event) {
    let value = event.target.value;
    if (event.target.name == "pipeForm1") {
      //pipeForm1
      switch (event.target.id) {
        case "sizeType":
          this.setState(prevState => {
            return { pipeForm1: { ...prevState.pipeForm1, sizeType: value } };
          });
          break;
        case "sizeTypeWire":
          this.setState(prevState => {
            return {
              pipeForm1: { ...prevState.pipeForm1, sizeTypeWire: value },
            };
          });
          break;
        case "boreFootage":
          this.setState(prevState => {
            return {
              pipeForm1: { ...prevState.pipeForm1, boreFootage: value },
            };
          });
          break;
        default:
          break;
      }
    } else if (event.target.name == "pipeForm2") {
      //pipeForm2
      switch (event.target.id) {
        case "sizeType":
          this.setState(prevState => {
            return { pipeForm2: { ...prevState.pipeForm2, sizeType: value } };
          });
          break;
        case "sizeTypeWire":
          this.setState(prevState => {
            return {
              pipeForm2: { ...prevState.pipeForm2, sizeTypeWire: value },
            };
          });
          break;
        case "boreFootage":
          this.setState(prevState => {
            return {
              pipeForm2: { ...prevState.pipeForm2, boreFootage: value },
            };
          });
          break;
        default:
          break;
      }
    } else if (event.target.name == "pipeForm3") {
      //pipeForm3
      switch (event.target.id) {
        case "sizeType":
          this.setState(prevState => {
            return { pipeForm3: { ...prevState.pipeForm3, sizeType: value } };
          });
          break;
        case "sizeTypeWire":
          this.setState(prevState => {
            return {
              pipeForm3: { ...prevState.pipeForm3, sizeTypeWire: value },
            };
          });
          break;
        case "boreFootage":
          this.setState(prevState => {
            return {
              pipeForm3: { ...prevState.pipeForm3, boreFootage: value },
            };
          });
          break;
        default:
          break;
      }
    }
  }
  uploadSignature() {
    const storagePath =
      this.props.job.id +
      "/" +
      "timesheets" +
      this.state.date +
      "/" +
      this.props.firebase.auth.currentUser.uid;
    const storageRef = this.props.firebase.storage.ref();
    let uploadTask = storageRef
      .child(storagePath)
      .putString(this.state.agreeSignature, "data_url", {
        contentType: "image/png",
      });
    uploadTask.on(
      "state_changed",
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        let message = "Upload " + " is " + progress.toFixed(2) + "% done";
        this.setState(prevState => {
          return {
            uploadProgress: progress + "%",
            message: message,
          };
        });
      }.bind(this),
      function (error) {
        // Handle unsuccessful uploads
        console.log(error);
        this.setState(prevState => {
          return {
            error: error.message,
            message: "Error Uploading Photo: " + error.message,
          };
        });
      }.bind(this),
      function () {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...

        uploadTask.snapshot.ref.getDownloadURL().then(
          function (downloadURL) {
            this.setState(
              prevState => {
                return { agreeSignature: downloadURL };
              },
              function () {
                //enter in database
                this.getJobPhotos();
              }
            );
          }.bind(this)
        );
      }.bind(this)
    );
  }
  getJobPhotos() {
    let photos = [];
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.job.id)
      .collection("timesheets-in-progress")
      .doc(this.props.tip.id)
      .collection("job-photos")
      .get()
      .then(
        function (photoSnapshot) {
          photoSnapshot.forEach(function (photo) {
            photos.push(photo.data());
          });
          //continue
          this.submitTimesheet(photos);
        }.bind(this)
      );
  }
  writeJobPhotos(docString, photos) {
    for (let i = 0; i < photos.length; i++) {
      this.props.firebase.db
        .collection("jobs")
        .doc(this.props.job.id)
        .collection("timesheets")
        .doc(docString)
        .collection("job-photos")
        .doc(photos[i].id)
        .set(photos[i]);
    }

    this.deleteTipsPhotos(photos, docString);
  }

  deleteTipsPhotos(photos, docString) {
    for (let i = 0; i < photos.length; i++) {
      this.props.firebase.db
        .collection("jobs")
        .doc(this.props.job.id)
        .collection("timesheets-in-progress")
        .doc(this.props.tip.id)
        .collection("job-photos")
        .doc(photos[i].id)
        .delete()
        .then(function () {
          console.log("photo deleted");
        })
        .catch(function (err) {
          console.log("error deleting photo: " + err.message);
        });
    }
    //get and move tailboard and then call this.deleteTIP();
    let tailboards = [];
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.job.id)
      .collection("timesheets-in-progress")
      .doc(this.props.tip.id)
      .collection("tailboards")
      .get()
      .then(
        function (tailboardSnapshot) {
          tailboardSnapshot.forEach(function (tailboard) {
            tailboards.push(tailboard.data());
          });
          this.writeTailboard(docString, tailboards);
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting tailboards: " + err.message);
      });
  }
  writeTailboard(docString, tailboards) {
    if (tailboards[0] !== undefined && tailboards[0] !== null) {
      tailboards[0].id = docString;
      this.props.firebase.db
        .collection("jobs")
        .doc(this.props.job.id)
        .collection("timesheets")
        .doc(docString)
        .collection("tailboards")
        .doc(docString)
        .set(tailboards[0])
        .then(
          function () {
            //delete old tailboard and call deleteTIP();
            this.props.firebase.db
              .collection("jobs")
              .doc(this.props.job.id)
              .collection("timesheets-in-progress")
              .doc(this.props.tip.id)
              .collection("tailboards")
              .doc(this.props.tip.id)
              .delete()
              .then(
                function () {
                  this.moveBoringProcedure(docString);
                }.bind(this)
              )
              .catch(function (err) {
                console.log("error deleting tailboard from TIP");
              });
          }.bind(this)
        )
        .catch(function (err) {
          console.log("error writing tailboard: " + err.message);
        });
    } else {
      this.moveBoringProcedure(docString);
    }
  }
  moveBoringProcedure(docString) {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.job.id)
      .collection("timesheets-in-progress")
      .doc(this.props.tip.id)
      .collection("boringProcedure")
      .doc(this.props.tip.id)
      .get()
      .then(
        function (boringSnapshot) {
          if (boringSnapshot.exists) {
            let bpv = boringSnapshot.data();
            bpv.id = docString;
            this.props.firebase.db
              .collection("jobs")
              .doc(this.props.job.id)
              .collection("timesheets")
              .doc(docString)
              .collection("boringProcedure")
              .doc(docString)
              .set(bpv)
              .then(
                function () {
                  this.props.firebase.db
                    .collection("jobs")
                    .doc(this.props.job.id)
                    .collection("timesheets-in-progress")
                    .doc(this.props.tip.id)
                    .collection("boringProcedure")
                    .doc(this.props.tip.id)
                    .delete()
                    .then(
                      function () {
                        this.moveBorePreShot(docString);
                      }.bind(this)
                    )
                    .catch(function (err) {
                      console.log(
                        "error deleting tip boringProcedure" + err.message
                      );
                    });
                }.bind(this)
              )
              .catch(function (err) {
                console.log(
                  "error setting new boringProcedure: " + err.message
                );
              });
          } else {
            this.moveBorePreShot(docString);
          }
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting boringProcedure: " + err.message);
      });
  }
  moveBorePreShot = docString => {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.job.id)
      .collection("timesheets-in-progress")
      .doc(this.props.tip.id)
      .collection("borePreShot")
      .doc(this.props.tip.id)
      .get()
      .then(
        function (boringSnapshot) {
          if (boringSnapshot.exists) {
            let bpv = boringSnapshot.data();
            bpv.id = docString;
            this.props.firebase.db
              .collection("jobs")
              .doc(this.props.job.id)
              .collection("timesheets")
              .doc(docString)
              .collection("borePreShot")
              .doc(docString)
              .set(bpv)
              .then(
                function () {
                  this.props.firebase.db
                    .collection("jobs")
                    .doc(this.props.job.id)
                    .collection("timesheets-in-progress")
                    .doc(this.props.tip.id)
                    .collection("borePreShot")
                    .doc(this.props.tip.id)
                    .delete()
                    .then(
                      function () {
                        this.deleteTIP();
                      }.bind(this)
                    )
                    .catch(function (err) {
                      console.log(
                        "error deleting tip borePreShot" + err.message
                      );
                    });
                }.bind(this)
              )
              .catch(function (err) {
                console.log("error setting new borePreShot: " + err.message);
              });
          } else {
            this.deleteTIP();
          }
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting boringProcedure: " + err.message);
      });
  };
  deleteTIP() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.job.id)
      .collection("timesheets-in-progress")
      .doc(this.props.tip.id)
      .delete()
      .then(
        function () {
          this.props.close("success");
        }.bind(this)
      )
      .catch(function (err) {
        console.log("ERROR DELETING TIP: " + err.message);
      });
  }
  submitTimesheet(photos) {
    this.setState(prevState => {
      return { loading: true };
    });

    // TODO replace with dayjs

    let timesheet = {};
    timesheet.job = this.props.job.id;
    timesheet.date = this.state.date;
    timesheet.datetime = this.state.dateTime;
    timesheet.month = this.state.date.getMonth();
    timesheet.year = this.state.date.getFullYear();
    timesheet.day = this.state.date.getDay();
    timesheet.signature = this.state.agreeSignature;
    if (this.props.tip.lat !== undefined && this.props.tip.lat !== null) {
      timesheet.lat = this.props.tip.lat;
      timesheet.lng = this.props.tip.lng;
    }
    if (this.state.pipeForm1 !== undefined) {
    }
    timesheet.employeesAndHours = this.state.employeesAndHours;
    timesheet.pipeForm1 = this.state.pipeForm1;
    timesheet.pipeForm2 = this.state.pipeForm2;
    timesheet.pipeForm3 = this.state.pipeForm3;
    timesheet.hdpePipeForm = this.state.hdpePipeForm;
    timesheet.createdBy = {
      uid: this.props.firebase.auth.currentUser.uid,
      name: this.props.firebase.auth.currentUser.displayName,
    };
    timesheet.notes = this.state.notes;
    timesheet.clockInTime = this.props.clockInTime;
    timesheet.clockOutTime = this.props.clockOutTime;
    let month = parseInt(timesheet.date.getMonth()) + 1;
    let docString = month + "-" + timesheet.date.getFullYear();
    let docString2 = timesheet.date.getDate() + "-" + docString;
    let timesheetTitle =
      this.props.uid + "-" + timesheet.job + "-" + docString2;
    timesheet.id = timesheetTitle;
    let timesheetDoc = this.props.firebase.db
      .collection("jobs")
      .doc(this.props.job.id)
      .collection("timesheets")
      .doc(timesheetTitle)
      .get()
      .then(
        function (doc) {
          if (doc.exists) {
            if (this.props.job.shopTime) {
              let rando = Math.floor(Math.random() * 10);
              timesheetTitle = timesheetTitle + "-" + rando;
              timesheet.id = timesheetTitle;
              this.props.firebase.db
                .collection("jobs")
                .doc(timesheet.job)
                .collection("timesheets")
                .doc(timesheetTitle)
                .set(timesheet)
                .then(
                  function () {
                    this.props.firebase.db
                      .collection("timesheets")
                      .doc(timesheetTitle)
                      .set(timesheet)
                      .then(
                        function () {
                          this.writeJobPhotos(timesheetTitle, photos);
                        }.bind(this)
                      )
                      .catch(function (err) {
                        console.log(err.message);
                      });
                  }.bind(this)
                )
                .catch(function (err) {
                  console.log(err.message);
                  this.setState(prevState => {
                    return { message: err.message };
                  });
                });
            } else {
              this.setState(prevState => {
                return {
                  message:
                    "There is already a timesheet for that job on that day.",
                  timesheet: timesheet,
                  overwrite: true,
                };
              });
              return;
            }
          } else {
            this.props.firebase.db
              .collection("jobs")
              .doc(timesheet.job)
              .collection("timesheets")
              .doc(timesheetTitle)
              .set(timesheet)
              .then(
                function () {
                  this.props.firebase.db
                    .collection("timesheets")
                    .doc(timesheetTitle)
                    .set(timesheet)
                    .then(
                      function () {
                        this.writeJobPhotos(timesheetTitle, photos);
                      }.bind(this)
                    )
                    .catch(function (err) {
                      console.log(err.message);
                    });
                }.bind(this)
              )
              .catch(function (err) {
                console.log(err.message);
                this.setState(prevState => {
                  return { message: err.message };
                });
              });
          }
        }.bind(this)
      )
      .catch(
        function (err) {
          let message =
            "There was an error submitting timesheet, you may be offline or have low connectivity. Keep this page open and try again with a better connection";
          this.setState({ message: message, loading: false });
        }.bind(this)
      );
  }
  onHoursChange(event, jobRate, times) {
    let hours = parseFloat(event.target.value) || 0;
    let regular = {};
    let operator = {};
    let laborer = {};
    if (event.target.value < 0) {
      for (let i = 0; i < this.state.employeesAndHours.length; i++) {
        if (this.state.employeesAndHours[i].uid == event.target.name) {
          let oldEmployeesAndHours = this.state.employeesAndHours;
          switch (jobRate) {
            case "regular":
              regular.hours = 0;
              oldEmployeesAndHours[i].regular = regular;
              break;
            case "laborer":
              laborer.hours = 0;
              oldEmployeesAndHours[i].laborer = laborer;
              break;
            case "operator":
              operator.hours = 0;
              oldEmployeesAndHours[i].operator = operator;
              break;
            default:
              break;
          }

          this.setState(prevState => {
            return {
              employeesAndHours: oldEmployeesAndHours,
              message: "You Must Enter A Positive Number",
            };
          });
        }
      }
    } else {
      for (let i = 0; i < this.state.employeesAndHours.length; i++) {
        if (this.state.employeesAndHours[i].uid == event.target.name) {
          let oldEmployeesAndHours = this.state.employeesAndHours;
          switch (jobRate) {
            case "regular":
              regular.hours = hours;
              regular.times = times;
              oldEmployeesAndHours[i].regular = regular;
              break;
            case "laborer":
              laborer.hours = hours;
              laborer.times = times;
              oldEmployeesAndHours[i].laborer = laborer;
              break;
            case "operator":
              operator.hours = hours;
              operator.times = times;
              oldEmployeesAndHours[i].operator = operator;
              break;
            default:
              break;
          }
          this.setState(prevState => {
            return { employeesAndHours: oldEmployeesAndHours, message: "" };
          });
        }
      }
    }
  }
  addEmployee(employee) {
    for (let i = 0; i < this.state.employeesAndHours.length; i++) {
      if (employee.uid === this.state.employeesAndHours[i].uid) {
        this.setState(prevState => {
          return {
            addEmployee: false,
            message: "Employee Already On Timesheet",
          };
        });
        return;
      }
    }
    let regular = { hours: this.state.hours };
    let laborer = { hours: 0 };
    let operator = { hours: 0 };
    let newEmployeesAndHours = this.state.employeesAndHours;
    let newEmployee = employee;
    newEmployee.regular = regular;
    newEmployee.operator = operator;
    newEmployee.laborer = laborer;
    newEmployeesAndHours.push(newEmployee);
    this.setState(prevState => {
      return {
        employeesAndHours: newEmployeesAndHours,
        addEmployee: false,
        message: "",
      };
    });
  }
  onAddEmployeeClick() {
    this.setState(prevState => {
      return { addEmployee: true, message: "" };
    });
  }
  onBackdropClick() {
    this.setState(prevState => {
      return { addEmployee: false, message: "" };
    });
  }
  onDateSelect(date) {
    let date1 = new Date(date);
    let dateTime = date1.getTime();
    let month = parseInt(date1.getMonth()) + 1;
    let docString = month + "-" + date1.getFullYear();
    let docString2 = date1.getDate() + "-" + docString;
    let timesheetTitle =
      this.props.uid + "-" + this.props.job.id + "-" + docString2;
    for (let i = 0; i < this.props.timesheets.length; i++) {
      if (this.props.timesheets[i].id == timesheetTitle) {
        this.setState({
          message:
            "There is already a timesheet for that day, edit that one instead",
        });

        return;
      }
    }
    this.setState(prevState => {
      return { date: date1, dateTime: dateTime, message: "" };
    });
  }
  componentDidMount() {
    //get current user
    window.scrollTo(0, 0); // TODO
    if (this.state.employeesAndHours.length < 1) {
      let foremanEmployee = {
        uid: this.props.firebase.auth.currentUser.uid,
        name: this.props.firebase.auth.currentUser.displayName,
      };
      let hours = Math.round(this.props.hours * 10) / 10;
      let regular = { hours: hours };
      let operator = { hours: 0 };
      let laborer = { hours: 0 };
      foremanEmployee.regular = regular;
      foremanEmployee.operator = operator;
      foremanEmployee.laborer = laborer;
      this.setState(prevState => {
        return { employeesAndHours: [foremanEmployee], hours: hours };
      });
    }
  }
  answerClose(answer) {
    if (answer) {
      this.props.close("exit");
    } else {
      this.setState(prevState => {
        return { closeConfirmation: false };
      });
    }
  }
  closeTimesheet() {
    this.setState(prevState => {
      return { closeConfirmation: true };
    });
  }
  enterTimes = (uid, rate) => {
    this.setState({ enterTime: true, timeUid: uid, timeRate: rate });
  };
  convertTimes = (time, uid, rate, times) => {
    this.setState({ enterTime: false });
    let event = { target: { value: time, name: uid } };
    this.onHoursChange(event, rate, times);
  };
  cancelEnterTime = () => {
    this.setState({
      enterTime: false,
      timeUid: undefined,
      timeRate: undefined,
    });
  };
  render() {
    let closeConfirmation,
      addEmployee,
      message,
      deleteEmployee,
      loading,
      overwrite,
      addSignature;
    if (this.state.addSignature) {
      addSignature = (
        <SignatureGrab
          worker={{
            name: this.props.firebase.auth.currentUser.displayName,
            uid: this.props.firebase.auth.currentUser.uid,
          }}
          grab={(worker, signature) => {
            this.grabSignature(worker, signature);
          }}
          close={this.closeSignatureGrab}
        />
      );
    } else {
      addSignature = "";
    }
    if (this.state.closeConfirmation) {
      closeConfirmation = (
        <CloseConfirmation answer={this.answerClose} form="Timesheet" />
      );
    } else {
      closeConfirmation = "";
    }
    if (this.state.addEmployee) {
      addEmployee = (
        <div>
          <AddEmployee
            employees={this.props.employees}
            addEmployee={this.addEmployee}
          />
          <Backdrop click={this.onBackdropClick} />{" "}
        </div>
      );
    } else {
      addEmployee = "";
    }
    if (this.state.message !== "") {
      message = <div className="alert alert-danger">{this.state.message}</div>;
    } else {
      message = "";
    }
    if (this.state.selectedEmployee !== null) {
      deleteEmployee = (
        <button
          onClick={this.deleteEmployee}
          className="btn btn-secondary dashboardButton"
        >
          Remove Selected Employee
        </button>
      );
    } else {
      deleteEmployee = "";
    }
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = (
        <button
          disabled={!this.state.agree ? true : false}
          className="btn btn-secondary addEmployeeButton dashboardButton submitTimesheet"
          onClick={() => {
            this.uploadSignature();
          }}
        >
          Submit Timesheet
        </button>
      );
    }
    if (this.state.overwrite) {
      overwrite = <Overwrite confirmation={this.confirmOverwrite} />;
    } else {
      overwrite = "";
    }
    return (
      <div className="jobForm">
        <button
          className="btn btn-secondary btn-sm exit"
          onClick={this.closeTimesheet}
        >
          CLOSE
        </button>
        <div className="jobFormContainer">
          <h1 className="display-5">Timesheet For Job # {this.props.job.id}</h1>
          <br />
          {message}
          <p className="headline lead">Date:</p>
          <DatePicker
            style={{ width: "100%" }}
            selected={this.state.date}
            onChange={this.onDateSelect}
          />
          <br />
          <button
            onClick={() => {
              this.onAddEmployeeClick();
            }}
            className="btn btn-secondary addEmployeeButton dashboardButton"
          >
            Add Employee
          </button>
          {deleteEmployee}
          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th />
                <th className="sort" scope="col">
                  Name
                </th>
                <th className="sort" scope="col">
                  Hours
                </th>
                <th className="sort" scope="col">
                  Job Rate
                </th>
              </tr>
            </thead>

            {this.state.employeesAndHours.map((employee, i) => (
              <tbody key={i}>
                <tr style={{ backgroundColor: "#ffd965" }}>
                  <td>
                    <Checkbox color="primary"
                      checked={
                        this.state.selectedEmployee == employee ? true : false
                      }
                      id="selectEmployee"
                      onChange={() => {
                        this.selectEmployee(employee);
                      }}
                    />
                  </td>
                  <th scope="row">{employee.name}</th>
                  <td>
                    <button
                      className="btn btn-secondary btn-sm clock"
                      onClick={() => {
                        this.enterTimes(employee.uid, "regular");
                      }}
                    >
                      Enter Clock Times
                    </button>
                    <br />

                    <input
                      type="number"
                      name={employee.uid}
                      value={employee.regular.hours}
                      onChange={event => {
                        this.onHoursChange(event, "regular");
                      }}
                    />
                  </td>
                  <td>
                    <h3 className="display-5">REGULAR</h3>
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#9cc2e5" }}>
                  <td />
                  <th scope="row">{employee.name}</th>
                  <td>
                    <button
                      className="btn btn-secondary btn-sm clock"
                      onClick={() => {
                        this.enterTimes(employee.uid, "laborer");
                      }}
                    >
                      Enter Clock Times
                    </button>
                    <br />
                    <input
                      type="number"
                      name={employee.uid}
                      value={employee.laborer.hours}
                      onChange={event => {
                        this.onHoursChange(event, "laborer");
                      }}
                    />
                  </td>
                  <td>
                    <h3 className="display-5">LABORER</h3>
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#92d050" }}>
                  <td />
                  <th scope="row">{employee.name}</th>
                  <td>
                    <button
                      className="btn btn-secondary btn-sm clock"
                      onClick={() => {
                        this.enterTimes(employee.uid, "operator");
                      }}
                    >
                      Enter Clock Times
                    </button>
                    <br />
                    <input
                      type="number"
                      name={employee.uid}
                      value={employee.operator.hours}
                      onChange={event => {
                        this.onHoursChange(event, "operator");
                      }}
                    />
                  </td>
                  <td>
                    <h3 className="display-5">OPERATOR</h3>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
          <HDPEPipeForm
            id="hdpePipeForm"
            value={this.state.hdpePipeForm}
            hdpePipeFormChange={this.hdpePipeFormChange}
          />
          <PipeForm
            id="pipeForm1"
            number="1"
            name="pipeForm1"
            value={this.state.pipeForm1}
            pipeFormChange={this.pipeFormChange}
          />
          <PipeForm
            id="pipeForm2"
            number="2"
            name="pipeForm2"
            value={this.state.pipeForm2}
            pipeFormChange={this.pipeFormChange}
          />
          <PipeForm
            id="pipeForm3"
            number="3"
            name="pipeForm3"
            value={this.state.pipeForm3}
            pipeFormChange={this.pipeFormChange}
          />
          <label htmlFor="notes">Notes:</label>
          <textarea
            className="notes"
            name="notes"
            id="notes"
            onChange={this.onNotesChange}
          />
          <div className="digitalSignature">
            <h3 className="display-5">Submit Agreement:</h3>
            <p className="lead">
              I {this.props.firebase.auth.currentUser.displayName} agree that
              the above information is correct.
            </p>
            <div className="timesheetSignature sigImage">
              {this.state.agree ? <img src={this.state.agreeSignature} /> : ""}
            </div>
            <br />
            <button id="addSignature" onClick={this.agreeCheck}>
              Add Signature
            </button>
          </div>
          <br />
          {addSignature}
          {message}
          {loading}
          {overwrite}
          {addEmployee}
          {closeConfirmation}
          {this.state.enterTime && (
            <EnterTime
              tip={this.props.tip}
              uid={this.state.timeUid}
              timeRate={this.state.timeRate}
              clockInTime={this.props.clockInTime}
              clockOutTime={this.props.clockOutTime}
              submit={this.convertTimes}
              close={this.cancelEnterTime}
            />
          )}
        </div>
      </div>
    );
  }
}

export default compose(withFirebase)(Timesheet);
