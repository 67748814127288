import React, { Component } from "react";

class JobsTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedJob: null
    };
    this.selectJob = this.selectJob.bind(this);
  }
  selectJob(event) {
    if (this.state.selectedJob == event) {
      this.props.selectJob(null);
      this.setState({ selectedJob: null });
    } else {
      this.props.selectJob(event);
      this.setState({ selectedJob: event });
    }
  }
  render() {
    return (
      <div>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th className="sort" scope="col" onClick={() => { this.props.sort("id"); }}>
                #
              </th>
              <th className="sort" scope="col">
                Title
              </th>
              <th className="sort" scope="col" onClick={() => { this.props.sort("date"); }}>
                Date
              </th>
              <th className="sort" scope="col" onClick={() => { this.props.sort("type"); }} >
                Type
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.jobs.map((job, i) => (
              <tr key={i} onClick={() => { this.selectJob(job.id); }}
                style={{ backgroundColor: this.state.selectedJob == job.id ? "#ccc" : undefined }}>
                <th scope="row">{job.id}</th>
                <td>{job.title}</td>
                <td>{job.date}</td>
                <td>{job.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default JobsTable;
