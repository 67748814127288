import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import formatDate from "./../../common/formatDate";
import GetWSMImage from "./GetWSMImage";

class WSM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      road: "",
      address: "",
      loading: true,
      mainTo: { material: "", pictures: [] },
      curbTo: { material: "", pictures: [] },
      housePictures: [],
      getImage: false,
      imageType: undefined,
      imageUpload: false,
      job: "2119-01",
      curbBoxDamage: false,
    };
  }
  closeForm = () => {
    this.props.close();
  };
  cancelAddPropertyImage = () => {
    this.setState({ addPropertyImage: false });
  };
  cancelAddMainToImage = () => {
    this.setState({ addMainToImage: false });
  };
  cancelAddCurbToImage = () => {
    this.setState({ addCurbToImage: false });
  };
  addPropertyImage = () => {
    this.setState({ addPropertyImage: true });
  };
  finishAddPropertyImages = (images) => {
    this.setState({ housePictures: images, addPropertyImage: false });
  };
  addMainToImage = () => {
    this.setState({ addMainToImage: true });
  };
  finishAddMainToImages = (images) => {
    let mainTo = this.state.mainTo;
    mainTo.pictures = images;
    this.setState({ mainTo: mainTo, addMainToImage: false });
  };
  addCurbToImage = () => {
    this.setState({ addCurbToImage: true });
  };
  finishAddCurbToImages = (images) => {
    let curbTo = this.state.curbTo;
    curbTo.pictures = images;
    this.setState({ curbTo: curbTo, addCurbToImage: false });
  };
  onRoadChange = (event) => {
    this.setState({ road: event.target.value });
  };
  onAddressChange = (event) => {
    this.setState({ address: event.target.value });
  };
  onMainToChange = (event) => {
    let mainTo = this.state.mainTo;
    mainTo.material = event.target.value;
    this.setState({ mainTo: mainTo });
  };
  onCurbToChange = (event) => {
    let curbTo = this.state.curbTo;
    curbTo.material = event.target.value;
    this.setState({ curbTo: curbTo });
  };
  onCurbBoxChange = (answer) => {
    this.setState({ curbBoxDamage: answer });
  };
  onMainToSizeChange = (event) => {
    let mainTo = this.state.mainTo;
    mainTo.size = event.target.value;
    this.setState({ mainTo: mainTo });
  };
  onCurbToSizeChange = (event) => {
    let curbTo = this.state.curbTo;
    curbTo.size = event.target.value;
    this.setState({ curbTo: curbTo });
  };
  finish = () => {
    let wsm = {
      road: this.state.road,
      address: this.state.address,
      housePictures: this.state.housePictures,
      mainTo: this.state.mainTo,
      curbTo: this.state.curbTo,
      curbBoxDamage: this.state.curbBoxDamage,
      id: this.state.wsmID,
      time: this.state.time,
      job: this.state.job,
      employee: this.props.firebase.auth.currentUser.displayName,
    };
    this.props.firebase.db
      .collection("wsm")
      .doc(wsm.id)
      .set(wsm)
      .then(
        function() {
          this.props.finish("success");
        }.bind(this)
      );
  };
  setFields = () => {
    this.setState({
      road: this.props.wsmData.road,
      address: this.props.wsmData.address,
      housePictures: this.props.wsmData.housePictures,
      mainTo: this.props.wsmData.mainTo,
      curbTo: this.props.wsmData.curbTo,
      curbBoxDamage: this.props.wsmData.curbBoxDamage,
      wsmID: this.props.wsmData.id,
      loading: false,
      time: this.props.wsmData.time,
      job: this.props.wsmData.job,
    });
  };
  uploadWSMImage = (img, index, total) => {
    return new Promise(async (res, rej) => {
      let ref = this.props.firebase.db
        .collection("wsm")
        .doc(this.state.wsmID)
        .collection("photos")
        .doc();
      let documentId = ref.id;
      // TODO replace with dayjs; let dateString2 = dayjs().format('M-D-YYYY');
      let date = new Date();
      let month = date.getMonth() + 1;
      let dateString = month + "-" + date.getDate() + "-" + date.getFullYear();
      let dateForNode = formatDate(date);
      img.dateForNode = dateForNode;
      const storagePath =
        img.job +
        "/" +
        "WSMPhotos" +
        dateString +
        "/" +
        documentId +
        "/" +
        img.photoName;
      img.storagePath = storagePath;
      const storageRef = this.props.firebase.storage.ref();
      let uploadTask = storageRef.child(storagePath).put(img.photo);
      await uploadTask.on(
        "state_changed",
        function(snapshot) {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          let i = index + 1;
          let message =
            "Upload " +
            i +
            " of " +
            total +
            " is " +
            progress.toFixed(2) +
            "% done";
          this.setState((prevState) => {
            return {
              uploadProgress: progress + "%",
              message: message,
              valid: false,
            };
          });
        }.bind(this),
        function(error) {
          // Handle unsuccessful uploads
          console.log(error);
          this.setState((prevState) => {
            return {
              error: error.message,
              message: "Error Uploading Photo: " + error.message,
            };
          });
          rej(error.message);
        }.bind(this),
        function() {
          // Handle successful uploads on complete
          // For instance, get the download URL: https://firebasestorage.googleapis.com/...
          uploadTask.snapshot.ref.getDownloadURL().then(
            function(downloadURL) {
              img.photo = downloadURL;
              img.id = documentId;
              this.props.firebase.db
                .collection("wsm")
                .doc(this.state.wsmID)
                .collection("photos")
                .doc(documentId)
                .set(img)
                .then(
                  function() {
                    this.setState({
                      message: "Image Uploaded Successfully",
                    });
                    res("success");
                  }.bind(this)
                );
            }.bind(this)
          );
        }.bind(this)
      );
    });
  };
  cancelGetImage = () => {
    this.setState({
      addCurbToImage: false,
      addMainToImage: false,
      addPropertyImage: false,
    });
  };
  submitWSMImage = (imageObject) => {
    this.setState((prevState) => {
      return {
        getImage: false,
        imageUpload: true,
        message: "Uploading " + imageObject.length + " Images",
      };
    });
    imageObject.forEach((img, i) => {
      if (i + 1 == imageObject.length) {
        this.uploadWSMImage(img, i, imageObject.length).then(() => {
          this.setState({
            imageUpload: false,
            message: "Success uploading " + imageObject.length + " images",
          });
          this.getWSMPhotos();
        });
      } else {
        this.uploadWSMImage(img, i, imageObject.length);
      }
    });
  };
  getWSMPhotos = () => {
    this.props.firebase.db
      .collection("wsm")
      .doc(this.state.wsmID)
      .collection("photos")
      .get()
      .then(
        function(res) {
          let propertyPhotos = [];
          let mainToPhotos = [];
          let curbToPhotos = [];
          res.forEach((photo, i) => {
            let p = photo.data();
            if (p.type == "property") {
              propertyPhotos.push(p);
            }
            if (p.type == "curbTo") {
              curbToPhotos.push(p);
            }
            if (p.type == "mainTo") {
              mainToPhotos.push(p);
            }
          });
          let stateMainTo = this.state.mainTo;

          let stateCurbTo = this.state.curbTo;
          let stateHousePictures = this.state.housePictures;

          stateMainTo.pictures = mainToPhotos;
          stateCurbTo.pictures = curbToPhotos;
          stateHousePictures = propertyPhotos;
          this.setState({
            housePictures: stateHousePictures,
            curbTo: stateCurbTo,
            mainTo: stateMainTo,
          });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("err getting wsmPhotos: " + err.message);
      });
  };
  deleteWSMPhoto() {
    this.setState({ imageUpload: true });
    const storageRef = this.props.firebase.storage.ref();
    let path, ref;
    path = this.state.selectedImage.storagePath;
    ref = storageRef.child(path);

    ref
      .delete()
      .then(
        function() {
          // File deleted successfully
          this.props.firebase.db
            .collection("wsm")
            .doc(this.state.wsmID)
            .collection("photos")
            .doc(this.state.selectedImage.id)
            .delete()
            .then(
              function() {
                this.setState({ jobStartPhoto: undefined }, () => {
                  this.props.getJobImages();
                });
              }.bind(this)
            )
            .catch(function(error) {
              console.error("Error removing document: ", error);
            });
        }.bind(this)
      )
      .catch(function(error) {
        // Uh-oh, an error occurred!
        console.log("error deleting object: " + error.message);
      });
  }
  componentDidMount() {
    if (this.props.wsmData !== undefined && this.props.wsmData !== null) {
      this.setFields();
    } else {
      let time = new Date().getTime();
      let wsmID = this.props.firebase.auth.currentUser.uid + "-" + time;
      this.setState({
        job: this.props.job,
        loading: false,
        wsmID: wsmID,
        time: time,
      });
    }
  }
  changeJob = (job) => {
    this.setState({ job: job });
  };
  render() {
    let streetSelect;
    // TODO investigate
    if (this.state.job == "2119-01") {
      streetSelect = (
        <select
          className="form-control"
          id="road"
          name="road"
          value={this.state.road}
          onChange={this.onRoadChange}
        >
          <option></option>
          <option>3rd Avenue</option>
          <option>Virginia Park St.</option>
          <option>Delaware St.</option>
          <option>Pallister Ave.</option>
          <option>Seville</option>
        </select>
      );
    } else if (this.state.job == "2119-02") {
      streetSelect = (
        <select
          className="form-control wsmSelect"
          id="road"
          name="road"
          value={this.state.road}
          onChange={this.onRoadChange}
        >
          <option></option>
          <option>Burt Rd.</option>
          <option>Auburn St.</option>
          <option>Minock St</option>
          <option>Chalfonte Ave.</option>
          <option>Penrod St.</option>
          <option>Ridge Rd.</option>
          <option>Bramell St.</option>
          <option>Puritan Ave.</option>
          <option>Lamphere St.</option>
          <option>Dolphin St.</option>
          <option>Verne Ave.</option>
          <option>Blackstone St.</option>
          <option>Pierson St.</option>
          <option>Braile St.</option>
          <option>Patton St.</option>
          <option>Southfield Service Dr.</option>
          <option>Lahser Rd.</option>
          <option>Westbrook St.</option>
        </select>
      );
    }

    return (
      <div>
        {this.state.imageUpload ? (
          <div>{this.state.message}</div>
        ) : (
          <div>
            {this.state.loading ? (
              "Loading"
            ) : (
              <div className="jobForm wsm">
                <button
                  className="btn btn-secondary btn-sm exit"
                  onClick={this.closeForm}
                >
                  CLOSE
                </button>
                <div className="jobFormContainer">
                  <h1 className="display-5">WSM For Job# {this.state.job}</h1>
                  <br />
                  <div className="form-group row">
                    Job #<label htmlFor="-01">2119-01</label>
                    <input
                      type="checkbox"
                      checked={this.state.job == "2119-01" ? true : false}
                      onChange={() => {
                        this.changeJob("2119-01");
                      }}
                      id="-01"
                      name="-01"
                    />
                    <label htmlFor="-01">2119-02</label>
                    <input
                      type="checkbox"
                      checked={this.state.job == "2119-02" ? true : false}
                      onChange={() => {
                        this.changeJob("2119-02");
                      }}
                      id="-02"
                      name="-02"
                    />
                  </div>
                  <h3>Property</h3>
                  <div className="form-group row">
                    <label htmlFor="road">Road</label>

                    {streetSelect}
                  </div>
                  <div className="form-group row">
                    <label htmlFor="address">Address</label>
                    <input
                      className="form-control"
                      type="text"
                      name="address"
                      value={this.state.address}
                      onChange={this.onAddressChange}
                      id="address"
                    />
                  </div>
                  <div className="form-group row">
                    {this.state.housePictures.map((photo, i) => (
                      <div key={i} className="col col-md-4">
                        <img className="icon" src={photo.photo} />
                      </div>
                    ))}
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={this.addPropertyImage}
                    >
                      Add Image{" "}
                    </button>
                  </div>

                  <h3>Existing Water Service Material</h3>
                  <hr />
                  <h3>Main To Curb Stop</h3>
                  <div className="form-group row">
                    <label htmlFor="mainToMaterial">Material</label>

                    <select
                      className="form-control wsmSelect"
                      id="mainToMaterial"
                      name="mainToMaterial"
                      value={this.state.mainTo.material}
                      onChange={this.onMainToChange}
                    >
                      <option></option>
                      <option>Copper</option>
                      <option>Galvanized</option>
                      <option>Lead</option>
                      <option>Other</option>
                    </select>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="mainToSize">Pipe Size</label>

                    <select
                      className="form-control wsmSelect"
                      id="mainToSize"
                      name="mainToSize"
                      value={this.state.mainTo.size}
                      onChange={this.onMainToSizeChange}
                    >
                      <option></option>
                      <option>3/4"</option>
                      <option>1"</option>
                      <option>1.5"</option>
                      <option>2"</option>
                      <option>3"</option>
                      <option>4"</option>
                    </select>
                  </div>
                  <div className="form-group row">
                    MainTo Images
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={this.addMainToImage}
                    >
                      Add Image
                    </button>
                    {this.state.mainTo.pictures.map((photo, i) => (
                      <div key={i} className="col col-md-4">
                        <img className="icon" src={photo.photo} />
                      </div>
                    ))}
                  </div>
                  <hr />
                  <h3>Curb To Home</h3>
                  <div className="form-group row">
                    <label htmlFor="curbTo">CurbTo Material</label>
                    <select
                      className="form-control wsmSelect"
                      id="curbToMaterial"
                      name="curbToMaterial"
                      value={this.state.curbTo.material}
                      onChange={this.onCurbToChange}
                    >
                      <option></option>
                      <option>Copper</option>
                      <option>Galvanized</option>
                      <option>Lead</option>
                      <option>Other</option>
                    </select>
                  </div>
                  <div className="form-group row">
                    <label htmlFor="curbTo">CurbTo Size</label>
                    <select
                      className="form-control wsmSelect"
                      id="curbToSize"
                      name="curbToSize"
                      value={this.state.curbTo.size}
                      onChange={this.onCurbToSizeChange}
                    >
                      <option></option>
                      <option>3/4"</option>
                      <option>1"</option>
                      <option>1.5"</option>
                      <option>2"</option>
                      <option>3"</option>
                      <option>4"</option>
                    </select>
                  </div>

                  <div className="form-group row">
                    CurbTo Images
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={this.addCurbToImage}
                    >
                      Add Image
                    </button>
                    {this.state.curbTo.pictures.map((photo, i) => (
                      <div key={i} className="col col-md-4">
                        <img className="icon" src={photo.photo} />
                      </div>
                    ))}
                  </div>
                  <hr />

                  <div className="form-group row curbBox">
                    <h3>Curb Box Damage? </h3>
                    <label htmlFor="yes">
                      <h3>Yes</h3>
                    </label>
                    <input
                      type="checkbox"
                      checked={this.state.curbBoxDamage ? true : false}
                      className="form-control-inline"
                      id="yes"
                      name="yes"
                      onChange={() => {
                        this.onCurbBoxChange(true);
                      }}
                    />
                    <label htmlFor="no">
                      <h3>No</h3>
                    </label>
                    <input
                      type="checkbox"
                      checked={this.state.curbBoxDamage ? false : true}
                      id="no"
                      className="form-control-inline"
                      name="no"
                      onChange={() => {
                        this.onCurbBoxChange(false);
                      }}
                    />
                  </div>
                  <button className="btn btn-primary" onClick={this.finish}>
                    Save WSM
                  </button>
                </div>
                {this.state.addPropertyImage ? (
                  <GetWSMImage
                    close={this.cancelGetImage}
                    wsmID={this.state.wsmID}
                    submit={this.submitWSMImage}
                    type="property"
                  />
                ) : (
                  ""
                )}
                {this.state.addMainToImage ? (
                  <GetWSMImage
                    close={this.cancelGetImage}
                    wsmID={this.state.wsmID}
                    submit={this.submitWSMImage}
                    type="mainTo"
                  />
                ) : (
                  ""
                )}
                {this.state.addCurbToImage ? (
                  <GetWSMImage
                    close={this.cancelGetImage}
                    wsmID={this.state.wsmID}
                    submit={this.submitWSMImage}
                    type="curbTo"
                  />
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(WSM);
