import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";

class GetImage extends Component {
  constructor() {
    super();
    this.state = {
      selectedImage: undefined
    };
    this.onFileSelect = this.onFileSelect.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitJobPhoto = this.submitJobPhoto.bind(this);
  }
  componentDidMount() {
    console.log("inside getImage, job is " + this.props.job);
  }
  onChange(event) {
    console.log("changed image caption to: " + event.target.value);
    let caption = event.target.value;
    let images = [];
    if (this.state.photos.length > 0) {
      images = [...this.state.photos];
    } else {
      images = [];
    }

    let newImages = [];
    images.forEach((img, i) => {
      if (img.name == event.target.name) {
        console.log("found img to change caption");
        img.caption = event.target.value;
      }
      newImages.push(img);
    });
    this.setState(prevState => {
      return { photos: newImages };
    });
  }
  submitJobPhoto(e) {
    e.preventDefault();
    console.log("inside submitJobPhoto");

    this.setState({ valid: false });
    console.log("submitting");
    let photos = [];
    this.state.photos.forEach((img, i) => {
      let caption;
      if (img.caption == undefined || img.caption == "") {
        console.log("caption undefined, setting to empty string");
        caption = "";
      } else {
        caption = img.caption;
      }
      let jobPhoto = {
        job: this.props.job,
        employee: this.props.firebase.auth.currentUser.displayName,
        photo: img.photo,
        photoName: img.name,
        caption: caption
      };
      photos.push(jobPhoto);
    });
    this.props.submit(photos);
  }
  onFileSelect(event) {
    let name = event.target.files[0].name;
    let photo = event.target.files[0];
    let photos = [...event.target.files];
    let statePhotos = [];
    if (this.state.photos == undefined || this.state.photos.length < 1) {
      statePhotos = [];
    } else {
      statePhotos = [...this.state.photos];
    }

    photos.forEach((file, i) => {
      console.log("got");
      let photo = {
        photo: event.target.files[i],
        url: URL.createObjectURL(event.target.files[i]),
        name: event.target.files[i].name
      };

      statePhotos.push(photo);
    });
    this.setState({
      photos: statePhotos,
      valid: true
    });
    console.log("from state: " + this.state.photo);
  }
  selectImage = event => {
    console.log("selecting : " + event.target.name);
    let images = this.state.photos;
    let newImages = [];
    let selectedImage = undefined;
    images.forEach((img, i) => {
      if (img.name == event.target.name) {
        if (img.selected == true) {
          img.selected = false;
          selectedImage = undefined;
        } else {
          img.selected = true;
          selectedImage = img;
        }
        newImages.push(img);
      } else {
        img.selected = false;
        newImages.push(img);
      }
    });
    this.setState({ photos: newImages, selectedImage: selectedImage });
  };
  removeSelectedImage = () => {
    console.log("inside removeSelectedImage");
    let images = [...this.state.photos];
    let newImages = [];
    images.forEach((img, i) => {
      if (img.selected) {
        //don't add
      } else {
        newImages.push(img);
      }
    });
    this.setState({ photos: newImages, selectedImage: undefined });
  };
  render() {
    let submit2, title;
    submit2 = (
      <input
        type="submit"
        onClick={this.submitJobPhoto}
        className="btn btn-secondary addJobButton"
        disabled={this.state.valid ? false : true}
        style={{ marginTop: "1em" }}
      />
    );
    switch (this.props.imageType) {
      case "start":
        title = "Job Start Photos";
        break;
      case "finish":
        title = "Job Finish Photos";
        break;
      case "other":
        title = "Other Job Photos";
        break;
      default:
        break;
    }
    return (
      <div className="getImage">
        {title}

        <form>
          <div onClick={this.props.close} className="exit">
            <p className="lead">Exit</p>
          </div>
          <div className="form-group formRow row">
            {this.state.photos !== undefined
              ? this.state.photos.map((img, i) => (
                <div
                  className={
                    img.selected ? "col col-md-3 selected" : "col col-md-3"
                  }
                  key={i}
                >
                  <img
                    name={img.name}
                    onClick={this.selectImage}
                    className="addImage"
                    src={img.url}
                  />
                  <label className="row col-form-label" htmlFor="jobLocation">
                    Image Caption
                  </label>
                  <input
                    className="form-control row"
                    type="text"
                    id="imageCaption"
                    onChange={this.onChange}
                    placeholder="Caption"
                    value={img.caption}
                    name={img.name}
                  />
                </div>
              ))
              : ""}{" "}
          </div>
          {this.state.selectedImage !== undefined ? (
            <a
              className="btn btn-secondary btn-sm"
              onClick={() => {
                this.removeSelectedImage();
              }}
            >
              Remove Selected Image
            </a>
          ) : (
            ""
          )}
          <div className="form-group formRow">
            <label className="row col-form-label" htmlFor="customFile">
              Select Images:
            </label>
            <div className="custom-file">
              <input
                type="file"
                className="custom-file-input lowInput"
                accept="image/*"
                multiple="multiple"
                onChange={this.onFileSelect}
                id="customFile"
              />
              <label className="custom-file-label" htmlFor="customFile">
                {this.state.photoName}
              </label>
            </div>
          </div>
          <div className="form-group row">
            <input
              type="submit"
              onClick={this.submitJobPhoto}
              className="btn btn-secondary addJobButton"
              disabled={this.state.valid ? false : true}
              style={{ marginTop: "1em" }}
            />
            </div>
        </form>
      </div>
    );
  }
}

export default compose(withFirebase)(GetImage);
