import React, { Component } from "react";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import LastJob from "./LastJob";
import version from "./../../version";
import { compareTime } from "../common/Helpers";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lastJob: undefined
    };
    this.getLastJob = this.getLastJob.bind(this);
  }
  componentDidMount() {
    this.getLastJob();
  }

  getLastJob() {
    this.props.firebase.db
      .collection("timesheets")
      .where("createdBy.uid", "==", this.props.firebase.auth.currentUser.uid)
      .get()
      .then(
        function(timesheetsSnapshot) {
          let timesheets = [];
          timesheetsSnapshot.forEach(function(timesheet) {
            timesheets.push(timesheet.data());
          });
          timesheets.sort(compareTime);
          if (timesheets[0] == undefined) {
            //no last job
            this.setState({ lastJob: undefined });
          } else {
            this.setState({ lastJob: timesheets[0].job });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting timesheets: " + err.message);
      });
  }
  render() {
    let lastJob;
    if (this.state.lastJob == undefined) {
      // TODO put loading message in here
      lastJob = <p>No Jobs Worked Yet</p>;
    } else {
      lastJob = <LastJob job={this.state.lastJob} />;
    }
    return (
      <div>
        Welcome, {this.props.firebase.auth.currentUser.displayName}
        <br />
        {lastJob}
        Version: {version}
      </div>
    );
  }
}

export default compose(withFirebase)(Home);
