import React, { Component } from "react";
import formatDate from "../../common/formatDate";

class WSMTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedWSM: null,
    };
    this.selectWSM = this.selectWSM.bind(this);
  }

  selectWSM(event) {
    if (this.state.selectedWSM == event) {
      this.props.selectWSM(null);
      this.setState({ selectedWSM: null });
    } else {
      this.props.selectWSM(event);
      this.setState({ selectedWSM: event });
    }
  }

  render() {
    return (
      <div>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th className="sort" scope="col">Time</th>
              <th className="sort" scope="col"
                // onClick={() => { TODO
                //   this.props.sort("date");
                // }}
              >
                Address
              </th>
              <th className="sort" scope="col" onClick={() => { this.props.sort("type"); }}>
                Job
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.wsm.map((wsm, i) => (
              <tr key={i}
                style={{
                  backgroundColor:
                    this.state.selectedWSM == wsm.id ? "#ccc" : undefined,
                }}
                onClick={() => {
                  this.selectWSM(wsm.id);
                }}
              >
                <th scope="row">{formatDate(wsm.time)}</th>
                <td>{wsm.address}</td>
                <td>{wsm.job}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default WSMTable;
