import React, { Component } from "react";
import Tabs from "./Tabs";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import { prepareEmployeeExpenseWeek } from "./prepareReport";
import WeeklyReportTable from "./WeeklyReportTable";
import ViewPrintableReport from "./ViewPrintableReport";
import Button from '@material-ui/core/Button';
import dayjs from "dayjs";

class ExpenseReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reports: [],
      loading: true,
      todayTime: 0,
      sundayTime: 0,
      weekOffset: 0,
      expenseReports: [],
      week: undefined,
      sundayDate: undefined,
      saturdayDate: undefined,
      viewReport: false,
      selectedReport: undefined
    };
  }

  componentDidMount() {
    this.figureOutWeekSpan();
  }
  figureOutWeekSpan = () => {
    let selectedWeek = dayjs().add(this.state.weekOffset, 'week');
    let selectedWeekSunday = selectedWeek.startOf('week');
    let selectedWeekSaturday = selectedWeek.endOf('week');

    this.setState(
      {
        saturdayDate: selectedWeekSaturday.format('ddd MMM DD YYYY'),
        sundayDate: selectedWeekSunday.format('ddd MMM DD YYYY'),
        loading: true
      },
      () => {
        this.getThisWeek(selectedWeekSunday, selectedWeekSaturday).then(
          function(res) {
            this.getEmployees(res);
          }.bind(this)
        );
      }
    );
  };

  getEmployees = reports => {
    let users = [];
    this.props.firebase.db
      .collection("users")
      .get()
      .then(
        function(userSnapshot) {
          userSnapshot.forEach(function(user) {
            users.push(user.data());
          });
          this.prepareWeeklyReport(reports, users);
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting users: " + err.message);
      });
  };
  nextWeek = () => {
    let newOffset = this.state.weekOffset + 1;
    let today = new Date();
    let day = today.getDay();
    let fromSunday = 86400000 * day;
    let todayTime = today.getTime();
    let thisSundayTime = todayTime - fromSunday;
    this.setState({ weekOffset: newOffset }, () => {
      this.figureOutWeekSpan(thisSundayTime);
    });
  };
  previousWeek = () => {
    let newOffset = this.state.weekOffset - 1;
    let today = new Date();
    let day = today.getDay();
    let fromSunday = 86400000 * day;
    let todayTime = today.getTime();
    let thisSundayTime = todayTime - fromSunday;
    this.setState({ weekOffset: newOffset }, () => {
      this.figureOutWeekSpan(thisSundayTime);
    });
  };
  prepareWeeklyReport = (expenseReports, employees) => {
    if (expenseReports == undefined || employees == undefined) {
      return;
    } else {
      //not undefined, preparing report
      let employeesAndReports = [];
      for (let i = 0; i < employees.length; i++) {
        let employee = prepareEmployeeExpenseWeek(employees[i], expenseReports)
          .then(function(employee) {
            employeesAndReports.push(employee);
          })
          .then(
            function() {
              if (this.state.employees === employeesAndReports) {
                return;
              } else {
                this.setState(prevState => {
                  return { employees: employeesAndReports, loading: false };
                });
              }
            }.bind(this)
          );
      }
    }
  };
  closeReport = () => {
    this.setState({ viewReport: false });
  };
  getThisWeek = (sunday, saturday, job) => {
    return new Promise(
      function(res, rej) {
        let expenseReports = [];
        let sundayTime = sunday.valueOf();
        let saturdayEnd = saturday.endOf('day').valueOf();
        this.props.firebase.db
          .collection("expense-reports")
          .where("dateTimeOfCharge", ">=", sundayTime)
          .get()
          .then(
            function(expenseReportSnapshot) {
              expenseReportSnapshot.forEach(function(expenseReport) {
                if (expenseReport.data().dateTimeOfCharge <= saturdayEnd) {
                  expenseReports.push(expenseReport.data());
                }
              });
              res(expenseReports);
            }.bind(this)
          )
          .catch(function(err) {
            console.log(err.message);
            rej(err);
          });
      }.bind(this)
    );
  };
  openReport = report => {
    this.setState({ selectedReport: report, viewReport: true });
  };
  render() {
    let loading, viewReport;
    if (this.state.viewReport) {
      viewReport = (
        <ViewPrintableReport
          report={this.state.selectedReport}
          type="expenseReport"
          exit={this.closeReport}
        />
      );
    } else {
      viewReport = "";
    }
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = (
        <div>
          <hr />
          {/* TODO */}
          <div className="dashboardControls" style={{marginBottom: "1em"}}>
            <Button variant="contained" color="inherit" onClick={() => { this.previousWeek(); }}>
              Previous
            </Button>
            <Button variant="contained" color="inherit" onClick={() => { this.nextWeek(); }}>
              Next
            </Button>
          </div>
          <p className="lead">
            {this.state.sundayDate} to {this.state.saturdayDate}
          </p>
          <WeeklyReportTable
            open={this.openReport}
            employees={this.state.employees}
            saturday={this.state.saturdayDate}
            sunday={this.state.sundayDate}
          />
        </div>
      );
    }
    return (
      <div>
        <Tabs />
        {loading}
        {viewReport}
      </div>
    );
  }
}

export default compose(withFirebase)(ExpenseReports);
