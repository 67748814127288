import React, { Component } from "react";
import boreValidationIMG1 from "./../../../assets/boreValidationIMG1.jpg";
import boreValidationIMG2 from "./../../../assets/boreValidationIMG2.jpg";

export default class BoreValidationSecond extends Component {
  render() {
    return (
      <div>
        <h3 className="display-5">
          GAS OPERATIONS CONSTRUCTION AND FIELD PRACTICE 013
        </h3>

        <p>
          Use the following procedures if boring parallel to and within 5' of
          existing gas facilities; or, if boring across existing gas facilities.
        </p>

        <p>
          Boring - For this standard, boring is the use of any form of
          trenchless main or service installation procedure.
        </p>

        <p>
          Protect our existing gas facilities from being damaged when using
          boring equipment to install new gas facilities.
        </p>

        <p>
          Refer to Practice 014 for specific road crossing requirements and to
          Practice 015 for tree boring requirements.
        </p>

        <p>
          Bore Head on Course - At intervals not to exceed 10', use a locating
          instrument to verify that the bore head is at the designed location
          and at the appropriate depth.
        </p>

        <p>Missiles are prohibited unless they are locatable.</p>
        <h3 className="display-5">
          PARALLEL INSTALLATIONS WITHIN 5' OF EXISTING GAS FACILITIES
        </h3>

        <p>
          Proposed Main or Service Location - Stake or paint the proposed
          location at intervals not to exceed 50' to determine the bore path.
        </p>

        <p>
          Existing Main or Service Location - Stake or paint the existing
          location at intervals not to exceed 10' to:
        </p>

        <p>Verify the accuracy of the work order drawing, and</p>

        <p>
          Assure that the main or service does not have an offset or change of
          direction, and
        </p>

        <p>Provide a reference for the bore head location verification.</p>

        <p>
          Ensure that the bore head and/or the reamer DO NOT make contact with
          existing gas facilities.
        </p>

        <p>
          Hand dig or use other nondestructive means to expose the existing gas
          facility at intervals not to exceed 50'.
        </p>

        <p>
          If the existing gas facility is parallel to; and, under pavement,
          initially determine locating accuracy by hand digging or using some
          other nondestructive means to expose the facility then periodically
          hand dig at the road edge to verify that the existing gas facility
          remains under the road.
        </p>
        <img src={boreValidationIMG1} />
        <p>
          <b>Notes:</b>
        </p>
        <p>
          A. Stake or paint proposed facility at intervals not to exceed 50'.
        </p>

        <p>
          B. Stake or paint existing facility at intervals not to exceed 10'.
        </p>
        <p>
          C. Verify that the bore head is on course at intervals not to exceed
          10'.
        </p>

        <p>D. Initially expose existing facility.</p>
        <p>E. Periodically hand dig at the road edge.</p>

        <p>
          If the existing gas facility crosses under the pavement, determine
          locating accuracy by exposing the facility at each curb.
        </p>

        <img src={boreValidationIMG2} />
        <p>
          <b>Notes:</b>
        </p>
        <p>
          A. Stake or paint proposed facility at intervals not to exceed 50'.
        </p>
        <p>
          B. Stake or paint existing facility at intervals not to exceed 10'.
        </p>
        <p>
          C. Verify that the bore head is on course at intervals not to exceed
          10'.
        </p>
        <p>D. Expose existing facility at each curb.</p>
        <p>E. Expose existing facility at intervals not to exceed 50'.</p>

        <h3 className="display-5">
          INSTALLATIONS CROSSING EXISTING GAS FACILITIES
        </h3>
        <p>
          Proposed Main or Service Location - Stake or paint the proposed
          location at the crossing and 10' each side of the crossing to
          determine the bore path.
        </p>
        <p>
          Existing Main or Service Location - Ensure that the bore head and/or
          the reamer DO NOT make contact with the existing gas facilities.
        </p>
        <p>
          Hand dig or use other nondestructive means to expose the existing gas
          facility at each bore crossing.
        </p>
        <p>
          Observe the crossing as the bore head and the reamer pass the gas
          facility and make adjustments if necessary.
        </p>
        <p>
          Maintain a minimum separation of 12" when crossing other gas
          facilities.
        </p>
      </div>
    );
  }
}
