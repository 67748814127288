import React, { Component } from "react";

class ExpenseReportTableItem extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    let date = new Date(this.props.expenseReport.dateTimeOfCharge);
    let dateString = date.toDateString();
    return (
      <tr
        className="openReport"
        onClick={() => {
          this.props.open();
        }}
      >
        <td>{dateString}</td>
        <td>{this.props.expenseReport.typeOfCharge}</td>
        <td>{this.props.expenseReport.amount}</td>
        <td>{this.props.expenseReport.employee}</td>
        <td>
          <img className="receiptPhoto" src={this.props.expenseReport.photo} />
        </td>
      </tr>
    );
  }
}

export default ExpenseReportTableItem;
