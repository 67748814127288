import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import AddEquipment from "./AddEquipment";
import Backdrop from "../../common/Backdrop";
import EquipmentTable from "./../../common/EquipmentTable";
import DeleteConfirmation from "./DeleteConfirmation";
import RequestsTable from "./../../common/RequestsTable";
import DeleteRequestConfirmation from "./../../common/DeleteRequestConfirmation";
import ViewMaintenanceRequest from "./ViewMaintenanceRequest";
class Maintenance extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      message: undefined,
      addEquipment: false,
      equipment: [],
      confirmation: false,
      requests: [],
      archivedRequests: [],
    };
  }
  addEquipment = () => {
    console.log("add equipment");
    this.setState({ addEquipment: true });
  };
  closeAddEquipment = () => {
    this.setState({ addEquipment: false });
  };
  componentDidMount() {
    this.getEquipment();
  }
  saveEquipment = (equipment) => {
    console.log("save equipment from Maintenance");
    let docId = this.props.firebase.db.collection("equipment").doc();
    this.setState({ addEquipment: false, loading: true });
    equipment.id = docId.id;
    this.props.firebase.db
      .collection("equipment")
      .doc(equipment.id)
      .set(equipment)
      .then(
        function(res) {
          this.setState(
            { message: "Successfully Saved Equipment.", loading: false },
            () => {
              this.getEquipment();
            }
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error saving equipment: " + err.message);
          this.setState(
            {
              message:
                "There Was An Error Saving The Equipment. Refresh And Try Again.",
              loading: false,
            },
            () => {
              this.getEquipment();
            }
          );
        }.bind(this)
      );
  };
  getArchivedRequests = () => {
    console.log("getting archived requests");
    this.props.firebase.db
      .collection("archived-maintenance")
      .get()
      .then(
        function(res) {
          let requests = [];
          res.forEach((request, i) => {
            requests.push(request.data());
          });
          this.setState({ archivedRequests: requests, loading: false });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting archived requests: " + err.message);
      });
  };
  getRequests = () => {
    this.props.firebase.db
      .collection("maintenance")
      .get()
      .then(
        function(res) {
          let requests = [];
          res.forEach((request, i) => {
            requests.push(request.data());
          });
          this.setState({ requests: requests }, () => {
            this.getArchivedRequests();
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error getting maintenance requests: " + err.message);
          this.setState({
            message: "There Was An Error Getting The Maintenance Requests.",
            loading: false,
          });
        }.bind(this)
      );
  };
  getEquipment = () => {
    this.props.firebase.db
      .collection("equipment")
      .get()
      .then(
        function(res) {
          let equipment = [];
          res.forEach((truck, i) => {
            equipment.push(truck.data());
          });
          this.setState({ equipment: equipment }, () => {
            this.getRequests();
          });
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error getting equipment : " + err.message);
          this.setState(
            {
              message: "There Was An Error Accessing The Database.",
            },
            () => {
              this.getRequests();
            }
          );
        }.bind(this)
      );
  };
  viewEquipment = () => {
    this.props.history.push(
      "/dashboard/maintenance/equipment/" + this.state.selectedEquipment.name
    );
  };
  selectEquipment = (equipment) => {
    if (equipment == null) {
      this.setState({ selectedEquipment: undefined });
    } else {
      this.setState({ selectedEquipment: equipment });
    }
  };
  deleteEquipment = () => {
    this.setState({ confirmation: true });
  };
  cancelDelete = () => {
    this.setState({ confirmation: false });
  };
  answerConfirmation = (answer) => {
    if (answer) {
      //will delete
      this.props.firebase.db
        .collection("equipment")
        .doc(this.state.selectedEquipment.id)
        .delete()
        .then(
          function(res) {
            this.setState(
              {
                message: "Successfully Deleted Equipment Document",
                confirmation: false,
              },
              () => {
                this.getEquipment();
              }
            );
          }.bind(this)
        )
        .catch(
          function(err) {
            console.log("error deleting equipment: " + err.message);
            this.setState(
              {
                message: "There Was An Error Deleting Document",
                confirmation: false,
              },
              () => {
                this.getEquipment();
              }
            );
          }.bind(this)
        );
    } else {
      //nope
      this.setState({ confirmation: false });
    }
  };
  selectRequest = (request) => {
    if (request == null) {
      this.setState({ selectedRequest: undefined });
    } else {
      this.setState({ selectedRequest: request });
    }
  };
  selectArchivedRequest = (request) => {
    if (request == null) {
      this.setState({ selectedArchivedRequest: undefined });
    } else {
      this.setState({ selectedArchivedRequest: request });
    }
  };
  restoreRequest = () => {
    this.setState({ loading: true });
    this.props.firebase.db
      .collection("maintenance")
      .doc(this.state.selectedArchivedRequest.id)
      .set(this.state.selectedArchivedRequest)
      .then(
        function(res) {
          this.props.firebase.db
            .collection("archived-maintenance")
            .doc(this.state.selectedArchivedRequest.id)
            .delete()
            .then(
              function(res) {
                this.setState(
                  {
                    message: "Successfully Restored Maintenance Request",
                    loading: false,
                    selectedArchivedRequest: undefined,
                  },
                  () => {
                    this.getRequests();
                  }
                );
              }.bind(this)
            )
            .catch(
              function(err) {
                console.log("error deleting request: " + err.message);
                this.setState({
                  message:
                    "There Was An Error Trying To Restore The Maintenance Request.",
                  loading: false,
                });
              }.bind(this)
            );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error setting archived request: " + err.message);
          this.setState({
            message: "There Was An Error Setting Restored Request.",
            loading: false,
          });
        }.bind(this)
      );
  };
  archiveRequest = () => {
    this.setState({ loading: true });
    this.props.firebase.db
      .collection("archived-maintenance")
      .doc(this.state.selectedRequest.id)
      .set(this.state.selectedRequest)
      .then(
        function(res) {
          this.props.firebase.db
            .collection("maintenance")
            .doc(this.state.selectedRequest.id)
            .delete()
            .then(
              function(res) {
                this.setState(
                  {
                    message: "Successfully Archived Maintenance Request",
                    loading: false,
                    selectedRequest: undefined,
                  },
                  () => {
                    this.getRequests();
                  }
                );
              }.bind(this)
            )
            .catch(
              function(err) {
                console.log("error deleting request: " + err.message);
                this.setState({
                  message:
                    "There Was An Error Trying To Archive The Maintenance Request.",
                  loading: false,
                });
              }.bind(this)
            );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error setting archived request: " + err.message);
          this.setState({
            message: "There Was An Error Setting Archived Request.",
            loading: false,
          });
        }.bind(this)
      );
  };
  deleteRequest = () => {
    this.setState({ deleteConfirmation: true });
  };
  answerDelete = (answer) => {
    if (answer) {
      this.executeDeleteRequest();
    } else {
      this.setState({ deleteConfirmation: false });
    }
  };
  answerDeleteArchived = (answer) => {
    if (answer) {
      this.executeDeleteArchivedRequest();
    } else {
      this.setState({ deleteArchivedConfirmation: false });
    }
  };
  deleteArchivedRequest = () => {
    this.setState({ deleteArchivedConfirmation: true });
  };
  executeDeleteArchivedRequest = () => {
    this.setState({ loading: true, deleteArchivedConfirmation: false });
    this.props.firebase.db
      .collection("archived-maintenance")
      .doc(this.state.selectedArchivedRequest.id)
      .delete()
      .then(
        function(res) {
          this.setState(
            {
              loading: false,
              message: "Successfully Deleted The Archived Request",
              selectedArchivedRequest: undefined,
            },
            () => {
              this.getRequests();
            }
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error deleting request: " + err.message);
          this.setState(
            {
              loading: false,
              message: "There was an error deleting the archived request",
              selectedArchivedRequest: undefined,
            },
            () => {
              this.getRequests();
            }
          );
        }.bind(this)
      );
  };
  viewRequest = () => {
    this.setState({ viewRequest: true });
  };
  cancelViewRequest = () => {
    this.setState({ viewRequest: false });
  };
  executeDeleteRequest = () => {
    this.setState({ loading: true, deleteConfirmation: false });
    this.props.firebase.db
      .collection("maintenance")
      .doc(this.state.selectedRequest.id)
      .delete()
      .then(
        function(res) {
          this.setState(
            {
              loading: false,
              message: "Successfully Deleted The Request",
              selectedRequest: undefined,
            },
            () => {
              this.getRequests();
            }
          );
        }.bind(this)
      )
      .catch(
        function(err) {
          console.log("error deleting request: " + err.message);
          this.setState(
            {
              loading: false,
              message: "There was an error deleting the request",
              selectedRequest: undefined,
            },
            () => {
              this.getRequests();
            }
          );
        }.bind(this)
      );
  };
  render() {
    return (
      <div>
        <div className="dashboardPanel">
          <div className="dashboardPanelHeading">
            <h1 className="display-5">Maintenance</h1>
          </div>
          {this.state.message !== undefined ? (
            <p className="alert">{this.state.message}</p>
          ) : (
            ""
          )}
          <h1 className="display-5">Maintenance Requests:</h1>

          <div className="row">
            <div className="col col-md-6">
              <p className="lead">Active Requests</p>{" "}
              <div className="dashboardControls">
              {/* TODO  */}
                {this.state.selectedRequest !== undefined ? (
                  <div>
                    <a
                      className="btn btn-secondary dashboardButton"
                      onClick={() => {
                        if (this.state.loading) {
                          return;
                        } else {
                          this.viewRequest();
                        }
                      }}
                    >
                      View Maintenance Request
                    </a>
                    <a
                      className="btn btn-secondary dashboardButton"
                      onClick={() => {
                        if (this.state.loading) {
                          return;
                        } else {
                          this.archiveRequest();
                        }
                      }}
                    >
                      Archive Maintenance Request
                    </a>
                    <a
                      className="btn btn-secondary dashboardButton"
                      onClick={() => {
                        if (this.state.loading) {
                          return;
                        } else {
                          this.deleteRequest();
                        }
                      }}
                    >
                      Delete Maintenance Request
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <RequestsTable
                requests={this.state.requests}
                equipment={this.state.equipment}
                selectRequest={this.selectRequest}
              />
            </div>
            <div className="col col-md-6">
              <p className="lead">Archived Requests</p>{" "}
              <div className="dashboardControls">
              {/* TODO  */}
                {this.state.selectedArchivedRequest !== undefined ? (
                  <div>
                    <a
                      className="btn btn-secondary dashboardButton"
                      onClick={() => {
                        if (this.state.loading) {
                          return;
                        } else {
                          this.restoreRequest();
                        }
                      }}
                    >
                      Restore Maintenance Request
                    </a>
                    <a
                      className="btn btn-secondary dashboardButton"
                      onClick={() => {
                        if (this.state.loading) {
                          return;
                        } else {
                          this.deleteArchivedRequest();
                        }
                      }}
                    >
                      Delete Maintenance Request
                    </a>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <RequestsTable
                requests={this.state.archivedRequests}
                equipment={this.state.equipment}
                selectRequest={this.selectArchivedRequest}
              />
            </div>
          </div>

          <hr />
          {this.state.addEquipment ? (
            <div>
              <AddEquipment
                equipment={this.state.equipment}
                saveEquipment={this.saveEquipment}
              />
              <Backdrop click={this.closeAddEquipment} />
            </div>
          ) : (
            ""
          )}
          {this.state.confirmation ? (
            <div>
              <DeleteConfirmation
                equipment={this.state.equipment.name}
                answer={this.answerConfirmation}
              />
              <Backdrop click={this.cancelDelete} />
            </div>
          ) : (
            ""
          )}
          <h1 className="display-5">Equipment:</h1>
          <div className="dashboardControls">
          {/* TODO  */}
            {" "}
            <a
              className="btn btn-secondary dashboardButton"
              onClick={() => {
                if (this.state.loading) {
                  return;
                } else {
                  this.addEquipment();
                }
              }}
            >
              Add Equipment
            </a>
            {this.state.selectedEquipment !== undefined ? (
              <div>
                {" "}
                <a
                  className="btn btn-secondary dashboardButton"
                  onClick={() => {
                    if (this.state.loading) {
                      return;
                    } else {
                      this.deleteEquipment();
                    }
                  }}
                >
                  Delete Equipment
                </a>
                <a
                  className="btn btn-secondary dashboardButton"
                  onClick={() => {
                    if (this.state.loading) {
                      return;
                    } else {
                      this.viewEquipment();
                    }
                  }}
                >
                  View Equipment
                </a>
              </div>
            ) : (
              ""
            )}
          </div>

          <EquipmentTable
            equipment={this.state.equipment}
            selectEquipment={this.selectEquipment}
          />
        </div>
        {this.state.deleteConfirmation ? (
          <DeleteRequestConfirmation answer={this.answerDelete} />
        ) : (
          ""
        )}
        {this.state.deleteArchivedConfirmation ? (
          <DeleteRequestConfirmation answer={this.answerDeleteArchived} />
        ) : (
          ""
        )}
        {this.state.viewRequest ? (
          <div>
            <ViewMaintenanceRequest request={this.state.selectedRequest} />
            <Backdrop click={this.cancelViewRequest} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(Maintenance);
