import React, { Component } from "react";
import formatDate from "./../../common/formatDate";
import ViewImage from "./../../common/ImageView";

export default class ViewJobPhotos extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      viewImage: false,
      selectedImage: undefined
    };
    this.onClickImage = this.onClickImage.bind(this);
    this.closeViewImage = this.closeViewImage.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  closeViewImage() {
    this.setState({ viewImage: false, selectedImage: undefined });
  }
  onClickImage(photo) {
    if (this.state.selectedImage == photo) {
      this.setState({ selectedImage: undefined, viewImage: false });
    } else {
      this.setState({ selectedImage: photo, viewImage: true });
    }
  }

  render() {
    let message, loading, viewImage, photos;
    if (this.props.photos.length > 0) {
      photos = (
        <div className="row">
          {this.props.photos.map((photo, i) => (
            <div
              onClick={() => {
                this.onClickImage(photo);
              }}
              className={
                this.state.selectedImage == photo
                  ? "col col-md-4 col-xs-4 jobPhoto card selectedJobPhoto"
                  : "col col-md-4 col-xs-4 jobPhoto card"
              }
              key={i}
            >
              <img src={photo.photo} />

              <br />
            </div>
          ))}
        </div>
      );
    }
    if (this.state.viewImage) {
      viewImage = (
        <ViewImage
          photo={this.state.selectedImage}
          close={() => {
            this.closeViewImage();
          }}
        />
      );
    } else {
      viewImage = "";
    }

    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = (
        <div>
          <div className="jobFormContainer">
            <h1 className="display-5">
              Photos For Job # {this.props.timesheet.job} For{" "}
              {formatDate(this.props.timesheet.datetime)}
            </h1>

            <br />
            {message}
            {viewImage}
            {photos}
          </div>
        </div>
      );
    }
    return (
      <div className="jobForm">
        {" "}
        <button
          className="btn btn-secondary btn-sm exit"
          onClick={() => {
            this.props.close();
          }}
        >
          CLOSE
        </button>
        {loading}
      </div>
    );
  }
}
