import React, { Component } from "react";
class EmployeesTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedEmployee: null
    };
    this.selectEmployee = this.selectEmployee.bind(this);
  }
  selectEmployee(event) {
    if (this.state.selectedEmployee == event) {
      this.props.selectEmployee(null);
      this.setState({ selectedEmployee: null });
    } else {
      this.props.selectEmployee(event);
      this.setState({ selectedEmployee: event });
    }
  }
  render() {
    return (
      <div>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th className="sort" scope="col" onClick={() => { this.props.sort("id"); }}>Name</th>
              <th className="sort" scope="col" onClick={() => { this.props.sort("date"); }}>Type</th>
            </tr>
          </thead>
          <tbody>
            {this.props.employees.map((employee, i) => (
              <tr key={i} onClick={() => { this.selectEmployee(employee); }}
              style={{ backgroundColor: this.state.selectedEmployee == employee ? "#ccc" : undefined }}>
                <th scope="row">{employee.name}</th>
                <td>{employee.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default EmployeesTable;
