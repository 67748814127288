import React, { Component } from "react";
import formatDate from "./../../common/formatDate";
import ConfirmDeleteTimesheet from "./confirmDeleteTimesheet";
import Backdrop from "./../../common/Backdrop";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import EditTimesheet from "./timesheet/EditTimesheet";

class TimesheetsTable extends Component {
  constructor() {
    super();
    this.state = {
      timesheets: [],
      confirmDelete: false,
      working: false,
      editTimesheet: false
    };
    this.selectTimesheet = this.selectTimesheet.bind(this);
    this.deleteTimesheet = this.deleteTimesheet.bind(this);
    this.editTimesheet = this.editTimesheet.bind(this);
    this.closeConfirmDelete = this.closeConfirmDelete.bind(this);
    this.answerDelete = this.answerDelete.bind(this);
    this.deleteJobPhotos = this.deleteJobPhotos.bind(this);
    this.deleteTimesheet = this.deleteTimesheet.bind(this);
    this.deleteTimesheetData = this.deleteTimesheetData.bind(this);
    this.deleteJobPhotoData = this.deleteJobPhotoData.bind(this);
    this.openEditTimesheet = this.openEditTimesheet.bind(this);
    this.closeEditTimesheet = this.closeEditTimesheet.bind(this);
  }
  openEditTimesheet() {
    this.setState({ editTimesheet: true });
  }
  closeEditTimesheet(answer) {
    if (answer == "edited") {
      console.log("closing edit timesheet, answer is edited");
      this.setState(
        { editTimesheet: false, selectedTimesheet: undefined },
        () => {
          this.props.refreshEdit();
        }
      );
    } else {
      this.setState({ editTimesheet: false, selectedTimesheet: undefined });
    }
  }
  closeConfirmDelete() {
    console.log("setting state selectedTimesheet to undefined");
    this.setState({ confirmDelete: false, selectedTimesheet: undefined });
  }
  clickDeleteTimesheet() {
    this.setState({ confirmDelete: true });
  }
  answerDelete(answer) {
    console.log("answered delete: " + answer);
    if (answer) {
      //delete timesheet
      this.setState({
        confirmDelete: false,
        working: true
      });
      this.deleteJobPhotos();
    } else {
      this.setState({ confirmDelete: false });
    }
  }
  deleteTimesheet() {
    this.deleteJobPhotos();
  }
  deleteJobPhotos() {
    if (this.state.working) {
      return;
    } else {
      this.props.firebase.db
        .collection("jobs")
        .doc(this.props.job.id)
        .collection("timesheets")
        .doc(this.state.selectedTimesheet.id)
        .collection("job-photos")
        .get()
        .then(
          function(jobPhotoSnapshot) {
            let jobPhotos = [];
            jobPhotoSnapshot.forEach(
              function(jobPhoto) {
                jobPhotos.push(jobPhoto.data());
              }.bind(this)
            );
            if (jobPhotos.length > 0) {
              console.log("deleting " + jobPhotos.length + " photos");
              for (let i = 0; i < jobPhotos.length; i++) {
                let child = jobPhotos[i].storagePath;
                console.log("storage child is: " + child);
                const storageRef = this.props.firebase.storage
                  .ref()
                  .child(child);
                storageRef
                  .delete()
                  .then(
                    function() {
                      console.log("deleted photo");
                    }.bind(this)
                  )
                  .catch(function(err) {
                    console.log("error deleting photo" + err.message);
                  });
              }
              this.deleteJobPhotoData(jobPhotos);
            } else {
              this.deleteBoringProcedure();
            }
          }.bind(this)
        )
        .catch(function(err) {
          console.log("error getting job photos" + err.message);
        });
    }
  }
  deleteJobPhotoData(jobPhotos) {
    for (let i = 0; i < jobPhotos.length; i++) {
      this.props.firebase.db
        .collection("jobs")
        .doc(this.props.job.id)
        .collection("timesheets")
        .doc(this.state.selectedTimesheet.id)
        .collection("job-photos")
        .doc(jobPhotos[i].id)
        .delete()
        .then(function() {
          console.log("deleted job photo node");
        })
        .catch(function(err) {
          console.log("error deleting job photo node");
        });
    }
    this.deleteBoringProcedure();
  }
  deleteTimesheetData() {
    console.log(
      "inside deleteTimsheetData: timesheet ID is: " +
        this.state.selectedTimesheet.id
    );
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.job.id)
      .collection("timesheets")
      .doc(this.state.selectedTimesheet.id)
      .delete()
      .then(
        function() {
          console.log("deleted timesheet node in jobs");
          this.props.firebase.db
            .collection("timesheets")
            .doc(this.state.selectedTimesheet.id)
            .delete()
            .then(
              function() {
                console.log("deleted timesheet node in timesheets");
                this.setState({ working: false, selectedTimesheet: undefined });
                this.props.refresh();
              }.bind(this)
            )
            .catch(function(err) {
              console.log(
                "error deleting timesheet node in timesheets" + err.message
              );
            });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error deleting timesheet node in jobs" + err.message);
      });
  }
  deleteBoringProcedure() {
    console.log("inside deleteBoringProcedure");
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.job.id)
      .collection("timesheets")
      .doc(this.state.selectedTimesheet.id)
      .collection("boringProcedure")
      .doc(this.state.selectedTimesheet.id)
      .get()
      .then(
        function(boringProcedure) {
          if (boringProcedure.exists) {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.props.job.id)
              .collection("timesheets")
              .doc(this.state.selectedTimesheet.id)
              .collection("boringProcedure")
              .doc(this.state.selectedTimesheet.id)
              .delete()
              .then(
                function() {
                  console.log("deleted boringProcedure");
                  this.deleteBorePreShot();
                }.bind(this)
              )
              .catch(function(err) {
                console.log("error deleting boringProcedure");
              });
          } else {
            console.log("calling deleteBorePreShot");
            this.deleteBorePreShot();
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting boringProcedure node in jobs" + err.message);
      });
  }
  deleteBorePreShot = () => {
    console.log("inside deleteBorePreShot");
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.job.id)
      .collection("timesheets")
      .doc(this.state.selectedTimesheet.id)
      .collection("borePreShot")
      .doc(this.state.selectedTimesheet.id)
      .get()
      .then(
        function(borePreShot) {
          if (borePreShot.exists) {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.props.job.id)
              .collection("timesheets")
              .doc(this.state.selectedTimesheet.id)
              .collection("borePreShot")
              .doc(this.state.selectedTimesheet.id)
              .delete()
              .then(
                function() {
                  console.log("deleted borePreShot");
                  this.deleteTailboard();
                }.bind(this)
              )
              .catch(function(err) {
                console.log("error deleting borePreShot");
              });
          } else {
            console.log("calling deleteTailboard");
            this.deleteTailboard();
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting borePreShot node in jobs" + err.message);
      });
  };
  deleteTailboard() {
    console.log("inside deleteTailboard");
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.job.id)
      .collection("timesheets")
      .doc(this.state.selectedTimesheet.id)
      .collection("tailboards")
      .doc(this.state.selectedTimesheet.id)
      .get()
      .then(
        function(tailboard) {
          if (tailboard.exists) {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.props.job.id)
              .collection("timesheets")
              .doc(this.state.selectedTimesheet.id)
              .collection("tailboards")
              .doc(this.state.selectedTimesheet.id)
              .delete()
              .then(
                function() {
                  console.log("deleted tailboard");
                  this.deleteTimesheetData();
                }.bind(this)
              )
              .catch(function(err) {
                console.log("error deleting tailboard");
              });
          } else {
            console.log("calling deleteTimesheetData");
            this.deleteTimesheetData();
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting tailboard node in jobs" + err.message);
      });
  }
  editTimesheet() {}
  selectTimesheet(timesheet) {
    if (this.state.selectedTimesheet == timesheet) {
      this.setState({ selectedTimesheet: null });
    } else {
      this.setState({ selectedTimesheet: timesheet });
    }
  }
  render() {
    let timesheetControls, confirmDelete, working, editTimesheet;
    if (this.state.editTimesheet) {
      editTimesheet = (
        <div>
          <EditTimesheet
            timesheet={this.state.selectedTimesheet}
            timesheets={this.props.timesheets}
            close={this.closeEditTimesheet}
            employees={this.props.employees}
            uid={this.props.uid}
            job={this.props.job}
          />
          <Backdrop click={this.closeEditTimesheet} />
        </div>
      );
    } else {
      editTimesheet = "";
    }
    if (this.state.working) {
      working = (
        <div>
          <i className="spinner fas fa-circle-notch fa-spin" />
        </div>
      );
    } else {
      working = "";
    }
    if (this.state.confirmDelete) {
      confirmDelete = (
        <div>
          <ConfirmDeleteTimesheet answer={this.answerDelete} />
          <Backdrop click={this.closeConfirmDelete} />
        </div>
      );
    } else {
      confirmDelete = "";
    }
    if (
      this.state.selectedTimesheet !== null &&
      this.state.selectedTimesheet !== undefined
    ) {
      timesheetControls = (
        <div>
          <a
            className="btn btn-secondary dashboardButton"
            onClick={() => {
              this.openEditTimesheet();
            }}
          >
            Edit Timesheet
          </a>
          <a
            className="btn btn-secondary dashboardButton"
            onClick={() => {
              if (this.state.working) {
                return;
              } else {
                this.setState({ confirmDelete: true });
              }
            }}
          >
            Delete Timesheet
          </a>
        </div>
      );
    } else {
      timesheetControls = "";
    }
    return (
      <div>
        <h2>Timesheets</h2>
        {confirmDelete}
        {timesheetControls}
        {working}
        {editTimesheet}
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th className="sort" scope="col">
                Date
              </th>
              <th className="sort" scope="col">
                Hours
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.timesheets.map((timesheet, i) => (
              <tr
                key={i}
                style={{
                  backgroundColor:
                    this.state.selectedTimesheet == timesheet
                      ? "#ccc"
                      : undefined
                }}
                onClick={() => {
                      this.selectTimesheet(timesheet);
                    }}
              >
                <th scope="row">{formatDate(timesheet.datetime)}</th>
                <td>{timesheet.employeesAndHours[0].hours}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}
export default compose(withFirebase)(TimesheetsTable);
