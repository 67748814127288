import React, { Component } from "react";

class ViewTailboard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }
  render() {
    let questions = [
      "Has Everyone Involved Been Given Instructions To Perform Their Task?",
      "Crew Input And Concerns Addressed?",
      "Identified and discussed underground utilities? (If required, Miss Dig number Positive Response)",
      "Discussed ergonomic issues? (body posture/position, tasks requiring 2 employees, use of tools/equipment)",
      "Does everyone have the proper PPE to perform their tasks safely?",
      "Identified and discussed potential slip, trip and fall hazards?",
      "Implemented appropriate traffic control plan? Traffic Control Plan Used #",
      "Job hazard walk-through completed using Hazard and Control identification list?"
    ];

    let date = new Date(this.props.tailboard.dateTime);
    let dateString = date.toDateString();

    return (
      <div className="viewReport" style={{ backgroundColor: "white" }}>
        <div className="dashboardPanelHeading" style={{ backgroundColor: "white" }}>
          <h3 className="display-5">
            Tailboard For Job #{this.props.tailboard.job.id}
          </h3>
          <p className="lead">
            Created by: {this.props.tailboard.employee.name}
            <br />
            Date: {dateString}
          </p>
        </div>
        <div>
          <hr />
          <div className="generalSafetyQuestions">
            {questions.map((question, i) => (
              <p key={i} className="lead">
                {question} - YES
              </p>
            ))}
          </div>
          <div className="additionalHazards">
            <table className="table table-hover">
              <thead className="thead-dark">
                <tr>
                  <th className="sort" scope="col">
                    Additional Hazards
                  </th>
                  <th className="sort" scope="col">
                    Controls Implemented
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{this.props.tailboard.additionalHazards1}</td>
                  <td>{this.props.tailboard.controlsImplemented1}</td>
                </tr>
                <tr>
                  <td>{this.props.tailboard.additionalHazards2}</td>
                  <td>{this.props.tailboard.controlsImplemented2}</td>
                </tr>
                <tr>
                  <td>{this.props.tailboard.additionalHazards3}</td>
                  <td>{this.props.tailboard.controlsImplemented3}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="workersAndSignatures" style={{ display: "inline-block" }}>
            {this.props.tailboard.workersAndSignatures.map((worker, i) => (
              <div
                style={{
                  display: "inline-block",
                  width: "300px",
                  margin: "0 1em",
                  border: "1px solid black"
                }}
                className="workerAndSignature col-md-4"
                key={i} 
              >
                {worker.uid == "consumers" ? (
                  <div>Non Employee Addition:</div>
                ) : (
                  ""
                )}
                <p className="lead">{worker.name}</p>
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={worker.signaturePic}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ViewTailboard;
