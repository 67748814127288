import React, { Component } from "react";
import formatDate from "./formatDate";
class RequestsTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedRequest: {},
    };
    this.selectRequest = this.selectRequest.bind(this);
  }
  selectRequest(event) {
    if (this.state.selectedRequest == event) {
      this.props.selectRequest(null);
      this.setState({ selectedRequest: {} });
    } else {
      this.props.selectRequest(event);
      this.setState({ selectedRequest: event });
    }
  }
  render() {
    return (
      <div>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th />
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  // TODO error here
                  this.props.sort("id");
                }}
              >
                Equipment
              </th>

              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("date");
                }}
              >
                Request Date
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.requests.map((request, i) => (
              <tr
                key={i}
                onClick={() => {
                  this.selectRequest(request);
                }}
                style={{
                  backgroundColor:
                    this.state.selectedRequest.id == request.id
                      ? "#ccc"
                      : undefined,
                }}
              > 
              {/* TODO remove */}
                <td>
                  <input
                    type="checkbox"
                    checked={
                      this.state.selectedRequest.id == request.id ? true : false
                    }
                    id="selectJob"
                    onClick={() => {
                      this.selectRequest(request);
                    }}
                  />
                </td>
                <th scope="row">{request.equipment.name}</th>
                <td>{formatDate(request.time)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default RequestsTable;
