import React from "react";

function AddEmployee(props) {
  return (
    <div className="card addEmployeeTimesheet">
      Add
      <ul className="employeeList list-group">
        {props.employees.map((employee, i) => (
          <li
            onClick={() => {
              props.addEmployee(employee);
            }}
            className="list-group-item"
            key={i}
          >
            {employee.name}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default AddEmployee;
