import React, { Component } from "react";
import formatDate from "../../common/formatDate";
import Button from "@material-ui/core/Button";

export default class WSMTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: true,
      loading: true,
    };
  }
  sortWSMReports = () => {
    let wsm = this.state.wsm;
    if (this.state.sort) {
      wsm.sort(this.compareTime);
    } else {
      wsm.sort(this.compareTimeReverse);
    }
    this.setState({ wsm: wsm, sort: !this.state.sort });
  };
  compareTime = (a, b) => {
    console.log("comparing time: " + a.dateTimeOfCharge);

    if (a.time < b.time) return 1;
    if (a.time > b.time) return -1;
    return 0;
  };
  compareTimeReverse = (a, b) => {
    console.log("comparing time reverse");
    if (a.time < b.time) return -1;
    if (a.time > b.time) return 1;
    return 0;
  };
  setToState = () => {
    this.setState({ wsm: this.props.wsm, loading: false });
  };
  componentDidMount() {
    this.setToState();
  }
  render() {
    return (
      <div>
        {this.state.loading ? (
          "Loading"
        ) : (
          <div className="jobReportContainer" style={{marginTop: "1em"}} >
            <h3 className="display-5">WSM Reports:</h3>
            <hr />
            <Button variant="contained" color="inherit" style={{marginBottom: "1em"}} onClick={() => { this.sortWSMReports(); }}>
              Sort
            </Button>
            <br />
            <table className="table table-hover">
              <thead className="thead-dark">
                <tr>
                  <th className="sort" scope="col">
                    Date
                  </th>
                  <th className="sort" scope="col">
                    Employee
                  </th>
                  <th className="sort" scope="col">
                    Job
                  </th>
                  <th className="sort" scope="col">
                    Road
                  </th>
                  <th className="sort" scope="col">
                    Address
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.wsm.map((wsm, i) => (
                  <tr
                    className="openReport"
                    onClick={() => {
                      this.props.open(wsm);
                    }}
                    key={i} 
                  >
                    <td>{formatDate(wsm.time)}</td>
                    <td>{wsm.employee}</td>
                    <td>{wsm.job}</td>
                    <td>{wsm.road}</td>
                    <td>{wsm.address}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}
