import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import { withRouter } from "react-router";
import TimesheetTable from "./TimesheetTable";
import ExpenseReportTable from "./ExpenseReportTable";
import ViewPrintableReport from "./ViewPrintableReport";
import ViewJobPhotos from "./ViewJobPhotos";
import GeoCode from "react-geocode";

class ViewJobReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      timesheets: [],
      expenseReports: [],
      tailboards: [],
      covidForms: [],
      wsm: [],
      loading: true,
    };
    this.onBackClick = this.onBackClick.bind(this);
    this.getJobInfo = this.getJobInfo.bind(this);
    this.getExpenseReports = this.getExpenseReports.bind(this);
    this.getTailboards = this.getTailboards.bind(this);
    this.viewExpenseReport = this.viewExpenseReport.bind(this);
    this.closeExpenseReport = this.closeExpenseReport.bind(this);
    this.viewTimesheet = this.viewTimesheet.bind(this);
    this.closeTimesheet = this.closeTimesheet.bind(this);
    this.viewTailboard = this.viewTailboard.bind(this);
    this.closeTailboard = this.closeTailboard.bind(this);
    this.viewJobPhotos = this.viewJobPhotos.bind(this);
    this.closeJobPhotos = this.closeJobPhotos.bind(this);
    this.setLocations = this.setLocations.bind(this);
    this.getAddress = this.getAddress.bind(this);
    GeoCode.setApiKey("AIzaSyB2_Mj46H543xr7f3yPvushEfmsSsQ5dvo");
  }
  setLocations() {
    let newTimesheets = [];
   
    for (let i = 0; i < this.state.timesheets.length; i++) {
      this.getAddress(this.state.timesheets[i]).then((timesheet) => {
        newTimesheets.push(timesheet);
      });
    }
    this.setState({ loading: false });
  }

  getAddress = (timesheet) => {
    return new Promise((resolve, reject) => {
      if (timesheet.lat !== undefined && timesheet.lat !== null) {
        GeoCode.fromLatLng(timesheet.lat, timesheet.lng).then(
          (response) => {
            const address = response.results[0].formatted_address;
            timesheet.address = address + "test";
            resolve(timesheet);
          },
          (error) => {
            console.error(error);
            resolve(timesheet);
          }
        );
      } else {
        resolve(timesheet);
      }
    });
  };
  closeTailboard() {
    this.setState((prevState) => {
      return { viewTailboard: false, report: null };
    });
  }
  closeJobPhotos() {
    this.setState({ viewJobPhotos: false, report: null });
  }
  viewTailboard(report) {
    if (this.state.tailboards.length == 0) {
      //no tailboards
      this.setState({ message: "No Tailboard Was Submitted" });
    } else {
      for (let i = 0; i < this.state.tailboards.length; i++) {
        if (
          this.state.tailboards[i] !== null &&
          this.state.tailboards[i] !== undefined
        ) {
          if (report.id == this.state.tailboards[i].id) {
            this.setState((prevState) => {
              return { viewTailboard: true, report: this.state.tailboards[i] };
            });
          }
        }
      }
    }
  }
  viewJobPhotos(report) {
    let jobPhotos = [];
    this.props.firebase.db
      .collection("jobs")
      .doc(report.job)
      .collection("timesheets")
      .doc(report.id)
      .collection("job-photos")
      .get()
      .then(
        function(jobPhotoSnapshot) {
          jobPhotoSnapshot.forEach(function(photo) {
            jobPhotos.push(photo.data());
          });
          this.setState({
            viewJobPhotos: true,
            jobPhotos: jobPhotos,
            report: report,
          });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting job photos: " + err.message);
      });
  }
  closeTimesheet() {
    this.setState((prevState) => {
      return { viewTimesheet: false, report: null };
    });
  }
  viewTimesheet(report) {
    this.setState((prevState) => {
      return { viewTimesheet: true, report: report };
    });
  }
  closeBorePreShot = () => {
    this.setState({ viewBorePreShot: false });
  };
  openBorePreShot = (report) => {
    this.setState({ viewBorePreShot: true, report: report });
  };
  openCovidForm = (report) => {
    this.setState({ viewCovidForm: true, report: report });
  };
  openWSM = (report) => {
    console.log("openWSM from viewJobReport");
    this.setState({ viewWSM: true, report: report });
  };
  closeWSM = () => {
    console.log("openWSM from viewJobReport");
    this.setState({ viewWSM: false });
  };
  closeCovidForm = () => {
    this.setState({ viewCovidForm: false });
  };
  closeBoreValidation = () => {
    this.setState({ viewBoreValidation: false });
  };
  openBoreValidation = (report) => {
    this.setState({ viewBoreValidation: true, report: report });
  };
  closeExpenseReport() {
    this.setState((prevState) => {
      return { viewExpenseReport: false, report: null };
    });
  }
  viewExpenseReport(report) {
    this.setState((prevState) => {
      return { viewExpenseReport: true, report: report };
    });
  }
  getTailboards(job, timesheets, expenseReports) {
    let tailboards = [];
    for (let i = 0; i < timesheets.length; i++) {
      if (i + 1 == timesheets.length) {
        //last item
        this.props.firebase.db
          .collection("jobs")
          .doc(job.id)
          .collection("timesheets")
          .doc(timesheets[i].id)
          .collection("tailboards")
          .doc(timesheets[i].id)
          .get()
          .then(
            function(tailboard) {
              if (tailboard.exists) {
                let newTailboard = {
                  ...tailboard.data(),
                  dateTime: timesheets[i].datetime,
                };
                tailboards.push(newTailboard);
              }
              this.setState(
                {
                  job: job,
                  timesheets: timesheets,
                  expenseReports: expenseReports,
                  tailboards: tailboards,
                },
                () => {
                  this.setLocations();
                }
              );
            }.bind(this)
          )
          .catch(function(err) {
            console.log("error getting tailboard : " + err.message);
          });
      } else {
        this.props.firebase.db
          .collection("jobs")
          .doc(job.id)
          .collection("timesheets")
          .doc(timesheets[i].id)
          .collection("tailboards")
          .doc(timesheets[i].id)
          .get()
          .then(function(tailboard) {
            if (tailboard.exists) {
              let newTailboard = {
                ...tailboard.data(),
                dateTime: timesheets[i].datetime,
              };
              tailboards.push(newTailboard);
            }
          })
          .catch(function(err) {
            console.log("error getting tailboard : " + err.message);
          });
      }
    }
    if (timesheets.length == 0) {
      this.setState({
        job: job,
        timesheets: timesheets,
        expenseReports: expenseReports,
        tailboards: tailboards,
        loading: false,
      });
    }
  }
  getWSM = (job, timesheets) => {
    console.log("inside getWSM");
    let wsm = [];
    for (let i = 0; i < timesheets.length; i++) {
      this.props.firebase.db
        .collection("jobs")
        .doc(job.id)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .collection("wsm")
        .doc(timesheets[i].id)
        .get()
        .then(
          function(res) {
            if (res.exists) {
              console.log("found wsm");
              wsm.push(res.data());
            }
          }.bind(this)
        )
        .catch(
          function(err) {
            console.log("error getting wsm");
          }.bind(this)
        );
    }
    this.setState({ wsm: wsm });
  };
  getCovidForms = (job, timesheets) => {
    let covidForms = [];
    for (let i = 0; i < timesheets.length; i++) {
      this.props.firebase.db
        .collection("jobs")
        .doc(job.id)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .collection("covidForm")
        .doc(timesheets[i].id)
        .get()
        .then(
          function(res) {
            if (res.exists) {
              covidForms.push(res.data());
            }
          }.bind(this)
        )
        .catch(
          function(err) {
            console.log("error getting covidform");
          }.bind(this)
        );
    }
    this.setState({ covidForms: covidForms });
  };
  getJobInfo() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.match.params.job)
      .get()
      .then(
        function(job) {
          if (job.exists) {
            this.props.firebase.db
              .collection("jobs")
              .doc(this.props.match.params.job)
              .collection("timesheets")
              .get()
              .then(
                function(timesheetSnapshot) {
                  let timesheets = [];
                  timesheetSnapshot.forEach(function(timesheet) {
                    timesheets.push(timesheet.data());
                  });
                  this.getExpenseReports(job.data(), timesheets);
                  this.getConsumersDocuments(job.data(), timesheets);
                  this.getWSM(job.data(), timesheets);
                  this.getCovidForms(job.data(), timesheets);
                }.bind(this)
              )
              .catch(function(err) {
                console.log(err.message);
              });
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log(err.message);
      });
  }
  getConsumersDocuments = (job, timesheets) => {
    let borePreshots = [];
    let boreValidations = [];
    for (let i = 0; i < timesheets.length; i++) {
      this.props.firebase.db
        .collection("jobs")
        .doc(job.id)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .collection("borePreShot")
        .doc(timesheets[i].id)
        .get()
        .then(
          function(boreSnapshot) {
            if (boreSnapshot.exists) {
              borePreshots.push(boreSnapshot.data());
            }
            this.props.firebase.db
              .collection("jobs")
              .doc(job.id)
              .collection("timesheets")
              .doc(timesheets[i].id)
              .collection("boringProcedure")
              .doc(timesheets[i].id)
              .get()
              .then(
                function(boringSnapshot) {
                  if (boringSnapshot.exists) {
                    console.log("got boringSnapshot");
                    boreValidations.push(boringSnapshot.data());
                  }
                }.bind(this)
              )
              .catch(function(err) {
                console.log("error getting boring procedure: " + err.message);
              });
          }.bind(this)
        )
        .catch(function(err) {
          console.log("error getting borePreShot: " + err.message);
        });
    }
    this.setState({
      borePreshots: borePreshots,
      boreValidations: boreValidations,
    });
  };
  getExpenseReports(job, timesheets) {
    this.props.firebase.db
      .collection("jobs")
      .doc(job.id)
      .collection("expense-reports")
      .get()
      .then(
        function(expenseSnapshot) {
          let expenseReports = [];
          expenseSnapshot.forEach(function(expense) {
            expenseReports.push(expense.data());
          });
          this.getTailboards(job, timesheets, expenseReports);
        }.bind(this)
      );
  }
  componentDidMount() {
    console.log("inside viewJobReport ");
    this.getJobInfo();
  }
  onBackClick() {
    this.props.history.push("/dashboard/reports/job");
  }
  render() {
    let loading,
      viewExpenseReport,
      viewTimesheet,
      viewTailboard,
      viewJobPhotos,
      viewBorePreShot,
      viewBoreValidation,
      viewCovidForm;

    if (this.state.viewCovidForm) {
      viewCovidForm = (
        <ViewPrintableReport
          exit={this.closeCovidForm}
          report={this.state.report}
          type="covidForm"
        />
      );
    } else {
      viewCovidForm = "";
    }
    if (this.state.viewBoreValidation) {
      viewBoreValidation = (
        <ViewPrintableReport
          exit={this.closeBoreValidation}
          report={this.state.report}
          type="boreValidation"
        />
      );
    } else {
      viewBoreValidation = "";
    }
    if (this.state.viewBorePreShot) {
      viewBorePreShot = (
        <ViewPrintableReport
          exit={this.closeBorePreShot}
          report={this.state.report}
          type="borePreShot"
        />
      );
    } else {
      viewBorePreShot = "";
    }
    if (this.state.viewJobPhotos) {
      viewJobPhotos = (
        <ViewJobPhotos
          photos={this.state.jobPhotos}
          close={this.closeJobPhotos}
          timesheet={this.state.report}
        />
      );
    } else {
      viewJobPhotos = "";
    }
    if (this.state.viewTailboard) {
      viewTailboard = (
        <ViewPrintableReport
          type="tailboard"
          report={this.state.report}
          exit={this.closeTailboard}
        />
      );
    } else {
      viewTailboard = "";
    }
    if (this.state.viewTimesheet) {
      viewTimesheet = (
        <ViewPrintableReport
          type="timesheet"
          report={this.state.report}
          exit={this.closeTimesheet}
        />
      );
    } else {
      viewTimesheet = "";
    }
    if (this.state.viewExpenseReport) {
      viewExpenseReport = (
        <ViewPrintableReport
          type="expenseReport"
          report={this.state.report}
          exit={this.closeExpenseReport}
        />
      );
    } else {
      viewExpenseReport = "";
    }
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = (
        <div className="jobReport">
          <div className="dashboardPanelHeading">
            <h3 className="display-5">Job #{this.state.job.id}</h3>
            <p className="lead">
              Date: {this.state.job.date}
              <br />
              Type: {this.state.job.type}
            </p>
          </div>
          <hr />
          <div className="container container-fluid reportsContainer">
            <div className="row row-space">
              <div className="col-md-6 col-sm-12">
                <TimesheetTable
                  timesheets={this.state.timesheets}
                  tailboards={this.state.tailboards}
                  borePreShots={this.state.borePreshots}
                  boreValidations={this.state.boreValidations}
                  covidForms={this.state.covidForms}
                  wsm={this.state.wsm}
                  viewReport={(report) => {
                    this.viewTimesheet(report);
                  }}
                  openTailboard={(report) => {
                    this.viewTailboard(report);
                  }}
                  openJobPhotos={(report) => {
                    this.viewJobPhotos(report);
                  }}
                  openBorePreShot={(report) => {
                    this.openBorePreShot(report);
                  }}
                  openBoreValidation={(report) => {
                    this.openBoreValidation(report);
                  }}
                  openCovidForm={(report) => {
                    this.openCovidForm(report);
                  }}
                  closeReport={() => {
                    this.closeTimesheet();
                  }}
                />
              </div>
              <div className="col-md-6 col-sm-12">
                <ExpenseReportTable
                  viewReport={(report) => {
                    this.viewExpenseReport(report);
                  }}
                  closeReport={() => {
                    this.closeExpenseReport();
                  }}
                  expenseReports={this.state.expenseReports}
                />
              </div>
            </div>
          </div>
          {viewJobPhotos}
          {viewTailboard}
          {viewBorePreShot}
          {viewBoreValidation}
          {viewTimesheet}
          {viewExpenseReport}
          {viewCovidForm}
        </div>
      );
    }
    let backString = "<- Back";
    return (
      <div className="">
        <a className="btn btn-secondary returnLink" 
          onClick={() => { this.onBackClick(); }} >
          {backString}
        </a>
        <br />
        <hr />
        {loading}
      </div>
    );
  }
}

export default compose(withFirebase, withRouter)(ViewJobReport);
