import React from "react";
import { CSVLink } from "react-csv";
import Button from '@material-ui/core/Button';

export default function ExportCSV({ csvData, filename, buttonName }) {
  return (
    <div>
      {csvData.length > 0 ? (
        <Button component={CSVLink} data={csvData} filename={filename + ".csv"} variant="contained" color="primary" style={{marginBottom: "1em"}}>
          {buttonName}
        </Button>
      ) : (
        ""
      )}
    </div>
  );
}
