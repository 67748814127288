import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import WeeklyTable from "./WeeklyTable";
import Tabs from "./Tabs";
import prepareEmployeeWeek from "./prepareReport";
import ViewPrintableReport from "./ViewPrintableReport";
import Button from "@material-ui/core/Button";

class WeeklyReports extends Component {
  constructor() {
    super();
    this.state = {
      reports: [],
      loading: true,
      todayTime: 0,
      sundayTime: 0,
      weekOffset: 0,
      timesheets: [],
      week: undefined,
      sundayDate: undefined,
      saturdayDate: undefined,
      viewTimesheet: false,
    };
    this.getThisWeek = this.getThisWeek.bind(this);
    this.prepareWeeklyReport = this.prepareWeeklyReport.bind(this);
    this.nextWeek = this.nextWeek.bind(this);
    this.previousWeek = this.previousWeek.bind(this);
    this.figureOutWeekSpan = this.figureOutWeekSpan.bind(this);
  }
  nextWeek() {
    // TODO replace with dayjs
    let newOffset = this.state.weekOffset + 1;
    let today = new Date();
    let day = today.getDay();
    let fromSunday = 86400000 * day;
    let todayTime = today.getTime();
    let thisSundayTime = todayTime - fromSunday;
    this.setState({ weekOffset: newOffset }, () => {
      this.figureOutWeekSpan(thisSundayTime);
    });
  }
  previousWeek() {
    // TODO replace with dayjs
    let newOffset = this.state.weekOffset - 1;
    let today = new Date();
    let day = today.getDay();
    let fromSunday = 86400000 * day;
    let todayTime = today.getTime();
    let thisSundayTime = todayTime - fromSunday;
    this.setState({ weekOffset: newOffset }, () => {
      this.figureOutWeekSpan(thisSundayTime);
    });
  }

  prepareWeeklyReport(timesheets, employees) {
    if (timesheets == undefined || employees == undefined) {
      return;
    } else {
      //not undefined, preparing report
      let employeesAndHours = [];
      for (let i = 0; i < employees.length; i++) {
        prepareEmployeeWeek(employees[i], timesheets)
          .then(function(totalHours) {
            employeesAndHours.push(totalHours);
          })
          .then(
            function() {
              if (this.state.employees === employeesAndHours) {
                return;
              } else {
                this.setState((prevState) => {
                  return {
                    employees: employeesAndHours,
                    loading: false,
                    timesheets: timesheets,
                  };
                });
              }
            }.bind(this)
          );
      }
    }
  }
  getThisWeek(sunday, saturday) {
    // TODO replace with dayjs
    let timesheets = [];
    let employees = [];
    saturday.setHours(23, 59, 59, 0);
    let sundayTime = sunday.getTime();
    let saturdayTime = saturday.getTime();
    this.props.firebase.db
      .collection("timesheets")
      .where("datetime", ">=", sundayTime)
      .get()
      .then(
        function(timeSheetsSnapshot) {
          timeSheetsSnapshot.forEach(function(timesheet) {
            if (timesheet.data().datetime <= saturdayTime) {
              timesheets.push(timesheet.data());
            }
          });
          this.props.firebase.db
            .collection("users")
            .get()
            .then(
              function(usersSnapshot) {
                usersSnapshot.forEach(function(user) {
                  employees.push(user.data());
                });
                this.prepareWeeklyReport(timesheets, employees);
              }.bind(this)
            )
            .catch(
              function(err) {
                console.log(err.message);
              }.bind(this)
            );
        }.bind(this)
      )
      .catch(function(err) {
        console.log(err.message);
      });
  }
  componentDidMount() {
    // TODO replace with dayjs
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    let sunday = new Date();
    let day = today.getDay();
    let dateTimeSpan = day * (24 * 60 * 60 * 1000);
    let sundayDateTime = today.getTime() - dateTimeSpan;
    let sundayDate = new Date(sunday.setDate(today.getDate() - day));
    sundayDate.setHours(0, 0, 0, 0);

    this.figureOutWeekSpan(sundayDate);
  }
  figureOutWeekSpan(sundayDate) {
    // TODO replace with dayjs

    let iterator = this.state.weekOffset * 7;
    let dateTimeSpan = iterator * (24 * 60 * 60 * 1000);
    let newDateTime = sundayDate + dateTimeSpan;
    let selectedSunday = new Date(newDateTime);
    selectedSunday.setHours(0, 0, 0, 0);
    let selectedSundayTime = selectedSunday.getTime();
    let nextSaturdayTime = selectedSundayTime + 518400000;
    let nextSaturday = new Date(nextSaturdayTime);

    this.setState(
      {
        saturdayDate: nextSaturday.toDateString(),
        sundayDate: selectedSunday.toDateString(),
        loading: true,
      },
      () => {
        this.getThisWeek(selectedSunday, nextSaturday);
      }
    );
  }

  viewTimesheet = (jobId, timesheetId) => {
    for (let i = 0; i < this.state.timesheets.length; i++) {
      if (this.state.timesheets[i].id == timesheetId) {
        this.setState({
          viewTimesheet: true,
          selectedTimesheet: this.state.timesheets[i],
        });
      }
    }
  };
  closeTimesheet = () => {
    this.setState({ viewTimesheet: false });
  };
  render() {
    let loading, timesheets;

    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = (
        <div>
          <hr />
          <div className="dashboardControls" style={{marginBottom: "1em"}}>
            <Button variant="contained" color="default"
              className="btn btn-secondary dashboardButton"
              onClick={() => {
                this.previousWeek();
              }}
            >
              Previous
            </Button>
            <Button variant="contained" color="default"
              className="btn btn-secondary dashboardButton"
              onClick={() => {
                this.nextWeek();
              }}
            >
              Next
            </Button>
          </div>
          <p className="lead">
            {this.state.sundayDate} to {this.state.saturdayDate}
          </p>
          <WeeklyTable
            viewTimesheet={this.viewTimesheet}
            week={this.state.week}
            sunday={this.state.sundayDate}
            saturday={this.state.saturdayDate}
            employees={this.state.employees}
          />
        </div>
      );
    }

    return (
      <div>
        <Tabs />
        {this.state.viewTimesheet ? (
          <ViewPrintableReport
            type="timesheet"
            report={this.state.selectedTimesheet}
            exit={this.closeTimesheet}
          />
        ) : (
          ""
        )}
        {loading}
      </div>
    );
  }
}

export default compose(withFirebase)(WeeklyReports);
