import React, { Component } from "react";
import { Route } from "react-router-dom";
import ViewArchivedJobReport from "./../jobs/archivedJobs/ViewArchivedJobReports";
import WeeklyReports from "./WeeklyReports";
import MonthlyReports from "./MonthlyReports";
import JobReports from "./JobReports";
import Reports from "./Reports";
import ViewJobReport from "./ViewJobReport";
import "./reports.css";
import ExpenseReports from "./ExpenseReports";
import WSMReports from "./WSMReports";

class ReportsHome extends Component {
  render() {
    return (
      <div>
        <div className="reportsPanel">
          <div className="reportsPanelHeading">
            <h1 className="display-5">Reports</h1>
          </div>
          <hr />
          <Route
            exact
            path="/dashboard/reports/weekly"
            component={WeeklyReports}
          />
          <Route exact path="/dashboard/reports/job" component={JobReports} />
          <Route
            exact
            path="/dashboard/reports/expense-reports"
            component={ExpenseReports}
          />
          <Route exact path="/dashboard/reports/wsm" component={WSMReports} />
          <Route exact path="/dashboard/reports" component={WeeklyReports} />
          <Route
            exact
            path="/dashboard/reports/job/:job"
            component={ViewJobReport}
          />
          <Route
            exact
            path="/dashboard/reports/archived-job/:job"
            component={ViewArchivedJobReport}
          />
        </div>
      </div>
    );
  }
}

export default ReportsHome;
