import React from "react";
import Button from '@material-ui/core/Button';

export default function ConfirmRestore(props) {
  return (
    <div className="card confirmation">
      <p className="lead">
        Are You Sure You Want To <b>Restore</b> Job #{props.job}?
      </p>
      <div className="confirmationControls" style={{ marginTop: "1em" }}>
        <Button variant="contained" color="inherit" onClick={() => { props.answer(true); }}>
          Yes
        </Button>
        <Button variant="contained" color="inherit" onClick={() => { props.answer(false); }}>
          No
        </Button>
        <Button variant="contained" color="inherit" onClick={() => { props.answer(false); }}>
          Cancel
        </Button>
      </div>
    </div>
  );
}
