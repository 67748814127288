import React, { Component } from "react";
import TimesheetEditForm from "./TimesheetEditForm";
import EditJobPhotos from "./EditJobPhotos";
import BoringProcedureValidation from "./../BoringProcedureValidation";
import BorePreShot from "./../BorePreShot";
import formatDate from "./../../../common/formatDate";
import { withFirebase } from "./../../../../firebase/index";
import { compose } from "redux";
import "./editTimesheet.css";

class EditTimesheet extends Component {
  constructor() {
    super();
    this.state = {
      editTimesheet: false,
      editJobPhotos: false,
      editBoringProcedure: false,
      loading: true
    };
    this.clickEditJobPhotos = this.clickEditJobPhotos.bind(this);
    this.closeEditJobPhotos = this.closeEditJobPhotos.bind(this);
    this.clickEditTimesheet = this.clickEditTimesheet.bind(this);
    this.closeEditTimesheet = this.closeEditTimesheet.bind(this);
    this.getBoringProcedure = this.getBoringProcedure.bind(this);
    this.finishTailboardEdit = this.finishTailboardEdit.bind(this);
    this.closeBoringProcedure = this.closeBoringProcedure.bind(this);
    this.finishBoringProcedure = this.finishBoringProcedure.bind(this);
  }
  finishBorePreShot = () => {
    this.props.close("edited");
  };
  closeBorePreShot = () => {
    this.setState({ editBorePreShot: false });
  };
  finishBoringProcedure() {
    this.props.close("edited");
  }
  closeBoringProcedure() {
    this.setState({ editBoringProcedure: false });
  }
  finishTailboardEdit() {
    this.props.close("edited");
  }
  clickEditBorePreShot() {
    console.log("clicked editborePreShot");
    this.setState({ editBorePreShot: true });
  }
  clickEditBoringProcedure() {
    console.log("clicked editboringprocedure");
    this.setState({ editBoringProcedure: true });
  }
  clickEditTimesheet() {
    this.setState({ editTimesheet: true, loading: true });
  }
  closeEditTimesheet(answer) {
    this.setState({ editTimesheet: false, loading: false }, () => {
      if (answer == "edited") {
        console.log("closing timesheetEditForm, answer is edited");
        this.props.close("edited");
      } else {
        this.props.close();
      }
    });
  }
  clickEditJobPhotos() {
    this.setState({ editJobPhotos: true, loading: true });
  }
  closeEditJobPhotos() {
    this.setState({ editJobPhotos: false, loading: false });
  }
  componentDidMount() {
    this.getBoringProcedure();
  }
  getBorePreShot = () => {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.timesheet.job)
      .collection("timesheets")
      .doc(this.props.timesheet.id)
      .collection("borePreShot")
      .doc(this.props.timesheet.id)
      .get()
      .then(
        function(borePreShot) {
          this.setState({
            borePreShot: borePreShot.data(),
            loading: false
          });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting borePreShot " + err.message);
      });
  };
  getBoringProcedure() {
    this.props.firebase.db
      .collection("jobs")
      .doc(this.props.timesheet.job)
      .collection("timesheets")
      .doc(this.props.timesheet.id)
      .collection("boringProcedure")
      .doc(this.props.timesheet.id)
      .get()
      .then(
        function(boringProcedure) {
          this.setState(
            {
              boringProcedure: boringProcedure.data()
            },
            () => {
              this.getBorePreShot();
            }
          );
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting boringProcedure " + err.message);
      });
  }
  render() {
    let editTimesheet,
      editJobPhotos,
      editBoringProcedure,
      editBorePreShot;
    if (this.state.editBorePreShot) {
      editBorePreShot = (
        <BorePreShot
          timesheet={this.props.timesheet}
          node="timesheets"
          borePreShot={this.state.borePreShot}
          close={this.closeBorePreShot}
          finish={this.finishBorePreShot}
          job={this.props.job}
        />
      );
    } else {
      editBoringProcedure = "";
    }
    if (this.state.editBoringProcedure) {
      editBoringProcedure = (
        <BoringProcedureValidation
          timesheet={this.props.timesheet}
          node="timesheets"
          boringProcedure={this.state.boringProcedure}
          close={this.closeBoringProcedure}
          finish={this.finishBoringProcedure}
          employees={this.props.employees}
          job={this.props.job}
        />
      );
    } else {
      editBoringProcedure = "";
    }
    if (this.state.editTimesheet) {
      editTimesheet = (
        <TimesheetEditForm
          timesheet={this.props.timesheet}
          timesheets={this.props.timesheets}
          close={this.closeEditTimesheet}
          employees={this.props.employees}
          uid={this.props.uid}
          job={this.props.job}
        />
      );
    } else {
      editTimesheet = "";
    }
    if (this.state.editJobPhotos) {
      editJobPhotos = (
        <EditJobPhotos
          timesheet={this.props.timesheet}
          close={this.closeEditJobPhotos}
          finish={this.finishTailboardEdit}
        />
      );
    } else {
      editJobPhotos = "";
    }
    return (
      <div>
        <div className="editTimesheet card">
          Edit Timesheet For {formatDate(this.props.timesheet.datetime)}
          <div className="row">
            <div className="col">
              <button
                disabled={this.state.loading ? true : false}
                className="btn btn-secondary "
                style={{ marginTop: "1em" }}
                onClick={() => {
                  this.clickEditTimesheet();
                }}
              >
                Edit Timesheet
              </button>
            </div>
            <div className="col">
              {" "}
              <button
                className="btn btn-secondary "
                style={{ marginTop: "1em" }}
                disabled={
                  this.state.loading || this.state.boringProcedure == undefined
                    ? true
                    : false
                }
                onClick={() => {
                  this.clickEditBoringProcedure();
                }}
              >
                Edit Boring Procedure Validation
              </button>
            </div>
            <div className="col">
              {" "}
              <button
                className="btn btn-secondary "
                style={{ marginTop: "1em" }}
                disabled={
                  this.state.loading || this.state.borePreShot == undefined
                    ? true
                    : false
                }
                onClick={() => {
                  this.clickEditBorePreShot();
                }}
              >
                Edit Bore Pre Shot Checklist
              </button>
            </div>
            <div className="col">
              {" "}
              <button
                className="btn btn-secondary "
                style={{ marginTop: "1em" }}
                disabled={this.state.loading ? true : false}
                onClick={() => {
                  this.clickEditJobPhotos();
                }}
              >
                Edit Job Pictures
              </button>
            </div>
            <div className="col">
              <button
                className="btn btn-secondary "
                style={{ marginTop: "1em" }}
                disabled={this.state.loading ? true : false}
                onClick={() => {
                  this.props.close();
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {editBorePreShot}
        {editBoringProcedure}
        {editTimesheet}
        {editJobPhotos}
      </div>
    );
  }
}

export default compose(withFirebase)(EditTimesheet);
