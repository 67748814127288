import React, { Component } from "react";
import TimesheetTableItem from "./TimesheetTableItem";
import { compareTime, compareTimeReverse } from "../../common/Helpers";

class TimesheetTable extends Component {
  constructor(props) {
    super(props);
    this.state = { sort: true, timesheets: [] };
    this.sortTimesheets = this.sortTimesheets.bind(this);
  }
  close() {
    this.props.closeReport();
  }
  open(timesheet) {
    this.props.viewReport(timesheet);
  }
  openCovidForm = (timesheet) => {
    this.props.openCovidForm(timesheet);
  };
  openTailboard(timesheet) {
    this.props.openTailboard(timesheet);
  }
  openJobPhotos(timesheet) {
    this.props.openJobPhotos(timesheet);
  }
  openBorePreShot(timesheet) {
    this.props.openBorePreShot(timesheet);
  }
  openBoreValidation = (timesheet) => {
    this.props.openBoreValidation(timesheet);
  };
  sortTimesheets() {
    let timesheets = this.state.timesheets;
    if (this.state.sort) {
      timesheets.sort(compareTime);
      this.setState((prevState) => {
        return { timesheets: timesheets, sort: !prevState.sort };
      });
    } else {
      timesheets.sort(compareTimeReverse);
      this.setState((prevState) => {
        return { timesheets: timesheets, sort: !prevState.sort };
      });
    }
  }
  setupTimesheets = () => {
    let timesheets = this.props.timesheets;
    for (let i = 0; i < timesheets.length; i++) {
      for (let c = 0; c < this.props.covidForms.length; c++) {
        if (this.props.covidForms[c].id == timesheets[i].id) {
          timesheets[i].covidForm = this.props.covidForms[c];
        }
      }
      for (let b = 0; b < this.props.borePreShots.length; b++) {
        if (this.props.borePreShots[b].id == timesheets[i].id) {
          timesheets[i].borePreShot = this.props.borePreShots[b];
        }
      }
      for (let d = 0; d < this.props.boreValidations.length; d++) {
        if (this.props.boreValidations[d].id == timesheets[i].id) {
          timesheets[i].boreValidation = this.props.boreValidations[d];
        }
      }
    }

    this.setState((prevState) => {
      return { timesheets: timesheets };
    });
  };
  componentDidMount() {
    this.setupTimesheets();
  }
  render() {
    return (
      <div className="jobReportContainer">
        <h3 className="display-5">Timesheets:</h3>
        <hr />
        <a
          className="btn btn-secondary"
          onClick={() => {
            this.sortTimesheets();
          }}
        >
          Sort
        </a>
        <br />
        Date - Submitted By
        {this.state.timesheets.map((timesheet, i) => (
          <TimesheetTableItem
            open={(timesheet) => {
              this.open(this.state.timesheets[i]);
            }}
            openTailboard={(timesheet) => {
              this.openTailboard(this.state.timesheets[i]);
            }}
            openJobPhotos={() => {
              this.openJobPhotos(this.state.timesheets[i]);
            }}
            openBoreValidation={() => {
              this.openBoreValidation(this.state.timesheets[i]);
            }}
            openBorePreShot={() => {
              this.openBorePreShot(this.state.timesheets[i]);
            }}
            openCovidForm={() => {
              this.openCovidForm(this.state.timesheets[i]);
            }}
            tailboard={
              this.props.tailboards[i] !== undefined
                ? this.props.tailboards[i]
                : undefined
            }
            background={i % 2 == 0 ? "dark" : "light"}
            timesheet={timesheet}
            key={i}
          />
        ))}
      </div>
    );
  }
}

export default TimesheetTable;
