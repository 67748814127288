import React, { Component } from "react";
import { withFirebase } from "./../../../firebase/index";
import { compose } from "redux";
import AddWSM from "./AddWSM";
import Backdrop from "./../../common/Backdrop";
import WSMTable from "./WSMTable";
import Button from '@material-ui/core/Button';

class WSM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      message: undefined,
      addWSM: false,
    };
  }
  componentDidMount() {
    this.getWSM();
  }
  addWSM = () => {
    this.setState({ addWSM: true });
  };
  closeWSM = () => {
    this.setState({ addWSM: false });
  };
  getWSM = () => {
    this.props.firebase.db
      .collection("wsm")
      .get()
      .then(
        function (res) {
          let wsm = [];
          res.forEach((report, i) => {
            wsm.push(report.data());
          });
          this.setState({ wsm: wsm, loading: false });
        }.bind(this)
      )
      .catch(function (err) {
        console.log("error getting wsm reports: " + err.message);
      });
  };
  openWSM = () => {
    let wsmArray = [...this.state.wsm];
    let wsmToOpen;
    wsmArray.forEach((w, i) => {
      if (w.id == this.state.selectedWSM) {
        wsmToOpen = w;
      }
    });
    this.setState({ wsmData: wsmToOpen, addWSM: true });
  };
  finishWSM = () => {
    this.setState(
      {
        addWSM: false,
        loading: false,
        wsmData: null,
        message: "Added WSM Successfully",
      },
      () => {
        this.getWSM();
      }
    );
  };
  selectWSM = (event) => {
    if (event == null) {
      this.setState({ selectedWSM: event, wsmData: null });
    } else {
      this.setState({ selectedWSM: event });
    }
  };
  render() {
    return (
      <div>
        {this.state.loading ? (
          "loading"
        ) : (
          <div>
            <div className="dashboardPanel">
              <div className="dashboardPanelHeading dashboardPanelHeading-flex">
                <h1 className="display-5">WSM Reports</h1>
                <Button
                  onClick={() => {
                    if (this.state.loading) {
                      return;
                    } else {
                      this.addWSM();
                    }
                  }}
                  color="inherit" variant="contained">
                  Add WSM Report
                </Button>
              </div>
              {this.state.message !== undefined ? (
                <p className="alert">{this.state.message}</p>
              ) : (
                ""
              )}
              <hr />
              <div className="dashboardControls" style={{ marginBottom: 1 + "em" }}>
                {this.state.selectedWSM !== null &&
                  this.state.selectedWSM !== undefined ? (
                  <Button onClick={this.openWSM} color="inherit" variant="contained">
                    Open/Edit WSM
                  </Button>
                ) : (
                  ""
                )}
              </div>
              <WSMTable wsm={this.state.wsm} selectWSM={this.selectWSM} />
            </div>
          </div>
        )}
        {this.state.addWSM ? (
          <div>
            <AddWSM
              // TODO this isn't cleared out if you view another job prior to adding a wsm job
              job={this.state.job}
              close={this.closeWSM}
              finish={this.finishWSM}
              uid={this.props.firebase.auth.currentUser.uid}
              wsmData={this.state.wsmData}
            />
            <Backdrop click={this.closeAddWSM} />
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default compose(withFirebase)(WSM);
