import React, { Component } from "react";
import CloseConfirmation from "./../CloseConfirmation";
import DatePicker from "react-datepicker";
import { withFirebase } from "./../../../../firebase/index";
import { compose } from "redux";
import AddEmployee from "./../AddEmployee";
import Backdrop from "./../../../common/Backdrop";
import PipeForm from "./PipeForm";
import HDPEPipeForm from "./HDPEPipeForm";
import Overwrite from "./Overwrite";
import EnterTime from "./EnterTime";

class TimesheetEditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      closeConfirmation: false,
      dateTime: new Date().getTime(),
      date: new Date(),
      addEmployee: false,
      selectedEmployee: null,
      employeesAndHours: [],
      message: "",
      pipeFormNumber: 1,
      pipeForm1: { sizeType: "", sizeTypeWire: "", boreFootage: "" },
      pipeForm2: { sizeType: "", sizeTypeWire: "", boreFootage: "" },
      pipeForm3: { sizeType: "", sizeTypeWire: "", boreFootage: "" },
      hdpePipeForm: { sizeType: "", sizeTypeWire: "", boreFootage: "" },
      notes: "",
      agree: false,
      loading: false,
      timesheet: undefined,
      overwrite: false,
    };
    this.answerClose = this.answerClose.bind(this);
    this.closeTimesheet = this.closeTimesheet.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.onAddEmployeeClick = this.onAddEmployeeClick.bind(this);
    this.addEmployee = this.addEmployee.bind(this);
    this.onBackdropClick = this.onBackdropClick.bind(this);
    this.onHoursChange = this.onHoursChange.bind(this);
    this.pipeFormChange = this.pipeFormChange.bind(this);
    this.hdpePipeFormChange = this.hdpePipeFormChange.bind(this);
    this.selectEmployee = this.selectEmployee.bind(this);
    this.deleteEmployee = this.deleteEmployee.bind(this);
    this.onNotesChange = this.onNotesChange.bind(this);
    this.agreeCheck = this.agreeCheck.bind(this);
    this.confirmOverwrite = this.confirmOverwrite.bind(this);
    this.setupForm = this.setupForm.bind(this);
    this.getPhotosAndTailboard = this.getPhotosAndTailboard.bind(this);
    this.moveTimesheet = this.moveTimesheet.bind(this);
    this.onJobRateChange = this.onJobRateChange.bind(this);
    this.getBoringProcedure = this.getBoringProcedure.bind(this);
  }
  onJobRateChange(event) {
    for (let i = 0; i < this.state.employeesAndHours.length; i++) {
      if (this.state.employeesAndHours[i].uid == event.target.name) {
        let oldEmployeesAndHours = this.state.employeesAndHours;
        oldEmployeesAndHours[i].jobRate = event.target.value;
        this.setState({ employeesAndHours: oldEmployeesAndHours });
      }
    }
  }
  confirmOverwrite(answer) {
    if (answer) {
      this.uploadSignature();
    } else {
      this.setState(
        prevState => {
          return { overwrite: false, loading: false };
        },
        () => {
          this.props.close("");
        }
      );
    }
  }
  agreeCheck() {
    this.setState(prevState => {
      return { addSignature: !prevState.addSignature };
    });
  }
  onNotesChange(event) {
    let notes = event.target.value;
    this.setState(prevState => {
      return { notes: notes };
    });
  }
  deleteEmployee() {
    let oldEmployeesAndHours = this.state.employeesAndHours;
    for (let i = 0; i < oldEmployeesAndHours.length; i++) {
      if (oldEmployeesAndHours[i].uid == this.state.selectedEmployee.uid) {
        oldEmployeesAndHours.splice(i, 1);
      }
    }
    this.setState(prevState => {
      return {
        employeesAndHours: oldEmployeesAndHours,
        selectedEmployee: null,
      };
    });
  }
  selectEmployee(employee) {
    if (this.state.selectedEmployee == employee) {
      this.setState(prevState => {
        return { selectedEmployee: null };
      });
    } else {
      this.setState(prevState => {
        return { selectedEmployee: employee };
      });
    }
  }
  hdpePipeFormChange(event) {
    let value = event.target.value;
    switch (event.target.id) {
      case "sizeType":
        this.setState(prevState => {
          return {
            hdpePipeForm: { ...prevState.hdpePipeForm, sizeType: value },
          };
        });
        break;
      case "sizeTypeWire":
        this.setState(prevState => {
          return {
            hdpePipeForm: { ...prevState.hdpePipeForm, sizeTypeWire: value },
          };
        });
        break;
      case "boreFootage":
        this.setState(prevState => {
          return {
            hdpePipeForm: { ...prevState.hdpePipeForm, boreFootage: value },
          };
        });
        break;
      default:
        break;
    }
  }
  pipeFormChange(event) {
    let value = event.target.value;
    if (event.target.name == "pipeForm1") {
      //pipeForm1
      switch (event.target.id) {
        case "sizeType":
          this.setState(prevState => {
            return { pipeForm1: { ...prevState.pipeForm1, sizeType: value } };
          });
          break;
        case "sizeTypeWire":
          this.setState(prevState => {
            return {
              pipeForm1: { ...prevState.pipeForm1, sizeTypeWire: value },
            };
          });
          break;
        case "boreFootage":
          this.setState(prevState => {
            return {
              pipeForm1: { ...prevState.pipeForm1, boreFootage: value },
            };
          });
          break;
        default:
          break;
      }
    } else if (event.target.name == "pipeForm2") {
      //pipeForm2
      switch (event.target.id) {
        case "sizeType":
          this.setState(prevState => {
            return { pipeForm2: { ...prevState.pipeForm2, sizeType: value } };
          });
          break;
        case "sizeTypeWire":
          this.setState(prevState => {
            return {
              pipeForm2: { ...prevState.pipeForm2, sizeTypeWire: value },
            };
          });
          break;
        case "boreFootage":
          this.setState(prevState => {
            return {
              pipeForm2: { ...prevState.pipeForm2, boreFootage: value },
            };
          });
          break;
        default:
          break;
      }
    } else if (event.target.name == "pipeForm3") {
      //pipeForm3
      switch (event.target.id) {
        case "sizeType":
          this.setState(prevState => {
            return { pipeForm3: { ...prevState.pipeForm3, sizeType: value } };
          });
          break;
        case "sizeTypeWire":
          this.setState(prevState => {
            return {
              pipeForm3: { ...prevState.pipeForm3, sizeTypeWire: value },
            };
          });
          break;
        case "boreFootage":
          this.setState(prevState => {
            return {
              pipeForm3: { ...prevState.pipeForm3, boreFootage: value },
            };
          });
          break;
        default:
          break;
      }
    }
  }
  moveTimesheet(timesheet) {
    timesheet.datetime = this.state.datetime;
    this.props.firebase.db
      .collection("jobs")
      .doc(timesheet.job)
      .collection("timesheets")
      .doc(timesheet.id)
      .set(timesheet)
      .then(
        function() {
          this.props.firebase.db
            .collection("jobs")
            .doc(timesheet.job)
            .collection("timesheets")
            .doc(this.props.timesheet.id)
            .delete()
            .then(
              function() {
                this.props.firebase.db
                  .collection("timesheets")
                  .doc(this.props.timesheet.id)
                  .delete()
                  .then(
                    function() {
                      this.props.firebase.db
                        .collection("timesheets")
                        .doc(timesheet.id)
                        .set(timesheet)
                        .then(
                          function() {
                            this.props.close("edited");
                          }.bind(this)
                        )
                        .catch(function(err) {
                          console.log(
                            "error setting new timesheet in timesheet node: " + err.message
                          );
                        });
                    }.bind(this)
                  )
                  .catch(function(err) {
                    console.log("error deleting timesheet from timesheet node" + err.message);
                  });
              }.bind(this)
            )
            .catch(function(err) {
              console.log("error deleting old timesheet: " + err.message);
            });
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error setting new timesheet: " + err.message);
      });
  }
  moveJobPhotos(timesheet) {
    this.props.firebase.db
      .collection("jobs")
      .doc(timesheet.job)
      .collection("timesheets")
      .doc(this.props.timesheet.id)
      .collection("job-photos")
      .get()
      .then(
        function(jobPhotoSnapshot) {
          let jobPhotos = [];
          jobPhotoSnapshot.forEach(
            function(jobPhoto) {
              jobPhotos.push(jobPhoto.data());
              this.props.firebase.db
                .collection("jobs")
                .doc(timesheet.job)
                .collection("timesheets")
                .doc(timesheet.id)
                .collection("job-photos")
                .doc(jobPhoto.data().id)
                .set(jobPhoto.data())
                .then(function() {
                  console.log("set new photo node");
                })
                .catch(function(err) {
                  console.log("error setting new jobphoto node : " + err.message);
                });
            }.bind(this)
          );
          if (jobPhotos.length == 0) {
            this.moveTimesheet(timesheet);
          } else {
            for (let i = 0; i < jobPhotos.length; i++) {
              if (i == jobPhotos.length - 1) {
                console.log("deleting last photo");

                this.props.firebase.db
                  .collection("jobs")
                  .doc(timesheet.job)
                  .collection("timesheets")
                  .doc(this.props.timesheet.id)
                  .collection("job-photos")
                  .doc(jobPhotos[i].id)
                  .delete()
                  .then(
                    function() {
                      console.log("deleted last photo, calling moveTimesheet");
                      this.moveTimesheet(timesheet);
                    }.bind(this)
                  )
                  .catch(function(err) {
                    console.log("error deleting old jobphoto node: " + err.message);
                  });
              } else {
                this.props.firebase.db
                  .collection("jobs")
                  .doc(timesheet.job)
                  .collection("timesheets")
                  .doc(this.props.timesheet.id)
                  .collection("job-photos")
                  .doc(jobPhotos[i].id)
                  .delete()
                  .then(function() {
                    console.log("deleted old photo node");
                  })
                  .catch(function(err) {
                    console.log("error deleting old jobphoto node: " + err.message);
                  });
              }
            }
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting job photos : " + err.message);
      });
  }
  getBoringProcedure(timesheet) {
    this.props.firebase.db
      .collection("jobs")
      .doc(timesheet.job)
      .collection("timesheets")
      .doc(this.props.timesheet.id)
      .collection("boringProcedure")
      .doc(this.props.timesheet.id)
      .get()
      .then(
        function(boringProcedure) {
          let boringProcedureObject;
          if (boringProcedure.exists) {
            boringProcedureObject = boringProcedure.data();
            boringProcedureObject.id = timesheet.id;
            this.props.firebase.db
              .collection("jobs")
              .doc(timesheet.job)
              .collection("timesheets")
              .doc(timesheet.id)
              .collection("boringProcedure")
              .doc(timesheet.id)
              .set(boringProcedureObject)
              .then(
                function() {
                  this.props.firebase.db
                    .collection("jobs")
                    .doc(timesheet.job)
                    .collection("timesheets")
                    .doc(this.props.timesheet.id)
                    .collection("boringProcedure")
                    .doc(this.props.timesheet.id)
                    .delete()
                    .then(
                      function() {
                        this.getBorePreShot(timesheet);
                      }.bind(this)
                    )
                    .catch(function(err) {
                      console.log("error deleting boringProcedure: " + err.message);
                    });
                }.bind(this)
              )
              .catch(function(err) {
                console.log("error writing new boringProcedure node: " + err.message);
              });
          } else {
            boringProcedureObject = undefined;

            this.getBorePreShot(timesheet);
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting boringProcedure : " + err.message);
      });
  }

  getBorePreShot = timesheet => {
    this.props.firebase.db
      .collection("jobs")
      .doc(timesheet.job)
      .collection("timesheets")
      .doc(this.props.timesheet.id)
      .collection("borePreShot")
      .doc(this.props.timesheet.id)
      .get()
      .then(
        function(borePreShot) {
          let borePreShotObject;
          if (borePreShot.exists) {
            borePreShotObject = borePreShot.data();
            borePreShotObject.id = timesheet.id;
            this.props.firebase.db
              .collection("jobs")
              .doc(timesheet.job)
              .collection("timesheets")
              .doc(timesheet.id)
              .collection("borePreShot")
              .doc(timesheet.id)
              .set(borePreShotObject)
              .then(
                function() {
                  this.props.firebase.db
                    .collection("jobs")
                    .doc(timesheet.job)
                    .collection("timesheets")
                    .doc(this.props.timesheet.id)
                    .collection("borePreShot")
                    .doc(this.props.timesheet.id)
                    .delete()
                    .then(
                      function() {
                        this.getPhotosAndTailboard(timesheet);
                      }.bind(this)
                    )
                    .catch(function(err) {
                      console.log("error deleting borePreShot: " + err.message);
                    });
                }.bind(this)
              )
              .catch(function(err) {
                console.log(
                  "error writing new borePreShot node: " + err.message
                );
              });
          } else {
            borePreShot = undefined;
            this.getPhotosAndTailboard(timesheet);
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting boringProcedure : " + err.message);
      });
  };
  getPhotosAndTailboard(timesheet) {
    this.props.firebase.db
      .collection("jobs")
      .doc(timesheet.job)
      .collection("timesheets")
      .doc(this.props.timesheet.id)
      .collection("tailboards")
      .doc(this.props.timesheet.id)
      .get()
      .then(
        function(tailboard) {
          let tailboardObject;
          if (tailboard.exists) {
            tailboardObject = tailboard.data();
            tailboardObject.id = timesheet.id;
            this.props.firebase.db
              .collection("jobs")
              .doc(timesheet.job)
              .collection("timesheets")
              .doc(timesheet.id)
              .collection("tailboards")
              .doc(timesheet.id)
              .set(tailboardObject)
              .then(
                function() {
                  this.props.firebase.db
                    .collection("jobs")
                    .doc(timesheet.job)
                    .collection("timesheets")
                    .doc(this.props.timesheet.id)
                    .collection("tailboards")
                    .doc(this.props.timesheet.id)
                    .delete()
                    .then(
                      function() {
                        this.moveJobPhotos(timesheet);
                      }.bind(this)
                    )
                    .catch(function(err) {
                      console.log("error deleting tailboard: " + err.message);
                    });
                }.bind(this)
              )
              .catch(function(err) {
                console.log("error writing new tailboard node: " + err.message);
              });
          } else {
            tailboardObject = undefined;
            this.moveJobPhotos(timesheet);
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting tailboard : " + err.message);
      });
  }
  submitTimesheet() {
    this.setState(prevState => {
      return { loading: true };
    });

    let timesheet = {};
    timesheet.job = this.props.timesheet.job;
    timesheet.date = this.state.date;
    timesheet.datetime = this.state.datetime;
    timesheet.month = this.state.date.getMonth();
    timesheet.year = this.state.date.getFullYear();
    timesheet.day = this.state.date.getDay();
    timesheet.signature = this.props.timesheet.signature;
    timesheet.employeesAndHours = this.state.employeesAndHours;
    timesheet.pipeForm1 = this.state.pipeForm1;
    timesheet.pipeForm2 = this.state.pipeForm2;
    timesheet.pipeForm3 = this.state.pipeForm3;
    timesheet.hdpePipeForm = this.state.hdpePipeForm;
    timesheet.createdBy = this.props.timesheet.createdBy;
    timesheet.notes = this.state.notes;

    // TODO replace with dayjs
    let month = parseInt(timesheet.date.getMonth()) + 1;
    let docString = month + "-" + timesheet.date.getFullYear();
    let docString2 = timesheet.date.getDate() + "-" + docString;
    let timesheetTitle =
      this.props.uid + "-" + timesheet.job + "-" + docString2;
    timesheet.id = timesheetTitle;
    if (timesheetTitle !== this.props.timesheet.id) {
      let suffix = this.props.timesheet.id.substr(
        this.props.timesheet.id.lastIndexOf("-") + 1
      );
      if (suffix.length > 2) {
        console.log("different date, deleting old nodes and creating new one");
        this.props.firebase.db
          .collection("jobs")
          .doc(timesheet.job)
          .collection("timesheets")
          .doc(this.props.timesheet.id)
          .set(timesheet, { merge: true })
          .then(
            function() {
              this.props.firebase.db
                .collection("jobs")
                .doc(timesheet.job)
                .collection("timesheets")
                .doc(this.props.timesheet.id)
                .get()
                .then(
                  function(timesheet) {
                    this.getBoringProcedure(timesheet.data());
                  }.bind(this)
                )
                .catch(function(err) {
                  console.log(
                    "error getting updated timesheet: " + err.message
                  );
                });
            }.bind(this)
          )
          .catch(function(err) {
            console.log("error setting timesheet: " + err.message);
          });
      } else {
        //same date, update node
        timesheet.id = this.props.timesheet.id;
        this.props.firebase.db
          .collection("jobs")
          .doc(timesheet.job)
          .collection("timesheets")
          .doc(this.props.timesheet.id)
          .set(timesheet, { merge: true })
          .then(
            function() {
              this.props.firebase.db
                .collection("timesheets")
                .doc(this.props.timesheet.id)
                .set(timesheet, { merge: true })
                .then(
                  function() {
                    this.props.close("edited");
                  }.bind(this)
                )
                .catch(function(err) {
                  console.log(err.message);
                });
            }.bind(this)
          )
          .catch(function(err) {
            console.log(err.message);
            this.setState(prevState => {
              return { message: err.message };
            });
          });
      }
    } else {
      //same date, update node
      this.props.firebase.db
        .collection("jobs")
        .doc(timesheet.job)
        .collection("timesheets")
        .doc(timesheetTitle)
        .set(timesheet, { merge: true })
        .then(
          function() {
            this.props.firebase.db
              .collection("timesheets")
              .doc(timesheetTitle)
              .set(timesheet, { merge: true })
              .then(
                function() {
                  this.props.close("edited");
                }.bind(this)
              )
              .catch(function(err) {
                console.log(err.message);
              });
          }.bind(this)
        )
        .catch(function(err) {
          console.log(err.message);
          this.setState(prevState => {
            return { message: err.message };
          });
        });
    }
  }

  onHoursChange(event, jobRate) {
    let hours = parseFloat(event.target.value) || 0;
    let regular = {};
    let operator = {};
    let laborer = {};
    if (event.target.value < 0) {
      for (let i = 0; i < this.state.employeesAndHours.length; i++) {
        if (this.state.employeesAndHours[i].uid == event.target.name) {
          console.log("found employee in employeesAndHours, changing hours");
          let oldEmployeesAndHours = this.state.employeesAndHours;
          switch (jobRate) {
            case "regular":
              regular.hours = 0;
              oldEmployeesAndHours[i].regular = regular;
              break;
            case "laborer":
              laborer.hours = 0;
              oldEmployeesAndHours[i].laborer = laborer;
              break;
            case "operator":
              operator.hours = 0;
              oldEmployeesAndHours[i].operator = operator;
              break;
            default:
              break;
          }

          this.setState(prevState => {
            return {
              employeesAndHours: oldEmployeesAndHours,
              message: "You Must Enter A Positive Number",
            };
          });
        }
      }
    } else {
      for (let i = 0; i < this.state.employeesAndHours.length; i++) {
        if (this.state.employeesAndHours[i].uid == event.target.name) {
          console.log("found employee in employeesAndHours, changing hours");
          let oldEmployeesAndHours = this.state.employeesAndHours;
          switch (jobRate) {
            case "regular":
              regular.hours = hours;
              oldEmployeesAndHours[i].regular = regular;
              break;
            case "laborer":
              laborer.hours = hours;
              oldEmployeesAndHours[i].laborer = laborer;
              break;
            case "operator":
              operator.hours = hours;
              oldEmployeesAndHours[i].operator = operator;
              break;
            default:
              break;
          }
          this.setState(prevState => {
            return { employeesAndHours: oldEmployeesAndHours, message: "" };
          });
        }
      }
    }
  }
  addEmployee(employee) {
    for (let i = 0; i < this.state.employeesAndHours.length; i++) {
      if (employee.uid === this.state.employeesAndHours[i].uid) {
        this.setState(prevState => {
          return {
            addEmployee: false,
            message: "Employee Already On Timesheet",
          };
        });
        return;
      }
    }
    let regular = { hours: this.state.hours };
    let laborer = { hours: 0 };
    let operator = { hours: 0 };
    let newEmployeesAndHours = this.state.employeesAndHours;
    let newEmployee = employee;
    newEmployee.regular = regular;
    newEmployee.operator = operator;
    newEmployee.laborer = laborer;
    newEmployeesAndHours.push(newEmployee);
    this.setState(prevState => {
      return {
        employeesAndHours: newEmployeesAndHours,
        addEmployee: false,
        message: "",
      };
    });
  }
  onAddEmployeeClick() {
    this.setState(prevState => {
      return { addEmployee: true, message: "" };
    });
  }
  onBackdropClick() {
    this.setState(prevState => {
      return { addEmployee: false, message: "" };
    });
  }
  onDateSelect(date) {
    // TODO dayjs
    let date1 = new Date(date);
    let dateTime = date1.getTime();
    let month = parseInt(date1.getMonth()) + 1;
    let docString = month + "-" + date1.getFullYear();
    let docString2 = date1.getDate() + "-" + docString;
    let timesheetTitle =
      this.props.uid + "-" + this.state.job + "-" + docString2;
    for (let i = 0; i < this.props.timesheets.length; i++) {
      if (this.props.timesheets[i].id == timesheetTitle) {
        //found timesheet for that day
        if (this.props.timesheets[i].id == this.props.timesheet.id) {
          this.setState(prevState => {
            return { date: date1, datetime: dateTime, message: "" };
          });
        } else {
          this.setState({
            message:
              "There is already a timesheet for that day, edit that one instead",
          });
        }
        return;
      }
    }
    this.setState(prevState => {
      return { date: date1, datetime: dateTime, message: "" };
    });
  }
  setupForm() {
    this.setState(
      {
        date: new Date(this.props.timesheet.datetime),
        datetime: this.props.timesheet.datetime,
        day: this.props.timesheet.day,
        employeesAndHours: this.props.timesheet.employeesAndHours,
        hdpePipeForm: this.props.timesheet.hdpePipeForm,
        id: this.props.timesheet.id,
        job: this.props.timesheet.job,
        month: this.props.timesheet.month,
        notes: this.props.timesheet.notes,
        pipeForm1: this.props.timesheet.pipeForm1,
        pipeForm2: this.props.timesheet.pipeForm2,
        pipeForm3: this.props.timesheet.pipeForm3,
        signature: this.props.timesheet.signature,
        year: this.props.timesheet.year,
      }
    );
  }
  componentDidMount() {
    //get current user
    if (this.state.id == undefined || this.state.id == null) {
      this.setupForm();
    }
  }
  answerClose(answer) {
    if (answer) {
      this.props.close("exit");
    } else {
      this.setState(prevState => {
        return { closeConfirmation: false };
      });
    }
  }
  enterTimes = (uid, rate) => {
    this.setState({ enterTime: true, timeUid: uid, timeRate: rate });
  };
  convertTimes = (time, uid, rate) => {
    this.setState({ enterTime: false });
    let event = { target: { value: time, name: uid } };
    this.onHoursChange(event, rate);
  };
  cancelEnterTime = () => {
    this.setState({
      enterTime: false,
      timeUid: undefined,
      timeRate: undefined,
    });
  };
  closeTimesheet() {
    this.setState(prevState => {
      return { closeConfirmation: true };
    });
  }
  render() {
    let closeConfirmation,
      addEmployee,
      message,
      deleteEmployee,
      loading,
      overwrite;
    if (this.state.closeConfirmation) {
      closeConfirmation = (
        <CloseConfirmation answer={this.answerClose} form="Timesheet" />
      );
    } else {
      closeConfirmation = "";
    }
    if (this.state.addEmployee) {
      addEmployee = (
        <div>
          <AddEmployee
            employees={this.props.employees}
            addEmployee={this.addEmployee}
          />
          <Backdrop click={this.onBackdropClick} />{" "}
        </div>
      );
    } else {
      addEmployee = "";
    }
    if (this.state.message !== "") {
      message = <div className="alert alert-danger">{this.state.message}</div>;
    } else {
      message = "";
    }
    if (this.state.selectedEmployee !== null) {
      deleteEmployee = (
        <button
          onClick={this.deleteEmployee}
          className="btn btn-secondary dashboardButton"
        >
          Remove Selected Employee
        </button>
      );
    } else {
      deleteEmployee = "";
    }
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      loading = (
        <button
          className="btn btn-secondary addEmployeeButton dashboardButton submitTimesheet"
          onClick={() => {
            this.submitTimesheet();
          }}
        >
          Submit Edited Timesheet
        </button>
      );
    }
    if (this.state.overwrite) {
      overwrite = <Overwrite confirmation={this.confirmOverwrite} />;
    } else {
      overwrite = "";
    }
    return (
      <div className="jobForm">
        <button
          className="btn btn-secondary btn-sm exit"
          onClick={this.closeTimesheet}
        >
          CLOSE
        </button>
        <div className="jobFormContainer">
          <h1 className="display-5">
            Timesheet For Job # {this.props.timesheet.job}
          </h1>
          <br />
          {message}
          <p className="headline lead">Date:</p>
          <DatePicker
            style={{ width: "100%" }}
            selected={this.state.date}
            onChange={this.onDateSelect}
          />
          <br />
          <button
            onClick={() => {
              this.onAddEmployeeClick();
            }}
            className="btn btn-secondary addEmployeeButton dashboardButton"
          >
            Add Employee
          </button>
          {deleteEmployee}
          <table className="table table-hover">
            <thead className="thead-dark">
              <tr>
                <th />
                <th className="sort" scope="col">
                  Name
                </th>
                <th className="sort" scope="col">
                  Hours
                </th>
                <th className="sort" scope="col">
                  Job Rate
                </th>
              </tr>
            </thead>
            {this.state.employeesAndHours.map((employee, i) => (
              <tbody key={i}>
                <tr style={{ backgroundColor: "#ffd965" }}>
                  <td>
                    <input
                      type="checkbox"
                      checked={
                        this.state.selectedEmployee == employee ? true : false
                      }
                      id="selectEmployee"
                      onChange={() => {
                        this.selectEmployee(employee);
                      }}
                    />
                  </td>
                  <th scope="row">{employee.name}</th>
                  <td>
                    <button
                      className="btn btn-secondary btn-sm clock"
                      onClick={() => {
                        this.enterTimes(employee.uid, "regular");
                      }}
                    >
                      Enter Clock Times
                    </button>
                    <br />
                    <input
                      type="number"
                      name={employee.uid}
                      value={employee.regular.hours}
                      onChange={event => {
                        this.onHoursChange(event, "regular");
                      }}
                    />
                  </td>
                  <td>
                    <h3 className="display-5">REGULAR</h3>
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#9cc2e5" }}>
                  <td />
                  <th scope="row">{employee.name}</th>
                  <td>
                    <button
                      className="btn btn-secondary btn-sm clock"
                      onClick={() => {
                        this.enterTimes(employee.uid, "laborer");
                      }}
                    >
                      Enter Clock Times
                    </button>
                    <br />
                    <input
                      type="number"
                      name={employee.uid}
                      value={employee.laborer.hours}
                      onChange={event => {
                        this.onHoursChange(event, "laborer");
                      }}
                    />
                  </td>
                  <td>
                    <h3 className="display-5">Laborer</h3>
                  </td>
                </tr>

                <tr style={{ backgroundColor: "#92d050" }}>
                  <td />
                  <th scope="row">{employee.name}</th>
                  <td>
                    <button
                      className="btn btn-secondary btn-sm clock"
                      onClick={() => {
                        this.enterTimes(employee.uid, "operator");
                      }}
                    >
                      Enter Clock Times
                    </button>
                    <br />
                    <input
                      type="number"
                      name={employee.uid}
                      value={employee.operator.hours}
                      onChange={event => {
                        this.onHoursChange(event, "operator");
                      }}
                    />
                  </td>
                  <td>
                    <h3 className="display-5">OPERATOR</h3>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
          <HDPEPipeForm
            id="hdpePipeForm"
            value={this.state.hdpePipeForm}
            hdpePipeFormChange={this.hdpePipeFormChange}
          />
          <PipeForm
            id="pipeForm1"
            number="1"
            name="pipeForm1"
            value={this.state.pipeForm1}
            pipeFormChange={this.pipeFormChange}
          />
          <PipeForm
            id="pipeForm2"
            number="2"
            name="pipeForm2"
            value={this.state.pipeForm2}
            pipeFormChange={this.pipeFormChange}
          />
          <PipeForm
            id="pipeForm3"
            number="3"
            name="pipeForm3"
            value={this.state.pipeForm3}
            pipeFormChange={this.pipeFormChange}
          />
          <label htmlFor="notes">Notes:</label>
          <textarea
            className="notes"
            name="notes"
            id="notes"
            value={this.state.notes}
            onChange={this.onNotesChange}
          />
          <br />
          {message}
          {loading}
          {overwrite}
          {addEmployee}
          {closeConfirmation}
          {this.state.enterTime ? (
            <div> 
              <EnterTime
                uid={this.state.timeUid}
                timeRate={this.state.timeRate}
                clockInTime={this.state.clockInTime}
                clockOutTime={this.state.clockOutTime}
                submit={this.convertTimes}
                close={this.cancelEnterTime}
              />
              <Backdrop click={this.cancelEnterTime} />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default compose(withFirebase)(TimesheetEditForm);
