import React, { Component } from "react";
import formatDate from "./../../common/formatDate";
import dayjs from "dayjs";

class TimesheetTableItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
    };
    this.onDetailsShow = this.onDetailsShow.bind(this);
  }
  onDetailsShow() {
    this.setState((prevState) => {
      return { showDetails: !prevState.showDetails };
    });
  }

  render() {
    let date = new Date(this.props.timesheet.datetime);
    let dateString = date.toDateString();
    let pre2024CovidFormChange = dayjs(date).isBefore(dayjs('2024-07-01'))

    let details, address;
    if (
      this.props.timesheet.address !== undefined &&
      this.props.timesheet.address !== null
    ) {
      address = this.props.timesheet.address;
    } else {
      address = "undefined";
    }
    if (this.state.showDetails) {
      details = (
        <div>
          <hr />
          {this.props.timesheet.employeesAndHours.map((employee, i) => (
            <div key={i} className="employeeAndHours">
              {employee.regular.hours > 0 ? (
                <div>
                  {employee.name} - {employee.regular.hours} hours - Rate:
                  Regular
                </div>
              ) : (
                ""
              )}
              {employee.laborer.hours > 0 ? (
                <div>
                  {employee.name} - {employee.laborer.hours} hours - Rate:
                  Laborer
                </div>
              ) : (
                ""
              )}
              {employee.operator.hours > 0 ? (
                <div>
                  {employee.name} - {employee.operator.hours} hours - Rate:
                  Operator
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
          <hr />
          <p>
            {this.props.timesheet.createdBy.name} clocked in at location:{" "}
            {address}{" "}
          </p>
          <p>Clock In Time: {formatDate(this.props.timesheet.clockInTime)}</p>
          <p>Clock Out Time: {formatDate(this.props.timesheet.clockOutTime)}</p>
          <button
            onClick={() => {
              this.props.open();
            }}
            className="btn btn-secondary btn-sm timesheetTableButton"
          >
            Open Timesheet
          </button>
          <button
            onClick={() => {
              this.props.openTailboard();
            }}
            disabled={
              this.props.tailboard == null || this.props.tailboard == undefined
                ? true
                : false
            }
            className="btn btn-secondary btn-sm timesheetTableButton"
          >
            Open Tailboard
          </button>
          <button
            onClick={() => {
              this.props.openBorePreShot();
            }}
            disabled={
              this.props.timesheet.borePreShot == null ||
              this.props.timesheet.borePreShot == undefined
                ? true
                : false
            }
            className="btn btn-secondary btn-sm timesheetTableButton"
          >
            Open Bore Pre Shot Checklist
          </button>
          <button
            onClick={() => {
              this.props.openBoreValidation();
            }}
            disabled={
              this.props.timesheet.boreValidation == null ||
              this.props.timesheet.boreValidation == undefined
                ? true
                : false
            }
            className="btn btn-secondary btn-sm timesheetTableButton"
          >
            Open Boring Procedure Validation
          </button>
          <button
            onClick={() => {
              this.props.openJobPhotos();
            }}
            className="btn btn-secondary btn-sm timesheetTableButton"
          >
            Open Job Photos
          </button>
          {pre2024CovidFormChange &&
            (<button
              onClick={() => {
                this.props.openCovidForm();
              }}
              disabled={
                this.props.timesheet.covidForm == null ||
                this.props.timesheet.covidForm == undefined
                  ? true
                  : false
              }
              className="btn btn-secondary btn-sm timesheetTableButton"
            >
              Open CovidForm
            </button>)
          }
          <div className="reportPipeForm">
            <p className="lead font-weight-bold">
              Size and Type of HDPE Pipe Installed:
            </p>
            <p className="lead">{this.props.timesheet.hdpePipeForm.sizeType}</p>
            <p className="lead font-weight-bold">
              Size and Type of Wire Installed:
            </p>
            <p className="lead">
              {this.props.timesheet.hdpePipeForm.sizeTypeWire}
            </p>
            <p className="lead font-weight-bold">
              Total Usable Bore Footage Installed AT DEPTH:
            </p>
            <p className="lead">
              {this.props.timesheet.hdpePipeForm.boreFootage}
            </p>
          </div>
          <div className="reportPipeForm">
            <p className="lead font-weight-bold">
              Size and Type of Pipe Installed:
            </p>
            <p className="lead"> {this.props.timesheet.pipeForm1.sizeType}</p>
            <p className="lead font-weight-bold">
              Size and Type of Wire Installed:
            </p>
            <p className="lead">
              {this.props.timesheet.pipeForm1.sizeTypeWire}
            </p>
            <p className="lead font-weight-bold">
              Total Usable Bore Footage Installed AT DEPTH:
            </p>
            <p className="lead">{this.props.timesheet.pipeForm1.boreFootage}</p>
          </div>
          <div className="reportPipeForm">
            <p className="lead font-weight-bold">
              Size and Type of Pipe Installed:
            </p>
            <p className="lead"> {this.props.timesheet.pipeForm2.sizeType}</p>
            <p className="lead font-weight-bold">
              Size and Type of Wire Installed:
            </p>
            <p className="lead">
              {this.props.timesheet.pipeForm2.sizeTypeWire}
            </p>
            <p className="lead font-weight-bold">
              Total Usable Bore Footage Installed AT DEPTH:
            </p>
            <p className="lead">{this.props.timesheet.pipeForm2.boreFootage}</p>
          </div>
          <div className="reportPipeForm">
            <p className="lead font-weight-bold">
              Size and Type of Pipe Installed:
            </p>
            <p className="lead"> {this.props.timesheet.pipeForm3.sizeType}</p>
            <p className="lead font-weight-bold">
              Size and Type of Wire Installed:
            </p>
            <p className="lead">
              {this.props.timesheet.pipeForm3.sizeTypeWire}
            </p>
            <p className="lead font-weight-bold">
              Total Usable Bore Footage Installed AT DEPTH:
            </p>
            <p className="lead">{this.props.timesheet.pipeForm3.boreFootage}</p>
          </div>
          <div className="reportPipeForm">
            <p className="lead font-weight-bold">Notes:</p>
            <p className="lead">{this.props.timesheet.notes}</p>
          </div>
          <div className="timesheetReportSignature">
            <p className="lead">
              Completed By: {this.props.timesheet.createdBy.name}
            </p>
            <img src={this.props.timesheet.signature} />
          </div>
        </div>
      );
    } else {
      details = "";
    }
    return (
      <div
        className={
          this.props.background == "light"
            ? "timesheetListItem list-group-item light"
            : "timesheetListItem list-group-item dark"
        }
      >
        <div className="timesheetTableItemHeader">
          <p className="lead font-weight-bold">
            {dateString} - {this.props.timesheet.createdBy.name}
          </p>
        </div>
        <button
          className="dashboardButton btn btn-secondary btn-sm"
          onClick={() => {
            this.onDetailsShow();
          }}
        >
          Expand
        </button>
        <div className="timesheetTableItemDetails">{details}</div>
      </div>
    );
  }
}

export default TimesheetTableItem;
