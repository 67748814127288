import React from "react";
import formatDate from "../../common/formatDate";

export default function ViewWSM(props) {
  return (
    <div className="viewReport" style={{ backgroundColor: "white" }}>
      <div
        className="dashboardPanelHeading"
        style={{ backgroundColor: "white" }}
      >
        <h3 className="display-5">
          Water Service Materials Report For : {props.wsm.address}{" "}
          {props.wsm.road}
        </h3>
        <hr />
        <div className="reportContent">
          <b>General</b>
          <hr />
          <b>Date:</b> {formatDate(props.wsm.time)}
          <br />
          <b>Employee:</b> {props.wsm.employee}
          <br />
          <b>Road:</b> {props.wsm.road}
          <br />
          <b>Address:</b> {props.wsm.address}
          <br />
          <hr />
          <b>Main To</b>
          <hr />
          <b>Material:</b> {props.wsm.mainTo.material}
          <br />
          <b>Pipe Size:</b> {props.wsm.mainTo.size}
          <br />
          <hr />
          <b>Curb To:</b>
          <hr />
          <b>Material:</b> {props.wsm.curbTo.material}
          <br />
          <b>Pipe Size:</b> {props.wsm.curbTo.size}
        </div>
      </div>
    </div>
  );
}
