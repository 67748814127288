export const getTimesheetsNew = (newId, job, db) => {
    return new Promise(function(res, rej) {
    db.collection("jobs")
      .doc(job)
      .collection("timesheets")
      .get()
      .then(function(timesheetSnapshot) {
        let timesheets = [];

        timesheetSnapshot.forEach(function(timesheet) {
          timesheets.push(timesheet.data());
        });
        res(timesheets);
        // this.getTailboardsForArchiving(job, timesheets);
      })
      .catch(function(err) {
        console.log("error getting timesheets");
        rej(err);
      });
  });
};

export const getTailboardsNew = (newId, job, timesheets, db) => {
  return new Promise(function(res, rej) {
    for (let i = 0; i < timesheets.length; i++) {
      db.collection("jobs")
        .doc(job)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .collection("tailboards")
        .doc(timesheets[i].id)
        .get()
        .then(function(tailboard) {
          if (tailboard.exists) {
            db.collection("jobs")
              .doc(newId)
              .collection("timesheets")
              .doc(timesheets[i].id)
              .collection("tailboards")
              .doc(timesheets[i].id)
              .set(tailboard.data())
              .then(function() {
                db.collection("jobs")
                  .doc(job)
                  .collection("timesheets")
                  .doc(timesheets[i].id)
                  .collection("tailboards")
                  .doc(timesheets[i].id)
                  .delete()
                  .then(function() {
                    console.log("deleted tailboard");
                  })
                  .catch(function(err) {
                    console.log("error deleting tailboard: " + err.message);
                    rej(err);
                  });
              })
              .catch(function(err) {
                console.log("error setting archive tailboard");
                rej(err);
              });
          }
        })
        .catch(function(err) {
          console.log("error getting tailboard: " + err.message);
          rej(err);
        });
    }

    res("moved tailboards");
  });

  // this.getJobPhotosForArchiving(job);
};
export const moveTimesheetsNew = (newId, job, timesheets, db) => {
  return new Promise(function(res, rej) {
    for (let i = 0; i < timesheets.length; i++) {
      timesheets[i].job = newId;
      db.collection("jobs")
        .doc(newId)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .set(timesheets[i])
        .then(
          function() {
            console.log("wrote timesheets in new job node");
            db.collection("jobs")
              .doc(job)
              .collection("timesheets")
              .doc(timesheets[i].id)
              .delete()
              .then(function() {
                console.log("deleted old timesheet node");
              })
              .catch(function(err) {
                console.log("error deleting old timesheet node");
                rej(err);
              });
          }.bind(this)
        )
        .catch(function(err) {
          console.log("error writing new-jobs timesheet");
          rej(err);
        });
    }
    res("moved timesheets");
  });
  // this.moveExpenseReports(job);
};
export const moveDocumentsNew = (newId, job, db) => {
  return new Promise(function(res, rej) {
    db.collection("jobs")
      .doc(job.id)
      .collection("documents")
      .get()
      .then(function(documentsSnapshot) {
        documentsSnapshot.forEach(function(document) {
          let docObj = document.data();
          docObj.jobId = newId;
          db.collection("jobs")
            .doc(newId)
            .collection("documents")
            .doc(document.data().documentName)
            .set(docObj)
            .then(function() {
              db.collection("jobs")
                .doc(job.id)
                .collection("documents")
                .doc(document.data().documentName)
                .delete()
                .then(function() {
                  console.log("deleted old document");
                })
                .catch(function(err) {
                  console.log("error deleting old document: " + err.message);
                  rej(err);
                });
            })
            .catch(function(err) {
              console.log("error setting new jobs document");
              rej(err);
            });
        });
        res("moved and deleted documents");
      })
      .catch(function(err) {
        console.log("error getting documents");
        rej(err);
      });
    // this.moveAndDeleteJobNode(job);
  });
};
export const moveRegularTimesheetsNew = (newId, job, db) => {
  return new Promise(function(res, rej) {
    db.collection("timesheets")
      .where("job", "==", job.id)
      .get()
      .then(function(timesheetSnapshot) {
        let timesheets = [];
        timesheetSnapshot.forEach(function(timesheet) {
          timesheets.push(timesheet.data());
        });

        for (let i = 0; i < timesheets.length; i++) {
          timesheets[i].job = newId;
          db.collection("timesheets")
            .doc(timesheets[i].id)
            .set(timesheets[i], { merge: true });
        }
        res("moved regular timesheets");
      })
      .catch(function(err) {
        console.log("error getting regular timesheets");
        rej(err);
      });
  });
};
export const moveJobNodeNew = (newId, job, db) => {
  return new Promise(function(res, rej) {
    db.collection("jobs")
      .doc(job.id)
      .get()
      .then(function(jobObject) {
        if (jobObject.exists) {
          let newJob = jobObject.data();
          newJob.id = newId;
          db.collection("jobs")
            .doc(newId)
            .set(newJob)
            .then(function() {
              db.collection("jobs")
                .doc(job.id)
                .delete()
                .then(function() {
                  console.log("done migrating job");

                  res("moved job node");
                })
                .catch(function(err) {
                  console.log("error deleting old job node: " + err.message);
                  rej(err);
                });
            })
            .catch(function(err) {
              console.log("error setting new job node");
              rej(err);
            });
        }
      })
      .catch(function(err) {
        console.log("error getting job node");
        rej(err);
      });
  });
};

export const moveAndDeleteExpenseReportsNew = (
  newId,
  expenseReports,
  job,
  db
) => {
  return new Promise(function(res, rej) {
    for (let i = 0; i < expenseReports.length; i++) {
      db.collection("jobs")
        .doc(newId)
        .collection("expense-reports")
        .doc(expenseReports[i].id)
        .set(expenseReports[i])
        .then(function() {
          console.log("set expense report in new jobs");
          db.collection("jobs")
            .doc(job.id)
            .collection("expense-reports")
            .doc(expenseReports[i].id)
            .delete()
            .then(function() {
              console.log("deleted old expense report node");
            })
            .catch(function(err) {
              console.log("error deleting old expense report: " + err.message);
              rej(err);
            });
        })
        .catch(function(err) {
          console.log("error setting new jobs expense report: " + err.message);
          rej(err);
        });
    }
    res("moved Expense Reports");
  });
  // this.moveDocuments(job);
};
export const getExpenseReportsNew = (newId, job, db) => {
  return new Promise(function(res, rej) {
    let expenseReports = [];
    db.collection("jobs")
      .doc(job.id)
      .collection("expense-reports")
      .get()
      .then(function(expenseReportSnapshot) {
        expenseReportSnapshot.forEach(function(expenseReport) {
          console.log("have expense report to move");
          expenseReports.push(expenseReport.data());
        });
        res(expenseReports);
        // this.moveAndDeleteExpenseReports(expenseReports, job);
      })
      .catch(function(err) {
        console.log("error getting expense reports: " + err.message);
        rej(err);
      });
  });
};
export const moveBorePreShot = (newId, job, timesheets, db) => {
  return new Promise(function(res, rej) {
    for (let i = 0; i < timesheets.length; i++) {
      db.collection("jobs")
        .doc(job)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .collection("borePreShot")
        .doc(timesheets[i].id)
        .get()
        .then(function(tailboard) {
          if (tailboard.exists) {
            db.collection("jobs")
              .doc(newId)
              .collection("timesheets")
              .doc(timesheets[i].id)
              .collection("borePreShot")
              .doc(timesheets[i].id)
              .set(tailboard.data())
              .then(function() {
                db.collection("jobs")
                  .doc(job)
                  .collection("timesheets")
                  .doc(timesheets[i].id)
                  .collection("borePreShot")
                  .doc(timesheets[i].id)
                  .delete()
                  .then(function() {
                    console.log("deleted borePreShot");
                  })
                  .catch(function(err) {
                    console.log("error deleting borePreShot: " + err.message);
                    rej(err);
                  });
              })
              .catch(function(err) {
                console.log("error setting archive borePreShot");
                rej(err);
              });
          }
        })
        .catch(function(err) {
          console.log("error getting borePreShot: " + err.message);
          rej(err);
        });
    }

    res("moved borePreShot");
  });
};
export const moveBoringProcedure = (newId, job, timesheets, db) => {
  return new Promise(function(res, rej) {
    for (let i = 0; i < timesheets.length; i++) {
      db.collection("jobs")
        .doc(job)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .collection("boringProcedure")
        .doc(timesheets[i].id)
        .get()
        .then(function(tailboard) {
          if (tailboard.exists) {
            db.collection("jobs")
              .doc(newId)
              .collection("timesheets")
              .doc(timesheets[i].id)
              .collection("boringProcedure")
              .doc(timesheets[i].id)
              .set(tailboard.data())
              .then(function() {
                db.collection("jobs")
                  .doc(job)
                  .collection("timesheets")
                  .doc(timesheets[i].id)
                  .collection("boringProcedure")
                  .doc(timesheets[i].id)
                  .delete()
                  .then(function() {
                    console.log("deleted boringProcedure");
                  })
                  .catch(function(err) {
                    console.log(
                      "error deleting boringProcedure: " + err.message
                    );
                    rej(err);
                  });
              })
              .catch(function(err) {
                console.log("error setting archive boringProcedure");
                rej(err);
              });
          }
        })
        .catch(function(err) {
          console.log("error getting boringProcedure: " + err.message);
          rej(err);
        });
    }

    res("moved boringProcedures");
  });
};
export const getJobPhotosForArchivingNew = (newId, job, timesheet, db) => {
  return new Promise(function(res, rej) {
    db.collection("jobs")
      .doc(job)
      .collection("timesheets")
      .doc(timesheet.id)
      .collection("job-photos")
      .get()
      .then(function(jobPhotoSnapshot) {
        jobPhotoSnapshot.forEach(function(jobPhoto) {
          console.log("jobPhotosnapshot recieved");
          if (jobPhoto.exists) {
            let photo = jobPhoto.data();
            let id;
            if (photo.id == undefined || photo.id == null) {
              id = photo.type;
            } else {
              id = photo.id;
            }
            db.collection("jobs")
              .doc(newId)
              .collection("timesheets")
              .doc(timesheet.id)
              .collection("job-photos")
              .doc(id)
              .set(photo)
              .then(function() {
                console.log("set new jobs job photo node");
                db.collection("jobs")
                  .doc(job)
                  .collection("timesheets")
                  .doc(timesheet.id)
                  .collection("job-photos")
                  .doc(id)
                  .delete()
                  .then(function() {
                    console.log("deleted job photo");
                  })
                  .catch(function(err) {
                    console.log(
                      "error deleting old job photo node : " + err.message
                    );
                    rej(err);
                  });
              })
              .catch(function(err) {
                console.log("error setting new-jobs jobPhoto: " + err.message);
                rej(err);
              });
          }
        });
      })
      .catch(function(err) {
        console.log("error getting job photos: " + err.message);
        rej(err);
      });
    res("photos moved");
  });

  // this.moveTimesheetNodes(job);
};
