import React, { Component } from "react";
import ExportCSV from "./../../common/ExportCSV";
import Button from '@material-ui/core/Button';

class WeeklyTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeesAndHours: [],
      week: {},
      exportReady: false,
    };

    this.calculateTotals = this.calculateTotals.bind(this);
  }

  calculateTotals(employeesAndHours) {
    for (let i = 0; i < employeesAndHours.length; i++) {
      let regularTotal = 0;
      regularTotal =
        employeesAndHours[i].sunday.regular +
        employeesAndHours[i].monday.regular +
        employeesAndHours[i].tuesday.regular +
        employeesAndHours[i].wednesday.regular +
        employeesAndHours[i].thursday.regular +
        employeesAndHours[i].friday.regular +
        employeesAndHours[i].saturday.regular;
      employeesAndHours[i].regularTotal = regularTotal;

      let laborerTotal = 0;
      laborerTotal =
        parseFloat(employeesAndHours[i].sunday.laborer) +
        parseFloat(employeesAndHours[i].monday.laborer) +
        parseFloat(employeesAndHours[i].tuesday.laborer) +
        parseFloat(employeesAndHours[i].wednesday.laborer) +
        parseFloat(employeesAndHours[i].thursday.laborer) +
        parseFloat(employeesAndHours[i].friday.laborer) +
        parseFloat(employeesAndHours[i].saturday.laborer);
      employeesAndHours[i].laborerTotal = laborerTotal;
      let operatorTotal = 0;
      operatorTotal =
        parseFloat(employeesAndHours[i].sunday.operator) +
        parseFloat(employeesAndHours[i].monday.operator) +
        parseFloat(employeesAndHours[i].tuesday.operator) +
        parseFloat(employeesAndHours[i].wednesday.operator) +
        parseFloat(employeesAndHours[i].thursday.operator) +
        parseFloat(employeesAndHours[i].friday.operator) +
        parseFloat(employeesAndHours[i].saturday.operator);
      employeesAndHours[i].operatorTotal = operatorTotal;
    }
    this.setState(prevState => {
      return { employeesAndHours: employeesAndHours };
    });
  }
  componentDidMount() {
    this.calculateTotals(this.props.employees);
  }
  getJobStrings = jobs => {
    if (jobs.length == 0) {
      return "";
    } else {
      let jobString = "";
      for (let i = 0; i < jobs.length; i++) {
        if (i == 0) {
          jobString = jobs[i].jobId;
        } else {
          jobString = jobString + ", " + jobs[i].jobId;
        }
      }
      return jobString;
    }
  };
  prepareExport = () => {
    let exportData = [];
    for (let i = 0; i < this.state.employeesAndHours.length; i++) {
      let regular = {
        name: this.state.employeesAndHours[i].name + " reg",
        sundayHours: this.state.employeesAndHours[i].sunday.regular,
        sundayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].sunday.regularJobs
        ),
        mondayHours: this.state.employeesAndHours[i].monday.regular,
        mondayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].monday.regularJobs
        ),
        tuesdayHours: this.state.employeesAndHours[i].tuesday.regular,
        tuesdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].tuesday.regularJobs
        ),
        wednesdayHours: this.state.employeesAndHours[i].wednesday.regular,
        wednesdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].wednesday.regularJobs
        ),
        thursdayHours: this.state.employeesAndHours[i].thursday.regular,
        thursdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].thursday.regularJobs
        ),
        fridayHours: this.state.employeesAndHours[i].friday.regular,
        fridayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].friday.regularJobs
        ),
        saturdayHours: this.state.employeesAndHours[i].saturday.regular,
        saturdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].saturday.regularJobs
        ),
        total: this.state.employeesAndHours[i].regularTotal,
      };
      console.log("pushing regular to array");
      exportData.push(regular);
      let laborer = {
        name: this.state.employeesAndHours[i].name + " lab",
        sundayHours: this.state.employeesAndHours[i].sunday.laborer,
        sundayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].sunday.laborerJobs
        ),
        mondayHours: this.state.employeesAndHours[i].monday.laborer,
        mondayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].monday.laborerJobs
        ),
        tuesdayHours: this.state.employeesAndHours[i].tuesday.laborer,
        tuesdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].tuesday.laborerJobs
        ),
        wednesdayHours: this.state.employeesAndHours[i].wednesday.laborer,
        wednesdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].wednesday.laborerJobs
        ),
        thursdayHours: this.state.employeesAndHours[i].thursday.laborer,
        thursdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].thursday.laborerJobs
        ),
        fridayHours: this.state.employeesAndHours[i].friday.laborer,
        fridayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].friday.laborerJobs
        ),
        saturdayHours: this.state.employeesAndHours[i].saturday.laborer,
        saturdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].saturday.laborerJobs
        ),
        total: this.state.employeesAndHours[i].laborerTotal,
      };
      console.log("pushing laborer to array");
      exportData.push(laborer);
      let operator = {
        name: this.state.employeesAndHours[i].name + " op",
        sundayHours: this.state.employeesAndHours[i].sunday.operator,
        sundayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].sunday.operatorJobs
        ),
        mondayHours: this.state.employeesAndHours[i].monday.operator,
        mondayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].monday.operatorJobs
        ),
        tuesdayHours: this.state.employeesAndHours[i].tuesday.operator,
        tuesdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].tuesday.operatorJobs
        ),
        wednesdayHours: this.state.employeesAndHours[i].wednesday.operator,
        wednesdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].wednesday.operatorJobs
        ),
        thursdayHours: this.state.employeesAndHours[i].thursday.operator,
        thursdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].thursday.operatorJobs
        ),
        fridayHours: this.state.employeesAndHours[i].friday.operator,
        fridayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].friday.operatorJobs
        ),
        saturdayHours: this.state.employeesAndHours[i].saturday.operator,
        saturdayJobs: this.getJobStrings(
          this.state.employeesAndHours[i].saturday.operatorJobs
        ),
        total: this.state.employeesAndHours[i].operatorTotal,
      };
      console.log("pushing operator to array");
      exportData.push(operator);
      // let regularExportEmployee = {name:this.state.employeesAndHours[i].name,monday:}
    }
    this.setState({ exportReady: true, exportData: exportData });
  };

  render() {
    return (
      <div>
        <div className="exportContainer">
          {this.state.exportReady ? (
            <ExportCSV
              csvData={this.state.exportData}
              filename={this.props.sunday + "-" + this.props.saturday}
            />
          ) : (
            <Button variant='contained' color='inherit' style={{marginBottom:"1em"}}
              onClick={this.prepareExport}
            >
              Prepare Export
            </Button>
          )}
        </div>
        <table className="table table-hover weeklyTable">
          <thead className="thead-dark">
            <tr>
              <th className="sort" scope="col">
                Name
              </th>
              <th className="sort" scope="col">
                Sunday
              </th>
              <th className="sort" scope="col">
                Monday
              </th>
              <th className="sort" scope="col">
                Tuesday
              </th>
              <th className="sort" scope="col">
                Wednesday
              </th>
              <th className="sort" scope="col">
                Thursday
              </th>
              <th className="sort" scope="col">
                Friday
              </th>
              <th className="sort" scope="col">
                Saturday
              </th>
              <th className="sort" scope="col">
                Total
              </th>
            </tr>
          </thead>

          {this.state.employeesAndHours.map((employee, i) => (
            <tbody key={i}>
              <tr
                style={{
                  backgroundColor: "#ffd965",
                }}
              >
                <th scope="row">{employee.name} - Regular</th>
                <th scope="row">
                  {employee.sunday.regular == 0 ||
                  employee.sunday.regular == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.sunday.regular}
                      <br />
                      Jobs:{" "}
                      {employee.sunday.regularJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.monday.regular == 0 ||
                  employee.monday.regular == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.monday.regular}
                      <br />
                      Jobs:{" "}
                      {employee.monday.regularJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.tuesday.regular == 0 ||
                  employee.tuesday.regular == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.tuesday.regular}
                      <br />
                      Jobs:{" "}
                      {employee.tuesday.regularJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.wednesday.regular == 0 ||
                  employee.wednesday.regular == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.wednesday.regular}
                      <br />
                      Jobs:{" "}
                      {employee.wednesday.regularJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.thursday.regular == 0 ||
                  employee.thursday.regular == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.thursday.regular}
                      <br />
                      Jobs:{" "}
                      {employee.thursday.regularJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.friday.regular == 0 ||
                  employee.friday.regular == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.friday.regular}
                      <br />
                      Jobs:{" "}
                      {employee.friday.regularJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.saturday.regular == 0 ||
                  employee.saturday.regular == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.saturday.regular}
                      <br />
                      Jobs:{" "}
                      {employee.saturday.regularJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">{employee.regularTotal}</th>
              </tr>

              <tr
                style={{
                  backgroundColor: "#9cc2e5",
                }}
              >
                <th scope="row">{employee.name} - Laborer</th>
                <th scope="row">
                  {employee.sunday.laborer == 0 ||
                  employee.sunday.laborer == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.sunday.laborer}
                      <br />
                      Jobs:{" "}
                      {employee.sunday.laborerJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.monday.laborer == 0 ||
                  employee.monday.laborer == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.monday.laborer}
                      <br />
                      Jobs:{" "}
                      {employee.monday.laborerJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.tuesday.laborer == 0 ||
                  employee.tuesday.laborer == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.tuesday.laborer}
                      <br />
                      Jobs:{" "}
                      {employee.tuesday.laborerJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.wednesday.laborer == 0 ||
                  employee.wednesday.laborer == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.wednesday.laborer}
                      <br />
                      Jobs:{" "}
                      {employee.wednesday.laborerJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.thursday.laborer == 0 ||
                  employee.thursday.laborer == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.thursday.laborer}
                      <br />
                      Jobs:{" "}
                      {employee.thursday.laborerJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.friday.laborer == 0 ||
                  employee.friday.laborer == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.friday.laborer}
                      <br />
                      Jobs:{" "}
                      {employee.friday.laborerJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.saturday.laborer == 0 ||
                  employee.saturday.laborer == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.saturday.laborer}
                      <br />
                      Jobs:{" "}
                      {employee.saturday.laborerJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">{employee.laborerTotal}</th>
              </tr>
              <tr
                style={{
                  backgroundColor: "#92d050",
                }}
                className="bottom-border"
              >
                <th scope="row">{employee.name} - Operator</th>
                <th scope="row">
                  {employee.sunday.operator == 0 ||
                  employee.sunday.operator == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.sunday.operator}
                      <br />
                      Jobs:{" "}
                      {employee.sunday.operatorJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.monday.operator == 0 ||
                  employee.monday.operator == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.monday.operator}
                      <br />
                      Jobs:{" "}
                      {employee.monday.operatorJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.tuesday.operator == 0 ||
                  employee.tuesday.operator == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.tuesday.operator}
                      <br />
                      Jobs:{" "}
                      {employee.tuesday.operatorJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.wednesday.operator == 0 ||
                  employee.wednesday.operator == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.wednesday.operator}
                      <br />
                      Jobs:{" "}
                      {employee.wednesday.operatorJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.thursday.operator == 0 ||
                  employee.thursday.operator == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.thursday.operator}
                      <br />
                      Jobs:{" "}
                      {employee.thursday.operatorJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.friday.operator == 0 ||
                  employee.friday.operator == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.friday.operator}
                      <br />
                      Jobs:{" "}
                      {employee.friday.operatorJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">
                  {employee.saturday.operator == 0 ||
                  employee.saturday.operator == undefined ? (
                    "0"
                  ) : (
                    <div>
                      Hours: {employee.saturday.operator}
                      <br />
                      Jobs:{" "}
                      {employee.saturday.operatorJobs.map((job, i) => (
                        <div key={i} className="jobLinkButtonContainer">
                          <button
                            className="btn btn-link jobLinKButton"
                            onClick={() => {
                              this.props.viewTimesheet(
                                job.jobId,
                                job.timesheetId
                              );
                            }}
                          >
                            {job.jobId}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </th>
                <th scope="row">{employee.operatorTotal}</th>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
    );
  }
}

export default WeeklyTable;
