import React, { Component } from "react";
import formatDate from "./../../common/formatDate";

export default class ViewBoreValidation extends Component {
  render() {
    return (
      <div className="viewReport" style={{ backgroundColor: "white" }}>
        <div
          className="dashboardPanelHeading"
          style={{ backgroundColor: "white" }}
        >
          <h3 className="display-5">
            Boring Procedure Validation For Job #{this.props.timesheet.job.id}
          </h3>
          <p className="lead">
            Created by: {this.props.timesheet.createdBy.name}
            <br />
            Date: {formatDate(this.props.timesheet.datetime)}
          </p>
        </div>
        <p className="lead">
          Order Number: {this.props.timesheet.boreValidation.orderNumber}
        </p>
        <hr />
        <h3 className="display-5">Crew Member's Names</h3>
        {this.props.timesheet.boreValidation.employees.map((employee, i) => (
          <p key={i} className="lead">
            {employee.name}
          </p>
        ))}
        <p className="lead">
          Inspector's Name: {this.props.timesheet.boreValidation.inspectorsName}
        </p>
        <p className="lead">
          Description Of Work: {this.props.timesheet.boreValidation.description}
        </p>
        <p className="lead">
          Address of Work: {this.props.timesheet.boreValidation.address}
        </p>
        <p className="lead">
          Bore Method Used: {this.props.timesheet.boreValidation.boreMethod}
        </p>
        <img
          className="boreValidationSignature"
          src={this.props.timesheet.boreValidation.signature}
        />
      </div>
    );
  }
}
