export function compareTime(a, b) {
  if (a.dateTime < b.dateTime) return 1;
  if (a.dateTime > b.dateTime) return -1;
  return 0;
}

export function compareTimeReverse(a, b) {
  if (a.dateTime < b.dateTime) return -1;
  if (a.dateTime > b.dateTime) return 1;
  return 0;
}

export function compareId(a, b) {
  if (parseInt(a.id) < parseInt(b.id)) return 1;
  if (parseInt(a.id) > parseInt(b.id)) return -1;
  return 0;
}
export function compareIdReverse(a, b) {
  if (parseInt(a.id) < parseInt(b.id)) return -1;
  if (parseInt(a.id) > parseInt(b.id)) return 1;
  return 0;
}