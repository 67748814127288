import React from "react";

export default function Overwrite(props) {
  return (
    <div className="card overwrite">
      <p className="lead">
        There is already a timesheet for that job, on that day, either change
        the date or edit the old one.
      </p>
      <div className="confirmationControls">
        <button
          className="btn btn-secondary "
          style={{ marginTop: "1em" }}
          onClick={() => {
            props.confirmation(false);
          }}
        >
          Okay
        </button>
      </div>
    </div>
  );
}
