import React from "react";

export default function ViewImage(props) {
  return (
    <div className="viewImage">
      <div className="controlBar row">
        <div className="col-md-6">
          <button onClick={() => { props.close(); }} className="btn btn-secondary btn-sm exit">
            Close
          </button>
        </div>
        <div className="col-md-6">
          <a href={props.photo.photo} target="_blank" className="btn btn-secondary btn-sm exit">
            Download
          </a>
        </div>
      </div>
      <img src={props.photo.photo} />
    </div>
  );
}
