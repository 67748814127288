import React, { Component } from "react";
import { Link } from "react-router-dom";
import version from "../../version";
import { withFirebase } from "./../../firebase/index";
import { compose } from "redux";
import WeekGlance from "./WeekGlance";
import ViewPrintableReport from "./reports/ViewPrintableReport";
import formatDate from "../common/formatDate";
import Button from '@material-ui/core/Button';
import dayjs from "dayjs";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      iterator: 0,
      selectedReports: "timesheets",
      selectedReport: null,
      viewReport: false,
    };
  }
  viewReportToggle = () => {
    this.setState((prevState) => {
      return { viewReport: !prevState.viewReport };
    });
  };
  componentDidMount() {
    this.getRecent();
    // SET GETJOBS TO GET EXPENSE REPORTS THAT AREN't IN THE REGULAR NODE, If Expense Reports Are Missing, Run This Function To Put It In Regular Node, Not Under Job.
    // this.getJobs();
  }
  getJobs = () => {
    let jobs = [];
    this.props.firebase.db
      .collection("jobs")
      .get()
      .then(
        function(jobSnapshot) {
          jobSnapshot.forEach(function(job) {
            jobs.push(job.data());
          });
          for (let i = 0; i < jobs.length; i++) {
            this.moveOldNodes(jobs[i].id);
          }
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting jobs");
      });
  };
  moveOldNodes = (job) => {
    this.props.firebase.db
      .collection("jobs")
      .doc(job)
      .collection("expense-reports")
      .get()
      .then(
        function(expenseSnapshot) {
          expenseSnapshot.forEach(
            function(expense) {
              let id = expense.data().id;
              this.props.firebase.db
                .collection("expense-reports")
                .get()
                .then(
                  function(expenseReports) {
                    let shouldI = true;
                    expenseReports.forEach(
                      function(expenseReport) {
                        if (
                          expenseReport.data().id == expense.data().id &&
                          expenseReport.data().job == expense.data().job
                        ) {
                          shouldI = false;
                          return;
                        }
                      }.bind(this)
                    );
                    
                    if (shouldI) {
                      this.props.firebase.db
                        .collection("expense-reports")
                        .doc(id)
                        .set(expense.data())
                        .catch(function(err) {
                          console.log("error writing reg expense");
                        });
                    } else {
                      return;
                    }
                  }.bind(this)
                )
                .catch(function(err) {
                  console.log("error getting reg expense");
                });
            }.bind(this)
          );
        }.bind(this)
      )
      .catch(function(err) {
        console.log("error getting jobs: " + err.message);
      });
  };
  increaseIterator = () => {
    this.setState(
      (prevState) => {
        return { iterator: prevState.iterator + 1, loading: true };
      },
      () => {
        this.getRecent();
      }
    );
  };
  decreaseIterator = () => {
    this.setState(
      (prevState) => {
        return { iterator: prevState.iterator - 1, loading: true };
      },
      () => {
        this.getRecent();
      }
    );
  };
  getRecentTimesheets = () => {
    return new Promise(
      function(res, rej) {
        let timesheets = [];

        this.props.firebase.db
          .collection("timesheets")
          .where("datetime", ">=", this.state.weekEnd.valueOf())
          .orderBy("datetime", "desc")
          .get()
          .then(
            function(timesheetsSnapshot) {
              timesheetsSnapshot.forEach(
                function(timesheet) {
                  if (
                    timesheet.data().datetime < this.state.weekStart.valueOf()
                  ) {
                    timesheets.push(timesheet.data());
                  }
                }.bind(this)
              );
              res(timesheets);
            }.bind(this)
          )
          .catch(function(err) {
            console.log("error getting timesheets" + err.message);
            rej("err");
          });
      }.bind(this)
    );
  };
  getRecentExpenseReports = () => {
    return new Promise(
      function(res, rej) {
        let expenseReports = [];

        this.props.firebase.db
          .collection("expense-reports")
          .where("dateTimeOfCharge", ">=", this.state.weekEnd.valueOf())
          .orderBy("dateTimeOfCharge", "desc")
          .get()
          .then(
            function(timesheetsSnapshot) {
              timesheetsSnapshot.forEach(
                function(expenseReport) {
                  if (
                    expenseReport.data().dateTimeOfCharge <
                    this.state.weekStart.valueOf()
                  ) {
                    expenseReports.push(expenseReport.data());
                  }
                }.bind(this)
              );
              res(expenseReports);
            }.bind(this)
          )
          .catch(function(err) {
            console.log("error getting timesheets" + err.message);
            rej(err);
          });
      }.bind(this)
    );
  };
  getRecent = () => {
    let amountOfWeeks = this.state.iterator - 1;

    let today = dayjs();
    let weekEnd = today.add(amountOfWeeks, 'week');
    let weekStart = weekEnd.add(1, 'week');

    this.setState({ weekStart: weekStart, weekEnd: weekEnd }, () => {
      this.getRecentTimesheets().then(
        function(res) {
          this.setState(
            { timesheets: res },
            function() {
              this.getRecentExpenseReports().then(
                function(res) {
                  this.setState({
                    expenseReports: res,
                    loading: false,
                    weekEnd: weekEnd,
                    weekStart: weekStart,
                  });
                }.bind(this)
              );
            }.bind(this)
          );
        }.bind(this)
      );
    });
  };
  changeSelectedReports = (type) => {
    this.setState({ selectedReports: type });
  };
  setActiveReport = (report) => {
    this.setState((prevState) => {
      return { selectedReport: report, viewReport: true };
    });
  };
  render() {
    let viewReport, loading, timeControls;
    if (this.state.viewReport) {
      if (this.state.selectedReports == "timesheets") {
        viewReport = (
          <ViewPrintableReport
            report={this.state.selectedReport}
            exit={() => {
              this.viewReportToggle();
            }}
            type="timesheet"
          />
        );
      } else if (this.state.selectedReports == "expenseReports") {
        viewReport = (
          <ViewPrintableReport
            report={this.state.selectedReport}
            exit={() => {
              this.viewReportToggle();
            }}
            type="expenseReport"
          />
        );
      }
    } else {
      viewReport = "";
    }
    if (this.state.iterator == 0) {
      timeControls = (
        <Button onClick={this.decreaseIterator} variant="contained" color="default">
          {"<-"} Back
        </Button>
      );
    } else {
      timeControls = (
        <div>
          <Button onClick={this.decreaseIterator} variant="contained" color="default" style={{ marginRight: "1em"}}>
            {" "}
            {"<-"} Back{" "}
          </Button>
          <Button onClick={this.increaseIterator} variant="contained" color="default">
            Forward ->
          </Button>
        </div>
      );
    }
    if (this.state.loading) {
      loading = <i className="spinner fas fa-circle-notch fa-spin" />;
    } else {
      if (this.state.selectedReports == "timesheets") {
        loading = (
          <WeekGlance
            type={this.state.selectedReports}
            reports={this.state.timesheets}
            setActive={(report) => {
              this.setActiveReport(report);
            }}
          />
        );
      } else if (this.state.selectedReports == "expenseReports") {
        loading = (
          <WeekGlance
            type={this.state.selectedReports}
            reports={this.state.expenseReports}
            setActive={(report) => {
              this.setActiveReport(report);
            }}
          />
        );
      }
    }
    return (
      <div>
        <h3 className="display-5">
          Hello, Admin, here are some helpful links:
        </h3>
        <br />
        <div className="links">
          <Button component={Link} to="/dashboard/reports/job" variant="contained" color="inherit" style={{ marginRight: "1em" }}>
            Job Reports
          </Button>
          <Button component={Link} to="/dashboard/jobs" variant="contained" color="inherit" style={{ marginRight: "1em" }}>
            Jobs
          </Button>
          <Button component={Link} to="/dashboard/employees" variant="contained" color="inherit">
            Employees
          </Button>
        </div>
        <div style={{ margin: "1em" }} className="recentReports">
          <h3 className="display-5 recentReportsText">
            Recent Reports
          </h3>
          {timeControls}

          <form>
            <label className="radio-inline recentReportsText">
              <input
                onChange={() => {
                  this.changeSelectedReports("timesheets");
                }}
                type="radio"
                name="timesheets"
                checked={
                  this.state.selectedReports == "timesheets" ? true : false
                }
              />
              Timesheets
            </label>
            <label className="radio-inline recentReportsText">
              <input
                onChange={() => {
                  this.changeSelectedReports("expenseReports");
                }}
                type="radio"
                name="expenseReports"
                checked={
                  this.state.selectedReports == "expenseReports" ? true : false
                }
              />
              Expense Reports
            </label>
          </form>
          <p className="recentReportsText">
            This week's {this.state.selectedReports}
          </p>
          <p className="recentReportsText">
            {" "}
            Showing {this.state.selectedReports} from{" "}
            {formatDate(this.state.weekEnd)} to{" "}
            {formatDate(this.state.weekStart)}
          </p>
          {loading}
          {viewReport}
        </div>
        Version: {version}
      </div>
    );
  }
}

export default compose(withFirebase)(Home);
