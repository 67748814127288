import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function CancelTIPConfirmation(props) {
  return (
    <Dialog
      className="dialog-padding"
      open={props.open}
      onClose={() => props.close()}
      aria-labelledby="cancel-tip-dialog-title"
      aria-describedby="cancel-tip-dialog-description"
    >
      <DialogTitle id="cancel-tip-confirmation-dialog-title">Are You Sure You Want To <b>Cancel</b> This Timesheet?</DialogTitle>
      <DialogActions>
        <Button onClick={() => props.answer(true)} color="inherit" variant="contained">
          Yes
        </Button>
        <Button onClick={() => props.answer(false)} color="inherit" variant="contained">
          No
        </Button>
        <Button onClick={() => props.answer(false)} color="inherit" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
