import React from "react";
import { Link } from "react-router-dom";

export default function LastJob(props) {
  let link = "/employee-dashboard/jobs/" + props.job;
  return (
    <div>
      Last Worked Job: <Link to={link}>{props.job}</Link>
    </div>
  );
}
