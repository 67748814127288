import React, { Component } from "react";
import formatDate from "./formatDate";

class EquipmentTable extends Component {
  constructor() {
    super();
    this.state = {
      selectedEquipment: {},
    };
    this.selectEquipment = this.selectEquipment.bind(this);
  }
  selectEquipment(event) {
    if (this.state.selectedEquipment == event) {
      this.props.selectEquipment(null);
      this.setState({ selectedEquipment: {} });
    } else {
      this.props.selectEquipment(event);
      this.setState({ selectedEquipment: event });
    }
  }
  render() {
    return (
      <div>
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th />
              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("id");
                }}
              >
                Equipment Name
              </th>

              <th
                className="sort"
                scope="col"
                onClick={() => {
                  this.props.sort("date");
                }}
              >
                Date Created
              </th>
            </tr>
          </thead>
          <tbody>
            {this.props.equipment.map((equipment, i) => (
              <tr
                key={i}
                style={{
                  backgroundColor:
                    this.state.selectedEquipment.name == equipment.name
                      ? "#ccc"
                      : undefined,
                }}
              >
                <td>
                  <input
                    type="checkbox"
                    checked={
                      this.state.selectedEquipment.name == equipment.name
                        ? true
                        : false
                    }
                    id="selectJob"
                    onClick={() => {
                      this.selectEquipment(equipment);
                    }}
                  />
                </td>
                <th scope="row">{equipment.name}</th>
                <td>{formatDate(equipment.dateCreated)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default EquipmentTable;
