import React from "react";

function jobPhoto(props) {
  let content;
  if (props.selected) {
    //draw border
    content = (
      <div
        onClick={() => {
          props.click(props.photoType);
        }}
        className="jobPhotoDisplay card selectedPhoto">
        <img className="jobPhotoImage" src={props.photo} />
      </div>
    );
  } else {
    //no border
    content = (
      <div
        onClick={() => {
          props.click(props.photoType);
        }}
        className="jobPhotoDisplay card"
      >
        <img className="jobPhotoImage" src={props.photo} />
      </div>
    );
  }
  return <div>{content}</div>;
}
export default jobPhoto;
