import React, { Component } from "react";
import ExpenseReportTableItem from "./ExpenseReportTableItem";

class ExpenseReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: true,
      expenseReports: [],
      selectedReport: null
    };
    this.sortExpenseReports = this.sortExpenseReports.bind(this);
    this.compareTime = this.compareTime.bind(this);
    this.compareTimeReverse = this.compareTimeReverse.bind(this);
    this.openExpenseReport = this.openExpenseReport.bind(this);
  }
  closeExpenseReport() {
    this.setState(
      prevState => {
        return { viewExpenseReport: false, selectedReport: null };
      },
      function() {
        this.props.closeReport();
      }.bind(this)
    );
  }
  openExpenseReport(expenseReport) {
    this.setState(
      prevState => {
        return { viewExpenseReport: true, selectedReport: expenseReport };
      },
      function() {
        this.props.viewReport(this.state.selectedReport);
      }.bind(this)
    );
  }
  sortExpenseReports() {
    console.log("sorting expense reports: " + this.state.sort);
    let expenseReports = this.state.expenseReports;
    if (this.state.sort) {
      expenseReports.sort(this.compareTime);
      this.setState(prevState => {
        return { expenseReports: expenseReports, sort: !prevState.sort };
      });
    } else {
      expenseReports.sort(this.compareTimeReverse);
      this.setState(prevState => {
        return { expenseReports: expenseReports, sort: !prevState.sort };
      });
    }
  }
  compareTime(a, b) {
    console.log("comparing time: " + a.dateTimeOfCharge);

    if (a.dateTimeOfCharge < b.dateTimeOfCharge) return 1;
    if (a.dateTimeOfCharge > b.dateTimeOfCharge) return -1;
    return 0;
  }
  compareTimeReverse(a, b) {
    console.log("comparing time reverse");
    if (a.dateTimeOfCharge < b.dateTimeOfCharge) return -1;
    if (a.dateTimeOfCharge > b.dateTimeOfCharge) return 1;
    return 0;
  }
  componentDidMount() {
    this.setState({ expenseReports: this.props.expenseReports });
  }
  render() {
    return (
      <div className="jobReportContainer">
        <h3 className="display-5">Expense Reports:</h3>
        <hr />
        <a
          className="btn btn-secondary"
          onClick={() => {
            this.sortExpenseReports();
          }}
        >
          Sort
        </a>
        <br />
        <table className="table table-hover">
          <thead className="thead-dark">
            <tr>
              <th className="sort" scope="col">
                Date
              </th>
              <th className="sort" scope="col">
                Type
              </th>
              <th className="sort" scope="col">
                Amount
              </th>
              <th className="sort" scope="col">
                Employee
              </th>
              <th className="sort" scope="col">
                Receipt
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.expenseReports.map((expenseReport, i) => (
              <ExpenseReportTableItem
                background={i % 2 == 0 ? "dark" : "light"}
                expenseReport={expenseReport}
                open={expenseReport => this.openExpenseReport(this.state.expenseReports[i])}
                key={i}
              />
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default ExpenseReportTable;
