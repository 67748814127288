import React, { Component } from "react";
import { Route } from "react-router-dom";
import AdminToolbar from "./toolbar/Toolbar";
import Jobs from "./jobs/Jobs";
import ReportsHome from "./reports/Home";
import Employees from "./employees/Employees";
import ArchivedJobs from "./jobs/archivedJobs/ArchivedJobs";
import ViewArchivedJob from "./jobs/archivedJobs/ViewArchivedJob";
import ViewJob from "./jobs/ViewJob";
import Home from "./Home";
import "./dashboard.css";
import Maintenance from "./maintenance/Maintenance";
import ViewEquipment from "./maintenance/ViewEquipment";
import Account from "./account/Account";

class Dashboard extends Component {
  render() {
    return (
      <div>
        <AdminToolbar />
        <div className="dashboardContent">
          <Route exact path="/dashboard" component={Home} />
          <Route exact path="/dashboard/jobs" component={Jobs} />
          <Route exact path="/dashboard/jobs/:job" component={ViewJob} />
          <Route
            exact
            path="/dashboard/archived-jobs/:job"
            component={ViewArchivedJob}
          />
          <Route path="/dashboard/reports" component={ReportsHome} />
          <Route exact path="/dashboard/employees" component={Employees} />
          <Route exact path="/dashboard/maintenance" component={Maintenance} />
          <Route
            exact
            path="/dashboard/maintenance/equipment/:equipment"
            component={ViewEquipment}
          />
          <Route path="/dashboard/account" component={Account} />
          <Route
            exact
            path="/dashboard/archived-jobs"
            component={ArchivedJobs}
          />
        </div>
      </div>
    );
  }
}

export default Dashboard;
