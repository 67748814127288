import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class AddJob extends Component {
  constructor() {
    super();
    this.state = {
      id: undefined,
      type: "",
      date: new Date(),
      dateTime: 0,
      dateString: "",
      valid: false,
      message: "",
      state: "Michigan",
      title: "",
      shopTime: false
    };
    this.onChange = this.onChange.bind(this);
    this.submitJob = this.submitJob.bind(this);
    this.onDateSelect = this.onDateSelect.bind(this);
    this.checkJobId = this.checkJobId.bind(this);
  }
  submitJob(event) {
    event.preventDefault();
    let description;
    if (this.state.description == undefined) {
      description = "";
    } else {
      description = this.state.description;
    }
    if (this.state.dateTime == 0 || this.state.dateString == "") {
      // TODO replace with dayjs
      let date = new Date();
      let dateTime = date.getTime();
      let dateObj = new Date(date);
      let dateString = dateObj.toDateString();

      let job = {
        id: this.state.id,
        type: this.state.type,
        date: dateString,
        dateTime: dateTime,
        state: this.state.state,
        description: description,
        title: this.state.title,
        shopTime: this.state.shopTime
      };
      this.props.submitJob(job);
      return;
    }

    let job = {
      id: this.state.id,
      type: this.state.type,
      date: this.state.dateString,
      dateTime: this.state.dateTime,
      state: this.state.state,
      description: description,
      title: this.state.title,
      shopTime: this.state.shopTime
    };
    this.props.submitJob(job);
  }

  onDateSelect(date) {
    // TODO replace with dayjs
    console.log("selected date: " + date);
    let dateTime = date.getTime();
    let dateObj = new Date(date);
    let dateString = dateObj.toDateString();
    console.log("datetime is : " + dateTime);
    this.setState(prevState => {
      return { date: date, dateTime: dateTime, dateString: dateString };
    });
  }
  checkJobId(jobId) {
    let reg = new RegExp(/^[0-9\-]+$/);
    if (reg.test(jobId)) {
      //good
      for (let i = 0; i < this.props.jobs.length; i++) {
        if (this.props.jobs[i].id === jobId) {
          //found job with that id
          this.setState({
            id: jobId,
            valid: false,
            message: "Job Already Exists With That ID"
          });
          return;
        }
      }
      if (this.state.type !== "") {
        this.setState({ id: jobId, valid: true, message: "" });
      } else {
        this.setState({ id: jobId, valid: false, message: "" });
      }
    } else {
      //not just numbers and dashes
      console.log("not just dashes and numbers");
      this.setState(prevState => {
        return {
          message: "Job ID Must Only Be Numbers and Dashes",
          id: jobId,
          valid: false
        };
      });
    }
  }
  onChange(event) {
    switch (event.target.name) {
      case "jobId":
        this.checkJobId(event.target.value);
        break;
      case "jobType":
        if (event.target.value == "") {
          return;
        } else {
          if (this.state.id !== 0) {
            this.setState({ type: event.target.value, valid: true });
          } else {
            this.setState({ type: event.target.value, valid: false });
          }
        }
        break;
      case "selectState":
        this.setState({ state: event.target.value });
      case "jobDescription":
        this.setState({ description: event.target.value });
        break;
      case "jobTitle":
        this.setState({ title: event.target.value });
        break;
      case "shopTime":
        if (event.target.value === "Yes") {
          this.setState({ shopTime: true });
        } else if (event.target.value === "No") {
          this.setState({ shopTime: false });
        }
        break;
      default:
        break;
    }

    // console.log('current state is: JOBID: ' + this.state.job.id + ' JOBTYPE: ' + this.state.job.type);
  }
  render() {
    let submit, message;
    if (this.state.valid) {
      submit = (
        <input
          type="submit"
          className="btn btn-secondary addJobButton"
          style={{ marginTop: "1em" }}
        />
      );
    } else {
      submit = (
        <input
          type="submit"
          className="btn btn-secondary addJobButton"
          disabled
          style={{ marginTop: "1em" }}
        />
      );
    }
    if (this.state.message !== "") {
      message = <div className="alert alert-danger">{this.state.message}</div>;
    } else {
      message = "";
    }
    return (
      <div className="card addJob">
        <p className="lead">Add Job</p>
        {message}
        <form onSubmit={this.submitJob}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="jobId">
              Job ID
            </label>
            <input
              className="form-control col-sm-9"
              type="text"
              id="jobId"
              onChange={this.onChange}
              placeholder="Job ID"
              name="jobId"
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="jobId">
              Job Title
            </label>
            <input
              className="form-control col-sm-9"
              type="text"
              id="jobTitle"
              onChange={this.onChange}
              placeholder="Job Title"
              name="jobTitle"
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="jobType">
              Job Type
            </label>
            <select
              className="form-control col-sm-9"
              id="jobType"
              onChange={this.onChange}
              name="jobType"
            >
              <option />
              <option>MTS</option>
              <option>MTS Drilling</option>
            </select>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="shopTime">
              Shop Time?
            </label>
            <select
              className="form-control col-sm-9"
              id="shopTime"
              onChange={this.onChange}
              name="shopTime"
            >
              <option>No</option>
              <option>Yes</option>
            </select>
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="datePicker">
              Date
            </label>
            <DatePicker
              style={{ width: "100%" }}
              id="datePicker"
              selected={this.state.date}
              onChange={this.onDateSelect}
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="selectState">
              State
            </label>
            <select
              className="form-control col-sm-9"
              id="selectState"
              name="selectState"
              onChange={this.onChange}
            >
              <option>Michigan</option>
              <option>Indiana</option>
              <option>Ohio</option>
              <option>Illinois</option>
            </select>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label" htmlFor="jobDescription">
              Description
            </label>
            <textarea
              className="form-control col-sm-8"
              type="text"
              id="jobDescription"
              onChange={this.onChange}
              placeholder="Job Description"
              name="jobDescription"
            />
          </div>
          <div className="form-group row">{submit}</div>
        </form>
      </div>
    );
  }
}

export default AddJob;
