import React from "react";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConfirmReset(props) {
  return (
    <Dialog
      open={true}
      onClose={() => props.close()}
      aria-labelledby="confirm-reset-dialog-title"
      aria-describedby="confirm-reset-dialog-description"
    >
      <DialogTitle id="reset-timesheet-confirmation-dialog-title">Are You Sure You Want To <b>Reset</b> The Clock In Time?</DialogTitle>
      <DialogActions>
        <Button onClick={() => props.answer(true)} color="inherit" variant="contained">
          Yes
        </Button>
        <Button onClick={() => props.answer(false)} color="inherit"  variant="contained">
          No
        </Button>
        <Button onClick={() => props.answer(false)} color="inherit" variant="contained">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
