import React, { Component } from "react";

export default class BorePreShotSecond extends Component {
  render() {
    return (
      <div>
        <h3 className="display-5">
          TALKING POINTS FOR EXPLAINING THE NEW DIRECTIONAL BORE WALK CHECKLIST
        </h3>
        <b>
          • This Checklist was developed to aid in the directional boring
          process, and to help ensure that every bore shot is performed safely
          and follows company standards.
        </b>
        <p>
          • This Checklist is to be used for every directional bore shot which
          is performed.
        </p>
        <p>
          {" "}
          • This Checklist is to be retained with the bore crew until the
          booklet is full. It then can be disposed of properly.
        </p>
        <p>
          • This Checklist is a tool, and does not take the place of Form 2834.
        </p>
        <h3 className="display-5">
          DIRECTIONAL BORE PRE-SHOT CHECKLIST DESCRIPTION
        </h3>
        <p>
          • Job Location - The address or job name where the bore shot is being
          performed.
        </p>
        <p>
          • Date - The date that the bore walk and bore shot is being performed.
        </p>
        <p>
          • Walked by - The crew members who walked the bore shot. This is the
          PIC, directional drill Locator, and directional drill Operator. This
          section must contain both a printed name and signature.
        </p>
        <p>
          • Bore Size, Length, Location - The size of the pipe or cable
          installed, the length of the bore shot, and the specific location on
          the job where the shot was made (street name and addresses).
        </p>

        <h3 className="display-5">YES OR NO CHECKBOXES</h3>
        <p>
          • Tailboard Conference Completed - A tailboard conference should be
          completed daily with all crew members, and additionally when there is
          a stoppage of the job or a change in scope which would warrant another
          tailboard being completed.
        </p>
        <p>
          {" "}
          • Job site secure with proper delineators and caution tape - The bore
          machine should be set in place prior to the walk being performed, with
          delineators and caution tape around the bore machine creating a safety
          awareness zone for the public or other employees.
        </p>
        <p>
          • MISS DIG Completed - Ensure that MISS DIG has been notified, and
          that we have good ticket information and locates for safe completion
          of the bore shot.
        </p>
        <p>
          • Gas and all other utility crossings verified/spotted per GOM
          standards “Damage Prevention and Third Party Activity” - Ensure that
          all utility crossings have been spotted correctly per the appropriate
          GOM Standards.{" "}
        </p>
        <p>
          • Walked with Crew Leader using prints and service cards - The bore
          locator, operator, and PIC must use all available construction prints
          and service cards to verify that the gas facilities which have been
          exposed are correct. Use service cards to verify that the gas service
          line spotted is the correct size and type for the address, and make
          visual comparisons with what is exposed at the building or riser. If
          the service card states that the gas service is inserted, confirm by
          finding the insertion point into the casing material. Ensure that any
          gas mains spotted coincide with the size and type identified on the
          construction print. Maintain the 12 inch observation zone while boring
          out and pulling the product back. • Have the sewers been
          located/verified - All sewer leads and mains being crossed must be
          located. If the bore shot will cross the sewer lead or main with less
          than 2 feet of separation between the bore head and/or reamer, the
          sewer lead or main must be hand exposed and observed to prevent damage
          during boring out and pulling the product back.
        </p>
        <p>
          {" "}
          • Proposed route staked/painted according to GOM standards “Boring
          Near Existing Gas Facilities” - The proposed bore route must be staked
          or painted according to the GOM Standards prior to the bore shot walk
          being performed.
        </p>
        <p>
          {" "}
          • Evidence of unmarked utilities - While walking the bore shot path,
          look for evidence of unmarked utilities such as but not limited to:
          fire hydrants, manholes, catch basins communication vaults, evidence
          of existing trench lines, drops on utility poles, above ground
          pedestals or transformers, etc. If evidence of unmarked utilities is
          found, STOP THE JOB and reassess the bore shot line. Seek guidance
          from a field leader if needed.
        </p>
        <p>
          • Safety - Safety is the number one priority, and one of the key
          reasons for this Checklist both being developed and used.{" "}
        </p>
        <p>
          • Voltage stake is in place and strike alert has been tested - The
          voltage stake must be used correctly. Drive the stake into the ground
          and test the system before starting the directional bore shot.{" "}
        </p>
        <p>
          • Radio communications verified - Verify that the radios, headset, and
          microphone are working properly. Headphones must be worn by the
          directional drill operator.{" "}
        </p>
        <p>
          • Locator calibration verified - Calibrate the locator with the sonde
          to ensure that it is working properly. Verify the calibration before
          each bore shot. • Remote lockout tested - Test the remote lockout
          before each shot is performed. The locator will have the remote
          lockout box with him at all times while the bore shot is in progress.{" "}
        </p>
        <p>
          • Comments - Use this section for any bore shot notes, AOC’s, or other
          information which is valuable or pertinent to the bore shot being
          performed.
        </p>
      </div>
    );
  }
}
