import React, { Component } from "react";
import SignatureCanvas from "react-signature-canvas";
import Button from "@material-ui/core/Button";
import "./sigGrab.css";

class SignatureGrab extends Component {
  constructor() {
    super();
    this.state = {
      trimmedDataURL: null
    };

    this.clear = this.clear.bind(this);
    this.trim = this.trim.bind(this);
    this.close = this.close.bind(this);
    this.sigPad = {};
  }
  close() {
    this.props.close();
  }
  clear() {
    this.sigPad.clear();
  }
  trim() {
    this.props.grab(
      this.props.worker,
      this.sigPad.getTrimmedCanvas().toDataURL("image/png")
    );
  }
  render() {
    let { trimmedDataURL } = this.state;
    return (
      <div className="container signatureGrab">
        Signature Grab For {this.props.worker.name}
        <div className="sigContainer">
          <SignatureCanvas
            canvasProps={{ className: "sigPad" }}
            ref={ref => {
              this.sigPad = ref;
            }}
          />
        </div>
        <div>
          <Button variant="contained" color="inherit" onClick={this.close}>
            Close
          </Button>
          <Button variant="contained" color="inherit" onClick={this.clear}>
            Clear
          </Button>
          <Button variant="contained" color="inherit" onClick={this.trim}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}

export default SignatureGrab;
