import React, { Component } from "react";

class EditEmployee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeName: "",
      type: "",
      foremanPassword: "",
      foremanEmail: "",
      valid: false,
      isForeman: false
    };
    this.onChange = this.onChange.bind(this);
    this.submitEmployee = this.submitEmployee.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
  }
  componentDidMount() {
    if (this.props.employee.type === "foreman") {
      this.setState(prevState => {
        return {
          employeeFirstName: this.props.employee.firstName,
          employeeLastName: this.props.employee.lastName,
          isForeman: true,
          foremanEmail: this.props.employee.email,
          foremanPassword: this.props.employee.password
        };
      });
    } else {
      this.setState(prevState => {
        return {
          employeeFirstName: this.props.employee.firstName,
          employeeLastName: this.props.employee.lastName,
          isForeman: false,
          foremanEmail: this.props.employee.email,
          foremanPassword: this.props.employee.password
        };
      });
    }
  }
  onChange(event) {
    if (event.target.name == "foreman") {
      this.setState(prevState => {
        return { isForeman: true, valid: false };
      });
      return;
    } else if (event.target.name == "worker") {
      if (
        this.state.employeeLastName !== "" &&
        this.state.employeeFirstName !== "" &&
        this.state.foremanEmail !== "" &&
        this.state.foremanPassword !== ""
      ) {
        this.setState(prevState => {
          return { isForeman: false, valid: true };
        });
      } else {
        this.setState(prevState => {
          return { isForeman: false, valid: false };
        });
      }
      return;
    }
    if (event.target.name == "employeeFirstName") {
      if (
        this.state.foremanPassword !== "" &&
        event.target.value !== "" &&
        this.state.foremanEmail !== "" &&
        this.state.employeeLastName !== ""
      ) {
        this.setState({ [event.target.name]: event.target.value, valid: true });
      } else {
        this.setState({
          [event.target.name]: event.target.value,
          valid: false
        });
      }
    }
    if (event.target.name == "employeeLastName") {
      if (
        this.state.foremanPassword !== "" &&
        event.target.value !== "" &&
        this.state.foremanEmail !== "" &&
        this.state.employeeFirstName !== ""
      ) {
        this.setState({ [event.target.name]: event.target.value, valid: true });
      } else {
        this.setState({
          [event.target.name]: event.target.value,
          valid: false
        });
      }
    }
    if (event.target.name == "foremanPassword") {
      if (
        this.state.employeeLastName !== "" &&
        this.state.employeeFirstName !== "" &&
        event.target.value !== "" &&
        this.state.foremanEmail !== ""
      ) {
        this.setState({ valid: true, [event.target.name]: event.target.value });
      } else {
        this.setState({
          valid: false,
          [event.target.name]: event.target.value
        });
      }
    }
    if (event.target.name == "foremanEmail") {
      if (this.validateEmail(event.target.value)) {
        if (
          this.state.foremanPassword !== "" &&
          this.state.employeeLastName !== "" &&
          this.state.employeeFirstName !== ""
        ) {
          this.setState({
            [event.target.name]: event.target.value,
            valid: true
          });
        } else {
          this.setState({
            [event.target.name]: event.target.value,
            valid: false
          });
        }
      } else {
        this.setState({
          [event.target.name]: event.target.value,
          valid: false
        });
      }
    }
  }
  validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  submitEmployee(event) {
    event.preventDefault();
    let employee;
    let name = this.state.employeeFirstName + " " + this.state.employeeLastName;
    if (this.state.isForeman) {
      employee = {
        firstName: this.state.employeeFirstName,
        lastName: this.state.employeeLastName,
        name: name,
        type: "foreman",
        password: this.state.foremanPassword,
        email: this.state.foremanEmail
      };
    } else {
      employee = {
        firstName: this.state.employeeFirstName,
        lastName: this.state.employeeLastName,
        name: name,
        type: "worker",
        password: this.state.foremanPassword,
        email: this.state.foremanEmail
      };
    }
    this.props.submitEdit(employee);
  }
  render() {
    let submit, foreman;
    if (this.state.valid) {
      submit = (
        <input
          type="submit"
          className="btn btn-secondary addJobButton"
          style={{ marginTop: "1em" }}
        />
      );
    } else {
      submit = (
        <input
          type="submit"
          className="btn btn-secondary addJobButton"
          disabled
          style={{ marginTop: "1em" }}
        />
      );
    }

    return (
      <div className="card addEmployee">
        <p className="lead">Edit Employee</p>
        <form onSubmit={this.submitEmployee}>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="jobId">
              First Name
            </label>
            <input
              className="form-control col-sm-9"
              type="text"
              id="employeeFirstName"
              onChange={this.onChange}
              placeholder="First Name"
              value={this.state.employeeFirstName}
              name="employeeFirstName"
            />
          </div>
          <div className="form-group row">
            <label className="col-sm-3 col-form-label" htmlFor="jobId">
              Last Name
            </label>
            <input
              className="form-control col-sm-9"
              type="text"
              id="employeeLastName"
              onChange={this.onChange}
              placeholder="Last Name"
              value={this.state.employeeLastName}
              name="employeeLastName"
            />
          </div>
          <div className="form-group row">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                checked={!this.state.isForeman ? true : false}
                name="worker"
                type="checkbox"
                id="worker"
                value="worker"
                onChange={this.onChange}
              />
              <label className="form-check-label" htmlFor="worker">
                Worker
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                checked={this.state.isForeman ? true : false}
                name="foreman"
                type="checkbox"
                id="foreman"
                value="foreman"
                onChange={this.onChange}
              />
              <label className="form-check-label" htmlFor="foreman">
                Foreman
              </label>
            </div>
          </div>
          <div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label" htmlFor="foremanEmail">
                Email
              </label>
              <input
                className="form-control col-sm-9"
                type="email"
                id="foremanEmail"
                onChange={this.onChange}
                placeholder="Email"
                name="foremanEmail"
                value={this.state.foremanEmail}
              />
            </div>

            <div className="form-group row">
              <label
                className="col-sm-3 col-form-label"
                htmlFor="foremanPassword"
              >
                Password
              </label>
              <input
                className="form-control col-sm-9"
                type="text"
                id="foremanPassword"
                onChange={this.onChange}
                placeholder="Password"
                name="foremanPassword"
                value={this.state.foremanPassword}
              />
            </div>
          </div>
          <div className="form-group row">{submit}</div>
        </form>
      </div>
    );
  }
}

export default EditEmployee;
