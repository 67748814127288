export const getTimesheets = (job, db) => {
  return new Promise(function(res, rej) {
    db.collection("archived-jobs")
      .doc(job)
      .collection("timesheets")
      .get()
      .then(function(timesheetSnapshot) {
        let timesheets = [];

        timesheetSnapshot.forEach(function(timesheet) {
          timesheets.push(timesheet.data());
        });
        res(timesheets);
        // this.getTailboardsForArchiving(job, timesheets);
      })
      .catch(function(err) {
        console.log("error getting timesheets");
        rej(err);
      });
  });
};

export const getTailboards = (job, timesheets, db) => {
  return new Promise(function(res, rej) {
    for (let i = 0; i < timesheets.length; i++) {
      db.collection("archived-jobs")
        .doc(job)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .collection("tailboards")
        .doc(timesheets[i].id)
        .get()
        .then(function(tailboard) {
          if (tailboard.exists) {
            db.collection("jobs")
              .doc(job)
              .collection("timesheets")
              .doc(timesheets[i].id)
              .collection("tailboards")
              .doc(timesheets[i].id)
              .set(tailboard.data())
              .then(function() {
                db.collection("archived-jobs")
                  .doc(job)
                  .collection("timesheets")
                  .doc(timesheets[i].id)
                  .collection("tailboards")
                  .doc(timesheets[i].id)
                  .delete()
                  .then(function() {
                    console.log("deleted tailboard");
                  })
                  .catch(function(err) {
                    console.log("error deleting tailboard: " + err.message);
                    rej(err);
                  });
              })
              .catch(function(err) {
                console.log("error setting archive tailboard");
                rej(err);
              });
          }
        })
        .catch(function(err) {
          console.log("error getting tailboard: " + err.message);
          rej(err);
        });
    }

    res("moved tailboards");
  });

  // this.getJobPhotosForArchiving(job);
};
export const moveTimesheets = (job, timesheets, db) => {
  return new Promise(function(res, rej) {
    for (let i = 0; i < timesheets.length; i++) {
      db.collection("jobs")
        .doc(job)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .set(timesheets[i])
        .then(
          function() {
            console.log("wrote archived-jobs timesheet");
            db.collection("archived-jobs")
              .doc(job)
              .collection("timesheets")
              .doc(timesheets[i].id)
              .delete()
              .then(function() {
                console.log("deleted old timesheet node");
              })
              .catch(function(err) {
                console.log("error deleting old timesheet node");
                rej(err);
              });
          }.bind(this)
        )
        .catch(function(err) {
          console.log("error writing archived-jobs timesheet");
          rej(err);
        });
    }
    res("moved timesheets");
  });
  // this.moveExpenseReports(job);
};
export const moveDocuments = (job, db) => {
  return new Promise(function(res, rej) {
    db.collection("archived-jobs")
      .doc(job.id)
      .collection("documents")
      .get()
      .then(function(documentsSnapshot) {
        documentsSnapshot.forEach(function(document) {
          db.collection("jobs")
            .doc(job.id)
            .collection("documents")
            .doc(document.data().documentName)
            .set(document.data())
            .then(function() {
              db.collection("archived-jobs")
                .doc(job.id)
                .collection("documents")
                .doc(document.data().documentName)
                .delete()
                .then(function() {
                  console.log("deleted old document");
                })
                .catch(function(err) {
                  console.log("error deleting old document: " + err.message);
                  rej(err);
                });
            })
            .catch(function(err) {
              console.log("error setting archived jobs document");
              rej(err);
            });
        });
        res("moved and deleted documents");
      })
      .catch(function(err) {
        console.log("error getting documents");
        rej(err);
      });
    // this.moveAndDeleteJobNode(job);
  });
};
export const moveJobNode = (job, db) => {
  return new Promise(function(res, rej) {
    db.collection("archived-jobs")
      .doc(job.id)
      .get()
      .then(function(jobObject) {
        if (jobObject.exists) {
          db.collection("jobs")
            .doc(job.id)
            .set(jobObject.data())
            .then(function() {
              db.collection("archived-jobs")
                .doc(job.id)
                .delete()
                .then(function() {
                  console.log("done migrating job");

                  res("moved job node");
                })
                .catch(function(err) {
                  console.log("error deleting old job node: " + err.message);
                  rej(err);
                });
            })
            .catch(function(err) {
              console.log("error setting new job node");
              rej(err);
            });
        }
      })
      .catch(function(err) {
        console.log("error getting job node");
        rej(err);
      });
  });
};

export const moveAndDeleteExpenseReports = (expenseReports, job, db) => {
  return new Promise(function(res, rej) {
    for (let i = 0; i < expenseReports.length; i++) {
      db.collection("jobs")
        .doc(job.id)
        .collection("expense-reports")
        .doc(expenseReports[i].id)
        .set(expenseReports[i])
        .then(function() {
          db.collection("archived-jobs")
            .doc(job.id)
            .collection("expense-reports")
            .doc(expenseReports[i].id)
            .delete()
            .then(function() {
              console.log("deleted old expense report node");
            })
            .catch(function(err) {
              console.log("error deleting old expense report: " + err.message);
              rej(err);
            });
        })
        .catch(function(err) {
          console.log(
            "error setting archived jobs expense report: " + err.message
          );
          rej(err);
        });
    }
    res("moved Expense Reports");
  });
  // this.moveDocuments(job);
};
export const getExpenseReports = (job, db) => {
  return new Promise(function(res, rej) {
    let expenseReports = [];
    db.collection("archived-jobs")
      .doc(job.id)
      .collection("expense-reports")
      .get()
      .then(function(expenseReportSnapshot) {
        expenseReportSnapshot.forEach(function(expenseReport) {
          expenseReports.push(expenseReport.data());
        });
        res(expenseReports);
        // this.moveAndDeleteExpenseReports(expenseReports, job);
      })
      .catch(function(err) {
        console.log("error getting expense reports: " + err.message);
        rej(err);
      });
  });
};
export const archiveBorePreShot = (job, timesheets, db) => {
  return new Promise(function(res, rej) {
    for (let i = 0; i < timesheets.length; i++) {
      db.collection("archived-jobs")
        .doc(job)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .collection("borePreShot")
        .doc(timesheets[i].id)
        .get()
        .then(function(tailboard) {
          if (tailboard.exists) {
            db.collection("jobs")
              .doc(job)
              .collection("timesheets")
              .doc(timesheets[i].id)
              .collection("borePreShot")
              .doc(timesheets[i].id)
              .set(tailboard.data())
              .then(function() {
                db.collection("archived-jobs")
                  .doc(job)
                  .collection("timesheets")
                  .doc(timesheets[i].id)
                  .collection("borePreShot")
                  .doc(timesheets[i].id)
                  .delete()
                  .then(function() {
                    console.log("deleted borePreShot");
                  })
                  .catch(function(err) {
                    console.log("error deleting borePreShot: " + err.message);
                    rej(err);
                  });
              })
              .catch(function(err) {
                console.log("error setting archive borePreShot");
                rej(err);
              });
          }
        })
        .catch(function(err) {
          console.log("error getting borePreShot: " + err.message);
          rej(err);
        });
    }

    res("moved borePreShot");
  });
};
export const archiveBoringProcedure = (job, timesheets, db) => {
  return new Promise(function(res, rej) {
    for (let i = 0; i < timesheets.length; i++) {
      db.collection("archived-jobs")
        .doc(job)
        .collection("timesheets")
        .doc(timesheets[i].id)
        .collection("boringProcedure")
        .doc(timesheets[i].id)
        .get()
        .then(function(tailboard) {
          if (tailboard.exists) {
            db.collection("jobs")
              .doc(job)
              .collection("timesheets")
              .doc(timesheets[i].id)
              .collection("boringProcedure")
              .doc(timesheets[i].id)
              .set(tailboard.data())
              .then(function() {
                db.collection("archived-jobs")
                  .doc(job)
                  .collection("timesheets")
                  .doc(timesheets[i].id)
                  .collection("boringProcedure")
                  .doc(timesheets[i].id)
                  .delete()
                  .then(function() {
                    console.log("deleted boringProcedure");
                  })
                  .catch(function(err) {
                    console.log(
                      "error deleting boringProcedure: " + err.message
                    );
                    rej(err);
                  });
              })
              .catch(function(err) {
                console.log("error setting archive boringProcedure");
                rej(err);
              });
          }
        })
        .catch(function(err) {
          console.log("error getting boringProcedure: " + err.message);
          rej(err);
        });
    }

    res("moved boringProcedures");
  });
};
// TODO nearly identical with getJobPhotosForArchiving in ArchiveJobsFunctions
export const getJobPhotosForArchiving = (job, timesheet, db) => {
  return new Promise(function(res, rej) {
    db.collection("archived-jobs")
      .doc(job)
      .collection("timesheets")
      .doc(timesheet.id)
      .collection("job-photos")
      .get()
      .then(function(jobPhotoSnapshot) {
        jobPhotoSnapshot.forEach(function(jobPhoto) {
          console.log("jobPhotosnapshot recieved");
          if (jobPhoto.exists) {
            let photo = jobPhoto.data();
            let id;
            if (photo.id == undefined || photo.id == null) {
              id = photo.type;
            } else {
              id = photo.id;
            }
            db.collection("jobs")
              .doc(job)
              .collection("timesheets")
              .doc(timesheet.id)
              .collection("job-photos")
              .doc(id)
              .set(photo)
              .then(function() {
                console.log("set archived jobs job photo node");
                db.collection("archived-jobs")
                  .doc(job)
                  .collection("timesheets")
                  .doc(timesheet.id)
                  .collection("job-photos")
                  .doc(id)
                  .delete()
                  .then(function() {
                    console.log("deleted job photo");
                  })
                  .catch(function(err) {
                    console.log(
                      "error deleting old job photo node : " + err.message
                    );
                    rej(err);
                  });
              })
              .catch(function(err) {
                console.log(
                  "error setting archived-jobs jobPhoto: " + err.message
                );
                rej(err);
              });
          }
        });
      })
      .catch(function(err) {
        console.log("error getting job photos: " + err.message);
        rej(err);
      });
    res("photos moved");
  });

  // this.moveTimesheetNodes(job);
};
const checkIds = (newId, jobs) => {
  console.log("checking ids");
  return new Promise(function(res, rej) {
    let decider;
    for (let i = 0; i < jobs.length; i++) {
      if (jobs[i].id == newId) {
        decider = false;
        res(decider);
        return;
      }
    }
    if (decider !== false) {
      decider = true;
    }
    res(decider);
  });
};
export const checkJobId = (newId, db) => {
  return new Promise(function(res, rej) {
    db.collection("archived-jobs")
      .get()
      .then(function(jobsSnapshot) {
        let jobs = [];

        jobsSnapshot.forEach(function(job) {
          jobs.push(job.data());
        });
        res(checkIds(newId, jobs));
      })
      .catch(function(err) {
        console.log("error getting job ids : " + err.message);
      });
  });
};
